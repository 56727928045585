type DayOfTheWeek = "sun" | "mon" | "tues" | "wed" | "thur" | "fri" | "sat";

const DAYS_OF_THE_WEEK: DayOfTheWeek[] = [
    "sun",
    "mon",
    "tues",
    "wed",
    "thur",
    "fri",
    "sat",
];

export interface BaseCalendarDay {
    date: number;
    month: number;
    year: number;
    dayOfTheWeek: DayOfTheWeek;
    label: string | undefined;
    otherLabels: string[];
    collectId: string;
    collect: string;
    weekOfTheYear: number;
    antiphonId: string;
    eveOf?: BaseCalendarDay;
    dayOfTheYear: number;
    sentenceId: string;
    hymnId: string;
    easterOctave: boolean;
    blackLetterDay?: BlackLetterDay;
    weekBeforeChristTheKing?: boolean;
}

export interface BlackLetterDay {
    id: string;
    title: string;
    optional?: boolean;
}

export interface Calendar {
    year: number;
    days: BaseCalendarDay[];
}

export type PrayerHour = "mattins" | "evensong" | "compline";

const getEaster = function Easter(Y: number) {
    var C = Math.floor(Y / 100);
    var N = Y - 19 * Math.floor(Y / 19);
    var K = Math.floor((C - 17) / 25);
    var I = C - Math.floor(C / 4) - Math.floor((C - K) / 3) + 19 * N + 15;
    I = I - 30 * Math.floor(I / 30);
    I =
        I -
        Math.floor(I / 28) *
            (1 -
                Math.floor(I / 28) *
                    Math.floor(29 / (I + 1)) *
                    Math.floor((21 - N) / 11));
    var J = Y + Math.floor(Y / 4) + I + 2 - C + Math.floor(C / 4);
    J = J - 7 * Math.floor(J / 7);
    var L = I - J;
    var M = 3 + Math.floor((L + 40) / 44);
    var D = L + 28 - 31 * Math.floor(M / 4);

    return { date: D, month: M };
};

const createCalendar = (year: number) => {
    let day = new Date(`January 1, ${year}`);

    const calendar: Calendar = {
        year,
        days: [],
    };

    let weekOfTheYear = 0;
    let dayOfTheYear = 0;
    while (day.getFullYear() === year) {
        if (DAYS_OF_THE_WEEK[day.getDay()] === "sun") {
            weekOfTheYear++;
        }

        calendar.days.push({
            year,
            date: day.getDate(),
            dayOfTheWeek: DAYS_OF_THE_WEEK[day.getDay()],
            month: day.getMonth() + 1,
            label: undefined,
            otherLabels: [],
            collectId: "",
            collect: "",
            weekOfTheYear,
            antiphonId: "",
            dayOfTheYear,
            sentenceId: "",
            hymnId: "",
            easterOctave: false,
            weekBeforeChristTheKing: false,
        });

        // Increment the day
        day = new Date(day);
        day.setDate(day.getDate() + 1);
        dayOfTheYear++;
    }

    return calendar;
};

const getDateMethods = (
    calendar: Calendar,
    date: number,
    month: number,
    skip: boolean
) => {
    return {
        maybePlaceLabel: (label: string) => {
            const day = calendar.days.find(
                (day) => day.date === date && day.month === month
            );

            const valid = day !== undefined && day.label === undefined && !skip;

            if (day !== undefined) {
                if (valid) {
                    day.label = label;
                }
            }

            return {
                else: () => {
                    getDateMethods(calendar, date, month, valid || skip);
                },
            };
        },
        placeLabel: (label: string) => {
            const day = calendar.days.find(
                (day) => day.date === date && day.month === month
            );

            const valid = day !== undefined && day.label === undefined && !skip;

            if (day !== undefined) {
                if (valid) {
                    day.label = label;
                } else {
                    day.otherLabels.push(label);
                }
            }
        },
        plus: (amount: number, unit: "weeks" | "days") => {
            const startIndex = calendar.days.findIndex(
                (day) => day.date === date && day.month === month
            );

            const endIndex = startIndex + amount * (unit === "weeks" ? 7 : 1);
            const day = calendar.days[endIndex];

            if (day) {
                return getDateMethods(calendar, day.date, day.month, skip);
            } else {
                return getDateMethods(calendar, 0, 0, true);
            }
        },
        minus: (amount: number, unit: "days" | "weeks") => {
            return getDateMethods(calendar, date, month, skip).plus(
                -amount,
                unit
            );
        },
        getNthAfter: (amount: number, dayOfTheWeek: DayOfTheWeek) => {
            let index = calendar.days.findIndex(
                (day) => day.date === date && day.month === month
            );

            while (amount !== 0) {
                index++;

                if (!calendar.days[index]) {
                    return getDateMethods(calendar, 0, 0, true);
                }

                if (calendar.days[index].dayOfTheWeek === dayOfTheWeek) {
                    amount--;
                }
            }

            const day = calendar.days[index];

            return getDateMethods(calendar, day.date, day.month, skip);
        },
        getNthBefore: (amount: number, dayOfTheWeek: DayOfTheWeek) => {
            let index = calendar.days.findIndex(
                (day) => day.date === date && day.month === month
            );

            while (amount !== 0) {
                index--;

                if (!calendar.days[index]) {
                    return getDateMethods(calendar, 0, 0, true);
                }

                if (calendar.days[index].dayOfTheWeek === dayOfTheWeek) {
                    amount--;
                }
            }

            const day = calendar.days[index];

            return getDateMethods(calendar, day.date, day.month, skip);
        },
        placeBlackLetterDay: (blackLetterDay: BlackLetterDay) => {
            const day = calendar.days.find(
                (day) => day.date === date && day.month === month
            );

            if (day !== undefined) {
                day.blackLetterDay = blackLetterDay;
            }
        },
    };
};

const modify = (calendar: Calendar) => {
    return {
        cal: modify,
        onDate: (date: number, month: number) => {
            return getDateMethods(calendar, date, month, false);
        },
        onLabel: (label: string) => {
            const day = calendar.days.find(
                (day) => day.label === label || day.otherLabels.includes(label)
            );

            if (day) {
                return getDateMethods(calendar, day.date, day.month, false);
            } else {
                return getDateMethods(calendar, 0, 0, true);
            }
        },
    };
};

export const getLiturgicalYear = (year: number, dateFeasts: boolean) => {
    const calendar = createCalendar(year);

    // The end of Christmas until Ephipany Day
    modify(calendar).onDate(1, 1).placeLabel("circumcision");
    modify(calendar).onDate(6, 1).placeLabel("epiphany");
    modify(calendar)
        .onLabel("epiphany")
        .getNthBefore(1, "sun")
        .placeLabel("christmas-2");

    // Easter and related feasts
    const easter = getEaster(year);
    modify(calendar).onDate(easter.date, easter.month).placeLabel("easter-day");
    modify(calendar)
        .onLabel("easter-day")
        .getNthBefore(1, "fri")
        .placeLabel("good-friday");
    modify(calendar)
        .onLabel("easter-day")
        .getNthAfter(1, "mon")
        .placeLabel("easter-monday");
    modify(calendar)
        .onLabel("easter-day")
        .getNthAfter(1, "tues")
        .placeLabel("easter-tuesday");
    modify(calendar)
        .onLabel("easter-day")
        .getNthBefore(6, "sun")
        .getNthBefore(1, "wed")
        .placeLabel("ash-wednesday");
    modify(calendar)
        .onLabel("easter-day")
        .getNthAfter(6, "thur")
        .placeLabel("ascension");
    modify(calendar)
        .onLabel("easter-day")
        .getNthAfter(7, "sun")
        .placeLabel("whitsun");
    modify(calendar)
        .onLabel("whitsun")
        .getNthAfter(1, "mon")
        .placeLabel("monday-after-whitsun");
    modify(calendar)
        .onLabel("whitsun")
        .getNthAfter(1, "tues")
        .placeLabel("tuesday-after-whitsun");
    modify(calendar)
        .onLabel("easter-day")
        .getNthAfter(8, "sun")
        .placeLabel("trinity-sunday");

    if (dateFeasts) {
        // Minor feasts until advent
        modify(calendar).onDate(25, 1).placeLabel("conversion-of-st-paul");
        modify(calendar).onDate(2, 2).placeLabel("presentation");
        modify(calendar).onDate(24, 2).placeLabel("st-matthias");
        modify(calendar).onDate(25, 3).placeLabel("annunciation");
        modify(calendar).onDate(25, 4).placeLabel("st-mark");
        modify(calendar).onDate(1, 5).placeLabel("st-philip-and-st-james");
        modify(calendar).onDate(11, 6).placeLabel("st-barnabas");
        modify(calendar).onDate(24, 6).placeLabel("st-john-baptist");
        modify(calendar).onDate(29, 6).placeLabel("st-peter");
        modify(calendar).onDate(22, 7).placeLabel("st-mary-magdalene");
        modify(calendar).onDate(25, 7).placeLabel("st-james");
        modify(calendar).onDate(6, 8).placeLabel("transfiguration");
        modify(calendar).onDate(24, 8).placeLabel("st-bartholomew");
        modify(calendar).onDate(14, 9).placeLabel("holy-cross");
        modify(calendar).onDate(21, 9).placeLabel("st-matthew");
        modify(calendar).onDate(29, 9).placeLabel("st-michael-and-all-angels");
        modify(calendar).onDate(18, 10).placeLabel("st-luke");
        modify(calendar).onDate(28, 10).placeLabel("st-simon-and-st-jude");
        modify(calendar).onDate(1, 11).placeLabel("all-saints");
    }

    // Sundays in Lent
    modify(calendar)
        .onLabel("easter-day")
        .getNthBefore(1, "sun")
        .placeLabel("lent-6");
    modify(calendar)
        .onLabel("easter-day")
        .getNthBefore(2, "sun")
        .placeLabel("lent-5");
    modify(calendar)
        .onLabel("easter-day")
        .getNthBefore(3, "sun")
        .placeLabel("lent-4");
    modify(calendar)
        .onLabel("easter-day")
        .getNthBefore(4, "sun")
        .placeLabel("lent-3");
    modify(calendar)
        .onLabel("easter-day")
        .getNthBefore(5, "sun")
        .placeLabel("lent-2");
    modify(calendar)
        .onLabel("easter-day")
        .getNthBefore(6, "sun")
        .placeLabel("lent-1");
    modify(calendar)
        .onLabel("easter-day")
        .getNthBefore(7, "sun")
        .placeLabel("quinquagesima");
    modify(calendar)
        .onLabel("easter-day")
        .getNthBefore(8, "sun")
        .placeLabel("sexagesima");
    modify(calendar)
        .onLabel("easter-day")
        .getNthBefore(9, "sun")
        .placeLabel("septuagesima");

    // Sundays in Epiphany (lower precedence than sundays in Lent)
    modify(calendar)
        .onLabel("epiphany")
        .getNthAfter(1, "sun")
        .maybePlaceLabel("epiphany-1");
    modify(calendar)
        .onLabel("epiphany")
        .getNthAfter(2, "sun")
        .maybePlaceLabel("epiphany-2");
    modify(calendar)
        .onLabel("epiphany")
        .getNthAfter(3, "sun")
        .maybePlaceLabel("epiphany-3");
    modify(calendar)
        .onLabel("epiphany")
        .getNthAfter(4, "sun")
        .maybePlaceLabel("epiphany-4");
    modify(calendar)
        .onLabel("epiphany")
        .getNthAfter(5, "sun")
        .maybePlaceLabel("epiphany-5");
    modify(calendar)
        .onLabel("epiphany")
        .getNthAfter(6, "sun")
        .maybePlaceLabel("epiphany-6");

    // Sundays in Easter
    modify(calendar)
        .onLabel("easter-day")
        .getNthAfter(1, "sun")
        .placeLabel("easter-1");
    modify(calendar)
        .onLabel("easter-day")
        .getNthAfter(2, "sun")
        .placeLabel("easter-2");
    modify(calendar)
        .onLabel("easter-day")
        .getNthAfter(3, "sun")
        .placeLabel("easter-3");
    modify(calendar)
        .onLabel("easter-day")
        .getNthAfter(4, "sun")
        .placeLabel("easter-4");
    modify(calendar)
        .onLabel("easter-day")
        .getNthAfter(5, "sun")
        .placeLabel("easter-5");
    modify(calendar)
        .onLabel("easter-day")
        .getNthAfter(6, "sun")
        .placeLabel("easter-6");

    // Christmas Day, and the Season of Advent
    modify(calendar).onDate(25, 12).placeLabel("christmas-day");

    if (dateFeasts) {
        modify(calendar).onDate(30, 11).placeLabel("st-andrew");
        modify(calendar).onDate(21, 12).placeLabel("st-thomas");
    }

    modify(calendar)
        .onLabel("christmas-day")
        .getNthBefore(4, "sun")
        .placeLabel("advent-1");
    modify(calendar)
        .onLabel("christmas-day")
        .getNthBefore(3, "sun")
        .placeLabel("advent-2");
    modify(calendar)
        .onLabel("christmas-day")
        .getNthBefore(2, "sun")
        .placeLabel("advent-3");
    modify(calendar)
        .onLabel("christmas-day")
        .getNthBefore(1, "sun")
        .placeLabel("advent-4");

    // The Season of Christmas up to the end of the year
    if (dateFeasts) {
        modify(calendar).onDate(26, 12).placeLabel("st-stephen");
        modify(calendar).onDate(27, 12).placeLabel("st-john");
        modify(calendar).onDate(28, 12).placeLabel("holy-innocents");
    }
    modify(calendar)
        .onLabel("christmas-day")
        .getNthAfter(1, "sun")
        .placeLabel("christmas-1");

    // Sunday before advent
    modify(calendar)
        .onLabel("advent-1")
        .getNthBefore(1, "sun")
        .placeLabel("sunday-before-advent");

    // Sundays in Trinity (lower precedence than Sundays in Advent)
    for (let i = 1; i <= 27; i++) {
        modify(calendar)
            .onLabel("trinity-sunday")
            .getNthAfter(i, "sun")
            .placeLabel(`trinity-${i}`);
    }

    // Assign the collects
    // NOTE THIS IS A LEGACY OF HOW SINGTHEOFFICE WORKED IN THE FIRST EDITION WITH THE BCP COLLECTS
    let collectId = "christmas-1";
    calendar.days.forEach((day) => {
        if (day.label) {
            day.collectId = day.label;
            collectId = day.collectId;
        } else {
            day.collectId = collectId;
        }
    });

    // Assign the old style invitatory antiphons
    let antiphonId = "christmas";
    let ordinaryCycle = 0;
    let easterOctave = false;
    calendar.days.forEach((day) => {
        if (day.label === "epiphany") {
            antiphonId = "epiphany";
            day.antiphonId = "epiphany";
        } else if (day.label === "epiphany-1") {
            antiphonId = "ordinary";
            day.antiphonId = "epiphany";
            ordinaryCycle = 0;
        } else if (day.label === "transfiguration") {
            day.antiphonId = "epiphany";
        } else if (day.label === "ash-wednesday") {
            antiphonId = "lent";
            day.antiphonId = "lent";
        } else if (day.label === "easter-day") {
            antiphonId = "easter";
            day.antiphonId = "easter";
            easterOctave = true;
            day.easterOctave = true;
        } else if (day.label === "easter-1") {
            day.antiphonId = "easter";
            easterOctave = false;
            day.easterOctave = true;
        } else if (day.label === "ascension") {
            antiphonId = "ascension";
            day.antiphonId = "ascension";
        } else if (day.label === "whitsun") {
            antiphonId = "whitsun";
            day.antiphonId = "whitsun";
        } else if (day.label === "trinity-sunday") {
            antiphonId = "ordinary";
            day.antiphonId = "trinity";
        } else if (
            day.label === "presentation" ||
            day.label === "annunciation"
        ) {
            day.antiphonId = "annunciation";
        } else if (
            day.label === "all-saints" ||
            (day.label && day.label.includes("st-"))
        ) {
            day.antiphonId = "saints";
        } else if (day.label === "advent-1") {
            antiphonId = "advent";
            day.antiphonId = "advent";
        } else if (day.label === "christmas-day") {
            antiphonId = "christmas";
            day.antiphonId = "christmas";
        } else {
            if (antiphonId === "ordinary") {
                day.antiphonId = `ordinary-${ordinaryCycle}`;
                ordinaryCycle = (ordinaryCycle + 1) % 3;
            } else {
                day.antiphonId = antiphonId;
            }

            day.easterOctave = easterOctave;
        }
    });

    const adventSundayIndex = calendar.days.findIndex(
        (day) => day.label === "advent-1"
    );
    for (let offset = -13; offset <= -8; offset++) {
        calendar.days[adventSundayIndex + offset].weekBeforeChristTheKing =
            true;
    }

    // Assign the sentences
    let sentenceId = "christmas";
    calendar.days.forEach((day) => {
        if (day.label === "epiphany") {
            sentenceId = "epiphany";
            day.sentenceId = "epiphany";
        } else if (day.label === "epiphany-1") {
            sentenceId = "ordinary";
            day.sentenceId = "epiphany";
        } else if (day.label === "transfiguration") {
            day.sentenceId = "epiphany";
        } else if (day.label === "ash-wednesday") {
            sentenceId = "lent";
            day.sentenceId = "lent";
        } else if (day.label === "lent-5") {
            sentenceId = "passiontide";
            day.sentenceId = "passiontide";
        } else if (day.label === "easter-day") {
            sentenceId = "easter";
            day.sentenceId = "easter";
        } else if (day.label === "ascension") {
            sentenceId = "ascension";
            day.sentenceId = "ascension";
        } else if (day.label === "whitsun") {
            sentenceId = "whitsun";
            day.sentenceId = "whitsun";
        } else if (day.label === "trinity-sunday") {
            sentenceId = "ordinary";
            day.sentenceId = "trinity";
        } else if (
            day.label === "presentation" ||
            day.label === "annunciation"
        ) {
            day.sentenceId = "annunciation";
        } else if (
            day.label === "all-saints" ||
            (day.label && day.label.includes("st-"))
        ) {
            day.sentenceId = "saints";
        } else if (day.label === "advent-1") {
            sentenceId = "advent";
            day.sentenceId = "advent";
        } else if (day.label === "christmas-day") {
            sentenceId = "christmas";
            day.sentenceId = "christmas";
        } else {
            day.sentenceId = sentenceId;
        }
    });

    // Assign the hymns
    let hymnId = "christmas";
    let passionTideIndex = 1;
    calendar.days.forEach((day) => {
        if (day.label === "epiphany") {
            hymnId = "epiphany";
            day.hymnId = "epiphany";
        } else if (day.label === "epiphany-1") {
            hymnId = "ordinary";
            day.hymnId = "epiphany";
        } else if (day.label === "transfiguration") {
            day.hymnId = "epiphany";
        } else if (day.label === "ash-wednesday") {
            hymnId = "lent-1";
            day.hymnId = "lent-1";
        } else if (day.label === "lent-2") {
            hymnId = "lent-2";
            day.hymnId = "lent-2";
        } else if (day.label === "lent-3") {
            hymnId = "lent-3";
            day.hymnId = "lent-3";
        } else if (day.label === "lent-4") {
            hymnId = "lent-4";
            day.hymnId = "lent-4";
        } else if (day.label === "lent-5") {
            hymnId = "passiontide";
            day.hymnId = "passiontide-0";
        } else if (day.label === "easter-day") {
            hymnId = "easter";
            day.hymnId = "easter";
        } else if (day.label === "ascension") {
            hymnId = "ascension";
            day.hymnId = "ascension";
        } else if (day.label === "whitsun") {
            hymnId = "whitsun";
            day.hymnId = "whitsun";
        } else if (day.label === "trinity-sunday") {
            hymnId = "ordinary";
            day.hymnId = "trinity";
        } else if (day.label === "presentation") {
            day.hymnId = "presentation";
        } else if (day.label === "annunciation") {
            day.hymnId = "annunciation";
        } else if (
            day.label === "all-saints" ||
            (day.label && day.label.includes("st-"))
        ) {
            if (hymnId === "easter") {
                day.hymnId = "apostle-or-evangelist-easter";
            } else {
                day.hymnId = "apostle-or-evangelist";
            }
        } else if (day.label === "advent-1") {
            hymnId = "advent";
            day.hymnId = "advent";
        } else if (day.label === "christmas-day") {
            hymnId = "christmas";
            day.hymnId = "christmas";
        } else {
            if (hymnId === "passiontide") {
                day.hymnId = `passiontide-${passionTideIndex++ % 2}`;
            } else {
                day.hymnId = hymnId;
            }
        }
    });

    // Assign the black letter days
    modify(calendar).onDate(8, 1).placeBlackLetterDay({
        id: "st-lucian",
        title: "Saint Lucian, Priest and Martyr",
    });
    modify(calendar).onDate(13, 1).placeBlackLetterDay({
        id: "st-hilary",
        title: "Saint Hilary, Bishop and Confessor",
    });
    modify(calendar).onDate(18, 1).placeBlackLetterDay({
        id: "st-prisca",
        title: "Saint Prisca, Virgin Martyr",
    });
    modify(calendar).onDate(20, 1).placeBlackLetterDay({
        id: "st-fabian",
        title: "Saint Fabian, Bishop of Rome and Martyr",
    });
    modify(calendar).onDate(21, 1).placeBlackLetterDay({
        id: "st-agnes",
        title: "Saint Agnes, Virgin Martyr",
    });
    modify(calendar).onDate(22, 1).placeBlackLetterDay({
        id: "st-vincent",
        title: "Saint Vincent, Deacon and Martyr",
    });
    modify(calendar).onDate(3, 2).placeBlackLetterDay({
        id: "st-blasius",
        title: "Saint Blasius, Bishop and Martyr",
    });
    modify(calendar).onDate(5, 2).placeBlackLetterDay({
        id: "st-agatha",
        title: "Saint Agatha, Virgin Martyr",
    });
    modify(calendar).onDate(14, 2).placeBlackLetterDay({
        id: "st-blasius",
        title: "Saint Valentine, Bishop and Martyr",
    });
    modify(calendar).onDate(1, 3).placeBlackLetterDay({
        id: "st-david-of-menevia",
        title: "Saint David, Archbishop of Menevia",
    });
    modify(calendar).onDate(2, 3).placeBlackLetterDay({
        id: "st-chad",
        title: "Saint Chad, Bishop of Lichfield",
    });
    modify(calendar).onDate(7, 3).placeBlackLetterDay({
        id: "st-perpetua",
        title: "Saint Perpetua, Martyr",
    });
    modify(calendar).onDate(12, 3).placeBlackLetterDay({
        id: "st-gregory-magnus",
        title: "Saint Gregory Magnus, Bishop and Confessor",
    });
    modify(calendar).onDate(18, 3).placeBlackLetterDay({
        id: "st-edward",
        title: "Saint Edward, King and Martyr",
    });
    modify(calendar).onDate(21, 3).placeBlackLetterDay({
        id: "st-benedict",
        title: "Saint Benedict, Abbot",
    });
    modify(calendar).onDate(3, 4).placeBlackLetterDay({
        id: "st-richard",
        title: "Saint Richard, Bishop of Chichester",
    });
    modify(calendar).onDate(4, 4).placeBlackLetterDay({
        id: "st-ambrose",
        title: "Saint Ambrose, Bishop of Milan",
    });
    modify(calendar).onDate(19, 4).placeBlackLetterDay({
        id: "st-alphege",
        title: "Saint Alphege, Archbishop of Canterbury",
    });
    modify(calendar).onDate(23, 4).placeBlackLetterDay({
        id: "st-george",
        title: "Saint George, Martyr",
    });
    modify(calendar).onDate(3, 5).placeBlackLetterDay({
        id: "invention-of-the-cross",
        title: "Invention of the Cross",
    });
    modify(calendar).onDate(6, 5).placeBlackLetterDay({
        id: "st-john-ante-portnam",
        title: "Saint John the Evangelist ante Portnam Latinam",
    });
    modify(calendar).onDate(19, 5).placeBlackLetterDay({
        id: "st-dunstan",
        title: "Saint Dunstan, Archbishop of Canterbury",
    });
    modify(calendar).onDate(26, 5).placeBlackLetterDay({
        id: "st-augustine-canterbury",
        title: "Saint Augustine, Archbishop of Canterbury",
    });
    modify(calendar).onDate(27, 5).placeBlackLetterDay({
        id: "venerable-bede",
        title: "Venerable Bede, Priest",
    });
    modify(calendar).onDate(1, 6).placeBlackLetterDay({
        id: "st-nicomede",
        title: "Saint Nicomede, Priest and Martyr",
    });
    modify(calendar).onDate(5, 6).placeBlackLetterDay({
        id: "st-boniface",
        title: "Saint Boniface, Bishop of Mainz and Martyr",
    });
    modify(calendar).onDate(17, 6).placeBlackLetterDay({
        id: "st-alban",
        title: "Saint Alban, Martyr",
    });
    modify(calendar).onDate(20, 6).placeBlackLetterDay({
        id: "tr-of-edward",
        title: "Translation of Edward, King of the West Saxons",
    });
    modify(calendar).onDate(2, 7).placeBlackLetterDay({
        id: "visitation",
        title: "Visitation of the Blessed Virgin Mary",
    });
    modify(calendar).onDate(4, 7).placeBlackLetterDay({
        id: "tr-of-st-martin",
        title: "Translation of Saint Martin, Bishop and Confessor",
    });
    modify(calendar).onDate(15, 7).placeBlackLetterDay({
        id: "st-swithun",
        title: "Saint Swithun, Bishop of Winchester",
    });
    modify(calendar).onDate(20, 7).placeBlackLetterDay({
        id: "st-margaret",
        title: "Saint Margaret, Virgin Martyr",
    });
    modify(calendar).onDate(26, 7).placeBlackLetterDay({
        id: "st-anne",
        title: "St Anne, Mother of the Blessed Virgin Mary",
    });
    modify(calendar).onDate(1, 8).placeBlackLetterDay({
        id: "lammas",
        title: "Lammas Day",
    });
    modify(calendar).onDate(7, 8).placeBlackLetterDay({
        id: "holy-name-of-jesus",
        title: "Holy Name of Jesus",
    });
    modify(calendar).onDate(10, 8).placeBlackLetterDay({
        id: "st-laurence",
        title: "Saint Laurence, Archdeacon of Rome and Martyr",
    });
    modify(calendar).onDate(28, 8).placeBlackLetterDay({
        id: "st-augustine-hippo",
        title: "Saint Augustine, Bishop, Confessor, and Doctor of the Church",
    });
    modify(calendar).onDate(28, 8).placeBlackLetterDay({
        id: "st-augustine-hippo",
        title: "Saint Augustine, Bishop, Confessor, and Doctor of the Church",
    });
    modify(calendar).onDate(29, 8).placeBlackLetterDay({
        id: "beheading-of-st-john",
        title: "The Beheading of St John the Baptist",
    });
    modify(calendar).onDate(1, 9).placeBlackLetterDay({
        id: "st-giles",
        title: "Saint Giles, Abbot and Confessor",
    });
    modify(calendar).onDate(7, 9).placeBlackLetterDay({
        id: "st-evurtius",
        title: "Saint Evurtius, Bishop of Orleans",
    });
    modify(calendar).onDate(8, 9).placeBlackLetterDay({
        id: "nativity-of-bvm",
        title: "Nativity of the Blessed Virgin Mary",
    });
    modify(calendar).onDate(17, 9).placeBlackLetterDay({
        id: "st-lambert",
        title: "Saint Lambert, Bishop and Martyr",
    });
    modify(calendar).onDate(26, 9).placeBlackLetterDay({
        id: "st-cyprian",
        title: "Saint Cyprian, Archbishop of Carthage and Martyr",
    });
    modify(calendar).onDate(30, 9).placeBlackLetterDay({
        id: "st-jerome",
        title: "Saint Jerome, Priest, Confessor, and Doctor of the Church",
    });
    modify(calendar).onDate(1, 10).placeBlackLetterDay({
        id: "st-remigius",
        title: "Saint Remigius, Bishop of Rheims",
    });
    modify(calendar).onDate(6, 10).placeBlackLetterDay({
        id: "st-faith",
        title: "Saint Faith, Virgin Martyr",
    });
    modify(calendar).onDate(9, 10).placeBlackLetterDay({
        id: "st-denys",
        title: "Saint Denys the Areopagite, Bishop and Martyr",
    });
    modify(calendar).onDate(13, 10).placeBlackLetterDay({
        id: "tr-of-king-edward-confessor",
        title: "Translation of King Edward the Confessor",
    });
    modify(calendar).onDate(17, 10).placeBlackLetterDay({
        id: "st-etheldreda",
        title: "Saint Etheldreda, Virgin",
    });
    modify(calendar).onDate(25, 10).placeBlackLetterDay({
        id: "st-crispin",
        title: "Saint Crispin, Martyr",
    });
    modify(calendar).onDate(2, 11).placeBlackLetterDay({
        id: "all-souls",
        title: "All Souls",
        optional: true,
    });
    modify(calendar).onDate(6, 11).placeBlackLetterDay({
        id: "st-leonard",
        title: "Saint Leonard, Confessor",
    });
    modify(calendar).onDate(11, 11).placeBlackLetterDay({
        id: "st-martin",
        title: "Saint Martin, Bishop and Confessor",
    });
    modify(calendar).onDate(13, 11).placeBlackLetterDay({
        id: "st-britius",
        title: "Saint Britius, Bishop",
    });
    modify(calendar).onDate(15, 11).placeBlackLetterDay({
        id: "st-machutus",
        title: "Saint Machutus, Bishop",
    });
    modify(calendar).onDate(17, 11).placeBlackLetterDay({
        id: "st-hugh",
        title: "Saint Hugh, Bishop of Lincoln",
    });
    modify(calendar).onDate(20, 11).placeBlackLetterDay({
        id: "st-edmund",
        title: "Saint Edmund, King and Martyr",
    });
    modify(calendar).onDate(22, 11).placeBlackLetterDay({
        id: "st-ceclia",
        title: "Saint Cecilia, Virgin Martyr",
    });
    modify(calendar).onDate(23, 11).placeBlackLetterDay({
        id: "st-clement",
        title: "Saint Clement I, Bishop of Rome and Martyr",
    });
    modify(calendar).onDate(25, 11).placeBlackLetterDay({
        id: "st-catherine",
        title: "Saint Catherine, Virgin Martyr",
    });
    modify(calendar).onDate(6, 12).placeBlackLetterDay({
        id: "st-nicholas",
        title: "Saint Nicholas, Bishop of Myra",
    });
    modify(calendar).onDate(6, 12).placeBlackLetterDay({
        id: "st-nicholas",
        title: "Saint Nicholas, Bishop of Myra",
    });
    modify(calendar).onDate(8, 12).placeBlackLetterDay({
        id: "conception-of-the-bvm",
        title: "Conception of the Blessed Virgin Mary",
    });
    modify(calendar).onDate(13, 12).placeBlackLetterDay({
        id: "st-lucy",
        title: "Saint Lucy, Virgin Martyr",
    });
    modify(calendar).onDate(16, 12).placeBlackLetterDay({
        id: "o-sapientia",
        title: "O Sapientia",
    });
    modify(calendar).onDate(31, 12).placeBlackLetterDay({
        id: "st-silvester",
        title: "Saint Silvester, Bishop of Rome",
    });

    // Set all the eves
    calendar.days.forEach((day, dayIndex) => {
        const nextDay = calendar.days[(dayIndex + 1) % calendar.days.length];

        if ((!day.label || day.label === "holy-saturday") && nextDay.label) {
            day.eveOf = nextDay;
        }
    });

    return calendar;
};

export const getCalendarDay = (
    year: number | string,
    month: number | string,
    date: number | string,
    dateFeasts: boolean
) => {
    const y = parseInt(`${year}`);
    const m = parseInt(`${month}`);
    const d = parseInt(`${date}`);

    const day = getLiturgicalYear(y, dateFeasts).days.find(
        (day) => day.month === m && day.date === d
    );

    if (day) {
        return day;
    } else {
        throw new Error("Could not find that day");
    }
};

export const getMonthName = (month: number) => {
    return [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
    ][month - 1];
};

export const getDayNameFromLabel = (label: string): string => {
    switch (label) {
        case "advent-1":
            return "The First Sunday in Advent";
        case "advent-2":
            return "The Second Sunday in Advent";
        case "advent-3":
            return "The Third Sunday in Advent";
        case "advent-4":
            return "The Fourth Sunday in Advent";
        case "christmas-day":
            return "Christmas Day";
        case "st-stephen":
            return "Saint Stephen the Martyr";
        case "st-john":
            return "Saint John the Evangelist";
        case "holy-innocents":
            return "The Holy Innocents";
        case "christmas-1":
            return "The Sunday after Christmas";
        case "christmas-2":
            return "The Second Sunday after Christmas";
        case "circumcision":
            return "The Circumcision of Christ";
        case "epiphany":
            return "The Epiphany";
        case "epiphany-1":
            return "The First Sunday after the Epiphany";
        case "epiphany-2":
            return "The Second Sunday after the Epiphany";
        case "epiphany-3":
            return "The Third Sunday after the Epiphany";
        case "epiphany-4":
            return "The Fourth Sunday after the Epiphany";
        case "epiphany-5":
            return "The Fifth Sunday after the Epiphany";
        case "epiphany-6":
            return "The Sixth Sunday after the Epiphany";
        case "septuagesima":
            return "Septuagesima Sunday";
        case "sexagesima":
            return "Sexagesima Sunday";
        case "quinquagesima":
            return "Quinquagesima Sunday";
        case "ash-wednesday":
            return "Ash Wednesday";
        case "lent-1":
            return "The First Sunday in Lent";
        case "lent-2":
            return "The Second Sunday in Lent";
        case "lent-3":
            return "The Third Sunday in Lent";
        case "lent-4":
            return "The Fourth Sunday in Lent";
        case "lent-5":
            return "The Fifth Sunday in Lent";
        case "lent-6":
            return "Palm Sunday";
        case "mon-before-easter":
            return "The Monday before Easter";
        case "tues-before-easter":
            return "The Tuesday before Easter";
        case "wed-before-easter":
            return "The Wednesday before Easter";
        case "maundy-thursday":
            return "Maundy Thursday";
        case "good-friday":
            return "Good Friday";
        case "holy-saturday":
            return "Holy Saturday";
        case "easter-day":
            return "Easter Day";
        case "easter-monday":
            return "Monday in Easter Week";
        case "easter-tuesday":
            return "Tuesday in Easter Week";
        case "easter-1":
            return "The First Sunday after Easter";
        case "easter-2":
            return "The Second Sunday after Easter";
        case "easter-3":
            return "The Third Sunday after Easter";
        case "easter-4":
            return "The Fourth Sunday after Easter";
        case "easter-5":
            return "The Fifth Sunday after Easter";
        case "ascension":
            return "The Ascension";
        case "easter-6":
            return "The Sunday after the Ascension";
        case "whitsun":
            return "Whitsunday";
        case "monday-after-whitsun":
            return "Monday in Whitsun Week";
        case "tuesday-after-whitsun":
            return "Tuesday in Whitsun Week";
        case "trinity-sunday":
            return "Trinity Sunday";
        case "sunday-before-advent":
            return "The Sunday next before Advent";

        case "st-andrew":
            return "Saint Andrew the Apostle";
        case "st-thomas":
            return "Saint Thomas the Apostle";
        case "conversion-of-st-paul":
            return "The Conversion of Saint Paul";
        case "presentation":
            return "The Presentation of Christ in the Temple";
        case "st-matthias":
            return "Saint Matthias the Apostle";
        case "annunciation":
            return "The Annunciation of the Blessed Virgin Mary";
        case "st-mark":
            return "Saint Mark the Evangelist";
        case "st-philip-and-st-james":
            return "Saint Philip and Saint James, Apostles";
        case "st-barnabas":
            return "Saint Barnabas the Apostle";
        case "st-john-baptist":
            return "Saint John the Baptist";
        case "st-peter":
            return "Saint Peter the Apostle";
        case "st-mary-magdalene":
            return "Saint Mary Magdalene";
        case "st-james":
            return "Saint James the Apostle";
        case "transfiguration":
            return "The Transfiguration";
        case "holy-cross":
            return "Holy Cross Day";
        case "st-bartholomew":
            return "Saint Bartholomew the Apostle";
        case "st-matthew":
            return "Saint Matthew the Apostle";
        case "st-michael-and-all-angels":
            return "Saint Michael and All Angels";
        case "st-luke":
            return "Saint Luke the Evangelist";
        case "st-simon-and-st-jude":
            return "Saint Simon and Saint Jude, Apostles";
        case "all-saints":
            return "All Saints";
    }

    if (label.includes("trinity")) {
        return (
            "The " +
            [
                "First",
                "Second",
                "Third",
                "Fourth",
                "Fifth",
                "Sixth",
                "Seventh",
                "Eighth",
                "Ninth",
                "Tenth",
                "Eleventh",
                "Twelfth",
                "Thirteenth",
                "Fourteenth",
                "Fifteenth",
                "Sixteenth",
                "Seventeenth",
                "Eighteenth",
                "Nineteenth",
                "Twentieth",
                "Twenty-first",
                "Twenty-second",
                "Twenty-third",
                "Twenty-fourth",
                "Twenty-fifth",
                "Twenty-sixth",
                "Twenty-seventh",
            ][parseInt(label.split("-")[1]) - 1] +
            " Sunday after Trinity"
        );
    }

    return label;
};
