export const TEXT_COLOR = "#231f20";
export const HYPHEN_COLOR = "#222110";
export const RUBRIC_COLOR = "#a54546";

const isWindows = navigator.platform.indexOf("Win") > -1;

//export const PAGE_BACKGROUND = "#29261e";
export const PAGE_BACKGROUND = "#eee8dc";
//export const BACKGROUND_COLOR = "#fffbf0";
export const BACKGROUND_COLOR = "#fafafa";
export const DARKENED_BACKGROUND_COLOR = "#f8f8f8";
export const EVEN_DARKER_BACKGROUND_COLOR = "#f4f4f4";
export const HORIZONTAL_RULE_COLOR = "#c2c2c2";
export const BUTTON_BACKGROUND_COLOR = "#dcdcdc";

export const TEXT_FONT = isWindows ? "SCEV" : "SCEV";
export const TEXT_SIZE = 18;
export const TEXT_INDENT = 48;

export const WIDTH = 680;

export const PRAY_PATH = "pray";
