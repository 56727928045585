import React from "react";

import { Padding } from "../Padding";
import { TEXT_COLOR, TEXT_INDENT, TEXT_SIZE } from "../tokens";
import { isSmallScreen } from "../utils";
import { MarkdownTextLine } from "./MarkdownTextLine";

export const TextBlock: React.FC<{ content: string; dropCap?: boolean }> = (
    props
) => {
    const { content, dropCap } = props;

    if (!content) {
        return <></>;
    }

    return (
        <Padding onlySides>
            <div
                style={{
                    paddingLeft: isSmallScreen()
                        ? TEXT_INDENT / 2
                        : TEXT_INDENT,
                    paddingRight: isSmallScreen()
                        ? TEXT_INDENT / 2
                        : TEXT_INDENT,
                    fontSize: isSmallScreen() ? TEXT_SIZE * 0.9 : TEXT_SIZE,
                    textAlign: "justify",
                    color: TEXT_COLOR,
                    paddingTop: 6,
                    paddingBottom: 6,
                }}
                className="text-block"
                data-content={content}
            >
                <MarkdownTextLine content={content} dropCap={dropCap} />
            </div>
        </Padding>
    );
};
