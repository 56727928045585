import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { useLocalStorage } from "usehooks-ts";
import { COMMONS } from "./calendar/commons";
import { getMorningAndEveningCelebrations } from "./calendar/ordinariate-calendar";

import { DataResponse, getTexts } from "./calendar/get-texts";
import { getOptionsOrd } from "./calendar/lectionary";
import { getCalendarDay } from "./calendar/utils";
import { LiturgyViewer } from "./liturgy-viewer/LiturgyViewer";
import { getOrdinariateLiturgy } from "./ordinariate-liturgy";
import { RUBRIC_COLOR } from "./tokens";

export const Liturgy: React.FC<{}> = () => {
    const {
        year,
        month,
        date,
        label,
        hour: rawHour,
    } = useParams<{
        year: string;
        month: string;
        date: string;
        label: string;
        hour: "mattins" | "evensong" | "first-evensong" | "second-evensong";
    }>();

    const hour = rawHour === "evensong" ? "second-evensong" : rawHour;

    const day = getCalendarDay(year ?? 1, month ?? 1, date ?? 1, false);

    console.log("day", day);

    const lectionaryOptions = getOptionsOrd(day, label ?? "", hour ?? "");

    console.log("lo", lectionaryOptions);

    const [transferEpiphany, setTransferEpiphany] = useLocalStorage(
        "transfer-epiphany",
        "no"
    );

    const [transferAscension, setTransferAscension] = useLocalStorage(
        "transfer-ascension",
        "no"
    );

    const [transferCorpusChristi, setTransferCorpusChristi] = useLocalStorage(
        "transfer-corpus",
        "no"
    );

    const [officiantType, setOfficiantType] = useLocalStorage(
        "officiant-type",
        "priest"
    );

    const celebrationYear = getMorningAndEveningCelebrations(
        day.year,
        transferEpiphany === "yes",
        transferAscension === "yes",
        transferCorpusChristi === "yes"
    );

    const celebrationDay = celebrationYear.find(
        (d) =>
            d.date === day.date && d.month === day.month && d.year === day.year
    );

    const celebrationList =
        hour === "mattins"
            ? celebrationDay?.morningOptions.concat(
                  celebrationDay.morningOutranked
              )
            : celebrationDay?.eveningOptions.concat(
                  celebrationDay.eveningOutranked
              );

    console.log("cl", celebrationList);

    const celebration = celebrationList?.find((c) => c.label === label);

    const isAdvent =
        celebrationList?.find(
            (c) =>
                (c.title.includes("Advent") || c.title.includes("December")) &&
                !c.title.includes("before")
        ) !== undefined;

    const isLent =
        celebrationList?.find(
            (c) =>
                (c.title.includes("Lent") ||
                    c.title.includes("Ash Wednesday") ||
                    c.title.includes("Passion Sunday") ||
                    c.title.includes("Palm Sunday") ||
                    c.title.includes("Holy Week") ||
                    c.title.includes("Good Friday") ||
                    c.title.includes("Holy Saturday")) &&
                !c.title.includes("Before")
        ) !== undefined;

    if (!celebration) {
        throw new Error("Could not find celebration");
    }

    const hymnIds: string[] = [];
    const benAntIds: string[] = [];
    const magAntIds: string[] = [];
    const invAntIds: string[] = [];

    if (hour === "first-evensong") {
        if (celebration.ep1HymnId) {
            hymnIds.push(celebration.ep1HymnId);
        }

        celebration.commons?.forEach((common) => {
            const commonHymnId = COMMONS[common].ep1HymnId;

            if (commonHymnId) {
                hymnIds.push(commonHymnId);
            }
        });

        if (celebration.ep1MagId) {
            magAntIds.push(celebration.ep1MagId);
        }

        celebration.commons?.forEach((common) => {
            const commonaAntId = COMMONS[common].ep1MagId;

            if (commonaAntId) {
                magAntIds.push(commonaAntId);
            }
        });
    }

    if (hour === "mattins") {
        if (celebration.mpHymnId) {
            hymnIds.push(celebration.mpHymnId);
        }

        celebration.commons?.forEach((common) => {
            const commonHymnId = COMMONS[common].mpHymnId;

            if (commonHymnId) {
                hymnIds.push(commonHymnId);
            }
        });

        if (celebration.mpBenId) {
            benAntIds.push(celebration.mpBenId);
        }

        celebration.commons?.forEach((common) => {
            const commonaAntId = COMMONS[common].mpBenId;

            if (commonaAntId) {
                benAntIds.push(commonaAntId);
            }
        });

        if (celebration.invId) {
            invAntIds.push(celebration.invId);
        }

        celebration.commons?.forEach((common) => {
            const commonaAntId = COMMONS[common].invId;

            if (commonaAntId) {
                invAntIds.push(commonaAntId);
            }
        });
    }

    if (hour === "second-evensong") {
        if (celebration.ep2HymnId) {
            hymnIds.push(celebration.ep2HymnId);
        }

        celebration.commons?.forEach((common) => {
            const commonHymnId = COMMONS[common].ep2HymnId;

            if (commonHymnId) {
                hymnIds.push(commonHymnId);
            }
        });

        if (celebration.ep2MagId) {
            magAntIds.push(celebration.ep2MagId);
        }

        celebration.commons?.forEach((common) => {
            const commonaAntId = COMMONS[common].ep2MagId;

            if (commonaAntId) {
                magAntIds.push(commonaAntId);
            }
        });
    }

    const [loadableTexts, setLoadableTexts] = useState<
        | { state: "initial" }
        | { state: "loading" }
        | { state: "loaded"; texts: DataResponse }
    >({ state: "initial" });
    useEffect(() => {
        const get = async () => {
            const texts = await getTexts({
                day,
                hour: hour ?? "mattins",
                celebration: celebration,
                hymnIds,
                benAntIds,
                magAntIds,
                invAntIds,
            });

            setLoadableTexts({ state: "loaded", texts });
        };

        if (loadableTexts.state === "initial") {
            setLoadableTexts({ state: "loading" });

            get();
        }
    }, [
        benAntIds,
        celebration,
        day,
        hour,
        hymnIds,
        invAntIds,
        loadableTexts,
        magAntIds,
    ]);

    console.log("LOADABLE TEXTS", loadableTexts);

    const [selectedOtCanticle, setSelectedOtCanticle] = useState("");

    let officeLabel = "Mattins";

    if (hour !== "mattins") {
        if (
            celebration.type === "sunday" ||
            celebration.type === "solemnity" ||
            celebration.type === "feast"
        ) {
            if (hour === "first-evensong") {
                officeLabel = "First Evensong";
            } else {
                officeLabel = "Second Evensong";
            }
        } else {
            officeLabel = "Evensong";
        }
    }

    const [antiphonOption, setAntiphonOption] = useState("Full");

    const [selectedCommon, setSelectedCommon] = useState(
        celebration.commons?.[0] ?? ""
    );

    const isEastertide =
        celebrationList?.find(
            (celebration) =>
                celebration.title.includes("Easter") ||
                celebration.title.includes("Ascension") ||
                celebration.title === "Rogation Monday" ||
                celebration.title === "Rogation Tuesday" ||
                celebration.title === "Rogation Wednesday" ||
                celebration.title === "Rogation Thursday" ||
                celebration.title === "Rogation Friday" ||
                celebration.title === "Rogation Saturday" ||
                celebration.title.includes("Whit") ||
                celebration.title.includes("Pentecost")
        ) !== undefined && celebration.title !== "Trinity Sunday";

    let celebrationWithCommonSelected = celebration;

    if (selectedCommon !== "") {
        if (celebration.specialManner) {
            // should just work
        } else {
            celebrationWithCommonSelected = {
                ...COMMONS[selectedCommon],
                ...celebration,
            };
        }
    }

    if (isEastertide && COMMONS[selectedCommon]?.easterOverrides) {
        celebrationWithCommonSelected = {
            ...celebrationWithCommonSelected,
            ...COMMONS[selectedCommon].easterOverrides,
        };
    }

    const [selectedPsalms, setSelectedPsalms] = useState("");

    const [simplifiedHaecDies, onSetSimplifiedHaecDies] = useState("Full");

    const [includeIntroduction, onSetIncludeIntroduction] = useState("Omit");

    const [selectedCollect, setSelectedCollect] = useState(
        "1. " +
            celebrationWithCommonSelected.collect
                ?.split(" ")
                .slice(0, 2)
                .join(" ") +
            " ..."
    );

    const liturgy = getOrdinariateLiturgy(
        day,
        hour ?? "mattins",
        celebrationWithCommonSelected,
        loadableTexts.state === "loaded" ? loadableTexts.texts : undefined,
        selectedOtCanticle,
        setSelectedOtCanticle,
        officiantType === "lay" ? "Lay officiant" : "Priest officiant",
        (newType: string) => {
            if (newType === "Lay officiant") {
                setOfficiantType("lay");
            }
            if (newType === "Priest officiant") {
                setOfficiantType("priest");
            }
        },
        antiphonOption,
        setAntiphonOption,
        selectedCommon,
        setSelectedCommon,
        selectedPsalms,
        setSelectedPsalms,
        isAdvent,
        isLent,
        isEastertide,
        simplifiedHaecDies,
        onSetSimplifiedHaecDies,
        includeIntroduction,
        onSetIncludeIntroduction,
        selectedCollect,
        setSelectedCollect
    );

    // useEffect(() => {
    //     const paragraphs =[];

    //     liturgy.forEach(item => {
    //         switch (item.type) {
    //             if (item.type === "heading")
    //         }
    //     })

    //     const doc = new Document({
    //         sections: [
    //             {
    //                 properties: {},
    //                 children: paragraphs, [
    //                     new Paragraph({
    //                         children: [
    //                             new TextRun("Hello World"),
    //                             new TextRun({
    //                                 text: "Foo Bar",
    //                                 bold: true,
    //                                 size: 40,
    //                             }),
    //                             new TextRun({
    //                                 children: [new Tab(), "Github is the best"],
    //                                 bold: true,
    //                             }),
    //                         ],
    //                     }),
    //                 ],
    //             },
    //         ],
    //     });

    //     Packer.toBlob(doc).then((blob) => {
    //         // saveAs from FileSaver will download the file
    //         //saveAs(blob, "example.docx");

    //         var blobUrl = URL.createObjectURL(blob);
    //         console.log(">>", blobUrl);
    //         var link = document.createElement("a"); // Or maybe get it from the current document
    //         link.href = blobUrl;
    //         link.download = "aDefaultFileName.txt";
    //         link.innerHTML = "Click here to download the file";
    //         document.body.appendChild(link);
    //         window.open(blobUrl);
    //     });
    // }, []);

    return (
        <>
            <div
                style={{
                    textAlign: "center",
                    padding: 24,
                    paddingTop: 64,
                }}
            >
                <div style={{ color: RUBRIC_COLOR, fontWeight: "bold" }}>
                    {celebration.regionNote}
                </div>
                <div
                    style={{
                        fontSize: "120%",
                        fontWeight: "bold",
                        lineHeight: 1,
                    }}
                >
                    {officeLabel} of
                </div>
                <div style={{ fontSize: "150%", fontWeight: "bold" }}>
                    {celebration.title}
                    {celebration.subtitle ? `, ${celebration.subtitle}` : ""}
                </div>
                {celebration.commonlyCalled ? (
                    <>
                        <div style={{}}>Commonly Called</div>
                        <div style={{ fontSize: "150%", fontWeight: "bold" }}>
                            {celebration.commonlyCalled}
                        </div>
                    </>
                ) : (
                    <></>
                )}
                <div
                    style={{
                        paddingTop: 4,
                        fontWeight: "bold",
                        color: RUBRIC_COLOR,
                    }}
                >
                    {celebration.optional ? "Optional" : ""}{" "}
                    {
                        {
                            "optional memorial": "",
                            memorial: "Memorial",
                            feast: "Feast",
                            solemnity: "Solemnity",
                            sunday: "",
                            misc: "",
                            feria: "Feria",
                        }[celebration.type]
                    }
                    {celebration.optionalInLent ? " (optional in Lent)" : ""}
                </div>
            </div>
            <LiturgyViewer items={liturgy} />
        </>
    );
};
