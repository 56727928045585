import React from "react";

import { Padding } from "../Padding";
import { Lesson } from "../calendar/get-texts";
import {
    BACKGROUND_COLOR,
    TEXT_COLOR,
    TEXT_INDENT,
    TEXT_SIZE,
} from "../tokens";
import { isSmallScreen } from "../utils";
import { MarkdownTextLine } from "./MarkdownTextLine";
import { TextBlock } from "./TextBlock";

const getBookName = (title: string) => {
    const names = {
        Genesis: "the Book of Genesis",
        Exodus: "the Book of Exodus",
        Leviticus: "the Book of Leviticus",
        Numbers: "the Book of Numbers",
        Deuteronomy: "the Book of Deuteronomy",
        Joshua: "the Book of Joshua",
        Judges: "the Book of Judges",
        Ruth: "the Book of Ruth",
        "1 Samuel": "the First Book of Samuel",
        "2 Samuel": "the Second Book of Samuel",
        "1 Kings": "the First Book of Kings",
        "2 Kings": "the Second Book of Kings",
        "1 Chronicles": "the First Book of Chronicles",
        "2 Chronicles": "the Second Book of Chronicles",
        Ezra: "the Book of Ezra",
        Nehemiah: "the Book of Nehemiah",
        Tobit: "the Book of Tobit",
        Judith: "the Book of Judith",
        Esther: "the Book of Esther",
        "1 Maccabees": "the First Book of Maccabees",
        "2 Maccabees": "the Second Book of Maccabees",
        Job: "the Book of Job",
        Proverbs: "the Book of Proverbs",
        Ecclesiastes: "the Book of Ecclesiastes",
        "Song of Songs": "the Song of Songs",
        "Song of Solomon": "the Song of Songs",
        Wisdom: "the Book of Wisdom",
        Sirach: "the Wisdom of Sirach",
        Isaiah: "the Book of the Prophet Isaiah",
        Jeremiah: "the Book of the Prophet Jeremiah",
        Lamentations: "the Book of Lamentations",
        Baruch: "the Book of the Prophet Baruch",
        Ezekiel: "the Book of the Prophet Ezekiel",
        Daniel: "the Book of the Prophet Daniel",
        Hosea: "the Book of the Prophet Hosea",
        Joel: "the Book of the Prophet Joel",
        Amos: "the Book of the Prophet Amos",
        Obadiah: "the Book of the Prophet Obadiah",
        Jonah: "the Book of the Prophet Jonah",
        Micah: "the Book of the Prophet Micah",
        Nahum: "the Book of the Prophet Nahum",
        Habakkuk: "the Book of the Prophet Habakkuk",
        Zephaniah: "the Book of the Prophet Zephaniah",
        Haggai: "the Book of the Prophet Haggai",
        Zechariah: "the Book of the Prophet Zechariah",
        Malachi: "the Book of the Prophet Malachi",
        Matthew: "the Gospel according to Saint Matthew",
        Mark: "the Gospel according to Saint Mark",
        Luke: "the Gospel according to Saint Luke",
        John: "the Gospel according to Saint John",
        Acts: "the Acts of the Apostles",
        Romans: "the Letter of Saint Paul to the Romans",
        "1 Corinthians": "the First Letter of Saint Paul to the Corinthians",
        "2 Corinthians": "the Second Letter of Saint Paul to the Corinthians",
        Galatians: "the Letter of Saint Paul to the Galatians",
        Ephesians: "the Letter of Saint Paul to the Ephesians",
        Philippians: "the Letter of Saint Paul to the Philippians",
        Colossians: "the Letter of Saint Paul to the Colossians",
        "1 Thessalonians":
            "the First Letter of Saint Paul to the Thessalonians",
        "2 Thessalonians":
            "the Second Letter of Saint Paul to the Thessalonians",
        "1 Timothy": "the First Letter of Saint Paul to Timothy",
        "2 Timothy": "the Second Letter of Saint Paul to Timothy",
        Titus: "the Letter of Saint Paul to Titus",
        Philemon: "the Letter of Saint Paul to Philemon",
        Hebrews: "the Letter to the Hebrews",
        James: "the Letter of Saint James",
        "1 Peter": "the First Letter of Saint Peter",
        "2 Peter": "the Second Letter of Saint Peter",
        "1 John": "the First Letter of Saint John",
        "2 John": "the Second Letter of Saint John",
        "3 John": "the Third Letter of Saint John",
        Jude: "the Letter of Saint Jude",
        Revelation: "the Book of Revelation",
    };

    const longName = Object.entries(names).find(([n, _]) => {
        return title.slice(0, n.length) === n;
    })?.[1];

    return longName ?? "the reading";
};

const getOrdinalNumber = (n: number) => {
    const base = [
        "zeroth",
        "first",
        "second",
        "third",
        "fourth",
        "fifth",
        "sixth",
        "seventh",
        "eighth",
        "ninth",
        "tenth",
        "eleventh",
        "twelfth",
        "thirteenth",
        "fourteenth",
        "fifteenth",
        "sixteenth",
        "seventeenth",
        "eighteenth",
        "nineteenth",
    ];

    const tens = [
        "twentieth",
        "thirtieth",
        "fortieth",
        "fiftieth",
        "sixtieth",
        "seventieth",
        "eightieth",
        "ninetieth",
    ];

    const tenPlus = [
        "twenty",
        "thirty",
        "forty",
        "fifty",
        "sixty",
        "seventy",
        "eighty",
        "ninety",
    ];

    if (n < 20) {
        return base[n];
    }

    if (n % 10 === 0) {
        return tens[n / 10 - 2];
    }
    return tenPlus[Math.floor(n / 10) - 2] + "-" + base[n % 10];
};

export const Scripture: React.FC<{
    title: string;
    lesson: Lesson;
}> = (props) => {
    const { title, lesson } = props;

    const id = title.replace(/[^A-Za-z]/g, "-");

    const bookName = getBookName(title);

    const chapterRef = parseInt(/ (\d+)/.exec(title)?.[1] ?? "0");
    const verseRef = parseInt(/:(\d+)/.exec(title)?.[1] ?? "1");

    let beginneth = `Here beginneth ${bookName}.`;

    if (verseRef && verseRef > 1 && chapterRef) {
        beginneth = `Here beginneth the ${getOrdinalNumber(
            verseRef
        )} verse of the ${getOrdinalNumber(
            chapterRef
        )} chapter of ${bookName}.`;
    } else if (chapterRef) {
        beginneth = `Here beginneth the ${getOrdinalNumber(
            chapterRef
        )} chapter of ${bookName}.`;
    }

    return (
        <>
            <div>
                <div
                    style={{
                        backgroundColor: BACKGROUND_COLOR,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        transition: "all 0.2s",
                    }}
                >
                    <Padding onlySides>
                        <div
                            style={{
                                fontWeight: "bold",
                                paddingBottom: 12,
                                paddingTop: 12,
                                fontSize: TEXT_SIZE,
                            }}
                            className="title"
                            data-content={title}
                        >
                            {title.replace("-100", "-end")}
                        </div>
                    </Padding>
                </div>
            </div>

            <TextBlock content={beginneth} />

            <Padding onlySides>
                <div
                    style={{
                        paddingBottom: 8,
                        paddingTop: 8,
                        paddingLeft: isSmallScreen()
                            ? TEXT_INDENT / 2
                            : TEXT_INDENT,
                        paddingRight: isSmallScreen()
                            ? TEXT_INDENT / 2
                            : TEXT_INDENT,
                        fontSize: isSmallScreen() ? TEXT_SIZE * 0.9 : TEXT_SIZE,
                        textAlign: "justify",
                        color: TEXT_COLOR,
                    }}
                    className="legacy-text-block"
                >
                    {lesson.parts.map((section, sectionIndex) => {
                        return (
                            <>
                                {sectionIndex > 0 ? (
                                    <div style={{ height: 12 }}></div>
                                ) : (
                                    <></>
                                )}
                                {section.content.map((line, lineIndex) => {
                                    return (
                                        <MarkdownTextLine
                                            content={line.text.replace(
                                                "(RSVCE)",
                                                ""
                                            )}
                                            indent={line.indent}
                                            dropCap={lineIndex === 0}
                                        />
                                    );
                                })}
                            </>
                        );
                    })}
                </div>
            </Padding>
        </>
    );
};
