import React, { useState } from "react";
import { useInterval } from "usehooks-ts";
import { BUTTON_BACKGROUND_COLOR, HORIZONTAL_RULE_COLOR } from "../tokens";

export const LoadingScreen: React.FC<{}> = () => {
    const [t, setT] = useState(0);

    useInterval(() => {
        setT((t) => t + 0.1);
    }, 100);

    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                padding: 16,
            }}
        >
            <div
                style={{
                    border: `1px solid ${HORIZONTAL_RULE_COLOR}`,
                    height: 12,
                    width: 100,
                    transition: "all 0.1s",
                }}
            >
                <div
                    style={{
                        height: "100%",
                        width: `${100 - 100 * (1 / (t / 5 + 1))}%`,
                        backgroundColor: BUTTON_BACKGROUND_COLOR,
                    }}
                ></div>
            </div>
            <div style={{ paddingTop: 8 }}>Loading...</div>
        </div>
    );
};
