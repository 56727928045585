import React from "react";

import { Padding } from "../Padding";
import { RUBRIC_COLOR, TEXT_COLOR, TEXT_INDENT, TEXT_SIZE } from "../tokens";
import { isSmallScreen } from "../utils";

export const VandR: React.FC<{ lines: string[] }> = (props) => {
    const { lines } = props;

    return (
        <Padding onlySides>
            <div
                style={{
                    paddingLeft: isSmallScreen()
                        ? TEXT_INDENT / 2
                        : TEXT_INDENT,
                    paddingRight: isSmallScreen()
                        ? TEXT_INDENT / 2
                        : TEXT_INDENT,
                    fontSize: isSmallScreen() ? TEXT_SIZE * 0.9 : TEXT_SIZE,
                    textAlign: "left",
                    color: TEXT_COLOR,
                    paddingTop: 6,
                    paddingBottom: 6,
                }}
                className="text-block"
                data-content={lines.join("\n\n")}
            >
                {lines.map((line, lineIndex) => {
                    return (
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "flex-start",
                            }}
                        >
                            <div style={{ width: 32, color: RUBRIC_COLOR }}>
                                {lineIndex % 2 === 0 ? "℣" : "℟"}
                            </div>
                            <div
                                style={{
                                    width: 0,
                                    flexGrow: 1,
                                    fontWeight:
                                        lineIndex % 2 === 1
                                            ? "bold"
                                            : undefined,
                                }}
                            >
                                {line.split("✠").map((part, partIndex) => {
                                    return (
                                        <>
                                            {partIndex > 0 ? (
                                                <span
                                                    style={{
                                                        color: RUBRIC_COLOR,
                                                    }}
                                                >
                                                    ✠
                                                </span>
                                            ) : (
                                                <></>
                                            )}
                                            {part}
                                        </>
                                    );
                                })}
                            </div>
                        </div>
                    );
                })}
            </div>
        </Padding>
    );
};
