import {
    Route,
    RouterProvider,
    createBrowserRouter,
    createRoutesFromElements,
} from "react-router-dom";
import "./App.css";
import { Home } from "./Home";
import { Liturgy } from "./Liturgy";
import { Ordo } from "./ordo/Ordo";
import {
    BACKGROUND_COLOR,
    HORIZONTAL_RULE_COLOR,
    PAGE_BACKGROUND,
    RUBRIC_COLOR,
    TEXT_COLOR,
    WIDTH,
} from "./tokens";
import { isSmallScreen } from "./utils";

function App() {
    const router = createBrowserRouter(
        createRoutesFromElements([
            <Route
                path="/pray/:year/:month/:date/:label/:hour"
                element={<Liturgy />}
            />,
            <Route path="/secret-ordo" element={<Ordo />} />,
            <Route path="/" element={<Home />} />,
        ])
    );

    return (
        <div className="App">
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "stretch",
                    height: "100%",
                    overflow: "hidden",
                }}
            >
                <div
                    style={{
                        borderBottom: `1px solid ${HORIZONTAL_RULE_COLOR}`,
                        boxShadow: `0 4px 4px -4px rgba(0, 0, 0, 0.1)`,
                        backgroundColor: BACKGROUND_COLOR,
                        zIndex: 999,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                    }}
                >
                    <div
                        style={{
                            maxWidth: WIDTH,
                            width: "100%",
                            boxSizing: "border-box",
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            padding: isSmallScreen() ? 4 : 8,
                            paddingLeft: isSmallScreen() ? 4 : 0,
                            paddingRight: isSmallScreen() ? 4 : 0,
                            fontSize: "120%",
                        }}
                    >
                        <a
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                textDecoration: "none",
                                color: TEXT_COLOR,
                            }}
                            href={"/"}
                        >
                            <img
                                src={`${process.env.PUBLIC_URL}/logo.svg`}
                                alt="App logo"
                                style={{ height: 48, maxWidth: "100%" }}
                            />
                            <div
                                style={{
                                    paddingLeft: 8,
                                    fontWeight: "bold",
                                    color: RUBRIC_COLOR,
                                    paddingTop: 2,
                                }}
                            >
                                DAILY OFFICE ONLINE
                            </div>
                        </a>

                        <div style={{ flexGrow: 1 }}></div>
                    </div>
                </div>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "stretch",
                        color: TEXT_COLOR,
                        lineHeight: 1.5,
                        overflow: "auto",
                        flexGrow: 1,
                        flexShrink: 1,
                        backgroundColor: PAGE_BACKGROUND,
                    }}
                    className="app-scroll-area"
                >
                    <div
                        style={{
                            paddingTop: isSmallScreen() ? 0 : 48,
                            paddingBottom: isSmallScreen() ? 0 : 64,
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "center",
                        }}
                    >
                        <div
                            style={{
                                maxWidth: WIDTH,
                                width: "100%",
                                boxSizing: "border-box",
                                backgroundColor: BACKGROUND_COLOR,
                                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                                borderRadius: isSmallScreen() ? 0 : 4,
                                overflow: "hidden",
                            }}
                        >
                            <RouterProvider router={router} />
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                    padding: 24,
                                    paddingTop: 0,
                                }}
                            >
                                <a
                                    href="mailto:Hello@DailyOffice.online"
                                    style={{
                                        textDecoration: "underline",
                                        color: RUBRIC_COLOR,
                                    }}
                                    target="_blank"
                                >
                                    Contact
                                </a>
                                <div style={{ textAlign: "center" }}>
                                    dailyoffice.online © 2024 the Personal
                                    Ordinariate of Our Lady of the Southern
                                    Cross
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default App;
