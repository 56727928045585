import { BaseCalendarDay } from "./utils";

function getMorningFirstLessonFromRow(row: string[], isYear2: boolean) {
    return isYear2 && row[3].length > 0 ? row[3] : row[2];
}

function getMorningSecondLessonFromRow(row: string[], isYear2: boolean) {
    return isYear2 && row[5].length > 0 ? row[5] : row[4];
}

function getEveningFirstLessonFromRow(row: string[], isYear2: boolean) {
    return isYear2 && row[7].length > 0 ? row[7] : row[6];
}

function getEveningSecondLessonFromRow(row: string[], isYear2: boolean) {
    return isYear2 && row[9].length > 0 ? row[9] : row[8];
}

export const getOptionsOrd = (
    day: BaseCalendarDay,
    label: string,
    hour: string
) => {
    const psalmDay = day.date;

    const ferialPsalms = BCP_PSALM_CYCLE[psalmDay];

    const saintPsalms = undefined;

    const psalms = {
        morning: saintPsalms ? saintPsalms[1] : ferialPsalms.morning,
        evening:
            saintPsalms && hour === "first-evensong" && saintPsalms[0]
                ? saintPsalms[0]
                : saintPsalms && hour === "second-evensong"
                ? saintPsalms[2]
                : ferialPsalms.evening,
    };

    const saintRow = SAINTS[label];

    if (saintRow) {
        return {
            firstLessonOptions: {
                morning: saintRow[3],
                evening:
                    saintRow && hour === "first-evensong"
                        ? saintRow[1]
                        : saintRow[5],
            },
            secondLessonOptions: {
                morning: saintRow[4],
                evening:
                    saintRow && hour === "first-evensong"
                        ? saintRow[2]
                        : saintRow[6],
            },
            psalms,
        };
    }

    const specificDateRow = TIME.find((row) => {
        if (`${day.month}-${day.date}` === row[0]) {
            return true;
        }
    });

    const isAfterAdventSunday =
        (day.month === 12 && day.date > 20) ||
        day.collectId.split("-")[0] === "advent";

    const isYear2 = isAfterAdventSunday
        ? day.year % 2 === 1
        : day.year % 2 === 0;

    const timeRow = TIME.find((row) => {
        if (
            label === "baptism-of-the-lord" &&
            hour !== "first-evensong" &&
            row[0] === "epiphany-1" &&
            row[1] === ""
        ) {
            // Special case for transferrable baptism of the lord
            return true;
        }

        if ((day.dayOfTheWeek === "sun" && row[1] === "") || row[1] === "any") {
            return row[0] === day.collectId;
        } else {
            return row[0] === day.collectId && row[1] === day.dayOfTheWeek;
        }
    });

    const row = timeRow ? timeRow : specificDateRow;

    const firstLessonRow = day.weekBeforeChristTheKing
        ? TIME.find((row) => {
              if (
                  row[0] === "trinity-26" &&
                  (row[1] === "" ? "sun" : row[1]) === day.dayOfTheWeek
              ) {
                  return true;
              }
          })
        : row;

    if (!row || !firstLessonRow) {
        throw new Error("no lectionary row");
    }

    return {
        firstLessonOptions: {
            morning: getMorningFirstLessonFromRow(firstLessonRow, isYear2),
            evening: getEveningFirstLessonFromRow(firstLessonRow, isYear2),
        },
        secondLessonOptions: {
            morning: getMorningSecondLessonFromRow(row, isYear2),
            evening: getEveningSecondLessonFromRow(row, isYear2),
        },
        psalms,
    };
};

export const ORD_SAINTS_PSALMS: { [label: string]: (string[] | undefined)[] } =
    {
        epiphany: [["19", "87"], ["72"], ["96", "97", "117"]],
        "trinity-sunday": [undefined, ["29", "33"], ["93", "99", "115"]],
        "corpus-christi": [
            ["23", "42", "43"],
            ["81", "147:13-100"],
            ["110", "111", "116:10-100"],
        ],
        "sacred-heart-of-jesus": [
            ["36", "61", "130"],
            ["108", "146"],
            ["97", "98"],
        ],
        "christ-the-king": [
            undefined,
            ["145", "146"],
            ["147", "148", "149", "150"],
        ],
        "christmas-day": [undefined, ["19", "45", "85"], ["89", "110", "132"]],
        "ash-wednesday": [undefined, ["6", "32", "38"], ["102", "130", "143"]],
        "good-friday": [undefined, ["22", "40", "54"], ["69", "88"]],
        "easter-day": [undefined, ["2", "57", "111"], ["113", "114", "118"]],
        ascension: [undefined, ["8", "15", "21"], ["24", "47", "108"]],
        whitsun: [undefined, ["48", "68"], ["104", "145"]],
        "mary-mother-of-god": [
            ["90", "133", "134"],
            ["119:1-32"],
            ["91", "121"],
        ],
        "saint-david-wales": [
            ["110", "112", "113", "132"],
            ["1", "2", "8"],
            ["110", "112", "113", "132"],
        ],
        "saint-patrick-australia": [
            ["110", "112", "113", "132"],
            ["1", "2", "8"],
            ["110", "112", "113", "132"],
        ],
        "saint-joseph": [["1", "15", "112"], ["132", "147:13-100"], ["34"]],
        annunciation: [
            ["89:1-4", "89:19-37"],
            ["85", "110", "146"],
            ["111", "113", "131"],
        ],
        "saint-george-england": [
            ["110", "111", "112"],
            ["11", "15", "21"],
            ["61", "64", "79"],
        ],
        "our-lady-help-of-christians-australia": [
            ["110", "113", "122"],
            ["24", "45", "85"],
            ["110", "113", "127"],
        ],
        "the-nativity-of-saint-john-the-baptist": [
            ["71"],
            ["50", "149"],
            ["80", "82"],
        ],
        "saints-peter-and-paul": [
            ["48", "138"],
            ["124", "125", "126", "149"],
            ["66", "67"],
        ],
        "saint-mary-of-the-cross-australia": [
            ["110", "113", "122"],
            ["24", "45", "85"],
            ["110", "113", "127"],
        ],
        "the-assumption-of-the-blessed-virgin-mary": [
            ["110", "113", "122"],
            ["24", "45", "85"],
            ["110", "113", "127"],
        ],
        "our-lady-of-the-southern-cross-australia": [
            ["110", "113", "122"],
            ["24", "45", "85"],
            ["110", "113", "127"],
        ],
        "our-lady-of-walsingham-britain": [
            ["110", "113", "122"],
            ["24", "45", "85"],
            ["110", "113", "127"],
        ],
        "all-saints": [["145"], ["97", "149"], ["33"]],
        "all-souls": [
            undefined,
            ["5", "6", "7"],
            ["116", "120", "121", "130", "138"],
        ],
        "the-immaculate-conception-of-the-blessed-virgin-mary": [
            ["113", "147:12-20"],
            ["46", "87"],
            ["122", "127"],
        ],
    };

const SAINTS: { [label: string]: string[] } = {
    "christmas-day": [
        "12-25",
        "Zechariah 2:10-100",
        "Titus 2:11-3:7",
        "Isaiah 9:2-7",
        "Luke 2:1-20",
        "Isaiah 7:10-14",
        "1 John 4:7-100",
    ],
    "saint-stephen": [
        "12-26",
        "",
        "",
        "Genesis 4:1-10",
        "Acts 6",
        "2 Chronicles 24:15-22",
        "Acts 7:59-8:8",
    ],
    "saint-john": [
        "12-27",
        "",
        "",
        "Exodus 33:9-19",
        "John 13:21-35",
        "Isaiah 6:1-8",
        "1 John 5:1-12",
    ],
    "the-holy-innocents": [
        "12-28",
        "",
        "",
        "Jeremiah 31:1-17",
        "Matthew 18:1-10",
        "Baruch 4:21-27",
        "Mark 10:13-16",
    ],
    "holy-family": [
        "",
        "Proverbs 4:1-6",
        "Luke 2:41-100",
        "Isaiah 41:8-20",
        "Colossians 1:1-20",
        "Isaiah 12",
        "Philippians 2:1-11",
    ],
    "mary-mother-of-god": [
        "",
        "Deuteronomy 10:12-11:1",
        "Luke 21:25-36",
        "Genesis 17:1-13",
        "Romans 2:17-100",
        "Deuteronomy 30",
        "Colossians 2:8-15",
    ],
    epiphany: [
        "1-6",
        "Isaiah 60:1-7",
        "Romans 15:8-21",
        "Isaiah 49:1-13",
        "Luke 3:15-22",
        "Isaiah 60:9-100",
        "John 2:1-11",
    ],
    "extra-jan-6": [
        "",
        "",
        "",
        "Hosea 2:14-3:100",
        "Matthew 5:17-100",
        "Hosea 4:1-11",
        "1 Thessalonians 4:1-12",
    ],
    "extra-jan-7": ["", "", "", "Hosea 5:8-6:6", "Matthew 6:1-18", "", ""],
    "the-conversion-of-saint-paul-the-apostle": [
        "01-25",
        "Jeremiah 1:4-10",
        "Acts 26:1-23",
        "Isaiah 56:1-8",
        "Galatians 1:11-100",
        "Sirach 39:1-10",
        "Philippians 3:1-14",
    ],
    "the-presentation-of-the-lord": [
        "02-02",
        "Exodus 13:11-16",
        "Galatians 4:1-7",
        "1 Samuel 1:21-100",
        "Hebrews 10:1-10",
        "Haggai 2:1-9",
        "Romans 12:1-5",
    ],
    "the-chair-of-saint-peter": [
        "",
        "Isaiah 43:10-15",
        "Matthew 9:25-10:4",
        "Ezekiel 3:4-11",
        "John 21:15-22",
        "Ezekiel 34:11-16",
        "Acts 11:1-18",
    ],
    "saint-david-wales": [
        "",
        "Isaiah 6:1-8",
        "2 Corinthians 4:1-10",
        "Malachi 2:5-7",
        "1 Timothy 3:1-9",
        "Jeremiah 1:4-10",
        "Matthew 24:42-46",
    ],
    "saint-patrick-australia": [
        "",
        "Isaiah 6:1-8",
        "2 Corinthians 4:1-10",
        "Malachi 2:5-7",
        "1 Timothy 3:1-9",
        "Jeremiah 1:4-10",
        "Matthew 24:42-46",
    ],
    "saint-joseph": [
        "",
        "Hosea 11:1-9",
        "Luke 2:41-52",
        "Isaiah 11:1-10",
        "Matthew 13:54-100",
        "Genesis 50:22-26",
        "Matthew 2:13-23",
    ],
    annunciation: [
        "03-25",
        "Genesis 3:1-15",
        "Romans 5:12-100",
        "Isaiah 52:7-12",
        "Hebrews 2:5-100",
        "1 Samuel 2:1-10",
        "Matthew 1:18-23",
    ],
    "the-annunciation-of-the-lord": [
        "03-25",
        "Genesis 3:1-15",
        "Romans 5:12-100",
        "Isaiah 52:7-12",
        "Hebrews 2:5-100",
        "1 Samuel 2:1-10",
        "Matthew 1:18-23",
    ],
    "saint-george-england": [
        "",
        "Jeremiah 15:15-21",
        "Hebrews 11:32-12:2",
        "Joshua 1:1-9",
        "Ephesians 6:10-20",
        "Isaiah 43:1-7",
        "John 15:1-8",
    ],
    "saint-mark": [
        "04-25",
        "Ezekiel 1:1-14",
        "Acts 12:25-13:13",
        "Sirach 51:13-100",
        "Acts 15:35-100",
        "Isaiah 62:6-100",
        "2 Timothy 4:1-11",
    ],
    "saint-mark-australia": [
        "04-25",
        "Ezekiel 1:1-14",
        "Acts 12:25-13:13",
        "Sirach 51:13-100",
        "Acts 15:35-100",
        "Isaiah 62:6-100",
        "2 Timothy 4:1-11",
    ],
    "saints-philip-and-james": [
        "05-01",
        "Isaiah 30:15-21",
        "John 1:43-100",
        "Proverbs 4:10-18",
        "John 6:1-14",
        "Job 23:1-12",
        "John 17:1-8",
    ],
    "saint-matthias": [
        "",
        "Isaiah 22:15-22",
        "John 15:1-16",
        "1 Samuel 2:27-35",
        "Matthew 7:15-27",
        "1 Samuel 16:1-13",
        "1 Corinthians 4:1-8",
    ],
    "our-lady-help-of-christians-australia": [
        "",
        "1 Samuel 2:1-10",
        "Revelation 12:1-6",
        "2 Chronicles 26:1-15",
        "John 2:1-12",
        "Song of Songs 2:8-14",
        "Acts 1:1-14",
    ],
    "the-visitation-of-the-blessed-virgin-mary": [
        "",
        "Song of Songs 2:8-14",
        "Luke 1:26-38",
        "1 Samuel 2:1-10",
        "Mark 3:31-100",
        "Zechariah 2:10-13",
        "John 3:25-30",
    ],
    "saint-barnabas": [
        "06-11",
        "Tobit 4:5-11",
        "Acts 4:32-100",
        "Jeremiah 9:23-24",
        "Acts 9:26-31",
        "Job 29:11-16",
        "Acts 14:8-100",
    ],
    "the-nativity-of-saint-john-the-baptist": [
        "06-24",
        "Malachi 3:1-6",
        "Matthew 3",
        "Judges 13:1-7",
        "Luke 1:5-25",
        "Malachi 4",
        "Matthew 11:2-19",
    ],
    "saints-peter-and-paul": [
        "06-29",
        "Ezekiel 2:1-7",
        "Acts 9:32-100",
        "Ezekiel 3:4-11",
        "Acts 11:1-18",
        "Ezekiel 34:11-16",
        "John 21:15-22",
    ],
    "saint-thomas": [
        "",
        "2 Samuel 15:17-21",
        "John 11:1-16",
        "Job 42:1-6",
        "John 14:1-7",
        "Sirach 2",
        "1 Peter 1:3-9",
    ],
    "saint-mary-magdalene": [
        "07-22",
        "1 Samuel 16:14-100",
        "Luke 8:1-3",
        "Isaiah 25:1-9",
        "John 20:1-10",
        "Zephaniah 3:14-100",
        "Mark 15:40-16:7",
    ],
    "saint-james": [
        "07-25",
        "Jeremiah 26:1-15",
        "Mark 1:14-20",
        "2 Kings 1:1-15",
        "Luke 9:46-56",
        "Jeremiah 45",
        "Mark 14:32-42",
    ],
    "the-transfiguration-of-the-lord": [
        "08-06",
        "Exodus 24:12-100",
        "Luke 9:28-45",
        "Exodus 34:29-100",
        "2 Corinthians 3",
        "Sirach 48:1-10",
        "2 Peter 1:12-100",
    ],
    "saint-mary-of-the-cross-australia": [
        "",
        "Isaiah 52:7-10",
        "Luke 6:20-31",
        "Micah 6:6-8",
        "Revelation 19:1; Revelation 19:5-9",
        "Isaiah 58:6-11",
        "Revelation 21:1-6",
    ],
    "the-assumption-of-the-blessed-virgin-mary": [
        "",
        "Proverbs 8:22-31",
        "John 19:23-27",
        "Isaiah 7:1-15",
        "Luke 11:27-28",
        "Song of Songs 2:1-7",
        "Acts 1:6-14",
    ],
    "saint-bartholomew": [
        "08-24",
        "Genesis 28:10-17",
        "John 1:43-100",
        "Deuteronomy 18:15-19",
        "Matthew 10:1-15",
        "Isaiah 49:1-13",
        "Matthew 10:16-22",
    ],
    "our-lady-of-the-southern-cross-australia": [
        "",
        "1 Samuel 2:1-10",
        "Acts 1:1-14",
        "Proverbs 8:22-31",
        "Revelation 11:19-12:100",
        "Zechariah 9:9-10",
        "Ephesians 1:3-10",
    ],
    "the-nativity-of-the-blessed-virgin-mary": [
        "",
        "1 Samuel 1:19-28",
        "Galatians 4:1-7",
        "Genesis 28:10-17",
        "Revelation 12:1-6",
        "Ezekiel 44:1-4",
        "Revelation 7:9-12",
    ],
    "the-exaltation-of-the-holy-cross": [
        "",
        "Numbers 21:4-9",
        "John 3:1-16",
        "Isaiah 53",
        "Philippians 2:5-11",
        "Isaiah 42:1-12",
        "Ephesians 2:11-100",
    ],
    "saint-matthew": [
        "09-21",
        "1 Kings 19:15-100",
        "Matthew 6:19-100",
        "Proverbs 3:1-17",
        "Matthew 19:16-100",
        "1 Chronicles 29:9-18",
        "1 Timothy 6:6-19",
    ],
    "our-lady-of-walsingham": [
        "",
        "Wisdom 9:1-12",
        "Galatians 4:1-5",
        "Isaiah 52:7-12",
        "Hebrews 2:5-100",
        "1 Samuel 2:1-10",
        "Matthew 1:18-23",
    ],
    "our-lady-of-walsingham-britain": [
        "",
        "Wisdom 9:1-12",
        "Galatians 4:1-5",
        "Isaiah 52:7-12",
        "Hebrews 2:5-100",
        "1 Samuel 2:1-10",
        "Matthew 1:18-23",
    ],
    "saints-michael-gabriel-and-raphael": [
        "09-29",
        "Daniel 10:4-100",
        "Revelation 5",
        "2 Kings 6:8-17",
        "Acts 12:1-11",
        "Daniel 12:1-4",
        "Revelation 8:1-6",
    ],
    "saint-luke": [
        "10-18",
        "Isaiah 55",
        "Luke 1:1-4",
        "Isaiah 61:1-6",
        "2 Timothy 3:10-100",
        "Sirach 38:1-14",
        "Colossians 4:7-100",
    ],
    "saints-simon-and-jude": [
        "10-28",
        "Isaiah 28:9-16",
        "Ephesians 2:11-100",
        "Isaiah 45:18-100",
        "Luke 6:12-19",
        "Wisdom 5:1-16",
        "John 14:15-24",
    ],
    "all-saints": [
        "11-01",
        "2 Esdras 2:42-100",
        "Hebrews 11:32-12:2",
        "Wisdom 3:1-9",
        "Revelation 19:6-10",
        "Sirach 44:1-15",
        "Revelation 7:9-100",
    ],
    "all-souls": [
        "",
        "",
        "",
        "Wisdom 4:7-end",
        "1 Corinthians 15:35-end",
        "Job 19:21-27",
        "1 Thessalonians 4:13-100",
    ],
    "the-dedication-of-the-lateran-basilica": [
        "",
        "Haggai 2:1-9",
        "1 Corinthians 3:9-17",
        "2 Chronicles 5-6:10; 2 Chronicles 5:13-6:2",
        "1 Peter 2:4-9",
        "Isaiah 6",
        "Matthew 5:21-37",
    ],
    "saint-andrew": [
        "11-30",
        "Sirach 14:20-100",
        "1 Corinthians 4:9-16",
        "Zechariah 8:20-100",
        "John 1:35-42",
        "Ezekiel 47:1-12",
        "John 12:20-32",
    ],
    "the-immaculate-conception-of-the-blessed-virgin-mary": [
        "",
        "Sirach 24:17-22",
        "Romans 8:28-30",
        "Isaiah 61:10-62:5",
        "1 Corinthians 1:26-30",
        "Zephaniah 3:14-17",
        "Revelation 11:19-12:6; Revelation 12:10",
    ],
};

const TIME: string[][] = [
    [
        "advent-1",
        "",
        "Isaiah 1:1-20",
        "Isaiah 1:1-20",
        "Matthew 24:1-28",
        "Revelation 14:13-15:4",
        "Isaiah 2:10-100",
        "Isaiah 2:10-100",
        "1 Thessalonians 5",
        "John 3:1-21",
    ],
    [
        "advent-1",
        "mon",
        "Isaiah 3:1-15",
        "",
        "Mark 1:1-20",
        "",
        "Isaiah 4:2-100",
        "",
        "Revelation 6",
        "",
    ],
    [
        "advent-1",
        "tues",
        "Isaiah 5:1-17",
        "",
        "Mark 1:21-100",
        "",
        "Isaiah 5:18-100",
        "",
        "Revelation 7",
        "",
    ],
    [
        "advent-1",
        "wed",
        "Isaiah 6",
        "",
        "Mark 2:1-22",
        "",
        "Isaiah 8:16-9:7",
        "",
        "Revelation 8",
        "",
    ],
    [
        "advent-1",
        "thur",
        "Isaiah 9:8-10:4",
        "",
        "Mark 2:23-3:12",
        "",
        "Isaiah 10:5-23",
        "",
        "Revelation 9",
        "",
    ],
    [
        "advent-1",
        "fri",
        "Isaiah 10:24-11:9",
        "",
        "Mark 3:13-100",
        "",
        "Isaiah 11:10-12:100",
        "",
        "Revelation 10",
        "",
    ],
    [
        "advent-1",
        "sat",
        "Isaiah 13:1-14:2",
        "",
        "Mark 4:1-20",
        "",
        "Isaiah 14:3-27",
        "",
        "Revelation 11",
        "",
    ],
    [
        "advent-2",
        "",
        "Isaiah 5:1-16",
        "Isaiah 11:1-9",
        "Matthew 24:29-100",
        "Revelation 20:11-21:7",
        "Isaiah 5:18-100",
        "Isaiah 11:10-12:100",
        "2 Timothy 3:14-4:8",
        "Luke 1:1-25",
    ],
    [
        "advent-2",
        "mon",
        "Isaiah 17",
        "",
        "Mark 4:21-100",
        "",
        "Isaiah 18",
        "",
        "Revelation 12",
        "",
    ],
    [
        "advent-2",
        "tues",
        "Isaiah 19:1-17",
        "",
        "Mark 5:1-20",
        "",
        "Isaiah 19:18-100",
        "",
        "Revelation 13",
        "",
    ],
    [
        "advent-2",
        "wed",
        "Isaiah 21:1-12",
        "",
        "Mark 5:21-100",
        "",
        "Isaiah 22:1-14",
        "",
        "Revelation 14",
        "",
    ],
    [
        "advent-2",
        "thur",
        "Isaiah 24",
        "",
        "Mark 6:1-13",
        "",
        "Isaiah 28:1-13",
        "",
        "Revelation 15",
        "",
    ],
    [
        "advent-2",
        "fri",
        "Isaiah 28:14-100",
        "",
        "Mark 6:14-29",
        "",
        "Isaiah 29:1-14",
        "",
        "Revelation 16",
        "",
    ],
    [
        "advent-2",
        "sat",
        "Isaiah 29:15-100",
        "",
        "Mark 6:30-100",
        "",
        "Isaiah 30:1-18",
        "",
        "Revelation 17",
        "",
    ],
    [
        "advent-3",
        "",
        "Isaiah 25:1-9",
        "Isaiah 28:9-22",
        "Matthew 25:1-30",
        "Revelation 21:9-22:5",
        "Isaiah 26:1-13",
        "Isaiah 30:8-21",
        "1 Timothy 1:12-2:8",
        "Matthew 3",
    ],
    [
        "advent-3",
        "mon",
        "Isaiah 30:19-100",
        "",
        "Mark 7:1-23",
        "",
        "Isaiah 31",
        "",
        "Revelation 18",
        "",
    ],
    [
        "advent-3",
        "tues",
        "Isaiah 38:1-20",
        "",
        "Mark 7:24-8:10",
        "",
        "Isaiah 40:1-11",
        "",
        "Revelation 19",
        "",
    ],
    [
        "advent-3",
        "wed",
        "Isaiah 40:12-100",
        "",
        "Mark 8:11-9:1",
        "",
        "Isaiah 41",
        "",
        "Revelation 20",
        "",
    ],
    [
        "advent-3",
        "thur",
        "Isaiah 42:1-17",
        "",
        "Mark 9:2-32",
        "",
        "Isaiah 42:18-43:13",
        "",
        "Revelation 21:1-14",
        "",
    ],
    [
        "advent-3",
        "fri",
        "Isaiah 43:14-44:5",
        "",
        "Mark 9:33-100",
        "",
        "Isaiah 44:6-23",
        "",
        "Revelation 21:15-22:5",
        "",
    ],
    [
        "advent-3",
        "sat",
        "Isaiah 44:24-45:13",
        "",
        "Mark 10:1-31",
        "",
        "Isaiah 45:14-100",
        "",
        "Revelation 22:6-100",
        "",
    ],
    [
        "advent-4",
        "",
        "Isaiah 32:1-8",
        "Isaiah 35",
        "Matthew 25:1-30",
        "Revelation 22:6-100",
        "Isaiah 40:1-11",
        "Isaiah 40:1-11",
        "2 Peter 3:1-14",
        "Luke 1:26-45",
    ],
    [
        "advent-4",
        "mon",
        "Isaiah 46",
        "",
        "Mark 10:32-100",
        "",
        "Isaiah 47",
        "",
        "Jude 1:1-16",
        "",
    ],
    [
        "advent-4",
        "tues",
        "Isaiah 48",
        "",
        "Mark 11:1-26",
        "",
        "Isaiah 50:4-10",
        "",
        "Jude 1:17-100",
        "",
    ],
    [
        "advent-4",
        "wed",
        "Isaiah 51:1-16",
        "",
        "Mark 11:27-12:12",
        "",
        "Isaiah 51:17-52:12",
        "",
        "2 Peter 1",
        "",
    ],
    [
        "advent-4",
        "thur",
        "Isaiah 52:13-53:100",
        "",
        "Mark 12:13-34",
        "",
        "Isaiah 54",
        "",
        "2 Peter 2",
        "",
    ],
    [
        "advent-4",
        "fri",
        "Isaiah 56:1-8",
        "",
        "Mark 12:35-13:13",
        "",
        "Isaiah 57:15-100",
        "",
        "2 Peter 3",
        "",
    ],
    ["advent-4", "sat", "Isaiah 59", "", "Mark 13:14-100", "", "", "", "", ""],
    [
        "christmas-1",
        "",
        "Isaiah 41:8-20",
        "",
        "Colossians 1:1-20",
        "",
        "Isaiah 12",
        "",
        "Philippians 2:1-11",
        "",
    ],
    [
        "12-29",
        "",
        "Isaiah 55",
        "",
        "John 1:14-18",
        "",
        "Isaiah 60:1-12",
        "",
        "Matthew 11:2-6",
        "",
    ],
    [
        "12-30",
        "",
        "Isaiah 60:13-100",
        "",
        "John 3:16-21",
        "",
        "Isaiah 61",
        "",
        "Matthew 16:13-20",
        "",
    ],
    [
        "12-31",
        "",
        "Isaiah 62",
        "",
        "John 6:41-58",
        "",
        "Deuteronomy 10:12-11:1",
        "",
        "Luke 21:25-36",
        "",
    ],
    [
        "christmas-2",
        "",
        "Isaiah 41:21-100",
        "",
        "1 John 1:1-2:6",
        "",
        "Isaiah 12",
        "",
        "Philippians 2:1-11",
        "",
    ],
    [
        "1-2",
        "",
        "Isaiah 63:1-6",
        "",
        "Matthew 1:18-100",
        "",
        "Isaiah 63:7-100",
        "",
        "1 Thessalonians 1",
        "",
    ],
    [
        "1-3",
        "",
        "Isaiah 64",
        "",
        "Matthew 2",
        "",
        "Isaiah 65:1-16",
        "",
        "1 Thessalonians 2:1-16",
        "",
    ],
    [
        "1-4",
        "",
        "Isaiah 65:17-100",
        "",
        "Matthew 3:1-4:11",
        "",
        "Isaiah 66:1-9",
        "",
        "1 Thessalonians 2:17-3:100",
        "",
    ],
    [
        "1-5",
        "",
        "Isaiah 66:10-100",
        "",
        "Matthew 4:12-5:16",
        "",
        "Isaiah 60:1-7",
        "",
        "Romans 15:8-21",
        "",
    ],
    [
        "epiphany",
        "",
        "Isaiah 49:1-13",
        "",
        "Luke 3:15-22",
        "",
        "Isaiah 60:9-100",
        "",
        "John 2:1-11",
        "",
    ],
    [
        "epiphany",
        "mon",
        "Hosea 2:14-3:100",
        "",
        "Matthew 5:17-100",
        "",
        "Hosea 4:1-11",
        "",
        "1 Thessalonians 4:1-12",
        "",
    ],
    [
        "epiphany",
        "tues",
        "Hosea 5:8-6:6",
        "",
        "Matthew 6:1-18",
        "",
        "Hosea 8",
        "",
        "1 Thessalonians 4:13-5:11",
        "",
    ],
    [
        "epiphany",
        "wed",
        "Hosea 9",
        "",
        "Matthew 6:19-100",
        "",
        "Hosea 10",
        "",
        "1 Thessalonians 4:12-100",
        "",
    ],
    [
        "epiphany",
        "thur",
        "Hosea 11",
        "",
        "Matthew 7",
        "",
        "Hosea 12",
        "",
        "2 Thessalonians 1",
        "",
    ],
    [
        "epiphany",
        "fri",
        "Hosea 13:1-14",
        "",
        "Matthew 8:1-17",
        "",
        "Hosea 14",
        "",
        "2 Thessalonians 2",
        "",
    ],
    [
        "epiphany",
        "sat",
        "Joel 1",
        "",
        "Matthew 8:18-100",
        "",
        "Joel 2:1-14",
        "",
        "2 Thessalonians 3",
        "",
    ],
    [
        "epiphany-1",
        "",
        "Isaiah 49:13-23",
        "Isaiah 42:1-12",
        "Matthew 17:1-13",
        "John 4:1-26",
        "Isaiah 61",
        "Isaiah 43:1-13",
        "Matthew 2",
        "John 12:20-36a",
    ],
    [
        "epiphany-1",
        "mon",
        "Joel 2:15-100",
        "",
        "Matthew 9:1-17",
        "",
        "Joel 3",
        "",
        "Galatians 1",
        "",
    ],
    [
        "epiphany-1",
        "tues",
        "Amos 1",
        "",
        "Matthew 9:18-34",
        "",
        "Amos 2",
        "",
        "Galatians 2",
        "",
    ],
    [
        "epiphany-1",
        "wed",
        "Amos 3",
        "",
        "Matthew 9:35-10:23",
        "",
        "Amos 4",
        "",
        "Galatians 3",
        "",
    ],
    [
        "epiphany-1",
        "thur",
        "Amos 5",
        "",
        "Matthew 10:24-100",
        "",
        "Amos 6",
        "",
        "Galatians 4:1-5:1",
        "",
    ],
    [
        "epiphany-1",
        "fri",
        "Amos 7",
        "",
        "Matthew 11",
        "",
        "Amos 8",
        "",
        "Galatians 5:2-100",
        "",
    ],
    [
        "epiphany-1",
        "sat",
        "Amos 9",
        "",
        "Matthew 12:1-21",
        "",
        "Obadiah",
        "",
        "Galatians 6",
        "",
    ],
    [
        "epiphany-2",
        "",
        "Isaiah 43:14-44:5",
        "Amos 3",
        "Ephesians 1",
        "John 6:22-40",
        "Isaiah 44:6-23",
        "Micah 3:5-100",
        "Mark 1:35-100",
        "John 4:43-5:9",
    ],
    [
        "epiphany-2",
        "mon",
        "Jonah 1-2",
        "",
        "Matthew 12:22-100",
        "",
        "Jonah 3-4",
        "",
        "1 Corinthians 1:1-25",
        "",
    ],
    [
        "epiphany-2",
        "tues",
        "Micah 1",
        "",
        "Matthew 13:1-23",
        "",
        "Micah 2",
        "",
        "1 Corinthians 1:26-2:100",
        "",
    ],
    [
        "epiphany-2",
        "wed",
        "Micah 3",
        "",
        "Matthew 13:24-43",
        "",
        "Micah 4:1-5:1",
        "",
        "1 Corinthians 3",
        "",
    ],
    [
        "epiphany-2",
        "thur",
        "Micah 5:2-100",
        "",
        "Matthew 13:44-100",
        "",
        "Micah 6",
        "",
        "1 Corinthians 4:1-17",
        "",
    ],
    [
        "epiphany-2",
        "fri",
        "Micah 7",
        "",
        "Matthew 14",
        "",
        "Nahum 1",
        "",
        "1 Corinthians 4:18-5:100",
        "",
    ],
    [
        "epiphany-2",
        "sat",
        "Nahum 2",
        "",
        "Matthew 15:1-28",
        "",
        "Nahum 3",
        "",
        "1 Corinthians 6",
        "",
    ],
    [
        "epiphany-3",
        "",
        "Isaiah 45:9-100",
        "Amos 5:6-24",
        "Ephesians 2",
        "John 6:41-100",
        "Isaiah 46:3-100",
        "Micah 4:1-7",
        "Mark 7:24-100",
        "John 9",
    ],
    [
        "epiphany-3",
        "mon",
        "Habakkuk 1",
        "",
        "1 Corinthians 7",
        "",
        "Habakkuk 2",
        "",
        "1 Corinthians 8",
        "",
    ],
    [
        "epiphany-3",
        "tues",
        "Habakkuk 3:2-100",
        "",
        "1 Corinthians 9",
        "",
        "Zephaniah 1",
        "",
        "1 Corinthians 10:1-11:1",
        "",
    ],
    [
        "epiphany-3",
        "wed",
        "Zephaniah 2",
        "",
        "1 Corinthians 11:2-100",
        "",
        "Zephaniah 3",
        "",
        "1 Corinthians 12:1-27",
        "",
    ],
    [
        "epiphany-3",
        "thur",
        "Zechariah 11",
        "",
        "1 Corinthians 12:27-13:100",
        "",
        "Zechariah 13",
        "",
        "1 Corinthians 14:1-19",
        "",
    ],
    [
        "epiphany-3",
        "fri",
        "Malachi 1",
        "",
        "1 Corinthians 14:20-100",
        "",
        "Malachi 2:1-16",
        "",
        "1 Corinthians 15:1-34",
        "",
    ],
    [
        "epiphany-3",
        "sat",
        "Malachi 2:17-3:12",
        "",
        "1 Corinthians 15:35-100",
        "",
        "Malachi 3:13-4:100",
        "",
        "1 Corinthians 16",
        "",
    ],
    [
        "epiphany-4",
        "",
        "Isaiah 48:12-100",
        "Amos 7:1-15",
        "Ephesians 3",
        "John 8:1-11",
        "Isaiah 54:1-14",
        "Micah 5:2-7",
        "Luke 13:1-17",
        "John 10:1-18",
    ],
    [
        "epiphany-4",
        "mon",
        "Jeremiah 1",
        "",
        "2 Corinthians 1:1-2:11",
        "",
        "Jeremiah 2:1-13",
        "",
        "2 Corinthians 2:12-3:100",
        "",
    ],
    [
        "epiphany-4",
        "tues",
        "Jeremiah 4:1-18",
        "",
        "2 Corinthians 4",
        "",
        "Jeremiah 5:1-19",
        "",
        "2 Corinthians 5",
        "",
    ],
    [
        "epiphany-4",
        "wed",
        "Jeremiah 5:20-100",
        "",
        "2 Corinthians 5:20-7:1",
        "",
        "Jeremiah 6:1-21",
        "",
        "2 Corinthians 7:2-100",
        "",
    ],
    [
        "epiphany-4",
        "thur",
        "Jeremiah 7:1-28",
        "",
        "2 Corinthians 8",
        "",
        "Jeremiah 8",
        "",
        "2 Corinthians 9",
        "",
    ],
    [
        "epiphany-4",
        "fri",
        "Jeremiah 9:1-24",
        "",
        "2 Corinthians 10",
        "",
        "Jeremiah 10",
        "",
        "2 Corinthians 11",
        "",
    ],
    [
        "epiphany-4",
        "sat",
        "Jeremiah 14",
        "",
        "2 Corinthians 12:1-13",
        "",
        "Jeremiah 15",
        "",
        "2 Corinthians 12:14-13:100",
        "",
    ],
    [
        "epiphany-5",
        "",
        "Isaiah 59:12-20",
        "Amos 8:4-12",
        "Ephesians 5:15-6:9",
        "John 7:14-36",
        "Isaiah 60",
        "Micah 6:1-8",
        "Mark 2:1-12",
        "John 5:19-29",
    ],
    [
        "epiphany-5",
        "mon",
        "Jeremiah 17:1-18",
        "",
        "Acts 15:1-29",
        "",
        "Jeremiah 17:19-100",
        "",
        "Acts 15:30-16:5",
        "",
    ],
    [
        "epiphany-5",
        "tues",
        "Jeremiah 18:1-17",
        "",
        "Acts 16:6-100",
        "",
        "Jeremiah 20",
        "",
        "Acts 17:1-15",
        "",
    ],
    [
        "epiphany-5",
        "wed",
        "Jeremiah 23:9-32",
        "",
        "Acts 17:16-100",
        "",
        "Jeremiah 30:1-22",
        "",
        "Acts 18:1-23",
        "",
    ],
    [
        "epiphany-5",
        "thur",
        "Jeremiah 31:1-20",
        "",
        "Acts 18:24-19:7",
        "",
        "Jeremiah 31:23-100",
        "",
        "Acts 19:8-20",
        "",
    ],
    [
        "epiphany-5",
        "fri",
        "Jeremiah 33:1-13",
        "",
        "Acts 19:21-100",
        "",
        "Jeremiah 33:14-100",
        "",
        "Acts 20:1-16",
        "",
    ],
    [
        "epiphany-5",
        "sat",
        "Jeremiah 35:1-11",
        "",
        "Acts 20:17-100",
        "",
        "Jeremiah 35:12-100",
        "",
        "Acts 21:1-16",
        "",
    ],
    [
        "epiphany-6",
        "",
        "Isaiah 63:7-16",
        "Amos 9:5-100",
        "Ephesians 6:10-100",
        "John 7:37-52",
        "Isaiah 64",
        "Micah 7:1-9",
        "Luke 7:1-10",
        "John 8:12-30",
    ],
    [
        "epiphany-6",
        "mon",
        "Tobit 4:5-19",
        "",
        "Acts 21:17-36",
        "",
        "Tobit 13",
        "",
        "Acts 21:37-22:22",
        "",
    ],
    [
        "epiphany-6",
        "tues",
        "Baruch 1:15-2:10",
        "",
        "Acts 22:23-23:11",
        "",
        "Baruch 2:11-100",
        "",
        "Acts 23:12-100",
        "",
    ],
    [
        "epiphany-6",
        "wed",
        "Baruch 3:1-8",
        "",
        "Acts 24:1-23",
        "",
        "Baruch 3:9-100",
        "",
        "Acts 24:24-25:12",
        "",
    ],
    [
        "epiphany-6",
        "thur",
        "Baruch 4:21-30",
        "",
        "Acts 25:13-100",
        "",
        "Baruch 4:36-5:100",
        "",
        "Acts 26",
        "",
    ],
    [
        "epiphany-6",
        "fri",
        "2 Maccabees 4:7-17",
        "",
        "Acts 27:1-26",
        "",
        "2 Maccabees 6:12-100",
        "",
        "Acts 27:27-100",
        "",
    ],
    [
        "epiphany-6",
        "sat",
        "2 Maccabees 7:1-19",
        "",
        "Acts 28:1-15",
        "",
        "2 Maccabees 7:20-41",
        "",
        "Acts 28:16-100",
        "",
    ],
    [
        "septuagesima",
        "",
        "Genesis 1:1-2:3",
        "Genesis 1:1-2:3",
        "John 1:1-18",
        "Revelation 21:1-14",
        "Genesis 2:4-100",
        "Genesis 2:4-100",
        "Revelation 4",
        "Mark 10:1-16",
    ],
    [
        "septuagesima",
        "mon",
        "Genesis 3",
        "",
        "Matthew 15:29-16:12",
        "",
        "Genesis 4:1-16",
        "",
        "Romans 1",
        "",
    ],
    [
        "septuagesima",
        "tues",
        "Genesis 6:5-100",
        "",
        "Matthew 16:13-100",
        "",
        "Genesis 7",
        "",
        "Romans 2",
        "",
    ],
    [
        "septuagesima",
        "wed",
        "Genesis 8:1-14",
        "",
        "Matthew 17:1-23",
        "",
        "Genesis 8:15-9:17",
        "",
        "Romans 3",
        "",
    ],
    [
        "septuagesima",
        "thur",
        "Genesis 11:1-9",
        "",
        "Matthew 17:24-18:14",
        "",
        "Genesis 11:27-12:10",
        "",
        "Romans 4",
        "",
    ],
    [
        "septuagesima",
        "fri",
        "Genesis 13",
        "",
        "Matthew 18:15-100",
        "",
        "Genesis 14",
        "",
        "Romans 5",
        "",
    ],
    [
        "septuagesima",
        "sat",
        "Genesis 15",
        "",
        "Matthew 19:1-15",
        "",
        "Genesis 16",
        "",
        "Romans 6",
        "",
    ],
    [
        "sexagesima",
        "",
        "Genesis 3",
        "Genesis 3",
        "1 Corinthians 10:1-13",
        "1 Corinthians 6:12-100",
        "Genesis 4:1-16",
        "Genesis 37",
        "1 John 3:1-15",
        "Luke 10:25-37",
    ],
    [
        "sexagesima",
        "mon",
        "Genesis 17:1-22",
        "",
        "Matthew 19:16-20:16",
        "",
        "Genesis 18",
        "",
        "Romans 7",
        "",
    ],
    [
        "sexagesima",
        "tues",
        "Genesis 19:1-3; Genesis 19:12-29",
        "",
        "Matthew 20:17-100",
        "",
        "Genesis 21",
        "",
        "Romans 8:1-17",
        "",
    ],
    [
        "sexagesima",
        "wed",
        "Genesis 22:1-19",
        "",
        "Matthew 21:1-22",
        "",
        "Genesis 23",
        "",
        "Romans 8:18-100",
        "",
    ],
    [
        "sexagesima",
        "thur",
        "Genesis 24:1-28",
        "",
        "Matthew 21:23-100",
        "",
        "Genesis 24:29-100",
        "",
        "Romans 9",
        "",
    ],
    [
        "sexagesima",
        "fri",
        "Genesis 25:7-11; Genesis 25:19-100",
        "",
        "Matthew 22:1-33",
        "",
        "Genesis 27:1-40",
        "",
        "Romans 10",
        "",
    ],
    [
        "sexagesima",
        "sat",
        "Genesis 27:41-28:100",
        "",
        "Matthew 22:34-23:12",
        "",
        "Genesis 29:1-20",
        "",
        "Romans 11",
        "",
    ],
    [
        "quinquagesima",
        "",
        "Genesis 12:1-9",
        "Genesis 12:1-9",
        "1 Corinthians 12:4-100",
        "1 Corinthians 12:4-100",
        "Genesis 6:5-100",
        "Genesis 41:1-40",
        "Luke 17:20-100",
        "1 John 4:7-100",
    ],
    [
        "quinquagesima",
        "mon",
        "Genesis 31:1-9; Genesis 31:14-21",
        "",
        "Matthew 23:13-100",
        "",
        "Genesis 31:22-32:2",
        "",
        "Romans 12",
        "",
    ],
    [
        "quinquagesima",
        "tues",
        "Genesis 32:3-30",
        "",
        "Matthew 24:1-28",
        "",
        "Genesis 33",
        "",
        "Romans 13",
        "",
    ],
    [
        "ash-wednesday",
        "wed",
        "Isaiah 58",
        "",
        "Mark 2:13-22",
        "",
        "Daniel 9:3-19",
        "",
        "Hebrews 3:12-4:13",
        "",
    ],
    [
        "ash-wednesday",
        "thur",
        "Genesis 35:1-20",
        "",
        "Matthew 24:29-100",
        "",
        "Genesis 37",
        "",
        "Romans 14",
        "",
    ],
    [
        "ash-wednesday",
        "fri",
        "Genesis 39",
        "",
        "Matthew 25:1-30",
        "",
        "Genesis 40",
        "",
        "Romans 15",
        "",
    ],
    [
        "ash-wednesday",
        "sat",
        "Genesis 41:1-40",
        "",
        "Matthew 25:31-100",
        "",
        "Genesis 41:41-100",
        "",
        "Romans 16",
        "",
    ],
    [
        "lent-1",
        "",
        "Genesis 13",
        "Genesis 27:1-40",
        "Luke 15:1-10",
        "Hebrews 4:14-5:10",
        "Genesis 8:15-9:17",
        "Genesis 42",
        "Mark 14:1-26",
        "Luke 22:1-23",
    ],
    [
        "lent-1",
        "mon",
        "Genesis 42",
        "Genesis 43:1-14",
        "Matthew 26:1-30",
        "Matthew 26:1-30",
        "Genesis 43",
        "Genesis 43:15-100",
        "Philippians 1",
        "Philippians 1",
    ],
    [
        "lent-1",
        "tues",
        "Genesis 44",
        "",
        "Matthew 26:31-56",
        "",
        "Genesis 45:1-15",
        "",
        "Philippians 2",
        "",
    ],
    [
        "lent-1",
        "wed",
        "Genesis 45:16-46:7",
        "",
        "Matthew 26:57-100",
        "",
        "Genesis 46:26-47:12",
        "",
        "Philippians 3",
        "",
    ],
    [
        "lent-1",
        "thur",
        "Genesis 47:13-100",
        "",
        "Matthew 27:1-26",
        "",
        "Genesis 48",
        "",
        "Philippians 4",
        "",
    ],
    [
        "lent-1",
        "fri",
        "Genesis 49:1-32",
        "",
        "Matthew 27:27-56",
        "",
        "Genesis 49:33-50:100",
        "",
        "Colossians 1:1-20",
        "",
    ],
    [
        "lent-1",
        "sat",
        "Exodus 1:1-14; Exodus 1:22-2:10",
        "",
        "Matthew 27:57-28:100",
        "",
        "Exodus 2:11-22",
        "",
        "Colossians 1:21-2:7",
        "",
    ],
    [
        "lent-2",
        "",
        "Genesis 18:1-16",
        "Genesis 28:10-100",
        "Luke 15:11-100",
        "Hebrews 10:19-100",
        "Genesis 11:1-9",
        "Genesis 43:1-100",
        "Mark 14:27-52",
        "Luke 22:24-53",
    ],
    [
        "lent-2",
        "mon",
        "Exodus 2:23-3:100",
        "",
        "John 1:1-28",
        "",
        "Exodus 4:1-23",
        "",
        "Colossians 2:8-3:11",
        "",
    ],
    [
        "lent-2",
        "tues",
        "Exodus 4:27-6:1",
        "",
        "John 1:29-100",
        "",
        "Exodus 6:2-13; Exodus 7:1-7",
        "",
        "Colossians 3:12-4:1",
        "",
    ],
    [
        "lent-2",
        "wed",
        "Exodus 7:8-100",
        "",
        "John 2",
        "",
        "Exodus 8:1-19",
        "",
        "Colossians 4:2-100",
        "",
    ],
    [
        "lent-2",
        "thur",
        "Exodus 8:20-9:12",
        "",
        "John 3:1-21",
        "",
        "Exodus 9:13-100",
        "",
        "Philemon",
        "",
    ],
    [
        "lent-2",
        "fri",
        "Exodus 10:1-20",
        "",
        "John 3:22-100",
        "",
        "Exodus 10:21-11:100",
        "",
        "Ephesians 1",
        "",
    ],
    [
        "lent-2",
        "sat",
        "Exodus 12:1-20",
        "",
        "John 4:1-26",
        "",
        "Exodus 12:21-36",
        "",
        "Ephesians 2",
        "",
    ],
    [
        "lent-3",
        "",
        "Genesis 18:17-100",
        "Genesis 29:1-20",
        "Luke 18:1-14",
        "Hebrews 12:14-100",
        "Genesis 24:1-28",
        "Genesis 44:1-45:8",
        "Mark 14:53-100",
        "Luke 22:54-100",
    ],
    [
        "lent-3",
        "mon",
        "Exodus 12:37-100",
        "",
        "John 4:27-100",
        "",
        "Exodus 13:1-16",
        "",
        "Ephesians 3",
        "",
    ],
    [
        "lent-3",
        "tues",
        "Exodus 13:17-14:14",
        "",
        "John 5:1-23",
        "",
        "Exodus 14:15-100",
        "",
        "Ephesians 4:1-16",
        "",
    ],
    [
        "lent-3",
        "wed",
        "Exodus 15:1-26",
        "",
        "John 5:24-100",
        "",
        "Exodus 15:27-16:35",
        "",
        "Ephesians 4:17-30",
        "",
    ],
    [
        "lent-3",
        "thur",
        "Exodus 17",
        "",
        "John 6:1-21",
        "",
        "Exodus 18",
        "",
        "Ephesians 4:31-5:21",
        "",
    ],
    [
        "lent-3",
        "fri",
        "Exodus 19",
        "",
        "John 6:22-40",
        "",
        "Exodus 20:1-21",
        "",
        "Ephesians 5:22-6:9",
        "",
    ],
    [
        "lent-3",
        "sat",
        "Exodus 22:20-23:17",
        "",
        "John 6:41-100",
        "",
        "Exodus 23:20-100",
        "",
        "Ephesians 6:10-100",
        "",
    ],
    [
        "lent-4",
        "",
        "Exodus 1:8-14; Exodus 1:22-2:10",
        "Genesis 32:3-30",
        "Luke 18:35-19:10",
        "Hebrews 13:1-21",
        "Genesis 24:29-100",
        "Genesis 45:16-46:7",
        "Mark 15:1-21",
        "Luke 23:1-25",
    ],
    [
        "lent-4",
        "mon",
        "Exodus 24",
        "",
        "John 7:1-24",
        "",
        "Exodus 25:1-22",
        "",
        "1 Timothy 1:1-17",
        "",
    ],
    [
        "lent-4",
        "tues",
        "Exodus 28:1-4; Exodus 29:1-9",
        "",
        "John 7:25-100",
        "",
        "Exodus 29:38-30:16",
        "",
        "1 Timothy 1:18-2:100",
        "",
    ],
    [
        "lent-4",
        "wed",
        "Exodus 32",
        "",
        "John 8:1-30",
        "",
        "Exodus 33",
        "",
        "1 Timothy 3",
        "",
    ],
    [
        "lent-4",
        "thur",
        "Exodus 34",
        "",
        "John 8:31-100",
        "",
        "Exodus 35:20-36:7",
        "",
        "1 Timothy 4",
        "",
    ],
    [
        "lent-4",
        "fri",
        "Exodus 40:17-100",
        "",
        "John 9",
        "",
        "Leviticus 6:8-100",
        "",
        "1 Timothy 5",
        "",
    ],
    [
        "lent-4",
        "sat",
        "Leviticus 19:1-18; Leviticus 19:30-100",
        "",
        "John 10:1-21",
        "",
        "Leviticus 25:1-24",
        "",
        "1 Timothy 6",
        "",
    ],
    [
        "lent-5",
        "",
        "Exodus 2:23-3:20",
        "Exodus 2:23-3:20",
        "Matthew 20:17-28",
        "Matthew 20:17-28",
        "Exodus 4:27-6:1",
        "Exodus 6:2-13",
        "Mark 15:22-39",
        "Luke 23:26-49",
    ],
    [
        "lent-5",
        "mon",
        "Numbers 6",
        "",
        "John 10:22-100",
        "",
        "Numbers 9:15-100; Numbers 10:29-100",
        "",
        "Titus 1:1-2:8",
        "",
    ],
    [
        "lent-5",
        "tues",
        "Numbers 11:10-23",
        "",
        "John 11:1-44",
        "",
        "Numbers 12",
        "",
        "Titus 2:9-3:100",
        "",
    ],
    [
        "lent-5",
        "wed",
        "Numbers 13:1-3; Numbers 13:17-100",
        "",
        "John 11:45-100",
        "",
        "Numbers 14:1-25",
        "",
        "2 Timothy 1",
        "",
    ],
    [
        "lent-5",
        "thur",
        "Numbers 16:1-35",
        "",
        "John 12:1-19",
        "",
        "Numbers 16:36-17:100",
        "",
        "2 Timothy 2",
        "",
    ],
    [
        "lent-5",
        "fri",
        "Numbers 20",
        "",
        "John 12:20-100",
        "",
        "Numbers 22:1-35",
        "",
        "2 Timothy 3",
        "",
    ],
    [
        "lent-5",
        "sat",
        "Numbers 22:36-23:26",
        "",
        "John 13",
        "",
        "Numbers 23:27-24:100",
        "",
        "2 Timothy 4",
        "",
    ],
    [
        "lent-6",
        "",
        "Exodus 11",
        "",
        "Matthew 26",
        "",
        "Isaiah 52:13-53:100",
        "",
        "Luke 19:29-100",
        "",
    ],
    [
        "lent-6",
        "mon",
        "Lamentations 1:1-12",
        "",
        "John 14:1-14",
        "",
        "Lamentations 2:8-19",
        "",
        "John 14:15-100",
        "",
    ],
    [
        "lent-6",
        "tues",
        "Lamentations 3:1-30",
        "",
        "John 15:1-16",
        "",
        "Lamentations 3:40-51",
        "",
        "John 15:17-100",
        "",
    ],
    [
        "lent-6",
        "wed",
        "Isaiah 42:1-9",
        "",
        "John 16:1-15",
        "",
        "Numbers 21:4-9",
        "",
        "John 16:16-100",
        "",
    ],
    [
        "lent-6",
        "thur",
        "Exodus 24:1-11",
        "",
        "John 17",
        "",
        "Leviticus 16:2-24",
        "",
        "John 13:1-35",
        "",
    ],
    [
        "good-friday",
        "fri",
        "Genesis 22:1-18",
        "",
        "John 18",
        "",
        "Isaiah 50:4-10",
        "",
        "John 19:31-100",
        "",
    ],
    [
        "good-friday",
        "sat",
        "Zechariah 9:9-12",
        "",
        "1 Peter 2:19-100",
        "",
        "Job 19:21-27",
        "",
        "John 2:13-22",
        "",
    ],
    [
        "easter-day",
        "",
        "Exodus 12:1-14",
        "",
        "Revelation 1:4-18",
        "",
        "Exodus 14:5-100",
        "",
        "John 20:1-23",
        "",
    ],
    [
        "easter-monday",
        "mon",
        "Exodus 15:1-18",
        "",
        "Luke 24:1-12",
        "",
        "Isaiah 12",
        "",
        "Revelation 7:9-100",
        "",
    ],
    [
        "easter-tuesday",
        "tues",
        "Isaiah 25:1-9",
        "",
        "1 Peter 1:1-12",
        "",
        "Isaiah 26:1-19",
        "",
        "Matthew 28:1-15",
        "",
    ],
    [
        "easter-tuesday",
        "wed",
        "Isaiah 61",
        "",
        "1 Peter 1:13-100",
        "",
        "Song of Songs 2:8-100",
        "",
        "Matthew 28:16-100",
        "",
    ],
    [
        "easter-tuesday",
        "thur",
        "Job 14:1-15",
        "",
        "1 Thessalonians 4:13-100",
        "",
        "Daniel 12",
        "",
        "Mark 16",
        "",
    ],
    [
        "easter-tuesday",
        "fri",
        "Zephaniah 3:14-100",
        "",
        "Acts 17:16-31",
        "",
        "2 Kings 4:8-37",
        "",
        "John 21:1-14",
        "",
    ],
    [
        "easter-tuesday",
        "sat",
        "Jeremiah 31:1-14",
        "",
        "Acts 26:1-23",
        "",
        "Micah 7:7-100",
        "",
        "John 21:15-100",
        "",
    ],
    [
        "easter-1",
        "",
        "Isaiah 51:1-16",
        "Ezekiel 37:1-14",
        "Luke 24:13-35",
        "Luke 24:13-35",
        "Exodus 15:1-18",
        "1 Kings 17:8-100",
        "John 20:24-100",
        "John 20:24-100",
    ],
    [
        "easter-1",
        "mon",
        "Deuteronomy 1:3-18",
        "",
        "Acts 1:1-14",
        "",
        "Deuteronomy 1:19-100",
        "",
        "Acts 1:15-100",
        "",
    ],
    [
        "easter-1",
        "tues",
        "Deuteronomy 2:1-25",
        "",
        "Acts 2:1-21",
        "",
        "Deuteronomy 2:26-3:5",
        "",
        "Acts 2:22-100",
        "",
    ],
    [
        "easter-1",
        "wed",
        "Deuteronomy 3:18-100",
        "",
        "Acts 3:1-4:4",
        "",
        "Deuteronomy 4:1-24",
        "",
        "Acts 4:5-31",
        "",
    ],
    [
        "easter-1",
        "thur",
        "Deuteronomy 4:25-40",
        "",
        "Acts 4:32-5:11",
        "",
        "Deuteronomy 5:1-21",
        "",
        "Acts 5:12-100",
        "",
    ],
    [
        "easter-1",
        "fri",
        "Deuteronomy 5:22-100",
        "",
        "Acts 6:1-7:16",
        "",
        "Deuteronomy 6",
        "",
        "Acts 7:17-34",
        "",
    ],
    [
        "easter-1",
        "sat",
        "Deuteronomy 7:1-11",
        "",
        "Acts 7:35-8:4",
        "",
        "Deuteronomy 7:12-100",
        "",
        "Acts 8:4-25",
        "",
    ],
    [
        "easter-2",
        "",
        "Exodus 16:2-15",
        "Isaiah 55",
        "1 Corinthians 15:1-26",
        "Mark 5:21-100",
        "Exodus 24",
        "Deuteronomy 4:25-40",
        "John 21:1-14",
        "Revelation 2:1-17",
    ],
    [
        "easter-2",
        "mon",
        "Deuteronomy 8",
        "",
        "Acts 8:26-100",
        "",
        "Deuteronomy 9:1-10",
        "",
        "Acts 9:1-31",
        "",
    ],
    [
        "easter-2",
        "tues",
        "Deuteronomy 9:11-100",
        "",
        "Acts 9:32-100",
        "",
        "Deuteronomy 10",
        "",
        "Acts 10:1-23",
        "",
    ],
    [
        "easter-2",
        "wed",
        "Deuteronomy 11:1-12",
        "",
        "Acts 10:24-100",
        "",
        "Deuteronomy 11:13-100",
        "",
        "Acts 11:1-18",
        "",
    ],
    [
        "easter-2",
        "thur",
        "Deuteronomy 12:1-14",
        "",
        "Acts 11:19-100",
        "",
        "Deuteronomy 15:1-18",
        "",
        "Acts 12:1-24",
        "",
    ],
    [
        "easter-2",
        "fri",
        "Deuteronomy 16:1-20",
        "",
        "Acts 12:25-13:12",
        "",
        "Deuteronomy 17:8-100",
        "",
        "Acts 13:13-43",
        "",
    ],
    [
        "easter-2",
        "sat",
        "Deuteronomy 18:9-100",
        "",
        "Acts 13:44-14:7",
        "",
        "Deuteronomy 19",
        "",
        "Acts 14:8-100",
        "",
    ],
    [
        "easter-3",
        "",
        "Exodus 32:1-14; 32:30-100",
        "Numbers 13:1-2; 13:17-100",
        "1 Corinthians 15:35-100",
        "Luke 7:11-17",
        "Exodus 33:7-100",
        "Deuteronomy 5:1-21",
        "John 21:15-100",
        "Revelation 2:18-3:6",
    ],
    [
        "easter-3",
        "mon",
        "Deuteronomy 21:22-22:8",
        "",
        "Acts 15:1-21",
        "",
        "Deuteronomy 24:5-100",
        "",
        "Acts 15:22-35",
        "",
    ],
    [
        "easter-3",
        "tues",
        "Deuteronomy 26",
        "",
        "Acts 15:36-16:5",
        "",
        "Deuteronomy 28:58-100",
        "",
        "Acts 16:6-100",
        "",
    ],
    [
        "easter-3",
        "wed",
        "Deuteronomy 29:10-100",
        "",
        "Acts 17:1-15",
        "",
        "Deuteronomy 30",
        "",
        "Acts 17:16-100",
        "",
    ],
    [
        "easter-3",
        "thur",
        "Deuteronomy 31:1-13",
        "",
        "Acts 18:1-23",
        "",
        "Deuteronomy 31:14-29",
        "",
        "Acts 18:24-19:7",
        "",
    ],
    [
        "easter-3",
        "fri",
        "Deuteronomy 31:30-32:14",
        "",
        "Acts 19:8-20",
        "",
        "Deuteronomy 32:15-47",
        "",
        "Acts 19:21-100",
        "",
    ],
    [
        "easter-3",
        "sat",
        "Deuteronomy 33",
        "",
        "Acts 20:1-16",
        "",
        "Deuteronomy 32:48-100; 34:1-100",
        "",
        "Acts 20:17-100",
        "",
    ],
    [
        "easter-4",
        "",
        "Exodus 34:1-10",
        "Numbers 22:1-35",
        "1 Peter 3:8-100",
        "John 11:1-44",
        "Exodus 35:30-36:1",
        "Deuteronomy 10:12-11:1",
        "Luke 16:19-100",
        "Revelation 3:7-100",
    ],
    [
        "easter-4",
        "mon",
        "Joshua 1",
        "",
        "Acts 21:1-16",
        "",
        "Joshua 2",
        "",
        "Acts 21:17-36",
        "",
    ],
    [
        "easter-4",
        "tues",
        "Joshua 3",
        "",
        "Acts 21:37-22:22",
        "",
        "Joshua 4:1-5:1",
        "",
        "Acts 22:23-23:11",
        "",
    ],
    [
        "easter-4",
        "wed",
        "Joshua 5:13-6:20",
        "",
        "Acts 23:12-100",
        "",
        "Joshua 7",
        "",
        "Acts 24:1-23",
        "",
    ],
    [
        "easter-4",
        "thur",
        "Joshua 9:3-100",
        "",
        "Acts 24:24-25:12",
        "",
        "Joshua 10:1-15",
        "",
        "Acts 25:13-100",
        "",
    ],
    [
        "easter-4",
        "fri",
        "Joshua 21:43-22:8",
        "",
        "Acts 26",
        "",
        "Joshua 22:9-100",
        "",
        "Acts 27",
        "",
    ],
    [
        "easter-4",
        "sat",
        "Joshua 23",
        "",
        "Acts 28:1-15",
        "",
        "Joshua 24:1-28",
        "",
        "Acts 28:16-100",
        "",
    ],
    [
        "easter-5",
        "",
        "Deuteronomy 34",
        "Numbers 22:36-23:12",
        "Acts 13:26-43",
        "Romans 6:1-14",
        "Deuteronomy 6",
        "Deuteronomy 28:1-14",
        "Luke 10:38-11:13",
        "Mark 4:1-20",
    ],
    [
        "easter-5",
        "mon",
        "Deuteronomy 7:6-13",
        "",
        "Matthew 6:5-18",
        "",
        "Deuteronomy 8",
        "",
        "Matthew 6:19-100",
        "",
    ],
    [
        "easter-5",
        "tues",
        "Deuteronomy 11:8-21",
        "",
        "Luke 5:1-11",
        "",
        "1 Kings 8:22-43",
        "",
        "James 5:1-18",
        "",
    ],
    [
        "easter-5",
        "wed",
        "Joel 2:21-27",
        "",
        "John 6:22-40",
        "",
        "Song of the Three Young Men 1:29-37",
        "",
        "Matthew 28:16-100",
        "",
    ],
    [
        "ascension",
        "thur",
        "2 Kings 2:1-15",
        "",
        "John 17",
        "",
        "Daniel 7:9-10; 7:13-14",
        "",
        "Hebrews 1",
        "",
    ],
    [
        "ascension",
        "fri",
        "Judges 2:6-100",
        "",
        "Hebrews 2",
        "",
        "Judges 4",
        "",
        "Hebrews 3",
        "",
    ],
    [
        "ascension",
        "sat",
        "Judges 5",
        "",
        "Hebrews 4:1-13",
        "",
        "Judges 6:1-24",
        "",
        "Hebrews 4:14-5:10",
        "",
    ],
    [
        "easter-6",
        "",
        "Isaiah 65:17-100",
        "Isaiah 52:1-12",
        "Luke 24:36-100",
        "Ephesians 4:1-16",
        "Jeremiah 31:1-13",
        "Isaiah 62",
        "John 14:1-14",
        "Revelation 5",
    ],
    [
        "easter-6",
        "mon",
        "Judges 6:25-100",
        "",
        "Hebrews 5:11-6:100",
        "",
        "Judges 7",
        "",
        "Hebrews 7",
        "",
    ],
    [
        "easter-6",
        "tues",
        "Judges 10:17-11:28",
        "",
        "Hebrews 8",
        "",
        "Judges 11:29-12:7",
        "",
        "Hebrews 9:1-14",
        "",
    ],
    [
        "easter-6",
        "wed",
        "Judges 13",
        "",
        "Hebrews 9:15-100",
        "",
        "Judges 14",
        "",
        "Hebrews 10:1-18",
        "",
    ],
    [
        "easter-6",
        "thur",
        "Judges 16:4-100",
        "",
        "Hebrews 10:19-100",
        "",
        "Ruth 1",
        "",
        "Hebrews 11",
        "",
    ],
    [
        "easter-6",
        "fri",
        "Ruth 2",
        "",
        "Hebrews 12:1-13",
        "",
        "Ruth 3",
        "",
        "Hebrews 12:14-100",
        "",
    ],
    [
        "easter-6",
        "sat",
        "Ruth 4:1-17",
        "",
        "Hebrews 13",
        "",
        "Deuteronomy 16:9-12",
        "",
        "Acts 18:24-19:7",
        "",
    ],
    [
        "whitsun",
        "",
        "Joel 2:28-100",
        "",
        "Romans 8:1-17",
        "",
        "Isaiah 11:1-9",
        "",
        "Romans 8:18-100",
        "",
    ],
    [
        "monday-after-whitsun",
        "mon",
        "Ezekiel 11:14-20",
        "",
        "Acts 2:12-36",
        "",
        "Wisdom 1:1-7",
        "",
        "Acts 2:37-100",
        "",
    ],
    [
        "tuesday-after-whitsun",
        "tues",
        "Ezekiel 37:1-14",
        "",
        "1 Corinthians 12:1-13",
        "",
        "Wisdom 7:15-8:1",
        "",
        "1 Corinthians 12:27-13:100",
        "",
    ],
    [
        "tuesday-after-whitsun",
        "wed",
        "1 Kings 19:1-18",
        "",
        "1 Corinthians 2",
        "",
        "Wisdom 9",
        "",
        "1 Corinthians 3",
        "",
    ],
    [
        "tuesday-after-whitsun",
        "thur",
        "2 Samuel 23:1-5",
        "",
        "Ephesians 6:10-20",
        "",
        "Exodus 35:30-36:1",
        "",
        "Galatians 5:13-100",
        "",
    ],
    [
        "tuesday-after-whitsun",
        "fri",
        "Numbers 11:16-17; 11:24-29",
        "",
        "2 Corinthians 5:14-6:10",
        "",
        "Jeremiah 31:31-34",
        "",
        "2 Corinthians 3",
        "",
    ],
    [
        "tuesday-after-whitsun",
        "sat",
        "Numbers 27:15-100",
        "",
        "Matthew 9:35-10:20",
        "",
        "Isaiah 61",
        "",
        "2 Timothy 1:3-14",
        "",
    ],
    [
        "trinity-sunday",
        "",
        "Isaiah 6:1-8",
        "",
        "Mark 1:1-13",
        "",
        "Isaiah 40:12-100",
        "",
        "1 Peter 1:3-12",
        "",
    ],
    [
        "trinity-sunday",
        "mon",
        "1 Samuel 1",
        "",
        "James 1",
        "",
        "1 Samuel 2:1-21",
        "",
        "Mark 1:14-31",
        "",
    ],
    [
        "trinity-sunday",
        "tues",
        "1 Samuel 2:22-100",
        "",
        "James 2:1-13",
        "",
        "1 Samuel 3",
        "",
        "Mark 1:32-100",
        "",
    ],
    [
        "trinity-sunday",
        "wed",
        "1 Samuel 4",
        "",
        "James 2:14-100",
        "",
        "1 Samuel 7",
        "",
        "Mark 2:1-22",
        "",
    ],
    [
        "trinity-sunday",
        "thur",
        "1 Samuel 8",
        "",
        "James 3",
        "",
        "1 Samuel 9:1-25",
        "",
        "Mark 2:23-3:12",
        "",
    ],
    [
        "trinity-sunday",
        "fri",
        "1 Samuel 9:26-10:16",
        "",
        "James 4",
        "",
        "1 Samuel 10:17-100",
        "",
        "Mark 3:13-100",
        "",
    ],
    [
        "trinity-sunday",
        "sat",
        "1 Samuel 11",
        "",
        "James 5",
        "",
        "1 Samuel 12",
        "",
        "Mark 4:1-34",
        "",
    ],
    [
        "trinity-1",
        "",
        "Joshua 1:1-9",
        "Jeremiah 5:1-19",
        "Mark 1:21-34",
        "Acts 9:1-22",
        "Hosea 6:1-6",
        "1 Kings 3:5-14",
        "Acts 1:1-14",
        "John 13:1-20",
    ],
    [
        "trinity-1",
        "mon",
        "1 Samuel 13",
        "",
        "1 Peter 1:1-21",
        "",
        "1 Samuel 14:1-23",
        "",
        "Mark 4:35-5:20",
        "",
    ],
    [
        "trinity-1",
        "tues",
        "1 Samuel 14:24-48",
        "",
        "1 Peter 1:22-2:10",
        "",
        "1 Samuel 15",
        "",
        "Mark 5:21-100",
        "",
    ],
    [
        "trinity-1",
        "wed",
        "1 Samuel 16",
        "",
        "1 Peter 2:11-3:7",
        "",
        "1 Samuel 17:1-30",
        "",
        "Mark 6:1-29",
        "",
    ],
    [
        "trinity-1",
        "thur",
        "1 Samuel 17:31-54",
        "",
        "1 Peter 3:8-4:6",
        "",
        "1 Samuel 17:55-18:16",
        "",
        "Mark 6:30-100",
        "",
    ],
    [
        "trinity-1",
        "fri",
        "1 Samuel 19",
        "",
        "1 Peter 4:7-100",
        "",
        "1 Samuel 20:1-17",
        "",
        "Mark 7:1-23",
        "",
    ],
    [
        "trinity-1",
        "sat",
        "1 Samuel 20:18-100",
        "",
        "1 Peter 5",
        "",
        "1 Samuel 21:1-22:5",
        "",
        "Mark 7:24-8:10",
        "",
    ],
    [
        "trinity-2",
        "",
        "Joshua 2",
        "Jeremiah 7:1-16",
        "Mark 2:23-3:19",
        "Acts 13:1-13",
        "Hosea 11:1-9a",
        "1 Kings 8:22-30",
        "Acts 2:1-21",
        "John 13:21-100",
    ],
    [
        "trinity-2",
        "mon",
        "1 Samuel 22:6-100",
        "",
        "2 Peter 1",
        "",
        "1 Samuel 23",
        "",
        "Mark 8:11-9:1",
        "",
    ],
    [
        "trinity-2",
        "tues",
        "1 Samuel 24",
        "",
        "2 Peter 2",
        "",
        "1 Samuel 25:2-42",
        "",
        "Mark 9:2-29",
        "",
    ],
    [
        "trinity-2",
        "wed",
        "1 Samuel 26",
        "",
        "2 Peter 3",
        "",
        "1 Samuel 28:3-100",
        "",
        "Mark 9:30-100",
        "",
    ],
    [
        "trinity-2",
        "thur",
        "1 Samuel 31",
        "",
        "Jude",
        "",
        "2 Samuel 1",
        "",
        "Mark 10:1-31",
        "",
    ],
    [
        "trinity-2",
        "fri",
        "2 Samuel 2:1-3:1",
        "",
        "1 John 1:1-2:6",
        "",
        "2 Samuel 3:17-100",
        "",
        "Mark 10:32-100",
        "",
    ],
    [
        "trinity-2",
        "sat",
        "2 Samuel 5:1-12",
        "",
        "1 John 2:7-100",
        "",
        "2 Samuel 6",
        "",
        "Mark 11:1-26",
        "",
    ],
    [
        "trinity-3",
        "",
        "Joshua 3",
        "Jeremiah 17:5-14",
        "Mark 3:20-100",
        "Acts 16:6-34",
        "Hosea 14",
        "1 Kings 10:1-13",
        "Acts 2:22-42",
        "John 14:1-14",
    ],
    [
        "trinity-3",
        "mon",
        "2 Samuel 7",
        "",
        "1 John 3:1-12",
        "",
        "2 Samuel 9",
        "",
        "Mark 11:27-12:12",
        "",
    ],
    [
        "trinity-3",
        "tues",
        "2 Samuel 11",
        "",
        "1 John 3:13-4:6",
        "",
        "2 Samuel 12:1-23",
        "",
        "Mark 12:13-34",
        "",
    ],
    [
        "trinity-3",
        "wed",
        "2 Samuel 13:38-14:24",
        "",
        "1 John 4:7-100",
        "",
        "2 Samuel 14:25-15:12",
        "",
        "Mark 12:35-13:13",
        "",
    ],
    [
        "trinity-3",
        "thur",
        "2 Samuel 15:13-100",
        "",
        "1 John 5",
        "",
        "2 Samuel 16:1-19",
        "",
        "Mark 13:14-100",
        "",
    ],
    [
        "trinity-3",
        "fri",
        "2 Samuel 17:1-23",
        "",
        "2 John",
        "",
        "2 Samuel 17:24-18:18",
        "",
        "Mark 14:1-26",
        "",
    ],
    [
        "trinity-3",
        "sat",
        "2 Samuel 18:19-100",
        "",
        "3 John",
        "",
        "2 Samuel 19:1-23",
        "",
        "Mark 14:27-52",
        "",
    ],
    [
        "trinity-4",
        "",
        "Joshua 5:13-6:20",
        "Jeremiah 18:1-17",
        "Mark 4:21-100",
        "Acts 17:16-100",
        "Joel 2:1-14",
        "1 Kings 12:1-20",
        "Acts 3:1-16",
        "John 4:15-100",
    ],
    [
        "trinity-4",
        "mon",
        "2 Samuel 19:24-100",
        "",
        "Romans 1",
        "",
        "2 Samuel 23:1-17",
        "",
        "Mark 14:53-100",
        "",
    ],
    [
        "trinity-4",
        "tues",
        "2 Samuel 24",
        "",
        "Romans 2:1-16",
        "",
        "1 Kings 1:5-31",
        "",
        "Mark 15:1-41",
        "",
    ],
    [
        "trinity-4",
        "wed",
        "1 Kings 1:32-100",
        "",
        "Romans 2:17-100",
        "",
        "1 Chronicles 22:2-100",
        "",
        "Mark 15:42-16:100",
        "",
    ],
    [
        "trinity-4",
        "thur",
        "1 Chronicles 28:1-10",
        "",
        "Romans 3",
        "",
        "1 Chronicles 28:20-29:9",
        "",
        "Luke 1:1-23",
        "",
    ],
    [
        "trinity-4",
        "fri",
        "1 Chronicles 29:10-100",
        "",
        "Romans 4",
        "",
        "1 Kings 3",
        "",
        "Luke 1:24-56",
        "",
    ],
    [
        "trinity-4",
        "sat",
        "1 Kings 4:21-100",
        "",
        "Romans 5",
        "",
        "1 Kings 5",
        "",
        "Luke 1:57-100",
        "",
    ],
    [
        "trinity-5",
        "",
        "Joshua 24:1-5; 24:13-25",
        "Jeremiah 26:1-16",
        "Mark 6:7-32",
        "Acts 19:21-100",
        "Joel 2:15-27",
        "1 Kings 18:17-39",
        "Acts 4:1-22",
        "John 15:1-16",
    ],
    [
        "trinity-5",
        "mon",
        "1 Kings 6:1-14",
        "",
        "Romans 6",
        "",
        "1 Kings 8:1-21",
        "",
        "Luke 2:1-21",
        "",
    ],
    [
        "trinity-5",
        "tues",
        "1 Kings 8:22-53",
        "",
        "Romans 7",
        "",
        "1 Kings 8:54-9:9",
        "",
        "Luke 2:22-100",
        "",
    ],
    [
        "trinity-5",
        "wed",
        "1 Kings 10",
        "",
        "Romans 8:1-17",
        "",
        "1 Kings 11:1-13",
        "",
        "Luke 3:1-22",
        "",
    ],
    [
        "trinity-5",
        "thur",
        "1 Kings 11:26-100",
        "",
        "Romans 8:18-100",
        "",
        "1 Kings 12:1-24",
        "",
        "Luke 4:1-30",
        "",
    ],
    [
        "trinity-5",
        "fri",
        "1 Kings 12:25-13:10",
        "",
        "Romans 9",
        "",
        "1 Kings 13:11-100",
        "",
        "Luke 4:31-100",
        "",
    ],
    [
        "trinity-5",
        "sat",
        "1 Kings 14:1-20",
        "",
        "Romans 10",
        "",
        "2 Chronicles 12",
        "",
        "Luke 5:1-16",
        "",
    ],
    [
        "trinity-6",
        "",
        "Judges 5",
        "Jeremiah 30:1-3; 30:10-22",
        "Mark 6:53-7:23",
        "Acts 20:17-100",
        "Zechariah 2",
        "1 Kings 19:1-18",
        "Acts 6",
        "John 15:17-100",
    ],
    [
        "trinity-6",
        "mon",
        "2 Chronicles 13",
        "",
        "Romans 11:1-24",
        "",
        "2 Chronicles 14",
        "",
        "Luke 5:17-100",
        "",
    ],
    [
        "trinity-6",
        "tues",
        "2 Chronicles 15",
        "",
        "Romans 11:25-100",
        "",
        "2 Chronicles 16",
        "",
        "Luke 6:1-19",
        "",
    ],
    [
        "trinity-6",
        "wed",
        "1 Kings 16:15-100",
        "",
        "Romans 12",
        "",
        "1 Kings 17",
        "",
        "Luke 6:20-38",
        "",
    ],
    [
        "trinity-6",
        "thur",
        "1 Kings 18:1-16",
        "",
        "Romans 13",
        "",
        "1 Kings 18:17-100",
        "",
        "Luke 6:39-7:10",
        "",
    ],
    [
        "trinity-6",
        "fri",
        "1 Kings 19",
        "",
        "Romans 14",
        "",
        "1 Kings 21",
        "",
        "Luke 7:11-35",
        "",
    ],
    [
        "trinity-6",
        "sat",
        "1 Kings 22:1-40",
        "",
        "Romans 15:1-13",
        "",
        "2 Chronicles 20:1-30",
        "",
        "Luke 7:36-100",
        "",
    ],
    [
        "trinity-7",
        "",
        "Judges 7:1-23",
        "Jeremiah 31:27-34",
        "Mark 9:14-29",
        "Acts 21:15-36",
        "Zechariah 8:1-17",
        "1 Kings 21:1-23",
        "Acts 8:4-17",
        "John 16:1-15",
    ],
    [
        "trinity-7",
        "mon",
        "2 Kings 1",
        "",
        "Romans 15:14-100",
        "",
        "2 Kings 2:1-22",
        "",
        "Luke 8:1-21",
        "",
    ],
    [
        "trinity-7",
        "tues",
        "2 Kings 4:1-37",
        "",
        "Romans 16",
        "",
        "2 Kings 5",
        "",
        "Luke 8:22-100",
        "",
    ],
    [
        "trinity-7",
        "wed",
        "2 Kings 6:1-23",
        "",
        "1 Corinthians 1:1-25",
        "",
        "2 Kings 6:24-7:2",
        "",
        "Luke 9:1-17",
        "",
    ],
    [
        "trinity-7",
        "thur",
        "2 Kings 7:3-100",
        "",
        "1 Corinthians 1:26-2:100",
        "",
        "2 Kings 8:1-15",
        "",
        "Luke 9:18-50",
        "",
    ],
    [
        "trinity-7",
        "fri",
        "2 Kings 9",
        "",
        "1 Corinthians 3",
        "",
        "2 Kings 11:1-20",
        "",
        "Luke 9:51-100",
        "",
    ],
    [
        "trinity-7",
        "sat",
        "2 Kings 11:21-12:100",
        "",
        "1 Corinthians 4:1-17",
        "",
        "2 Kings 13",
        "",
        "Luke 10:1-24",
        "",
    ],
    [
        "trinity-8",
        "",
        "Judges 16:4-100",
        "Jeremiah 36:1-26",
        "Mark 9:30-100",
        "Acts 25:1-12",
        "Jonah 1; 2",
        "1 Kings 22:1-38",
        "Acts 8:4-17",
        "John 16:1-15",
    ],
    [
        "trinity-8",
        "mon",
        "2 Kings 14",
        "",
        "1 Corinthians 4:18-5:100",
        "",
        "2 Chronicles 26",
        "",
        "Luke 10:25-100",
        "",
    ],
    [
        "trinity-8",
        "tues",
        "2 Kings 15:17-100",
        "",
        "1 Corinthians 6",
        "",
        "2 Kings 16",
        "",
        "Luke 11:1-28",
        "",
    ],
    [
        "trinity-8",
        "wed",
        "Isaiah 7:7-17",
        "",
        "1 Corinthians 7",
        "",
        "Isaiah 8:1-18",
        "",
        "Luke 11:29-100",
        "",
    ],
    [
        "trinity-8",
        "thur",
        "2 Kings 17:1-23",
        "",
        "1 Corinthians 8",
        "",
        "2 Kings 17:24-100",
        "",
        "Luke 12:1-34",
        "",
    ],
    [
        "trinity-8",
        "fri",
        "2 Kings 18:1-8",
        "",
        "1 Corinthians 9",
        "",
        "2 Chronicles 30",
        "",
        "Luke 12:35-53",
        "",
    ],
    [
        "trinity-8",
        "sat",
        "2 Kings 18:13-100",
        "",
        "1 Corinthians 10:1-11:1",
        "",
        "2 Kings 19",
        "",
        "Luke 12:54-13:9",
        "",
    ],
    [
        "trinity-9",
        "",
        "1 Samuel 1:1-20",
        "Jeremiah 38:1-13",
        "Mark 10:17-31",
        "Acts 27",
        "Jonah 3; 4",
        "2 Kings 4:8-37",
        "Acts 11:1-18",
        "John 16:23-100",
    ],
    [
        "trinity-9",
        "mon",
        "2 Kings 20",
        "",
        "1 Corinthians 11:2-100",
        "",
        "2 Chronicles 33",
        "",
        "Luke 13:10-100",
        "",
    ],
    [
        "trinity-9",
        "tues",
        "2 Kings 22",
        "",
        "1 Corinthians 12:1-27",
        "",
        "2 Kings 23:1-20",
        "",
        "Luke 14:1-24",
        "",
    ],
    [
        "trinity-9",
        "wed",
        "2 Kings 23:21-35",
        "",
        "1 Corinthians 12:27-13:100",
        "",
        "2 Kings 23:36-24:17",
        "",
        "Luke 14:25-15:10",
        "",
    ],
    [
        "trinity-9",
        "thur",
        "2 Kings 24:18-25:7",
        "",
        "1 Corinthians 14:1-19",
        "",
        "2 Kings 25:8-100",
        "",
        "Luke 15:11-100",
        "",
    ],
    [
        "trinity-9",
        "fri",
        "Jeremiah 19",
        "",
        "1 Corinthians 14:20-100",
        "",
        "Jeremiah 21:1-10",
        "",
        "Luke 16",
        "",
    ],
    [
        "trinity-9",
        "sat",
        "Jeremiah 22:20-23:8",
        "",
        "1 Corinthians 15:1-34",
        "",
        "Jeremiah 24",
        "",
        "Luke 17:1-19",
        "",
    ],
    [
        "trinity-10",
        "",
        "1 Samuel 3:1-4:1a",
        "Jeremiah 52:1-11",
        "Mark 12:18-100",
        "Acts 28:11-100",
        "Proverbs 1:20-100",
        "2 Kings 5:1-19",
        "Acts 15:1-31",
        "John 17",
    ],
    [
        "trinity-10",
        "mon",
        "Jeremiah 25:1-14",
        "",
        "1 Corinthians 15:35-100",
        "",
        "Jeremiah 27:2-100",
        "",
        "Luke 17:20-100",
        "",
    ],
    [
        "trinity-10",
        "tues",
        "Jeremiah 28",
        "",
        "1 Corinthians 16",
        "",
        "Jeremiah 29:1-20",
        "",
        "Luke 18:1-30",
        "",
    ],
    [
        "trinity-10",
        "wed",
        "Jeremiah 32:1-25",
        "",
        "2 Corinthians 1:1-22",
        "",
        "Jeremiah 32:26-100",
        "",
        "Luke 18:31-19:10",
        "",
    ],
    [
        "trinity-10",
        "thur",
        "Jeremiah 33",
        "",
        "2 Corinthians 1:23-2:100",
        "",
        "Jeremiah 34:8-100",
        "",
        "Luke 19:11-28",
        "",
    ],
    [
        "trinity-10",
        "fri",
        "Jeremiah 37",
        "",
        "2 Corinthians 3",
        "",
        "Jeremiah 38:1-13",
        "",
        "Luke 19:29-100",
        "",
    ],
    [
        "trinity-10",
        "sat",
        "Jeremiah 38:14-100",
        "",
        "2 Corinthians 4",
        "",
        "Jeremiah 39",
        "",
        "Luke 20:1-26",
        "",
    ],
    [
        "trinity-11",
        "",
        "1 Samuel 9:1-10:1",
        "Ezekiel 11:14-20",
        "Romans 1:1-25",
        "Luke 4:1-15",
        "Proverbs 8:1-17",
        "2 Kings 17:1-23",
        "Matthew 4:23-5:20",
        "Galatians 1",
    ],
    [
        "trinity-11",
        "mon",
        "Jeremiah 40",
        "",
        "2 Corinthians 5",
        "",
        "Jeremiah 41",
        "",
        "Luke 20:27-21:4",
        "",
    ],
    [
        "trinity-11",
        "tues",
        "Jeremiah 42",
        "",
        "2 Corinthians 5:20-7:1",
        "",
        "Jeremiah 43",
        "",
        "Luke 21:5-100",
        "",
    ],
    [
        "trinity-11",
        "wed",
        "Jeremiah 44:1-14",
        "",
        "2 Corinthians 7:2-100",
        "",
        "Jeremiah 44:15-100",
        "",
        "Luke 22:1-38",
        "",
    ],
    [
        "trinity-11",
        "thur",
        "Ezekiel 1:1-14",
        "",
        "2 Corinthians 8",
        "",
        "Ezekiel 2:1-3:3",
        "",
        "Luke 22:39-53",
        "",
    ],
    [
        "trinity-11",
        "fri",
        "Ezekiel 3:4-100",
        "",
        "2 Corinthians 9",
        "",
        "Ezekiel 8",
        "",
        "Luke 22:54-100",
        "",
    ],
    [
        "trinity-11",
        "sat",
        "Ezekiel 11:14-100",
        "",
        "2 Corinthians 10",
        "",
        "Ezekiel 12:17-100",
        "",
        "Luke 23:1-25",
        "",
    ],
    [
        "trinity-12",
        "",
        "1 Samuel 16:1-13",
        "Ezekiel 18:1-4; 19-100",
        "Romans 5:1-11",
        "Luke 4:16-30",
        "Proverbs 8:22-100",
        "2 Kings 18:17-22; 18:28-19:7",
        "Matthew 5:21-100",
        "Galatians 6:1-10",
    ],
    [
        "trinity-12",
        "mon",
        "Ezekiel 13:1-16",
        "",
        "2 Corinthians 11",
        "",
        "Ezekiel 14:1-11",
        "",
        "Luke 23:26-49",
        "",
    ],
    [
        "trinity-12",
        "tues",
        "Ezekiel 14:12-100",
        "",
        "2 Corinthians 12:1-13",
        "",
        "Ezekiel 20:1-20",
        "",
        "Luke 23:50-24:12",
        "",
    ],
    [
        "trinity-12",
        "wed",
        "Ezekiel 20:27-44",
        "",
        "2 Corinthians 12:14-13:100",
        "",
        "Ezekiel 33:21-100",
        "",
        "Luke 24:13-100",
        "",
    ],
    [
        "trinity-12",
        "thur",
        "Ezekiel 34:1-16",
        "",
        "Galatians 1",
        "",
        "Ezekiel 34:17-100",
        "",
        "John 1:1-28",
        "",
    ],
    [
        "trinity-12",
        "fri",
        "Ezekiel 36:22-36",
        "",
        "Galatians 2",
        "",
        "Ezekiel 37:1-14",
        "",
        "John 1:29-100",
        "",
    ],
    [
        "trinity-12",
        "sat",
        "Ezekiel 37:15-100",
        "",
        "Galatians 3",
        "",
        "Ezekiel 47:1-12",
        "",
        "John 2",
        "",
    ],
    [
        "trinity-13",
        "",
        "1 Samuel 17:1-11; 17:32-51",
        "Ezekiel 33:1-11",
        "Romans 12",
        "Luke 6:20-38",
        "Proverbs 14:31-15:17",
        "2 Kings 19:8-35",
        "Matthew 6:1-18",
        "1 Corinthians 1:1-25",
    ],
    [
        "trinity-13",
        "mon",
        "Ezra 1",
        "",
        "Galatians 4:1-5:1",
        "",
        "Ezra 3",
        "",
        "John 3:1-21",
        "",
    ],
    [
        "trinity-13",
        "tues",
        "Ezra 4",
        "",
        "Galatians 5:2-100",
        "",
        "Haggai 1:1-2:9",
        "",
        "John 3:22-100",
        "",
    ],
    [
        "trinity-13",
        "wed",
        "Zechariah 1:1-17",
        "",
        "Galatians 6",
        "",
        "Zechariah 1:18-2:100",
        "",
        "John 4:1-26",
        "",
    ],
    [
        "trinity-13",
        "thur",
        "Zechariah 3",
        "",
        "Ephesians 1:1-14",
        "",
        "Zechariah 4",
        "",
        "John 4:27-100",
        "",
    ],
    [
        "trinity-13",
        "fri",
        "Zechariah 6:9-100",
        "",
        "Ephesians 1:15-100",
        "",
        "Haggai 2:10-100",
        "",
        "John 5:1-23",
        "",
    ],
    [
        "trinity-13",
        "sat",
        "Ezra 5",
        "",
        "Ephesians 2:1-10",
        "",
        "Ezra 6",
        "",
        "John 5:24-100",
        "",
    ],
    [
        "trinity-14",
        "",
        "1 Samuel 18:1-16",
        "Ezekiel 33:21-100",
        "Romans 14:1-15:3",
        "Luke 6:39-100",
        "Proverbs 31:10-100",
        "2 Kings 22",
        "Matthew 6:19-100",
        "1 Corinthians 1:26-2:9",
    ],
    [
        "trinity-14",
        "mon",
        "Zechariah 7",
        "",
        "Ephesians 2:11-100",
        "",
        "Zechariah 8",
        "",
        "John 6:1-21",
        "",
    ],
    [
        "trinity-14",
        "tues",
        "Ezra 7",
        "",
        "Ephesians 3",
        "",
        "Ezra 8:15-100",
        "",
        "John 6:22-40",
        "",
    ],
    [
        "trinity-14",
        "wed",
        "Ezra 9",
        "",
        "Ephesians 4:1-16",
        "",
        "Ezra 10:1-19",
        "",
        "John 6:41-100",
        "",
    ],
    [
        "trinity-14",
        "thur",
        "Nehemiah 1",
        "",
        "Ephesians 4:17-30",
        "",
        "Nehemiah 2",
        "",
        "John 7:1-24",
        "",
    ],
    [
        "trinity-14",
        "fri",
        "Nehemiah 4",
        "",
        "Ephesians 4:31-5:21",
        "",
        "Nehemiah 5",
        "",
        "John 7:25-100",
        "",
    ],
    [
        "trinity-14",
        "sat",
        "Nehemiah 6:1-7:4",
        "",
        "Ephesians 5:22-100",
        "",
        "Nehemiah 8",
        "",
        "John 8:1-30",
        "",
    ],
    [
        "trinity-15",
        "",
        "1 Samuel 26",
        "Ezekiel 34:1-16",
        "1 Peter 1:13-100",
        "Luke 7:36-8:3",
        "Job 1",
        "Ezra 1:1-8",
        "Matthew 7:1-14",
        "1 Corinthians 3",
    ],
    [
        "trinity-15",
        "mon",
        "Nehemiah 9:1-23",
        "",
        "Ephesians 6:1-9",
        "",
        "Nehemiah 9:24-100",
        "",
        "John 8:31-100",
        "",
    ],
    [
        "trinity-15",
        "tues",
        "Nehemiah 13",
        "",
        "Ephesians 6:10-100",
        "",
        "Daniel 1",
        "",
        "John 9",
        "",
    ],
    [
        "trinity-15",
        "wed",
        "Daniel 2:1-24",
        "",
        "Philippians 1:1-11",
        "",
        "Daniel 2:25-100",
        "",
        "John 10:1-21",
        "",
    ],
    [
        "trinity-15",
        "thur",
        "Daniel 4:1-18",
        "",
        "Philippians 1:12-100",
        "",
        "Daniel 4:19-100",
        "",
        "John 10:22-100",
        "",
    ],
    [
        "trinity-15",
        "fri",
        "Daniel 7:9-100",
        "",
        "Philippians 2:1-11",
        "",
        "Daniel 9",
        "",
        "John 11:1-44",
        "",
    ],
    [
        "trinity-15",
        "sat",
        "Daniel 10",
        "",
        "Philippians 2:12-100",
        "",
        "Daniel 12",
        "",
        "John 11:45-100",
        "",
    ],
    [
        "trinity-16",
        "",
        "1 Samuel 28:3-100",
        "Ezekiel 36:22-28; 36:34-36",
        "1 Peter 2:1-17",
        "Luke 9:46-100",
        "Job 2",
        "Ezra 3",
        "Matthew 7:15-100",
        "1 Corinthians 13",
    ],
    [
        "trinity-16",
        "mon",
        "Esther 1",
        "",
        "Philippians 3",
        "",
        "Esther 2:5-11; 15-100",
        "",
        "John 12:1-19",
        "",
    ],
    [
        "trinity-16",
        "tues",
        "Esther 3",
        "",
        "Philippians 4",
        "",
        "Esther 4",
        "",
        "John 12:20-100",
        "",
    ],
    [
        "trinity-16",
        "wed",
        "Esther 5",
        "",
        "Colossians 1:1-20",
        "",
        "Esther 6; 7",
        "",
        "John 13",
        "",
    ],
    [
        "trinity-16",
        "thur",
        "1 Maccabees 1:1-19",
        "",
        "Colossians 1:21-2:7",
        "",
        "1 Maccabees 1:20-40",
        "",
        "John 14",
        "",
    ],
    [
        "trinity-16",
        "fri",
        "1 Maccabees 1:41-100",
        "",
        "Colossians 2:8-19",
        "",
        "1 Maccabees 2:1-28",
        "",
        "John 15",
        "",
    ],
    [
        "trinity-16",
        "sat",
        "1 Maccabees 2:29-48",
        "",
        "Colossians 2:20-3:11",
        "",
        "1 Maccabees 2:49-100",
        "",
        "John 16",
        "",
    ],
    [
        "trinity-17",
        "",
        "2 Samuel 1",
        "Ezekiel 37:15-100",
        "1 Peter 4",
        "Luke 10:1-24",
        "Job 4:1; 5:6-100",
        "Nehemiah 1",
        "Matthew 11:2-19",
        "Philippians 1:12-100",
    ],
    [
        "trinity-17",
        "mon",
        "1 Maccabees 3:1-26",
        "",
        "Colossians 3:12-4:1",
        "",
        "1 Maccabees 3:27-41",
        "",
        "John 17",
        "",
    ],
    [
        "trinity-17",
        "tues",
        "1 Maccabees 3:42-100",
        "",
        "Colossians 4:2-100",
        "",
        "1 Maccabees 4:1-25",
        "",
        "John 18:1-27",
        "",
    ],
    [
        "trinity-17",
        "wed",
        "1 Maccabees 4:26-35",
        "",
        "Philemon",
        "",
        "1 Maccabees 4:36-100",
        "",
        "John 18:28-100",
        "",
    ],
    [
        "trinity-17",
        "thur",
        "1 Maccabees 6:1-17",
        "",
        "1 Thessalonians 1",
        "",
        "1 Maccabees 6:18-47",
        "",
        "John 19:1-30",
        "",
    ],
    [
        "trinity-17",
        "fri",
        "1 Maccabees 7:1-20",
        "",
        "1 Thessalonians 2:1-16",
        "",
        "1 Maccabees 7:21-100",
        "",
        "John 19:31-100",
        "",
    ],
    [
        "trinity-17",
        "sat",
        "1 Maccabees 9:1-22",
        "",
        "1 Thessalonians 2:17-3:100",
        "",
        "1 Maccabees 13:41-100; 14:4-15",
        "",
        "John 20",
        "",
    ],
    [
        "trinity-18",
        "",
        "2 Samuel 7",
        "Daniel 3",
        "1 Peter 5:1-11",
        "Luke 11:37-100",
        "Job 19:1-27a",
        "Nehemiah 2",
        "Matthew 11:20-100",
        "Philippians 2:1-18",
    ],
    [
        "trinity-18",
        "mon",
        "Job 1",
        "",
        "1 Thessalonians 4:1-12",
        "",
        "Job 2",
        "",
        "John 21",
        "",
    ],
    [
        "trinity-18",
        "tues",
        "Job 3",
        "",
        "1 Thessalonians 4:13-5:11",
        "",
        "Job 4",
        "",
        "Hebrews 1",
        "",
    ],
    [
        "trinity-18",
        "wed",
        "Job 5",
        "",
        "1 Thessalonians 5:12-100",
        "",
        "Job 6",
        "",
        "Hebrews 2",
        "",
    ],
    [
        "trinity-18",
        "thur",
        "Job 7",
        "",
        "2 Thessalonians 1",
        "",
        "Job 8",
        "",
        "Hebrews 3",
        "",
    ],
    [
        "trinity-18",
        "fri",
        "Job 9",
        "",
        "2 Thessalonians 2",
        "",
        "Job 10",
        "",
        "Hebrews 4:1-13",
        "",
    ],
    [
        "trinity-18",
        "sat",
        "Job 11",
        "",
        "2 Thessalonians 3",
        "",
        "Job 12",
        "",
        "Hebrews 4:14-5:10",
        "",
    ],
    [
        "trinity-19",
        "",
        "2 Samuel 12:1-23",
        "Daniel 5",
        "Colossians 1:21-2:7",
        "Luke 12:1-21",
        "Job 28",
        "Ruth 1",
        "Matthew 12:22-45",
        "Philippians 3:1-16",
    ],
    [
        "trinity-19",
        "mon",
        "Job 13",
        "",
        "1 Timothy 1:1-17",
        "",
        "Job 14",
        "",
        "Hebrews 5:11-6:100",
        "",
    ],
    [
        "trinity-19",
        "tues",
        "Job 15:1-16",
        "",
        "1 Timothy 1:18-2:100",
        "",
        "Job 16:1-17:2",
        "",
        "Hebrews 7",
        "",
    ],
    [
        "trinity-19",
        "wed",
        "Job 17:3-100",
        "",
        "1 Timothy 3",
        "",
        "Job 18",
        "",
        "Hebrews 8",
        "",
    ],
    [
        "trinity-19",
        "thur",
        "Job 19",
        "",
        "1 Timothy 4",
        "",
        "Job 21",
        "",
        "Hebrews 9:1-14",
        "",
    ],
    [
        "trinity-19",
        "fri",
        "Job 22",
        "",
        "1 Timothy 5",
        "",
        "Job 23",
        "",
        "Hebrews 9:15-100",
        "",
    ],
    [
        "trinity-19",
        "sat",
        "Job 24",
        "",
        "1 Timothy 6",
        "",
        "Job 25; 26",
        "",
        "Hebrews 10:1-18",
        "",
    ],
    [
        "trinity-20",
        "",
        "2 Samuel 18",
        "Daniel 6:1-23",
        "Colossians 3:12-4:6",
        "Luke 12:22-34",
        "Job 38; 42:1-6",
        "Ruth 2:1-20a; 4:13-17",
        "Matthew 13:44-100",
        "Philippians 4",
    ],
    [
        "trinity-20",
        "mon",
        "Job 27",
        "",
        "Titus 1:1-2:8",
        "",
        "Job 28",
        "",
        "Hebrews 10:19-100",
        "",
    ],
    [
        "trinity-20",
        "tues",
        "Job 29:1-30:1",
        "",
        "Titus 2:9-3:100",
        "",
        "Job 31:13-100",
        "",
        "Hebrews 11:1-16",
        "",
    ],
    [
        "trinity-20",
        "wed",
        "Job 32",
        "",
        "2 Timothy 1",
        "",
        "Job 33",
        "",
        "Hebrews 11:17-100",
        "",
    ],
    [
        "trinity-20",
        "thur",
        "Job 38:1-21",
        "",
        "2 Timothy 2",
        "",
        "Job 38:22-100",
        "",
        "Hebrews 12:1-13",
        "",
    ],
    [
        "trinity-20",
        "fri",
        "Job 39",
        "",
        "2 Timothy 3",
        "",
        "Job 40",
        "",
        "Hebrews 12:14-100",
        "",
    ],
    [
        "trinity-20",
        "sat",
        "Job 41",
        "",
        "2 Timothy 4",
        "",
        "Job 42",
        "",
        "Hebrews 13",
        "",
    ],
    [
        "trinity-21",
        "",
        "Wisdom 2",
        "1 Maccabees 2:1-22",
        "Philemon",
        "Luke 12:35-100",
        "Baruch 3:1-14",
        "Sirach 3:17-29",
        "Matthew 14:13-33",
        "2 Corinthians 1:1-22",
    ],
    [
        "trinity-21",
        "mon",
        "Proverbs 1:1-19",
        "",
        "James 1:1-11",
        "",
        "Proverbs 1:20-100",
        "",
        "James 1:12-100",
        "",
    ],
    [
        "trinity-21",
        "tues",
        "Proverbs 2",
        "",
        "James 2:1-13",
        "",
        "Proverbs 3:1-26",
        "",
        "James 2:14-100",
        "",
    ],
    [
        "trinity-21",
        "wed",
        "Proverbs 3:27-4:19",
        "",
        "James 3",
        "",
        "Proverbs 4:20-5:14",
        "",
        "James 4",
        "",
    ],
    [
        "trinity-21",
        "thur",
        "Proverbs 6:1-19",
        "",
        "James 5",
        "",
        "Proverbs 8",
        "",
        "1 Peter 1:1-12",
        "",
    ],
    [
        "trinity-21",
        "fri",
        "Proverbs 9",
        "",
        "1 Peter 1:13-100",
        "",
        "Proverbs 10:1-22",
        "",
        "1 Peter 2:1-10",
        "",
    ],
    [
        "trinity-21",
        "sat",
        "Proverbs 11:1-25",
        "",
        "1 Peter 2:11-3:7",
        "",
        "Proverbs 12:10-100",
        "",
        "1 Peter 3:8-100",
        "",
    ],
    [
        "trinity-22",
        "",
        "Wisdom 4:7-17",
        "1 Maccabees 2:49-69",
        "James 1:1-18; James 1:19-100",
        "Luke 13:18-100",
        "Baruch 4:36-5:100",
        "Sirach 4:11-28",
        "Matthew 16:13-100",
        "1 Corinthians 4",
    ],
    [
        "trinity-22",
        "mon",
        "Proverbs 14:9-27",
        "",
        "1 Peter 4:1-11",
        "",
        "Proverbs 15:18-100",
        "",
        "1 Peter 4:12-100",
        "",
    ],
    [
        "trinity-22",
        "tues",
        "Proverbs 16:31-17:17",
        "",
        "1 Peter 5",
        "",
        "Proverbs 18:10-100",
        "",
        "1 John 1:1-2:6",
        "",
    ],
    [
        "trinity-22",
        "wed",
        "Proverbs 20:1-22",
        "",
        "1 John 2:7-17",
        "",
        "Proverbs 22:1-16",
        "",
        "1 John 2:18-100",
        "",
    ],
    [
        "trinity-22",
        "thur",
        "Proverbs 24:23-100",
        "",
        "1 John 3:1-18",
        "",
        "Proverbs 25",
        "",
        "1 John 3:19-4:6",
        "",
    ],
    [
        "trinity-22",
        "fri",
        "Proverbs 26:12-100",
        "",
        "1 John 4:7-100",
        "",
        "Proverbs 27:1-22",
        "",
        "1 John 5",
        "",
    ],
    [
        "trinity-22",
        "sat",
        "Proverbs 30:1-16",
        "",
        "2 John",
        "",
        "Proverbs 31:10-100",
        "",
        "3 John",
        "",
    ],
    [
        "trinity-23",
        "",
        "Wisdom 6:1-21",
        "1 Maccabees 3:42-100",
        "James 2:1-13",
        "Luke 14:15-100",
        "Prayer of Manasses",
        "Sirach 4:29-6:1",
        "Matthew 18:1-20",
        "2 Corinthians 5",
    ],
    [
        "trinity-23",
        "mon",
        "Sirach 1:1-10",
        "",
        "Acts 1",
        "",
        "Sirach 1:11-100",
        "",
        "Acts 2:1-21",
        "",
    ],
    [
        "trinity-23",
        "tues",
        "Sirach 2",
        "",
        "Acts 2:22-100",
        "",
        "Sirach 3:17-29",
        "",
        "Acts 3:1-4:4",
        "",
    ],
    [
        "trinity-23",
        "wed",
        "Sirach 4:11-28",
        "",
        "Acts 4:5-31",
        "",
        "Sirach 4:29-6:1",
        "",
        "Acts 4:32-5:11",
        "",
    ],
    [
        "trinity-23",
        "thur",
        "Sirach 6:14-31",
        "",
        "Acts 5:12-100",
        "",
        "Sirach 7:27-100",
        "",
        "Acts 6:1-7:16",
        "",
    ],
    [
        "trinity-23",
        "fri",
        "Sirach 10:6-8; 10:12-24",
        "",
        "Acts 7:17-34",
        "",
        "Sirach 11:7-28",
        "",
        "Acts 7:35-8:4",
        "",
    ],
    [
        "trinity-23",
        "sat",
        "Sirach 14:20-15:10",
        "",
        "Acts 8:4-25",
        "",
        "Sirach 15:11-100",
        "",
        "Acts 8:26-100",
        "",
    ],
    [
        "trinity-24",
        "",
        "Wisdom 7:15-8:1",
        "1 Maccabees 14:4-15",
        "James 3",
        "Luke 15:1-10",
        "2 Esdras 16:53-67",
        "Sirach 11:7-28",
        "Matthew 21:12-32",
        "2 Corinthians 9",
    ],
    [
        "trinity-24",
        "mon",
        "Sirach 16:17-100",
        "",
        "Acts 9:1-31",
        "",
        "Sirach 17:1-24",
        "",
        "Acts 9:32-100",
        "",
    ],
    [
        "trinity-24",
        "tues",
        "Sirach 18:1-14",
        "",
        "Acts 10:1-23",
        "",
        "Sirach 19:13-100",
        "",
        "Acts 10:24-100",
        "",
    ],
    [
        "trinity-24",
        "wed",
        "Sirach 21:1-17",
        "",
        "Acts 11:1-18",
        "",
        "Sirach 22:6-22",
        "",
        "Acts 11:19-100",
        "",
    ],
    [
        "trinity-24",
        "thur",
        "Sirach 22:27-23:15",
        "",
        "Acts 12:1-24",
        "",
        "Sirach 24:1-22",
        "",
        "Acts 12:25-13:12",
        "",
    ],
    [
        "trinity-24",
        "fri",
        "Sirach 24:23-100",
        "",
        "Acts 13:13-43",
        "",
        "Sirach 27:30-28:9",
        "",
        "Acts 13:44-14:7",
        "",
    ],
    [
        "trinity-24",
        "sat",
        "Sirach 31:1-11",
        "",
        "Acts 14:8-100",
        "",
        "Sirach 34:9-100",
        "",
        "Acts 15:1-21",
        "",
    ],
    [
        "trinity-25",
        "",
        "Wisdom 9",
        "Sirach 15:11-100",
        "James 4",
        "Luke 17:1-10",
        "Sirach 18:1-13",
        "Sirach 27:30-28:9",
        "Matthew 21:33-100",
        "1 Timothy 6:1-16",
    ],
    [
        "trinity-25",
        "mon",
        "Sirach 35",
        "",
        "Acts 15:22-35",
        "",
        "Sirach 37:7-15",
        "",
        "Acts 15:36-16:5",
        "",
    ],
    [
        "trinity-25",
        "tues",
        "Sirach 38:1-14",
        "",
        "Acts 16:6-100",
        "",
        "Sirach 38:24-100",
        "",
        "Acts 17:1-15",
        "",
    ],
    [
        "trinity-25",
        "wed",
        "Sirach 39:1-11",
        "",
        "Acts 17:16-100",
        "",
        "Sirach 39:13-100",
        "",
        "Acts 18:1-23",
        "",
    ],
    [
        "trinity-25",
        "thur",
        "Sirach 42:15-100",
        "",
        "Acts 18:24-19:7",
        "",
        "Sirach 43:1-12",
        "",
        "Acts 19:8-20",
        "",
    ],
    [
        "trinity-25",
        "fri",
        "Sirach 43:13-100",
        "",
        "Acts 19:21-100",
        "",
        "Sirach 50:1-24",
        "",
        "Acts 20:1-16",
        "",
    ],
    [
        "trinity-25",
        "sat",
        "Sirach 51:1-12",
        "",
        "Acts 20:17-100",
        "",
        "Sirach 51:13-100",
        "",
        "Acts 21:1-16",
        "",
    ],
    [
        "trinity-26",
        "",
        "Wisdom 11:21-12:2",
        "Sirach 42:15-100",
        "James 5",
        "Luke 20:1-19",
        "Sirach 38:24-100",
        "Sirach 43:13-26",
        "Matthew 23:1-22",
        "2 Timothy 1:1-14",
    ],
    [
        "trinity-26",
        "mon",
        "Ecclesiastes 1",
        "",
        "Acts 21:17-36",
        "",
        "Ecclesiastes 2:1-23",
        "",
        "Acts 21:37-22:22",
        "",
    ],
    [
        "trinity-26",
        "tues",
        "Ecclesiastes 3:1-15",
        "",
        "Acts 22:23-23:11",
        "",
        "Ecclesiastes 3:16-4:6",
        "",
        "Acts 23:12-100",
        "",
    ],
    [
        "trinity-26",
        "wed",
        "Ecclesiastes 4:7-100",
        "",
        "Acts 24:1-23",
        "",
        "Ecclesiastes 5",
        "",
        "Acts 24:24-25:12",
        "",
    ],
    [
        "trinity-26",
        "thur",
        "Ecclesiastes 6",
        "",
        "Acts 25:13-100",
        "",
        "Ecclesiastes 7:1-14",
        "",
        "Acts 26",
        "",
    ],
    [
        "trinity-26",
        "fri",
        "Ecclesiastes 7:15-100",
        "",
        "Acts 27:1-26",
        "",
        "Ecclesiastes 8",
        "",
        "Acts 27:27-100",
        "",
    ],
    [
        "trinity-26",
        "sat",
        "Ecclesiastes 9",
        "",
        "Acts 28:1-15",
        "",
        "Ecclesiastes 10:5-18",
        "",
        "Acts 28:16-100",
        "",
    ],
    [
        "sunday-before-advent",
        "",
        "Ecclesiastes 11-12",
        "",
        "Hebrews 11:1-16",
        "",
        "Malachi 3:1-6; Malachi 4",
        "",
        "Hebrews 11:17-12:2",
        "",
    ],
    [
        "sunday-before-advent",
        "mon",
        "Wisdom 1",
        "",
        "Matthew 5:1-16",
        "",
        "Wisdom 2",
        "",
        "Revelation 1",
        "",
    ],
    [
        "sunday-before-advent",
        "tues",
        "Wisdom 3:1-9",
        "",
        "Matthew 5:17-100",
        "",
        "Wisdom 4:7-100",
        "",
        "Revelation 2:1-17",
        "",
    ],
    [
        "sunday-before-advent",
        "wed",
        "Wisdom 5:1-16",
        "",
        "Matthew 6:1-18",
        "",
        "Wisdom 6:1-21",
        "",
        "Revelation 2:18-3:6",
        "",
    ],
    [
        "sunday-before-advent",
        "thur",
        "Wisdom 7:15-8:4",
        "",
        "Matthew 6:19-100",
        "",
        "Wisdom 8:5-18",
        "",
        "Revelation 3:7-100",
        "",
    ],
    [
        "sunday-before-advent",
        "fri",
        "Wisdom 8:21-9:100",
        "",
        "Matthew 7:1-14",
        "",
        "Wisdom 10:15-11:10",
        "",
        "Revelation 4",
        "",
    ],
    [
        "sunday-before-advent",
        "sat",
        "Wisdom 11:21-12:2",
        "",
        "Matthew 7:15-100",
        "",
        "Wisdom 12:12-21",
        "",
        "Revelation 5",
        "",
    ],
];

const BCP_PSALM_CYCLE: {
    [date: number]: { morning: string[]; evening: string[] };
} = {
    1: { morning: ["1", "2", "3", "4", "5"], evening: ["6", "7", "8"] },
    2: { morning: ["9", "10", "11"], evening: ["12", "13", "14"] },
    3: { morning: ["15", "16", "17"], evening: ["18"] },
    4: { morning: ["19", "20", "21"], evening: ["22", "23"] },
    5: { morning: ["24", "25", "26"], evening: ["27", "28", "29"] },
    6: { morning: ["30", "31"], evening: ["32", "33", "34"] },
    7: { morning: ["35", "36"], evening: ["37"] },
    8: { morning: ["38", "39", "40"], evening: ["41", "42", "43"] },
    9: { morning: ["44", "45", "46"], evening: ["47", "48", "49"] },
    10: { morning: ["50", "51", "52"], evening: ["53", "54", "55"] },
    11: { morning: ["56", "57", "58"], evening: ["59", "60", "61"] },
    12: { morning: ["62", "63", "64"], evening: ["65", "66", "67"] },
    13: { morning: ["68"], evening: ["69", "70"] },
    14: { morning: ["71", "72"], evening: ["73", "74"] },
    15: { morning: ["75", "76", "77"], evening: ["78"] },
    16: { morning: ["79", "80", "81"], evening: ["82", "83", "84", "85"] },
    17: { morning: ["86", "87", "88"], evening: ["89"] },
    18: { morning: ["90", "91", "92"], evening: ["93", "94"] },
    19: { morning: ["95", "96", "97"], evening: ["98", "99", "100", "101"] },
    20: { morning: ["102", "103"], evening: ["104"] },
    21: { morning: ["105"], evening: ["106"] },
    22: { morning: ["107"], evening: ["108", "109"] },
    23: { morning: ["110", "111", "112", "113"], evening: ["114", "115"] },
    24: { morning: ["116", "117", "118"], evening: ["119:1-32"] },
    25: { morning: ["119:33-72"], evening: ["119:73-104"] },
    26: { morning: ["119:105-144"], evening: ["119:145-176"] },
    27: {
        morning: ["120", "121", "122", "123", "124", "125"],
        evening: ["126", "127", "128", "129", "130", "131"],
    },
    28: {
        morning: ["132", "133", "134", "135"],
        evening: ["136", "137", "138"],
    },
    29: { morning: ["139", "140"], evening: ["141", "142", "143"] },
    30: {
        morning: ["144", "145", "146"],
        evening: ["147", "148", "149", "150"],
    },
    31: {
        morning: ["144", "145", "146"],
        evening: ["147", "148", "149", "150"],
    },
};

export const getPsalmIncipit = (ref: string) => {
    const lookup = ref.split("-")[0];

    return PSALM_INCIPITS.split("\n")
        .find((line) => line.split("	")[0].split("-")[0] === lookup)
        ?.split("	")[1];
};

const PSALM_INCIPITS = `
1	Beatus vir, qui non abiit, &c.
2	Quare fremuerunt gentes?
3	Domine, quid multiplicati,
4	Cum invocarem.
5	Verba mea auribus.
6	Domine, ne in furore.
7	Domine, Deus meus.
8	Domine, Dominus noster.
9	Confitebor tibi.
10	Ut quid, Domine?
11	In Domino confido.
12	Salvum me fac.
13	Usque quo, Domine?
14	Dixit insipiens.
15	Domine, quis habitabit?
16	Conserva me, Domine.
17	Exaudi, Domine.
18	Diligam te, Domine.
19	Caeli enarrant.
20	Exaudiat te Dominus.
21	Domine, in virtute tua.
22	Deus, Deus meus.
23	Dominus regit me.
24	Domini est terra.
25	Ad te, Domine, levavi.
26	Judica me, Domine.
27	Dominus illuminatio.
28	Ad te, Domine.
29	Afferte Domino.
30	Exaltabo te, Domine.
31	In te, Domine, speravi.
32	Beati, quorum.
33	Exultate, justi.
34	Benedicam Domino
35	Judica, Domine.
36	Dixit injustus.
37	Noli aemulari.
38	Domine, ne in furore.
39	Dixi, Custodiam.
40	Expectans expectavi.
41	Beatus qui intelligit.
42	Quemadmodum.
43	Judica me, Deus.
44	Deus, auribus.
45	Eructavit cor meum.
46	Deus noster refugium.
47	Omnes gentes, plaudite.
48	Magnus Dominus.
49	Audite haec, omnes.
50	Deus deorum.
51	Miserere mei, Deus.
52	Quid gloriaris?
53	Dixit insipiens.
54	Deus, in nomine.
55	Exaudi, Deus.
56	Miserere mei, Deus.
57	Miserere mei, Deus.
58	Si vere utique.
59	Eripe me de inimicis.
60	Deus, repulisti nos.
61	Exaudi, Deus.
62	Nonne Deo?
63	Deus, Deus meus.
64	Exaudi, Deus.
65	Te decet hymnus.
66	Jubilate Deo.
67	Deus misereatur.
68	Exurgat Deus.
69	Salvum me fac.
70	Deus, in adjutorium.
71	In te, Domine, speravi.
72	Deus, judicium.
73	Quam bonus Israel!
74	Ut quid, Deus?
75	Confitebimur tibi.
76	Notus in Judaea.
77	Voce mea ad Dominum.
78	Attendite, popule.
79	Deus, venerunt.
80	Qui regis Israel.
81	Exultate Deo.
82	Deus stetit.
83	Deus, quis similis?
84	Quam dilecta!
85	Benedixisti, Domine.
86	Inclina, Domine.
87	Fundamenta ejus.
88	Domine Deus.
89	Misericordias Domini.
90	Domine, refugium.
91	Qui habitat.
92	Bonum est confiteri.
93	Dominus regnavit.
94	Deus ultionum.
95	Venite, exultemus.
96	Cantate Domino.
97	Dominus regnavit.
98	Cantate Domino.
99	Dominus regnavit.
100	Jubilate Deo.
101	Misericordiam et judicium.
102	Domine, exaudi.
103	Benedic, anima mea.
104	Benedic, anima mea.
105	Confitemini Domino.
106	Confitemini Domino.
107	Confitemini Domino.
108	Paratum cor meum.
109	Deus, laudem.
110	Dixit Dominus.
111	Confitebor tibi.
112	Beatus vir.
113	Laudate, pueri.
114	In exitu Israel.
115	Non nobis, Domine.
116	Dilexi, quoniam.
117	Laudate Dominum.
118	Confitemini Domino.
119:1-8	Beati immaculati.
119:9-16	In quo corriget?
119:17-24	Retribue servo tuo.
119:25-32	Adhaesit pavimento.
119:33-40	Legem pone.
119:41-48	Et veniat super me.
119:49-56	Memor esto servi tui.
119:57-64	Portio mea, Domine.
119:65-72	Bonitatem fecisti.
119:73-80	Manus tuae fecerunt me.
119:81-88	Defecit anima mea.
119:89-96	In aeternum. Domine.
119:97-104	Quomodo dilexi!
119:105-112	Lucerna pedibus meis.
119:113-120	Iniquos odio habui.
119:121-128	Feci judicium.
119:129-136	Mirabilia.
119:137-144	Justus es, Domine.
119:145-152	Clamavi in toto corde meo.
119:153-160	Vide humilitatem.
119:161-168	Principes persecuti sunt.
119:169-176	Appropinquet deprecatio.
120	Ad Dominum.
121	Levavi oculus.
122	Laetatus sum.
123	Ad te levavi oculos meos.
124	Nisi quia Dominus.
125	Qui confidunt.
126	In convertendo.
127	Nisi Dominus.
128	Beati omnes.
129	Saepe expugnaverunt.
130	De profundis.
131	Domine, non est.
132	Memento, Domine.
133	Ecce, quam bonum!
134	Ecce nunc.
135	Laudate Nomen.
136	Confitemini.
137	Super flumina.
138	Confitebor tibi.
139	Domine, probasti.
140	Eripe me, Domine.
141	Domine, clamavi.
142	Voce mea ad Dominum.
143	Domine, exaudi.
144	Benedictus Dominus.
145	Exaltabo te, Deus.
146	Lauda, anima mea.
147	Laudate Dominum.
148	Laudate Dominum.
149	Cantate Domino.
150	Laudate Dominum.
`;
