const CANTICLES: { [id: string]: { title?: string; lines: string[][] } } = {
    "simple venite": {
        lines: [
            [
                "O COME, let us sing unto the LORD;",
                "let us heartily rejoice in the strength of our salvation.",
            ],
            [
                "Let us come before his presence with thanksgiving",
                "and show ourselves glad in him with Psalms.",
            ],
            ["For the LORD is a great God", "and a great King above all gods."],
            [
                "In his hand are all the corners of the earth",
                "and the strength of the hills is his also.",
            ],
            [
                "The sea is his, and he made it",
                "and his hands prepared the dry land.",
            ],
            [
                "O come, let us worship and fall down",
                "and kneel before the Lord our Maker.",
            ],
            [
                "For he is the LORD our God",
                "and we are the people of his pasture, and the sheep of his hand.",
            ],
            [
                "Today if ye will hear his voice, harden not your hearts",
                "as in the provocation, and as in the day of temptation in the wilderness;",
            ],
            ["When your fathers tempted me", "proved me, and saw my works."],
            [
                "Forty years long was I grieved with this generation, and said,",
                "It is a people that do err in their heart, for they have not known my ways.",
            ],
            [
                "Unto whom I sware in my wrath",
                "that they should not enter into my rest.",
            ],
        ],
    },
    "benedictus es": {
        lines: [
            [
                "BLESSED art thou, O Lord God of our fathers;",
                "praised and exalted above all for ever.",
            ],
            [
                "Blessed art thou for the name of thy Majesty;",
                "praised and exalted above all for ever.",
            ],
            [
                "Blessed art thou in the temple of thy holiness;",
                "praised and exalted above all for ever.",
            ],
            [
                "Blessed art thou that beholdest the depths, and dwellest between the Cherubim;",
                "praised and exalted above all for ever.",
            ],
            [
                "Blessed art thou on the glorious throne of thy kingdom;",
                "praised and exalted above all for ever.",
            ],
            [
                "Blessed art thou in the firmament of heaven;",
                "praised and exalted above all for ever.",
            ],
        ],
    },
    "pascha nostrum": {
        lines: [
            [
                "ALLELUIA. Christ our Passover is sacrificed for us;",
                "therefore let us keep the feast,",
            ],
            [
                "Not with old leaven, neither with the leaven of malice and wickedness,",
                "but with the unleavened bread of sincerity and truth. Alleluia.",
            ],
            [
                "Christ being raised from the dead dieth no more;",
                "death hath no more dominion over him.",
            ],
            [
                "For in that he died, he died unto sin once;",
                "but in that he liveth, he liveth unto God.",
            ],
            [
                "Likewise reckon ye also yourselves to be dead indeed unto sin,",
                "but alive unto God through Jesus Christ our Lord. Alleluia.",
            ],
            [
                "Christ is risen from the dead,",
                "and become the first-fruits of them that slept.",
            ],
            [
                "For since by man came death,",
                "by man came also the resurrection of the dead.",
            ],
            [
                "For as in Adam all die,",
                "even so in Christ shall all be made alive. Alleluia.",
            ],
        ],
    },
    venite: {
        lines: [
            [
                "O COME, let us sing unto the LORD;",
                "let us heartily rejoice in the strength of our salvation:",
                "Let us come before his presence with thanksgiving,",
                "and show ourselves glad in him with Psalms.",
            ],
            [
                "For the LORD is a great God,",
                "and a great King above all gods:",
                "In his hand are all the corners of the earth,",
                "and the strength of the hills is his also.",
            ],
            [
                "The sea is his, and he made it,",
                "and his hands prepared the dry land:",
                "O come, let us worship and fall down,",
                "and kneel before the Lord our Maker.",
            ],
            [
                "For he is the LORD our God,",
                "and we are the people of his pasture, and the sheep of his hand.",
                "Today if ye will hear his voice, harden not your hearts,",
                "as in the provocation, and as in the day of temptation in the wilderness;",
            ],
            [
                "When your fathers tempted me, proved me, and saw my works;",
                "Forty years long was I grieved with this generation, and said:",
                "It is a people that do err in their heart, for they have not known my ways.",
                "Unto whom I sware in my wrath that they should not enter into my rest.",
            ],
            [
                "Glory be to the Father and to the Son",
                "and to the Holy Ghost.",
                "As it was in the beginning, is now and ever shall be,",
                "world without end. Amen.",
            ],
        ],
    },
    jubilate: {
        lines: [
            [
                "O BE joyful in the Lord, all ye lands",
                "serve the Lord with gladness, and come before his presence with a song.",
                "Be ye sure that the Lord he is God",
                "it is he that hath made us, and not we ourselves; we are his people, and the sheep of his pasture.",
            ],
            [
                "O go your way into his gates with thanksgiving, and into his courts with praise",
                "be thankful unto him, and speak good of his Name.",
                "For the Lord is gracious, his mercy is everlasting",
                "and his truth endureth from generation to generation.",
            ],
            [
                "Glory be to the Father and to the Son",
                "and to the Holy Ghost.",
                "As it was in the beginning, is now and ever shall be,",
                "world without end. Amen.",
            ],
        ],
    },
    "te deum": {
        title: "Te Deum",
        lines: [
            ["WE PRAISE thee, O God", "we acknowledge thee to be the Lord."],
            ["All the earth doth worship thee", "the Father everlasting."],
            [
                "To thee all Angels cry aloud",
                "the heavens and all the powers therein.",
            ],
            ["To thee Cherubin and Seraphin", "continually do cry,"],
            ["Holy, Holy, Holy", "Lord God of Sabaoth;"],
            ["Heaven and earth are full of the Majesty", "of thy glory."],
            ["The glorious company of the Apostles", "praise thee."],
            ["The goodly fellowship of the Prophets", "praise thee."],
            ["The noble army of Martyrs", "praise thee."],
            [
                "The holy Church throughout all the world",
                "doth acknowledge thee;",
            ],
            ["The Father", "of an infinite Majesty; "],
            ["Thine honourable, true", "and only Son;"],
            ["Also the Holy Ghost", "the Comforter."],
            ["Thou art the King of Glory", "O Christ."],
            ["Thou art the everlasting Son", "of the Father."],
            [
                "When thou tookest upon thee to deliver man",
                "thou didst not abhor the Virgin's womb.",
            ],
            [
                "When thou hadst overcome the sharpness of death",
                "thou didst open the kingdom of heaven to all believers.",
            ],
            [
                "Thou sittest at the right hand of God",
                "in the glory of the Father.",
            ],
            ["We believe that thou shalt come", "to be our Judge."],
            [
                "We therefore pray thee, help thy servants",
                "whom thou hast redeemed with thy precious blood.",
            ],
            [
                "Make them to be numbered with thy Saints",
                "in glory everlasting.",
            ],
            ["O Lord, save thy people", "and bless thine heritage."],
            ["Govern them", "and lift them up for ever."],
            ["Day by day", "we magnify thee;"],
            ["And we worship thy Name", "ever world without end."],
            ["Vouchsafe, O Lord", "to keep us this day without sin."],
            ["O Lord, have mercy upon us", "have mercy upon us."],
            [
                "O Lord, let thy mercy lighten upon us",
                "as our trust is in thee.",
            ],
            ["O Lord, in thee have I trusted", "let me never be confounded."],
        ],
    },
    benedictus: {
        lines: [
            [
                "BLESSED ✠ be the Lord God of Israel",
                "for he hath visited and redeemed his people;",
            ],
            [
                "And hath raised up a mighty salvation for us",
                "in the house of his servant David;",
            ],
            [
                "As he spake by the mouth of his holy Prophets",
                "which have been since the world began;",
            ],
            [
                "That we should be saved from our enemies",
                "and from the hand of all that hate us.",
            ],
            [
                "To perform the mercy promised to our forefathers",
                "and to remember his holy covenant;",
            ],
            [
                "To perform the oath which he sware to our forefather Abraham",
                "that he would give us;",
            ],
            [
                "That we being delivered out of the hand of our enemies",
                "might serve him without fear;",
            ],
            [
                "In holiness and righteousness before him",
                "all the days of our life.",
            ],
            [
                "And thou, child, shalt be called the Prophet of the Highest",
                "for thou shalt go before the face of the Lord to prepare his ways;",
            ],
            [
                "To give knowledge of salvation unto his people",
                "for the remission of their sins,",
            ],
            [
                "Through the tender mercy of our God",
                "whereby the day-spring from on high hath visited us;",
            ],
            [
                "To give light to them that sit in darkness, and in the shadow of death",
                "and to guide our feet into the way of peace.",
            ],
        ],
    },
    magnificat: {
        lines: [
            [
                "MY ✠ soul doth magnify the Lord",
                "and my spirit hath rejoiced in God my Saviour.",
            ],
            ["For he hath regarded", "the lowliness of his handmaiden."],
            [
                "For behold, from henceforth",
                "all generations shall call me blessed.",
            ],
            [
                "For he that is mighty hath magnified me",
                "and holy is his Name.",
            ],
            [
                "And his mercy is on them that fear him",
                "throughout all generations.",
            ],
            [
                "He hath showed strength with his arm",
                "he hath scattered the proud in the imagination of their hearts.",
            ],
            [
                "He hath put down the mighty from their seat",
                "and hath exalted the humble and meek.",
            ],
            [
                "He hath filled the hungry with good things",
                "and the rich he hath sent empty away.",
            ],
            [
                "He remembering his mercy hath holpen his servant Israel",
                "as he promised to our forefathers, Abraham and his seed, for ever.",
            ],
        ],
    },
    "nunc dimittis": {
        lines: [
            [
                "LORD, ✠ now lettest thou thy servant depart in peace",
                "according to thy word.",
            ],
            [
                "For mine eyes have seen thy salvation,",
                "Which thou hast prepared before the face of all people;",
            ],
            [
                "To be a light to lighten the Gentiles",
                "and to be the glory of thy people Israel.",
            ],
        ],
    },
    mon: {
        title: "Confitebor Tibi Domine",
        lines: [
            [
                "O LORD, I will praise thee, though thou wast angry with me",
                "thine anger is turned away, and thou comfortedst me.",
            ],
            ["Behold, God is my salvation", "I will trust, and not be afraid."],
            [
                "For the LORD GOD is my strength and my song",
                "he also is become my salvation.",
            ],
            [
                "Therefore with joy shall ye draw water out of the wells of salvation",
                "and in that day shall ye say, Praise the Lord, call upon his Name.",
            ],
            [
                "Declare his doings among the people",
                "make mention that his Name is exalted.",
            ],
            [
                "Sing unto the LORD, for he hath done excellent things",
                "this is known in all the earth.",
            ],
            [
                "Cry out and shout, thou inhabitant of Sion",
                "for great is the Holy One of Israel in the midst of thee.",
            ],
        ],
    },
    tues: {
        title: "Ego Dixi",
        lines: [
            [
                "I SAID: in the cutting off of my days",
                "I shall go to the gates of the grave.",
            ],
            [
                "I am deprived of the residue of my years",
                "I said, I shall not see the Lord, even the Lord in the land of the living.",
            ],
            [
                "I shall behold man no more",
                "with the inhabitants of the world.",
            ],
            [
                "Mine age is departed",
                "and is removed from me as a shepherd’s tent.",
            ],
            [
                "I have cut off like a weaver my life",
                "he will cut me off with pining sickness.",
            ],
            ["From day even to night", "wilt thou make an end of me."],
            [
                "I reckoned till morning that, as a lion, so will he break all my bones",
                "from day even to night wilt thou make an end of me.",
            ],
            [
                "Like a crane or a swallow, so did I chatter",
                "I did mourn as a dove.",
            ],
            [
                "Mine eyes fail with looking upward",
                "O LORD, I am oppressed; undertake for me.",
            ],
            [
                "What shall I say? He hath both spoken unto me, and himself hath done it",
                "I shall go softly all my years in the bitterness of my soul.",
            ],
            [
                "O LORD, by these things men live, and in all these things is the life of my spirit",
                "so wilt thou recover me, and make me to live.",
            ],
            [
                "Behold, for peace I had great bitterness; but thou hast in love to my soul delivered it from the pit of corruption",
                "for thou hast cast all my sins behind my back.",
            ],
            [
                "For the grave cannot praise thee, death cannot celebrate thee",
                "they that go down into the pit cannot hope for thy truth.",
            ],
            [
                "The living, the living, he shall praise thee, as I do this day",
                "the father to the children shall make known thy truth.",
            ],
            [
                "The LORD was ready to save me",
                "therefore we will sing my songs to the stringed instruments all the days of our life in the house of the Lord.",
            ],
        ],
    },
    wed: {
        title: "Exsultavit Cor Meum",
        lines: [
            [
                "MY heart rejoiceth in the LORD",
                "mine horn is exalted in the LORD.",
            ],
            [
                "My mouth is enlarged over mine enemies",
                "because I rejoice in thy salvation.",
            ],
            [
                "There is none holy as the LORD",
                "for there is none beside thee; neither is there any rock like our God.",
            ],
            [
                "Talk no more so exceeding proudly",
                "let not arrogancy come out of your mouth.",
            ],
            [
                "For the LORD is a God of knowledge",
                "and by him actions are weighed.",
            ],
            [
                "The bows of the mighty men are broken",
                "and they that stumbled are girded with strength.",
            ],
            [
                "They that were full have hired out themselves for bread",
                "and they that were hungry ceased.",
            ],
            [
                "So that the barren hath born seven",
                "and she that hath many children is waxed feeble.",
            ],
            [
                "The LORD killeth, and maketh alive",
                "he bringeth down to the grave, and bringeth up.",
            ],
            [
                "The LORD maketh poor, and maketh rich",
                "he bringeth low, and lifteth up.",
            ],
            [
                "He raiseth up the poor out of the dust",
                "and lifteth up the beggar from the dunghill.",
            ],
            [
                "To set them among princes",
                "and to make them inherit the throne of glory.",
            ],
            [
                "For the pillars of the earth are the LORD's",
                "and he hath set the world upon them.",
            ],
            [
                "He will keep the feet of his saints, and the wicked shall be silent in darkness",
                "for by strength shall no man prevail.",
            ],
            [
                "The adversaries of the Lord shall be broken to pieces",
                "out of heaven shall he thunder upon them.",
            ],
            [
                "The Lord shall judge the ends of the earth",
                "and he shall give strength unto his king, and exalt the horn of his anointed.",
            ],
        ],
    },
    thur: {
        title: "Cantemus Domino",
        lines: [
            [
                "I WILL sing unto the LORD, for he hath triumphed gloriously",
                "the horse and his rider hath he thrown into the sea.",
            ],
            [
                "The LORD is my strength and song",
                "and he is become my salvation.",
            ],
            [
                "He is my God, and I will prepare him an habitation",
                "my father’s God, and I will exalt him.",
            ],
            ["The LORD is a man of war", "the LORD is his Name."],
            [
                "Pharaoh’s chariots and his host hath he cast into the sea",
                "his chosen captains also are drowned in the Red sea.",
            ],
            [
                "The depths have covered them",
                "they sank into the bottom as a stone.",
            ],
            [
                "Thy right hand, O LORD, is become glorious in power",
                "thy right hand, O LORD, hath dashed in pieces the enemy.",
            ],
            [
                "And in the greatness of thine excellency thou hast overthrown them that rose up against thee",
                "thou sentest forth thy wrath, which consumed them as stubble. ",
            ],
            [
                "And with the blast of thy nostrils the waters were gathered together",
                "the floods stood upright as an heap, and the depths were congealed in the heart of the sea.",
            ],
            [
                "The enemy said, I will pursue, I will overtake, I will divide the spoil",
                "my lust shall be satisfied upon them.",
            ],
            ["I will draw my sword", "my hand shall destroy them."],
            [
                "Thou didst blow with thy wind, the sea covered them",
                "they sank as lead in the mighty waters.",
            ],
            [
                "Who is like unto thee, O LORD, among the gods?",
                "who is like thee, glorious in holiness, fearful in praises, doing wonders?",
            ],
            [
                "Thou stretchedst out thy right hand",
                "the earth swallowed them.",
            ],
            [
                "Thou in thy mercy hast led forth the people which thou hast redeemed",
                "thou hast guided them in thy strength unto thy holy habitation.",
            ],
            [
                "The people shall hear, and be afraid",
                "sorrow shall take hold of the inhabitants of Palestina.",
            ],
            [
                "Then the dukes of Edom shall be amazed",
                "the mighty men of Moab, trembling shall take hold upon them; all the inhabitants of Canaan shall melt away.",
            ],
            [
                "Fear and dread shall fall upon them;",
                "by the greatness of thine arm they shall be as still as a stone",
            ],
            [
                "till thy people pass over, O LORD,",
                "till the people pass over, which thou hast purchased.",
            ],
            [
                "Thou shalt bring them in, and plant them in the mountain of thine inheritance",
                "in the place, O LORD, which thou hast made for thee to dwell in, in the sanctuary, O LORD, which thy hands have established.",
            ],
            ["The Lord shall reign", "for ever and ever. "],
            [
                "For the horse of Pharaoh went in with his chariots and with his horsemen into the sea",
                "and the Lord brought again the waters of the sea upon them.",
            ],
            [
                "But the children of Israel went on dry land",
                "in the midst of the sea.",
            ],
        ],
    },
    fri: {
        title: "Domine Audivi",
        lines: [
            ["O LORD, I have heard thy speech", "and was afraid."],
            [
                "O LORD, revive thy work in the midst of the years, in the midst of the years make known",
                "in wrath remember mercy.",
            ],
            ["God came from Teman", "and the Holy One from mount Paran."],
            [
                "His glory covered the heavens",
                "and the earth was full of his praise.",
            ],
            [
                "And his brightness was as the light; he had horns coming out of his hand",
                "and there was the hiding of his power.",
            ],
            [
                "Before him went the pestilence",
                "and burning coals went forth at his feet.",
            ],
            [
                "He stood, and measured the earth,",
                "he beheld, and drove asunder the nations.",
            ],
            [
                "And the everlasting mountains were scattered, the perpetual hills did bow;",
                "his ways are everlasting.",
            ],
            [
                "I saw the tents of Cushan in affliction",
                "and the curtains of the land of Midian did tremble.",
            ],
            [
                "Was the LORD displeased against the rivers",
                "was thine anger against the rivers?",
            ],
            [
                "Was thy wrath against the sea",
                "that thou didst ride upon thy horses and thy chariots of salvation?",
            ],
            [
                "Thy bow was made quite naked",
                "according to the oaths of the tribes, even thy word.",
            ],
            [
                "Thou didst cleave the earth with rivers",
                "the mountains saw thee, and they trembled.",
            ],
            [
                "The overflowing of the water passed by",
                "the deep uttered his voice, and lifted up his hands on high.",
            ],
            [
                "The sun and moon stood still in their habitation",
                "at the light of thine arrows they went, and at the shining of thy glittering spear.",
            ],
            [
                "Thou didst march through the land in indignation",
                "thou didst thresh the heathen in anger.",
            ],
            [
                "Thou wentest forth for the salvation of thy people",
                "even for salvation with thine anointed.",
            ],
            [
                "Thou woundedst the head out of the house of the wicked",
                "by discovering the foundation unto the neck.",
            ],
            [
                "Thou didst strike through with his staves the head of his villages",
                "they came out as a whirlwind to scatter me; their rejoicing was as to devour the poor secretly.",
            ],
            [
                "Thou didst walk through the sea with thine horses",
                "through the heap of great waters.",
            ],
            [
                "When I heard, my belly trembled",
                "my lips quivered at the voice.",
            ],
            [
                "Rottenness entered into my bones, and I trembled in myself",
                "that I might rest in the day of trouble.",
            ],
            [
                "When he cometh up unto the people",
                "he will invade them with his troops.",
            ],
            [
                "Although the figtree shall not blossom",
                "neither shall fruit be in the vines;",
            ],
            [
                "The labour of the olive shall fail",
                "and the field shall yield no meat;",
            ],
            [
                "The flock shall be cut off from the fold",
                "and there shall be no herd in the stalls;",
            ],
            [
                "Yet I will rejoice in the LORD",
                "I will joy in the God of my salvation.",
            ],
            [
                "The LORD God is my strength, and he will make my feet like hinds’ feet",
                "and he will make me to walk upon mine high places.",
            ],
        ],
    },
    "sat-a": {
        title: "Audite Caeli",
        lines: [
            [
                "GIVE ear, O ye heavens, and I will speak",
                "and hear, O earth, the words of my mouth.",
            ],
            [
                "My doctrine shall drop as the rain, my speech shall distil as the dew",
                "as the small rain upon the tender herb, and as the showers upon the grass.",
            ],
            [
                "Because I will publish the Name of the Lord",
                "ascribe ye greatness unto our God.",
            ],
            [
                "He is the Rock, his work is perfect",
                "for all his ways are judgement.",
            ],
            ["A God of truth and without iniquity", "just and right is he."],
            [
                "They have corrupted themselves, their spot is not the spot of his children",
                "they are a perverse and crooked generation.",
            ],
            [
                "Do ye thus requite the Lord, O foolish people and unwise",
                "is not he thy Father that hath bought thee? hath he not made thee, and established thee?",
            ],
            [
                "Remember the days of old, consider the years of many generations",
                "ask thy father, and he will show thee; thy elders, and they will tell thee.",
            ],
            [
                "When the most High divided to the nations their inheritance",
                "when he separated the sons of Adam.",
            ],
            [
                "He set the bounds of the people",
                "according to the number of the children of Israel.",
            ],
            [
                "For the Lord’s portion is his people",
                "Jacob is the lot of his inheritance.",
            ],
            [
                "He found him in a desert land, and in the waste howling wilderness",
                "he led him about, he instructed him, he kept him as the apple of his eye.",
            ],
            [
                "As an eagle stirreth up her nest, fluttereth over her young,",
                "spreadeth abroad her wings, taketh them, beareth them on her wings:",
            ],
            [
                "So the Lord alone did lead him,",
                "and there was no strange god with him.",
            ],
            [
                "He made him ride on the high places of the earth,",
                "that he might eat the increase of the fields;",
            ],
            [
                "And he made him to suck honey out of the rock,",
                "and oil out of the flinty rock.",
            ],
            [
                "Butter of kine, and milk of sheep",
                "with fat of lambs, and rams of the breed of Basan,",
            ],
            [
                "And goats, with the fat of kidneys of wheat",
                "and thou didst drink the pure blood of the grape.",
            ],
            [
                "But Jeshurun waxed fat, and kicked",
                "thou art waxen fat, thou art grown thick, thou art covered with fatness;",
            ],
            [
                "Then he forsook God which made him",
                "and lightly esteemed the Rock of his salvation.",
            ],
            [
                "They provoked him to jealousy with strange gods",
                "with abominations provoked they him to anger.",
            ],
            [
                "They sacrificed unto devils, not to God",
                "to gods whom they knew not, to new gods that came newly up, whom your fathers feared not.",
            ],
            [
                "Of the Rock that begat thee thou art unmindful",
                "and hast forgotten God that formed thee.",
            ],
            [
                "And when the Lord saw it, he abhorred them",
                "because of the provoking of his sons, and of his daughters.",
            ],
            [
                "And he said, I will hide my face from them, I will see what their end shall be",
                "for they are a very froward generation, children in whom is no faith.",
            ],
            [
                "They have moved me to jealousy with that which is not God",
                "they have provoked me to anger with their vanities;",
            ],
            [
                "And I will move them to jealousy with those which are not a people",
                "I will provoke them to anger with a foolish nation.",
            ],
        ],
    },
    "sat-b": {
        title: "Ignis Succensus Est",
        lines: [
            [
                "FOR a fire is kindled in mine anger, and shall burn unto the lowest hell",
                "and shall consume the earth with her increase, and set on fire the foundations of the mountains.",
            ],
            [
                "I will heap mischiefs upon them",
                "I will spend mine arrows upon them.",
            ],
            [
                "They shall be burnt with hunger",
                "and devoured with burning heat, and with bitter destruction.",
            ],
            [
                "I will also send the teeth of beasts upon them",
                "with the poison of serpents of the dust.",
            ],
            [
                "The sword without, and terror within, shall destroy both the young man and the virgin",
                "the suckling also with the man of gray hairs.",
            ],
            [
                "I said, I would scatter them into corners",
                "I would make the remembrance of them to cease from among men.",
            ],
            [
                "Were it not that I feared the wrath of the enemy",
                "lest their adversaries should behave themselves strangely:",
            ],
            [
                "And lest they should say, Our hand is high",
                "and the Lord hath not done all this.",
            ],
            [
                "For they are a nation void of counsel",
                "neither is there any understanding in them.",
            ],
            [
                "O that they were wise, that they understood this",
                "that they would consider their latter end!",
            ],
            [
                "How should one chase a thousand, and two put ten thousand to flight",
                "except their Rock had sold them, and the Lord had shut them up?",
            ],
            [
                "For their rock is not as our Rock",
                "even our enemies themselves being judges.",
            ],
            [
                "For their vine is of the vine of Sodom",
                "and of the fields of Gomorrah.",
            ],
            ["Their grapes are grapes of gall", "their clusters are bitter."],
            [
                "Their wine is the poison of dragons",
                "and the cruel venom of asps.",
            ],
            [
                "Is not this laid up in store with me, and sealed up among my treasures",
                "to me belongeth vengeance and recompense.",
            ],
            [
                "Their foot shall slide in due time;",
                "for the day of their calamity is at hand, and the things that shall come upon them make haste.",
            ],
            [
                "For the Lord shall judge his people, and repent himself for his servants",
                "when he seeth that their power is gone, and there is none shut up, or left.",
            ],
            [
                "And he shall say, Where are their gods,",
                "their rock in whom they trusted,",
            ],
            [
                "Which did eat the fat of their sacrifices,",
                "and drank the wine of their drink offerings?",
            ],
            ["Let them rise up and help you", "and be your protection."],
            ["See now that I, even I, am he", "and there is no god with me."],
            [
                "I kill, and I make alive; I wound and I heal",
                "neither is there any that can deliver out of my hand.",
            ],
            ["For I lift up my hand to heaven", "and say, I live for ever."],
            [
                "If I whet my glittering sword, and mine hand take hold on judgement",
                "I will render vengeance to mine enemies, and I will reward them that hate me.",
            ],
            [
                "I will make mine arrows drunk with blood",
                "and my sword shall devour lesh.",
            ],
            [
                "And that with the blood of the slain and of the captives",
                "from the beginning of revenges upon the enemy.",
            ],
            [
                "Rejoice, O ye nations, with his people",
                "for he will avenge the blood of his servants;",
            ],
            [
                "And will render vengeance to his adversaries",
                "and will be merciful unto his land, and to his people.",
            ],
        ],
    },
    benedicte: {
        title: "Benedicite",
        lines: [
            [
                "O ALL ye Works of the Lord, bless ye the Lord",
                "praise him, and magnify him for ever.",
            ],
            [
                "O ye Angels of the Lord, bless ye the Lord",
                "praise him, and magnify him for ever.",
            ],
            [
                "O ye Heavens, bless ye the Lord",
                "praise him, and magnify him for ever.",
            ],
            [
                "O ye Waters that be above the Firmament, bless ye the Lord",
                "praise him, and magnify him for ever.",
            ],
            [
                "O all ye Powers of the Lord, bless ye the Lord",
                "praise him, and magnify him for ever.",
            ],
            [
                "O ye Sun and Moon, bless ye the Lord",
                "praise him, and magnify him for ever.",
            ],
            [
                "O ye Stars of Heaven, bless ye the Lord",
                "praise him, and magnify him for ever.",
            ],
            [
                "O ye Showers and Dew, bless ye the Lord",
                "praise him, and magnify him for ever.",
            ],
            [
                "O ye Winds of God, bless ye the Lord",
                "praise him, and magnify him for ever.",
            ],
            [
                "O ye Fire and Heat, bless ye the Lord",
                "praise him, and magnify him for ever.",
            ],
            [
                "O ye Winter and Summer, bless ye the Lord",
                "praise him, and magnify him for ever.",
            ],
            [
                "O ye Dews and Frosts, bless ye the Lord",
                "praise him, and magnify him for ever.",
            ],
            [
                "O ye Frost and Cold, bless ye the Lord",
                "praise him, and magnify him for ever.",
            ],
            [
                "O ye Ice and Snow, bless ye the Lord",
                "praise him, and magnify him for ever.",
            ],
            [
                "O ye Nights and Days, bless ye the Lord",
                "praise him, and magnify him for ever.",
            ],
            [
                "O ye Light and Darkness, bless ye the Lord",
                "praise him, and magnify him for ever.",
            ],
            [
                "O ye Lightnings and Clouds, bless ye the Lord",
                "praise him, and magnify him for ever.",
            ],
            [
                "O let the Earth bless the Lord",
                "yea, let it praise him, and magnify him for ever.",
            ],
            [
                "O ye Mountains and Hills, bless ye the Lord",
                "praise him, and magnify him for ever.",
            ],
            [
                "O all ye Green Things upon the Earth, bless ye the Lord",
                "praise him, and magnify him for ever.",
            ],
            [
                "O ye Wells, bless ye the Lord",
                "praise him, and magnify him for ever.",
            ],
            [
                "O ye Seas and Floods, bless ye the Lord",
                "praise him, and magnify him for ever.",
            ],
            [
                "O ye Whales, and all that move in the Waters, bless ye the Lord",
                "praise him, and magnify him for ever.",
            ],
            [
                "O all ye Fowls of the Air, bless ye the Lord",
                "praise him, and magnify him for ever.",
            ],
            [
                "O all ye Beasts and Cattle, bless ye the Lord",
                "praise him, and magnify him for ever.",
            ],
            [
                "O ye Children of Men, bless ye the Lord",
                "praise him, and magnify him for ever.",
            ],
            [
                "O let Israel bless the Lord",
                "praise him, and magnify him for ever.",
            ],
            [
                "O ye Priests of the Lord, bless ye the Lord",
                "praise him, and magnify him for ever.",
            ],
            [
                "O ye Servants of the Lord, bless ye the Lord",
                "praise him, and magnify him for ever.",
            ],
            [
                "O ye Spirits and Souls of the Righteous, bless ye the Lord",
                "praise him, and magnify him for ever.",
            ],
            [
                "O ye holy and humble Men of heart, bless ye the Lord",
                "praise him, and magnify him for ever.",
            ],
            [
                "O Ananias, Azarias, and Misael, bless ye the Lord",
                "praise him, and magnify him for ever.",
            ],
        ],
    },
    sun: {
        title: "Benedicite (shorter)",
        lines: [
            [
                "O ALL ye Works of the Lord, bless ye the Lord",
                "praise him, and magnify him for ever.",
            ],
            [
                "O ye Angels of the Lord, bless ye the Lord",
                "O ye Heavens, bless ye the Lord.",
            ],
            [
                "O ye Waters that be above the Firmament, bless ye the Lord",
                "O all ye Powers of the Lord, bless ye the Lord.",
            ],
            [
                "O ye Sun and Moon, bless ye the Lord",
                "O ye Stars of Heaven, bless ye the Lord.",
            ],
            [
                "O ye Showers and Dew, bless ye the Lord",
                "O ye Winds of God, bless ye the Lord.",
            ],
            [
                "O ye Fire and Heat, bless ye the Lord",
                "O ye Winter and Summer, bless ye the Lord.",
            ],
            [
                "O ye Dews and Frosts, bless ye the Lord",
                "O ye Frost and Cold, bless ye the Lord.",
            ],
            [
                "O ye Ice and Snow, bless ye the Lord",
                "O ye Nights and Days, bless ye the Lord.",
            ],
            [
                "O ye Light and Darkness, bless ye the Lord",
                "O ye Lightnings and Clouds, bless ye the Lord.",
            ],
            [
                "O let the Earth bless the Lord",
                "yea, let it praise him, and magnify him for ever.",
            ],
            [
                "O ye Mountains and Hills, bless ye the Lord",
                "O all ye Green Things upon the Earth, bless ye the Lord.",
            ],
            [
                "O ye Wells, bless ye the Lord",
                "O ye Seas and Floods, bless ye the Lord.",
            ],
            [
                "O ye Whales, and all that move in the Waters, bless ye the Lord",
                "O all ye Fowls of the Air, bless ye the Lord.",
            ],
            [
                "O all ye Beasts and Cattle, bless ye the Lord",
                "O ye Children of Men, bless ye the Lord.",
            ],
            [
                "O let Israel bless the Lord",
                "praise him, and magnify him for ever.",
            ],
            [
                "O ye Priests of the Lord, bless ye the Lord",
                "O ye Servants of the Lord, bless ye the Lord.",
            ],
            [
                "O ye Spirits and Souls of the Righteous, bless ye the Lord",
                "O ye holy and humble Men of heart, bless ye the Lord.",
            ],
            [
                "O Ananias, Azarias, and Misael, bless ye the Lord",
                "praise him, and magnify him for ever.",
            ],
            [
                "Let us bless the Father and the Son, and the Holy Ghost",
                "let us praise him and manify hime for ever.",
            ],
            [
                "Blessed art thou, O Lord, in the firmament of heaven",
                "and to be praised and exalted above all for ever.",
            ],
        ],
    },
    "quicunque vult": {
        lines: [
            [
                "Whosoever will be saved",
                "before all things it is necessary that he hold the Catholic Faith.",
            ],
            [
                "Which Faith except every one do keep whole and undefiled",
                "without doubt he shall perish everlastingly.",
            ],
            [
                "And the Catholic Faith is this:",
                "That we worship one God in Trinity, and Trinity in Unity;",
            ],
            ["Neither confounding the Persons", "nor dividing the Substance."],
            [
                "For there is one Person of the Father, another of the Son",
                "and another of the Holy Ghost.",
            ],
            [
                "But the Godhead of the Father, of the Son, and of the Holy Ghost, is all one",
                "the Glory equal, the Majesty co-eternal.",
            ],
            [
                "Such as the Father is, such is the Son",
                "and such is the Holy Ghost.",
            ],
            [
                "The Father uncreate, the Son uncreate",
                "and the Holy Ghost uncreate.",
            ],
            [
                "The Father incomprehensible, the Son incomprehensible",
                "and the Holy Ghost incomprehensible.",
            ],
            [
                "The Father eternal, the Son eternal",
                "and the Holy Ghost eternal.",
            ],
            ["And yet they are not three eternals", "but one eternal."],
            [
                "As also there are not three incomprehensibles, nor three uncreated",
                "but one uncreated, and one incomprehensible.",
            ],
            [
                "So likewise the Father is Almighty, the Son Almighty",
                "and the Holy Ghost Almighty.",
            ],
            ["And yet they are not three Almighties", "but one Almighty."],
            [
                "So the Father is God, the Son is God, and the Holy Ghost is God.",
                "And yet they are not three Gods, but one God.",
            ],
            [
                "So likewise the Father is Lord, the Son Lord, and the Holy Ghost Lord.",
                "And yet not three Lords, but one Lord.",
            ],
            [
                "For like as we are compelled by the Christian verity",
                "to acknowledge every Person by himself to be God and Lord;",
            ],
            [
                "So are we forbidden by the Catholic Religion",
                "to say there be three Gods, or three Lords.",
            ],
            ["The Father is made of none", "neither created, nor begotten."],
            [
                "The Son is of the Father alone",
                "not made, nor created, but begotten.",
            ],
            [
                "The Holy Ghost is of the Father and of the Son",
                "neither made, nor created, nor begotten, but proceeding.",
            ],
            [
                "So there is one Father, not three Fathers; one Son, not three Sons",
                "one Holy Ghost, not three Holy Ghosts.",
            ],
            [
                "And in this Trinity none is afore, or after other; none is greater, or less than another;",
                "But the whole three Persons are co-eternal together and co-equal.",
            ],
            [
                "So that in all things, as is aforesaid",
                "the Unity in Trinity, and the Trinity in Unity is to be worshipped.",
            ],
            [
                "He therefore that will be saved",
                "must thus think of the Trinity.",
            ],
            [
                "Furthermore it is necessary to everlasting salvation",
                "that he also believe rightly the Incarnation of our Lord Jesus Christ.",
            ],
            [
                "For the right Faith is that we believe and confess",
                "that our Lord Jesus Christ, the Son of God, is God and Man;",
            ],
            [
                "God, of the Substance of the Father, begotten before the worlds",
                "and Man, of the Substance of his Mother, born in the world;",
            ],
            [
                "Perfect God, and Perfect Man",
                "of a reasonable soul and human flesh subsisting;",
            ],
            [
                "Equal to the Father, as touching his Godhead",
                "and inferior to the Father, as touching his Manhood.",
            ],
            [
                "Who although he be God and Man",
                "yet he is not two, but one Christ;",
            ],
            [
                "One, not by conversion of the Godhead into flesh",
                "but by taking of the Manhood into God;",
            ],
            [
                "One altogether, not by confusion of Substance",
                "but by unity of Person.",
            ],
            [
                "For as the reasonable soul and flesh is one man",
                "so God and Man is one Christ.",
            ],
            [
                "Who suffered for our salvation",
                "descended into hell, rose again the third day from the dead.",
            ],
            [
                "He ascended into heaven, he sitteth on the right hand of the Father, God Almighty:",
                "from whence he shall come to judge the quick and the dead.",
            ],
            [
                "At whose coming all men shall rise again with their bodies",
                "and shall give account for their own works.",
            ],
            [
                "And they that have done good shall go into life everlasting",
                "and they that have done evil into everlasting fire.",
            ],
            [
                "This is the Catholic Faith",
                "which except a man believe faithfully, he cannot be saved.",
            ],
        ],
    },
};

export const getCanticle = (id: string, addGloriaPatri: boolean) => {
    const gloriaPatri = [
        ["Glory be to the Father and to the Son", "and to the Holy Ghost."],
        [
            "As it was in the beginning, is now, and ever shall be",
            "world without end. Amen.",
        ],
    ];

    return {
        id,
        title: CANTICLES[id].title,
        lines: CANTICLES[id].lines.concat(addGloriaPatri ? gloriaPatri : []),
    };
};
