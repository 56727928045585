import { BaseCalendarDay, PrayerHour } from "./calendar/utils";
import { LiturgyItem } from "./liturgy-viewer/LiturgyViewer";

export const OPENING_SENTENCES: {
    [id: string]: { text: string; ref: string }[];
} = {
    "1662": [
        {
            text: "When the wicked man turneth away from his wickedness that he hath committed, and doeth that which is lawful and right, he shall save his soul alive.",
            ref: "Ezekiel 18:27",
        },
        {
            text: "I acknowledge my transgressions, and my sin is ever before me.",
            ref: "Psalm 51:3",
        },
        {
            text: "Hide thy face from my sins, and blot out all mine iniquities.",
            ref: "Psalm 51:9",
        },
        {
            text: "The sacrifices of God are a broken spirit: a broken and a contrite heart, O God, thou wilt not despise.",
            ref: "Psalm 51:17",
        },
        {
            text: "Rend your heart, and not your garments, and turn unto the Lord your God: for he is gracious and merciful, slow to anger, and of great kindness, and repenteth him of the evil.",
            ref: "Joel 2:13",
        },
        {
            text: "To the Lord our God belong mercies and forgivenesses, though we have rebelled against him: neither have we obeyed the voice of the Lord our God, to walk in his laws which he set before us.",
            ref: "Daniel 9:9",
        },
        {
            text: "O Lord, correct me, but with judgement; not in thine anger, lest thou bring me to nothing.",
            ref: "Jeremiah 10:24",
        },
        {
            text: "Repent ye; for the Kingdom of heaven is at hand.",
            ref: "St. Matthew 3:2",
        },
        {
            text: "I will arise and go to my father, and will say unto him, Father, I have sinned against heaven, and before thee, and am no more worthy to be called thy son.",
            ref: "St. Luke 15:18-19",
        },
        {
            text: "Enter not into judgement with thy servant, O Lord; for in thy sight shall no man living be justified.",
            ref: "Psalm 143:2",
        },
        {
            text: "If we say that we have no sin, we deceive ourselves, and the truth is not in us: but if we confess our sins, he is faithful and just to forgive us our sins, and to cleanse us from all unrighteousness.",
            ref: "1 St. John 1:8-9",
        },
    ],
};

function findHCF(x: number, y: number) {
    while (Math.max(x, y) % Math.min(x, y) != 0) {
        if (x > y) {
            x %= y;
        } else {
            y %= x;
        }
    }

    return Math.min(x, y);
}

export const getOpeningSentence = (
    day: BaseCalendarDay,
    hour: PrayerHour
): LiturgyItem[] => {
    const id: string = "1662";

    const sentences = OPENING_SENTENCES[id];

    if (id === "ordinary") {
        if (hour === "mattins") {
            sentences.push(...OPENING_SENTENCES["morning"]);
        } else {
            sentences.push(...OPENING_SENTENCES["evening"]);
        }
    }

    if (sentences.length === 0) {
        return [];
    } else {
        const possibleCycles = [];
        for (let i = 2; i < sentences.length; i++) {
            if (findHCF(i, sentences.length) === 1) {
                possibleCycles.push(i);
            }
        }
        const target = 0.66 * sentences.length;
        let bestCycle = -1000;
        possibleCycles.forEach((possibleCycle) => {
            if (
                Math.abs(target - possibleCycle) < Math.abs(target - bestCycle)
            ) {
                bestCycle = possibleCycle;
            }
        });

        const sentence =
            sentences[
                ((day.dayOfTheYear * 2 + (hour === "evensong" ? 1 : 0)) *
                    bestCycle) %
                    sentences.length
            ];

        return [
            {
                type: "rubric",
                content: `Before the Office, the Officiant shall read one or more sentences of Scripture.`,
            },
            { type: "text", content: sentence.text },
            { type: "spacer" },
        ];
    }
};
