import { Common } from "./ordinariate-calendar";

const oneMartyr = {
    ep1HymnId: "martyr of god whose strength was steeled",
    invId: "martyr inv",
    mpHymnId: "martyr of god whose strength was steeled",
    mpVandR: [
        "The righ_teous shall flou_rish like a palm tree.",
        "And shall spread a_broad like a ce_dar in Li_ba_nus.",
    ],
    mpBenId: "martyr ben",
    ep2HymnId: "o god thy soldiers crown and guard",
    ep2VandR: [
        "The righ_teous shall flou_rish like a palm tree.",
        "And shall spread a_broad like a ce_dar in Li_ba_nus.",
    ],
    ep2MagId: "martyr ep2 mag",
    easterOverrides: {
        mpVandR: [
            "Right dear in the sight of the Lord.",
            "Is the death of his Saints.",
        ],
        ep2VandR: [
            "Right dear in the sight of the Lord.",
            "Is the death of his Saints.",
        ],
    },
};

const confessorBishop = {
    ep1HymnId: "he whose confession god of old accepted",
    ep1MagId: "bishop o high priest",
    ep1VandR: [
        "The Lord loved him and a_dorned him.",
        "He clothed him with a robe of glo_ry.",
    ],
    invId: "religious the lord the king",
    mpHymnId: "he whose confession god of old accepted",
    mpVandR: [
        "The Lord gui_ded the righ_teous in right paths.",
        "And showed him the king_dom of God.",
    ],
    mpBenId: "bishops well done good and faithful",
    ep2HymnId: "o thou whose allredeeming might",
    ep2VandR: [
        "The Lord gui_ded the righ_teous in right paths.",
        "And showed him the king_dom of God.",
    ],
    ep2MagId: "bishops the lord loved him",
};

const virgin = {
    ep1HymnId: "son of a virgin maker of thy mother",
    ep1VandR: [
        "In thy grace and in the beau_ty go forth.",
        "Ride pro_spe_rous_ly and reign.",
    ],
    ep1MagId: "virgins come thou bride",
    invId: "virgins the lord the king",
    mpHymnId: "jesu the virgins crown do thou",
    mpVandR: [
        "Full of grace are thy lips.",
        "Be_cause God hath blessed thee for e_ver.",
    ],
    mpBenId: "virgins the kingdom of",
    ep2HymnId: "son of a virgin maker of thy mother",
    ep2VandR: [
        "In thy grace and in the beau_ty go forth.",
        "Ride pro_spe_rous_ly and reign.",
    ],
    ep2MagId: "virgins come thou bride",
};

const confessor = {
    ep1HymnId: "he whose confession god of old accepted",
    ep1VandR: [
        "The Lord loved him and a_dorned him.",
        "He clothed him with a robe of glo_ry.",
    ],
    ep1MagId: "religious i will liken him",
    invId: "religious the lord the king",
    mpHymnId: "he whose confession god of old accepted",
    mpVandR: [
        "The Lord gui_ded the righ_teous in right paths.",
        "And showed him the king_dom of God.",
    ],
    mpBenId: "religious well done good and faithful",
    ep2HymnId: "o thou whose allredeeming might",
    ep2VandR: [
        "The Lord gui_ded the righ_teous in right paths.",
        "And showed him the king_dom of God.",
    ],
    ep2MagId: "religious this man the world",
};

export const COMMONS: { [id: string]: Common } = {
    "Abbot or Abbess": {
        ...confessor,
        ep1HymnId: "sing we of those who in the forest wild",
        mpHymnId: "why fledst thou from all folk apart",
        ep2HymnId: "sing we of those who in the forest wild",
    },
    Religious: {
        ...confessor,
        ep1HymnId: "sing we of those who in the forest wild",
        mpHymnId: "why fledst thou from all folk apart",
        ep2HymnId: "sing we of those who in the forest wild",
    },
    "Confessor: Bishop": confessorBishop,
    "Confessor: Holy Pope": confessorBishop,
    "Blessed Virgin Mary": {
        ep1HymnId: "hail o star that pointest",
        ep1VandR: [
            "Vouch_safe to re_ceive my /prai_ses, ' O hal_lowed Vir_gin.",
            "Streng_then /me to re_sist ' all thine e_ne_mies.",
        ],
        ep1MagId: "bvm o holy mary",
        invId: "bvm inv",
        mpHymnId: "the god whom earth and sea and sky",
        mpVandR: [
            "Full of grace are thy lips.",
            "Be_cause God hath blessed thee for e_ver.",
        ],
        mpBenId: "bvm blessed art thou",
        ep2HymnId: "hail o star that pointest",
        ep2VandR: [
            "Vouch_safe to re_ceive my /prai_ses, ' O hal_lowed Vir_gin.",
            "Streng_then /me to re_sist ' all thine e_ne_mies.",
        ],
        ep2MagId: "bvm all generations",
    },
    Apostles: {
        ep1HymnId: "lord of creation bow thine ear o christ to hear",
        ep1VandR: [
            "For their sound is gone out in_to all lands.",
            "And their words in_to the ends of the world.",
        ],
        ep1MagId: "apostles for they shall",
        invId: "apostles inv",
        mpHymnId: "let the round world with songs rejoice",
        mpVandR: [
            "They de_clared the work of God.",
            "And wise_ly con_si_dered of his do_ings.",
        ],
        mpBenId: "apostles ye which have",
        ep2HymnId: "let the round world with songs rejoice",
        ep2VandR: [
            "They de_clared the work of God.",
            "And wise_ly con_si_dered of his do_ings.",
        ],
        ep2MagId: "apostles be ye valiant",
        easterOverrides: {
            ep1HymnId: "the sad apostles mourn him slain",
            ep1VandR: [
                "O ye saints and righ_teous, re_joice in the Lord.",
                "God hath cho_sen you for his own in_he_ri_tance.",
            ],
            ep1MagId: "apostles light perpetual",
            mpHymnId: "on that fair day of paschal joy",
            mpVandR: [
                "Right dear in the sight of the Lord.",
                "Is the death of his Saints.",
            ],
            mpBenId: "apostles daughters of jerusalem",
            ep2HymnId: "on that fair day of paschal joy",
            ep2VandR: [
                "Right dear in the sight of the Lord.",
                "Is the death of his Saints.",
            ],
            ep2MagId: "apostles o ye holy",
        },
    },
    "Doctor of the Church": confessor,
    "Confessor: Priest": confessor,
    "Confessor: Missionary": confessor,
    "Martyr: Bishop": oneMartyr,
    "Martyr: Pope or Bishop": oneMartyr,
    "One Martyr": oneMartyr,
    "Virgin Martyr": {
        ...virgin,
    },
    "Many Virgin Martyrs": {
        ...virgin,
        ep1VandR: [
            "The Vir_gins shall be brought un_to the King.",
            "Her fel_lows shall be brought un_to thee.",
        ],
        mpVandR: [
            "The Vir_gins shall be brought un_to the King.",
            "Her fel_lows shall be brought un_to thee.",
        ],
        ep2VandR: [
            "The Vir_gins shall be brought un_to the King.",
            "Her fel_lows shall be brought un_to thee.",
        ],
    },
    Virgin: {
        ...virgin,
    },
    Educator: {
        ...confessor,
    },
    "Several Martyrs": {
        ep1HymnId: "the merits of the saints",
        ep1VandR: [
            "Be glad O ye right_eous, and re_joice in the Lord.",
            "And be joy_ful, all ye that are true of heart.",
        ],
        ep1MagId: "many martyrs for theirs is the kingdom",
        invId: "martyr inv",
        mpHymnId: "the merits of the saints",
        mpVandR: [
            "Let the Saints be joy_ful in glo_ry.",
            "Let them re_joice in their beds.",
        ],
        mpBenId: "many martyrs the very hairs",
        ep2HymnId: "o glorious king of martyr hosts",
        ep2VandR: [
            "Let the Saints be joy_ful in glo_ry.",
            "Let them re_joice in their beds.",
        ],
        ep2MagId: "many martyrs in the heavenly",
        easterOverrides: {
            ep1HymnId: "o glorious king of martyr hosts",
            ep1VandR: [
                "O ye saints and righ_teous, re_joice in the Lord.",
                "God hath cho_sen you for his own in_he_ri_tance.",
            ],
            ep1MagId: "apostles light perpetual",
            mpHymnId: "o glorious king of martyr hosts",
            mpVandR: [
                "Right dear in the sight of the Lord.",
                "Is the death of his Saints.",
            ],
            mpBenId: "apostles daughters of jerusalem",
            ep2HymnId: "o glorious king of martyr hosts",
            ep2VandR: [
                "Right dear in the sight of the Lord.",
                "Is the death of his Saints.",
            ],
            ep2MagId: "apostles o ye holy",
        },
    },
    "Any Holy Man": {
        ep1HymnId: "o thou whose allredeeming might",
        ep1VandR: [
            "The Lord loved him and a_dorned him.",
            "He clothed him with a robe of glo_ry.",
        ],
        ep1MagId: "religious i will liken him",
        invId: "inv any holy man",
        mpHymnId: "jesu eternal truth sublime",
        mpVandR: [
            "The Lord gui_ded the righ_teous in right paths.",
            "And showed him the king_dom of God.",
        ],
        mpBenId: "religious well done good and faithful",
        ep2HymnId: "o thou whose allredeeming might",
        ep2VandR: [
            "The Lord loved him and a_dorned him.",
            "He clothed him with a robe of glo_ry.",
        ],
        ep2MagId: "religious this man the world",
    },
    "Any Matron or Holy Woman": {
        ep1HymnId: "the praises of that saint we sing",
        ep1VandR: [
            "In thy grace and in thy beau_ty go forth.",
            "Ride pros_pe_rous_ly and reign.",
        ],
        ep1MagId: "virgins the kingdom of",
        // INV IS PER SAINT
        mpHymnId: "the praises of that saint we sing",
        mpVandR: [
            "Full of grace are thy lips.",
            "Be_cause God hath blessed thee for e_ver.",
        ],
        mpBenId: "holy woman give her",
        ep2HymnId: "the praises of that saint we sing",
        ep2VandR: [
            "In thy grace and in thy beau_ty go forth.",
            "Ride pros_pe_rous_ly and reign.",
        ],
        ep2MagId: "matron she stretcheth",
    },
    "Dedication of a Church": {
        ep1HymnId: "blessed city heavenly salem",
        ep1VandR: [
            "This is the house of the Lord, most firm_ly build_ed.",
            "It is well found_ed u_pon a strong rock.",
        ],
        ep1MagId: "dedication the lord hath",
        invId: "dedication inv",
        mpHymnId: "christ is made the sure foundation",
        mpVandR: [
            "This is the house of the Lord, most firm_ly build_ed.",
            "It is well found_ed u_pon a strong rock.",
        ],
        mpBenId: "dedication zacchaeus",
        ep2HymnId: "christ is made the sure foundation",
        ep2VandR: [
            "Ho_li_ness be_com_eth thine house, O Lord.",
            "For e_ver and e_ver.",
        ],
        ep2MagId: "dedication o how",
    },
};
