import React, { useLayoutEffect, useRef } from "react";
import ReactMarkdown from "react-markdown";
import { capitalizeFirstWord } from "../ordinariate-liturgy";
import { isSmallScreen } from "../utils";

const NULL: any = null;

export const MarkdownTextLine: React.FC<{
    content: string;
    indent?: number;
    dropCap?: boolean;
}> = (props) => {
    const { content, indent, dropCap } = props;

    console.log("content", content);

    let initialLength = dropCap ? 1 : 0;
    try {
        while (
            !"ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz".includes(
                content[initialLength - 1]
            ) &&
            dropCap
        ) {
            initialLength++;
        }
    } catch {
        // pass
    }

    const capitalized = dropCap ? capitalizeFirstWord(content) : content;

    const initial = capitalized.slice(0, initialLength);
    const main = capitalized.slice(initialLength);

    const containerRef = useRef<HTMLDivElement>(NULL);

    useLayoutEffect(() => {
        const el = containerRef.current.querySelector("p");

        const existingInitial = containerRef.current.querySelector(
            isSmallScreen() ? "p span.initial-small" : "p span.initial"
        );

        const initialEl = document.createElement("span");
        initialEl.className = isSmallScreen() ? "initial-small" : "initial";
        initialEl.appendChild(document.createTextNode(initial));

        if (el && !existingInitial && dropCap) {
            el.insertBefore(initialEl, el.childNodes[0] || null);
        }
    });

    return (
        <div
            style={{
                paddingLeft:
                    indent === 10
                        ? 0
                        : (indent ?? 0) * (isSmallScreen() ? 16 : 24),
                paddingTop: (indent ?? 0) === 0 ? 6 : 0,
                paddingBottom: (indent ?? 0) === 0 ? 6 : 0,
                textAlign:
                    indent === 10
                        ? "right"
                        : (indent ?? 0) === 0
                        ? "justify"
                        : "left",
            }}
            ref={containerRef}
            className="markdown-text-line"
            data-content={content}
            data-indent={indent}
        >
            <ReactMarkdown>{main.replace(/\\n/g, "  \n")}</ReactMarkdown>
        </div>
    );
};
