import React from "react";
import { Lesson } from "../calendar/get-texts";
import { PAGE_BACKGROUND } from "../tokens";
import { isSmallScreen } from "../utils";
import { Hymn } from "./Hymn";
import { LoadingScreen } from "./LoadingScreen";
import { PsalmOrCanticle } from "./PsalmOrCanticle";
import { Rubric } from "./Rubric";
import { Scripture } from "./Scripture";
import { ScriptureLink } from "./ScriptureLink";
import { Spacer } from "./Spacer";
import { TextBlock } from "./TextBlock";
import { VandR } from "./VandR";

export type LiturgyItem =
    | { type: "rubric"; content: string }
    | { type: "text"; content: string; dropCap?: boolean }
    | { type: "spacer"; big?: boolean }
    | { type: "loading" }
    | {
          type: "scripture";
          title: string;
          lesson: Lesson;
      }
    | {
          type: "picker";
          options: string[];
          selectedOption: string;
          onChange: (newSelection: string) => void;
      }
    | {
          type: "psalm or canticle";
          title: string;
          lines: string[][];
          ant: string | undefined;
          incipit?: string;
      }
    | { type: "v and r"; lines: string[] }
    | { type: "hymn"; id: string; title: string; text: string }
    | { type: "link"; title: string; url: string };

export const LiturgyViewer: React.FC<{
    items: LiturgyItem[];
    label?: string;
}> = (props) => {
    const { items, label } = props;

    return (
        <>
            {items
                .map((item) => {
                    switch (item.type) {
                        case "rubric":
                            return <Rubric content={item.content} />;

                        case "text":
                            return (
                                <TextBlock
                                    content={item.content}
                                    dropCap={item.dropCap}
                                />
                            );

                        case "v and r":
                            return <VandR lines={item.lines} />;

                        case "spacer":
                            return <Spacer big={item.big} />;

                        case "loading":
                            return <LoadingScreen />;

                        case "psalm or canticle":
                            return (
                                <PsalmOrCanticle
                                    title={item.title}
                                    lines={item.lines}
                                    ant={item.ant}
                                    incipit={item.incipit}
                                />
                            );

                        case "scripture":
                            return (
                                <Scripture
                                    title={item.title}
                                    lesson={item.lesson}
                                />
                            );

                        case "picker":
                            return (
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "center",
                                    }}
                                >
                                    <div
                                        style={{
                                            backgroundColor: PAGE_BACKGROUND,
                                            display: "flex",
                                            flexDirection: "row",
                                            padding: 2,
                                            borderRadius: 4,
                                        }}
                                    >
                                        {item.options.map((option) => {
                                            return (
                                                <div
                                                    style={{
                                                        padding: 4,
                                                        paddingLeft:
                                                            isSmallScreen()
                                                                ? 6
                                                                : 12,
                                                        paddingRight:
                                                            isSmallScreen()
                                                                ? 6
                                                                : 12,
                                                        backgroundColor:
                                                            item.selectedOption ===
                                                            option
                                                                ? "white"
                                                                : "transparent",
                                                        borderRadius: 2,
                                                        fontSize: "90%",
                                                        fontWeight: "bold",
                                                        color: "black",
                                                        cursor: "pointer",
                                                    }}
                                                    onClick={() => {
                                                        item.onChange(option);
                                                    }}
                                                >
                                                    {option}
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            );

                        case "hymn":
                            return (
                                <Hymn
                                    hymnId={item.id}
                                    title={item.title}
                                    text={item.text}
                                />
                            );

                        case "link":
                            return (
                                <ScriptureLink
                                    title={item.title}
                                    url={item.url}
                                />
                            );
                    }
                })
                .map((element, index) => {
                    return (
                        <div
                            className="liturgy-item"
                            key={`${index} - ${JSON.stringify(items[index])}`}
                        >
                            {element}
                        </div>
                    );
                })}
        </>
    );
};
