import React from "react";
import { isSmallScreen } from "./utils";

export const Padding: React.FC<{
    onlySides?: boolean;
    children: JSX.Element | JSX.Element[];
}> = (props) => {
    const { onlySides, children } = props;

    const padding = isSmallScreen() ? 8 : 48;

    return (
        <div
            style={
                onlySides
                    ? { paddingLeft: padding, paddingRight: padding }
                    : {
                          padding: padding,
                      }
            }
        >
            {children}
        </div>
    );
};
