export const ORD_SAINTS = [
    {
        day: 2,
        month: 1,
        title: "Saints Basil the Great and Gregory Nazianzen",
        subtitle: "Bishops and Doctors of the Church",
        type: "memorial",
        rank: 10,
        optional: false,
        collect:
            "Almighty God, whose servants Basil and Gregory proclaimed the mystery of thy Word made flesh, that thy Church might be built up in wisdom and strength: grant that we, through their prayers, and rejoicing in the Lord's presence among us, may with them be brought to know the power of thine unending love; through the same Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        commons: ["Confessor: Bishop"],
    },
    {
        day: 3,
        month: 1,
        title: "The Most Holy Name of Jesus",
        subtitle: "",
        type: "memorial",
        rank: 12,
        optional: true,
        collect:
            "Almighty God, who by thy blessed Apostle hast taught us that there is none other name given among men whereby we must be saved, but only the Name of our Lord Jesus Christ: grant, we beseech thee; that we may ever glory in this Name, and strive to make thy salvation known unto all mankind; through the same Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        supplements: {
            invId: "hnj inv",
            mpHymnId: "jesu the very thought is sweet",
            mpVandR: [
                "Our help stan_deth in the Name of the Lord.",
                "Who hath made hea_ven and earth.",
            ],
            mpBenId: "hnj he gave himself",
            ep2HymnId: "o let the heart beat high with bliss",
            ep2VandR: [
                "Bless_ed be the Name of the Lord.",
                "From this time forth for e_ver_more, al_le_lu_ia.",
            ],
            ep2MagId: "hnj thou salt call",
        },
    },
    {
        day: 7,
        month: 1,
        title: "Saint Raymond of Penyafort",
        subtitle: "Priest",
        type: "memorial",
        rank: 12,
        optional: true,
        collect:
            "O God, who didst appoint blessed Raymond excellently to minister the Sacrament of Penance, and didst wondrously make for him a passage upon the waves of the sea: grant, we pray thee; that, at his intercession, we may bring forth fruits worthy of repentance, and be found meet to attain to the harbour of everlasting salvation; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        commons: ["Confessor: Priest"],
    },
    {
        day: 12,
        month: 1,
        title: "Saint Benedict Biscop",
        subtitle: "Abbot",
        type: "memorial",
        rank: 12,
        optional: true,
        collect:
            "O God, by whose gift the blessed Abbot Benedict left all things that he might be made perfect: grant unto all those who have entered upon the path of evangelical perfection; that they may neither look back nor linger, in the way; but hastening to thee without stumbling, may lay hold on life eternal; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        commons: ["Abbot or Abbess"],
    },
    {
        day: 12,
        month: 1,
        title: "Saint Ælred of Rievaulx",
        subtitle: "Abbot",
        regionNote: "Britain",
        type: "memorial",
        rank: 12,
        optional: true,
        collect:
            "Almighty God, who didst endow the blessed Abbot Ælred with the gift of Christian friendship and the wisdom to lead others into the way of holiness: grant to thy people that same spirit of mutual affection; that in loving one another we may know the love of Christ and rejoice in the eternal possession of thine unsurpassable goodness; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        commons: ["Abbot or Abbess"],
    },
    {
        day: 13,
        month: 1,
        title: "Saint Hilary",
        subtitle: "Bishop and Doctor of the Church",
        type: "memorial",
        rank: 12,
        optional: true,
        collect:
            "Almighty, everlasting God, whose servant Hilary steadfastly confessed thy Son our Saviour Jesus Christ to be very God and very Man: grant that we may hold to this faith, and evermore magnify his holy Name; through the same Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        commons: ["Confessor: Bishop", "Doctor of the Church"],
    },
    {
        day: 13,
        month: 1,
        title: "Saint Kentigern (Mungo)",
        subtitle: "Bishop",
        regionNote: "Australia, Britain",
        type: "memorial",
        rank: 12,
        optional: true,
        collect:
            "O God, who through thy Bishop Saint Kentigern made the light of the true faith to shine forth in splendour: grant, we pray; that by faithfully following the teaching of him whose feast we now celebrate, we may obtain the glory of eternal light; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        commons: ["Confessor: Bishop"],
    },
    {
        day: 17,
        month: 1,
        title: "Saint Anthony",
        subtitle: "Abbot",
        type: "memorial",
        rank: 10,
        optional: false,
        collect:
            "Most gracious God, who didst call thy servant Anthony, to sell all that he had and to serve thee in the solitude of the desert: grant that we, through his intercession and following his example, may learn to deny ourselves and to love thee before all things; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        commons: ["Abbot or Abbess"],
    },
    {
        day: 19,
        month: 1,
        title: "Saint Wulfstan",
        subtitle: "Bishop",
        regionNote: "Australia, Britain",
        type: "memorial",
        rank: 12,
        optional: true,
        collect:
            "O God, the light of the faithful and shepherd of souls, who didst call thy Bishop Wulfstan to feed thy sheep by his word, and guide them by his example: grant us, we pray thee, by his intercession, to keep the faith he taught, and to follow in his footsteps; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        commons: ["Confessor: Bishop"],
    },
    {
        day: 20,
        month: 1,
        title: "Saint Fabian",
        subtitle: "Pope and Martyr",
        type: "memorial",
        rank: 12,
        optional: true,
        collect:
            "O God, who dost support and defend us with the glorious witness and prayers of thy blessed Martyr Pope Fabian: grant us so to go forward in his footsteps, and ever to rejoice in fellowship with him; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        commons: ["Martyr: Pope or Bishop", "Confessor: Holy Pope"],
    },
    {
        day: 20,
        month: 1,
        title: "Saint Sebastian",
        subtitle: "Martyr",
        type: "memorial",
        rank: 12,
        optional: true,
        collect:
            "Grant unto us, we pray thee, O Lord, a spirit of fortitude: so that, taught by the glorious example of the Martyr Saint Sebastian, we may learn to obey thee rather than men; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        commons: ["One Martyr"],
    },
    {
        day: 21,
        month: 1,
        title: "Saint Agnes",
        subtitle: "Virgin and Martyr",
        type: "memorial",
        rank: 10,
        optional: false,
        collect:
            "Almighty, everlasting God, who dost choose the weak things of the world to confound those things that are strong: mercifully grant that we, who keep the festival of blessed Agnes thy Martyr, may perceive within ourselves the effect of her prayers; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        commons: ["Virgin Martyr"],
    },
    {
        day: 22,
        month: 1,
        title: "Saint Vincent",
        subtitle: "Deacon and Martyr",
        type: "memorial",
        rank: 12,
        optional: true,
        collect:
            "Almighty God, by whose grace and power thy holy Deacon and Martyr Vincent triumphed over suffering and despised death: grant, we beseech thee, by his intercession; that enduring hardness, and waxing valiant in fight, we may with the noble army of Martyrs receive the crown of everlasting life; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one ever one God, world without end. Amen.",
        commons: ["One Martyr"],
    },
    {
        day: 24,
        month: 1,
        title: "Saint Francis de Sales",
        subtitle: "Bishop and Doctor of the Church",
        type: "memorial",
        rank: 10,
        optional: false,
        collect:
            "God of all holiness, who didst call thy Bishop Francis de Sales to bring many to Christ through his devout life, and to renew thy Church with patience and understanding: grant that through his intercession, we may, by word and example, reflect thy gentleness and love to all those whom we meet; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        commons: ["Doctor of the Church", "Confessor: Bishop"],
        supplements: {
            ep2MagId: "doctor francis de sales",
        },
    },
    {
        day: 25,
        month: 1,
        title: "The Conversion of Saint Paul the Apostle",
        subtitle: "",
        type: "feast",
        feastHasFirstEvensong: true,
        rank: 7,
        optional: false,
        collect:
            "O God, who, through the preaching of the blessed Apostle Paul, hast caused the light of the Gospel to shine throughout the world: grant, we beseech thee; that we, having his wonderful conversion in remembrance, may show forth our thankfulness unto thee for the same, by following the holy doctrine which he taught; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        supplements: {
            ep1HymnId:
                "lord of creation bow thine ear o christ to hear st paul",
            ep1VandR: [
                "Thou art a cho_sen ves_sel, ho_ly A_po_stle Paul.",
                "A prea_cher of the truth through_out the world.",
            ],
            ep1MagId: "saint paul go forth",
            invId: "saint paul inv",
            mpHymnId: "let the round world with songs rejoice",
            mpVandR: [
                "Thou art a cho_sen ves_sel, ho_ly A_po_stle Paul.",
                "A prea_cher of the truth through_out the world.",
            ],
            mpBenId: "saint paul ye which have",
            ep2HymnId: "let the round world with songs rejoice",
            ep2VandR: [
                "Thou art a cho_sen ves_sel, ho_ly A_po_stle Paul.",
                "A prea_cher of the truth through_out the world.",
            ],
            ep2MagId: "saint paul o holy apostle",
        },
    },
    {
        day: 26,
        month: 1,
        title: "Saints Timothy and Titus",
        subtitle: "Bishops",
        regionNote: "Britain",
        type: "memorial",
        rank: 10,
        optional: false,
        collect:
            "Heavenly Father, who didst send thine Apostle Paul to preach the Gospel, and gavest him Timothy and Titus to be his companions in the Faith: grant that, through their prayers, our fellowship in the Holy Spirit may bear witness to the Name of Jesus; who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        commons: ["Confessor: Bishop"],
    },
    {
        day: 27,
        month: 1,
        title: "Saint Angela Merici",
        subtitle: "Virgin",
        type: "memorial",
        rank: 12,
        optional: true,
        collect:
            "O God, who through thy blessed Saint Angela didst cause a new household of Virgins consecrated to thy service to be established in thy Church: grant us, we pray thee, by her intercession, so to live after the manner of the holy Angels; that, putting g aside all things earthly, we may be found worthy, to rejoice in everlasting felicity; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, world without end. Amen.",
        commons: ["Virgin", "Educator"],
    },
    {
        day: 28,
        month: 1,
        title: "Saint Thomas Aquinas",
        subtitle: "Priest and Doctor of the Church",
        type: "memorial",
        rank: 10,
        optional: false,
        collect:
            "Everlasting God, who didst enrich thy Church with the learning and holiness of thy servant Saint Thomas Aquinas: grant to all who seek thee a humble mind and a pure heart; that they may know thy Son Jesus Christ to be the way, the truth and the life; who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        commons: ["Doctor of the Church", "Confessor: Priest"],
        supplements: {
            ep2MagId: "doctor thomas aquinas",
        },
    },
    {
        day: 31,
        month: 1,
        title: "Saint John Bosco",
        subtitle: "Priest",
        type: "memorial",
        rank: 10,
        optional: false,
        collect:
            "O God, who didst raise up Saint John Bosco thy Confessor to be a father and teacher of the young, and through him, with the aid of the Virgin Mary, didst will that new families should flourish in thy Church: grant, we beseech thee, that being kindled by the same fire of charity, we may have the strength to seek for souls, and to serve thee alone; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        commons: ["Confessor: Priest", "Educator"],
    },
    {
        day: 1,
        month: 2,
        title: "Saint Brigid of Kildare",
        subtitle: "Abbess",
        type: "memorial",
        rank: 10,
        optional: false,
        collect:
            "Grant, we beseech thee, O Lord, that the prayers of thy holy Abbess, blessed Brigid, may commend us unto thee: that we, who have no power of ourselves to help ourselves, may by her advocacy find favour in thy sight; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        alternativeCollects: [
            "O God, who to the blessed Abbess Brigid gavest grace to imitate Christ in his poverty, and with humble heart to follow him to the end: grant that all who enter the path of Gospel perfection may neither look back nor go astray from the way; but hastening to thee without stumbling, may attain the crown of eternal life whereunto thou dost call them; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        ],
        commons: ["Abbot or Abbess"],
    },
    {
        day: 3,
        month: 2,
        title: "Saint Blaise",
        subtitle: "Bishop and Martyr",
        type: "memorial",
        rank: 12,
        optional: true,
        collect:
            "O God, who makest us glad with the yearly festival of blessed Blaise, thy Martyr and Bishop: mercifully grant that, as we now observe his heavenly birthday; so we may likewise rejoice in his protection; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        commons: ["Martyr: Pope or Bishop"],
    },
    {
        day: 3,
        month: 2,
        title: "Saint Ansgar",
        subtitle: "Bishop",
        type: "memorial",
        rank: 12,
        optional: true,
        collect:
            "Almighty and gracious God, who didst send thy servant Ansgar to spread the Gospel among the Nordic peoples: through his intercession, we beseech thee to raise up, in this our generation, messengers of thy good tidings and heralds of thy kingdom; that the world may come to know the immeasurable riches of our Saviour Jesus Christ; who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        commons: ["Confessor: Bishop", "Confessor: Missionary"],
    },
    {
        day: 4,
        month: 2,
        title: "Saint Gilbert of Sempringham",
        subtitle: "Religious",
        type: "memorial",
        rank: 12,
        optional: true,
        collect:
            "Almighty God, our heavenly Father: we remember before thee all thy servants who have served thee faithfully in their generation, and have entered into rest, especially Gilbert of Sempringham, beseeching thee to give us grace so to follow in their steps; that with them we may be partakers of thy heavenly kingdom; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        commons: ["Religious"],
    },
    {
        day: 5,
        month: 2,
        title: "Saint Agatha",
        subtitle: "Virgin and Martyr",
        type: "memorial",
        rank: 10,
        optional: false,
        collect:
            "O God, who among the manifold works of thine almighty power hast bestowed even upon the gentleness of women strength to win the victory of martyrdom: grant, we beseech thee; that we, who on this day recall the heavenly birth of Saint Agatha, thy Virgin and Martyr, may so follow in her footsteps, that we may likewise attain unto thee; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        commons: ["Virgin Martyr"],
    },
    {
        day: 6,
        month: 2,
        title: "Saint Paul Miki and Companions",
        subtitle: "Martyrs",
        type: "memorial",
        rank: 10,
        optional: false,
        collect:
            "O God, the strength of all the Saints, who through the Cross wast pleased to call the Martyrs Saint Paul Miki and Companions to everlasting life: grant, we pray; that by their intercession we may hold with courage to the faith that we profess, even unto death; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        commons: ["Several Martyrs"],
    },
    {
        day: 8,
        month: 2,
        title: "Saint Jerome Emiliani",
        subtitle: "Priest",
        type: "memorial",
        rank: 12,
        optional: true,
        collect:
            "O God, the Father of mercies, who didst raise up Saint Jerome Emiliani to be a defender and father of the fatherless: vouchsafe, through his merits and intercession; that we may faithfully guard thy spirit of adoption, whereby we are called and are indeed thy children; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        commons: ["Educator"],
    },
    {
        day: 8,
        month: 2,
        title: "Saint Josephine Bakhita",
        subtitle: "Virgin",
        type: "memorial",
        rank: 12,
        optional: true,
        collect:
            "O God, who didst lead Saint Josephine Bakhita from abject slavery to the dignity of being thy daughter and a bride of Christ: grant, we pray; that by her example we may show constant love for the Lord Jesus crucified, remaining steadfast in charity and prompt to show compassion; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        commons: ["Virgin"],
    },
    {
        day: 9,
        month: 2,
        title: "Saint Teilo",
        subtitle: "Bishop",
        regionNote: "Wales",
        type: "memorial",
        rank: 12,
        optional: true,
        collect:
            "O God, by whose grace the Bishop Saint Teilo taught thy Church with simplicity and sustained her in adversity by his prayers: grant her, we pray thee, in our own day, through his intercession and example, guidance and consolation; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        commons: ["Confessor: Bishop"],
    },
    {
        day: 10,
        month: 2,
        title: "Saint Scholastica",
        subtitle: "Virgin",
        type: "memorial",
        rank: 10,
        optional: false,
        collect:
            "O God, who for a testimony to the path of innocency didst cause the soul of blessed Scholastica, thy Virgin, to enter heaven in the appearance of a dove: grant unto us; that by her merits and intercession, we may walk in such innocency of life; that we may be worthy to attain to everlasting felicity; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        commons: ["Virgin", "Abbot or Abbess"],
    },
    {
        day: 11,
        month: 2,
        title: "Our Lady of Lourdes",
        subtitle: "",
        type: "memorial",
        rank: 12,
        optional: true,
        collect:
            "O God, who by the Immaculate Conception of the Blessed Virgin Mary didst consecrate a dwelling-place meet for thy Son: we humbly pray thee; that we, celebrating the apparition of the same Blessed Virgin, may obtain thy healing, both in body and soul; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        commons: ["Blessed Virgin Mary"],
    },
    {
        day: 14,
        month: 2,
        title: "Saints Cyril, Monk, and Methodius, Bishop",
        shortTitle: "Saints Cyril and Methodius",
        patronNote: "Patrons of Europe",
        type: "memorial",
        rank: 10,
        optional: false,
        collect:
            "Almighty and everlasting God, we thank thee for thy servants Cyril and Methodius, whom thou didst call to preach the Gospel to the Slavic peoples: raise up, we pray thee, in this and every land, heralds and evangelists of thy kingdom; that thy Church may make known the unsearchable riches of Christ, and may increase with the increase of God; through the same Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        commons: ["Confessor: Missionary"],
    },
    {
        day: 14,
        month: 2,
        title: "Saints Cyril, Monk, and Methodius, Bishop",
        shortTitle: "Saints Cyril and Methodius",
        patronNote: "Patrons of Europe",
        type: "feast",
        regionNote: "Britain",
        rank: 7,
        optional: false,
        collect:
            "Almighty and everlasting God, we thank thee for thy servants Cyril and Methodius, whom thou didst call to preach the Gospel to the Slavic peoples: raise up, we pray thee, in this and every land, heralds Sand evangelists of thy kingdom; that thy Church may make known the unsearchable riches of Christ, and may increase with the increase of God; through the same Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        commons: ["Confessor: Missionary"],
    },
    {
        day: 17,
        month: 2,
        title: "The Seven Holy Founders of the Servite Order",
        subtitle: "",
        type: "memorial",
        rank: 12,
        optional: true,
        collect:
            "O Lord Jesus Christ, who for the remembrance of the sorrows of thy most holy Mother didst by the seven blessed Fathers enrich thy Church with a new household of her servants: mercifully grant that we may in such wise be joined to them in their sorrowing; that we may be made worthy to be partakers of their gladness; who livest and reignest with the Father, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        commons: ["Religious"],
    },
    {
        day: 21,
        month: 2,
        title: "Saint Peter Damian",
        subtitle: "Bishop and Doctor of the Church",
        type: "memorial",
        rank: 12,
        optional: true,
        collect:
            "Grant, we beseech thee, Almighty God: that we may so follow the teaching and examples of thy blessed Confessor Saint Peter Damian; that learning of him to despise all things earthly, we may attain in the end to everlasting felicity; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        commons: ["Doctor of the Church", "Confessor: Bishop"],
        supplements: {
            ep2MagId: "doctor peter damian",
        },
    },
    {
        day: 23,
        month: 2,
        title: "Saint Polycarp",
        subtitle: "Bishop and Martyr",
        type: "memorial",
        rank: 10,
        optional: false,
        collect:
            "Fulfil, O Lord, the petitions of thy servants who on this day devoutly reverence the passion of blessed Polycarp, thy Martyr and Bishop: and accept us, together with him, as a whole burnt-offering dedicated unto thee; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        commons: ["Martyr: Bishop"],
    },
    {
        day: 27,
        month: 2,
        title: "Saint Gregory of Narek",
        subtitle: "Abbot and Doctor of the Church",
        type: "memorial",
        rank: 12,
        optional: true,
        collect:
            "Grant, we beseech thee, O Lord, that the prayers of thy holy Abbot, blessed Gregory of Narek, may commend us unto thee: that we, who have no power of ourselves to help ourselves, may by his advocacy find favour in thy sight; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        alternativeCollects: [
            "O God, who to the blessed Abbot Gregory of Narek gavest grace to imitate Christ in his poverty, and with humble heart to follow him to the end: grant that all who enter the path of Gospel perfection may neither look back nor go astray from the way; but hastening to thee without stumbling, may attain the crown of eternal life where unto thou dost call them; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
            "O God, by whose providence blessed Gregory of Narek was sent to guide thy people in the way of everlasting salvation: grant, we beseech thee; as we on earth have learned of him the doctrine of life, so we may be found worthy to have him for our advocate in heaven; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        ],
        commons: ["Doctor of the Church", "Abbot or Abbess"],
        supplements: {
            ep2MagId: "doctor gregory",
        },
    },
    {
        day: 1,
        month: 3,
        title: "Saint David",
        subtitle: "Bishop",
        patronNote: "Patron of Wales",
        type: "memorial",
        rank: 12,
        optional: true,
        collect:
            "O Almighty God, who in thy providence didst choose thy servant David to be an apostle to the people of Wales, to bring those who were wandering in darkness and error to the true light and knowledge of thee: grant us, by his intercession, so to walk in that light; that we may come at last to the light of everlasting life through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        commons: ["Confessor: Bishop"],
    },
    {
        day: 1,
        month: 3,
        title: "Saint David",
        subtitle: "Bishop",
        patronNote: "Patron of Wales",
        regionNote: "Britain",
        type: "feast",
        feastHasFirstEvensong: true,
        rank: 7,
        optional: false,
        collect:
            "O Almighty God, who in thy providence didst choose thy servant David to be an apostle to the people of Wales, to bring those who were wandering in darkness and error to the true light and knowledge of thee: grant us, by his intercession, so to walk in that light; that we may come at last to the light of everlasting life through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        commons: ["Confessor: Bishop"],
    },
    {
        day: 1,
        month: 3,
        title: "Saint David",
        subtitle: "Bishop",
        patronNote: "Patron of Wales",
        regionNote: "Wales",
        type: "solemnity",
        rank: 3,
        optional: false,
        collect:
            "O Almighty God, who in thy providence didst choose thy servant David to be an apostle to the people of Wales, to bring those who were wandering in darkness and error to the true light and knowledge of thee: grant us, by his intercession, so to walk in that light; that we may come at last to the light of everlasting life through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        commons: ["Confessor: Bishop"],
        supplements: {
            ep1ProperPsalms: ["110", "112", "113", "132"],
            mpProperPsalms: ["1", "2", "8"],
            ep2ProperPsalms: ["110", "112", "113", "132"],
            properPsalmsOptional: true,
        },
    },
    {
        day: 4,
        month: 3,
        title: "Saint Casimir",
        subtitle: "",
        type: "memorial",
        rank: 12,
        optional: true,
        collect:
            "O God, who, amidst the pleasures of a temporal kingdom, didst endue thy blessed Saint Casimir with constancy to resist all temptations: grant, we beseech thee; that by his intercession; thy faithful people may learn to despise all things earthly, and to seek earnestly after all things heavenly; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        commons: ["Any Holy Man"],
    },
    {
        day: 5,
        month: 3,
        title: "Saint Piran",
        subtitle: "Abbot",
        type: "memorial",
        rank: 12,
        optional: true,
        collect:
            "Grant, we beseech thee, O Lord, that the prayers of thy holy Abbot, blessed Piran, may commend us unto thee: that we, who have no power of ourselves to help ourselves, may by his by his advocacy find favour in thy Son our Lord, who liveth and reigneth with thee, in the u ever of the Holy Spirit, ever one God, world without end. Amen.",
        alternativeCollects: [
            "O God, who to the blessed Abbot Piran gavest grace to imitate Christ in his poverty, and with humble heart to follow him to the end: grant that all who enter the path of Gospel perfection may neither look back nor go astray from the way; but hastening to thee without stumbling, may attain the crown of eternal life whereunto thou dost call them; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        ],
        commons: ["Abbot or Abbess"],
    },
    {
        day: 7,
        month: 3,
        title: "Saints Perpetua and Felicitas",
        subtitle: "Martyrs",
        type: "memorial",
        rank: 10,
        optional: false,
        collect:
            "O holy God, who gavest great courage to Saints Perpetua, Felicitas and their Companions: grant that, through their prayers, we may be worthy to climb the ladder of sacrifice, and be received into the garden of peace; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        commons: ["Several Martyrs"],
    },
    {
        day: 8,
        month: 3,
        title: "Saint John of God",
        subtitle: "Religious",
        type: "memorial",
        rank: 12,
        optional: true,
        collect:
            "O God, who didst cause blessed John, by the fire of thy love, to pass unhurt amid the flames, and through him didst enrich thy Church with a new offspring: grant, by the pleading of his merits; that our vices be may healed by the fire of thy charity, and that we may obtain thine eternal healing; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one. God, world without end. Amen.",
        commons: ["Religious"],
    },
    {
        day: 9,
        month: 3,
        title: "Saint Frances of Rome",
        subtitle: "Religious",
        type: "memorial",
        rank: 12,
        optional: true,
        collect:
            "O God, who amongst other gifts of thy grace, didst honour blessed Frances, thy handmaid, with the familiar converse of an Angel: grant, we beseech thee; that by the help of her intercession, we may be worthy to attain unto the fellowship of the Angels in thy heavenly kingdom; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        commons: ["Any Matron or Holy Woman", "Abbot or Abbess"],
        supplements: {
            invId: "frances of rome inv",
        },
    },
    {
        day: 17,
        month: 3,
        title: "Saint Patrick",
        subtitle: "Bishop",
        patronNote: "Patron of Ireland",
        type: "memorial",
        rank: 12,
        optional: true,
        collect:
            "O Almighty God, who in thy providence didst choose thy servant Patrick k to be an be an apostle to the people of Ireland, to bring those who were wandering in darkness and error to the true light and knowledge of thee: grant us, by his intercession, so to walk in that light; that we may come at last to the light of everlasting life; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        commons: ["Confessor: Bishop"],
    },
    {
        day: 17,
        month: 3,
        title: "Saint Patrick",
        subtitle: "Bishop",
        patronNote: "Patron of Ireland",
        regionNote: "Britain",
        type: "feast",
        rank: 7,
        optional: false,
        collect:
            "O Almighty God, who in thy providence didst choose thy servant Patrick k to be an be an apostle to the people of Ireland, to bring those who were wandering in darkness and error to the true light and knowledge of thee: grant us, by his intercession, so to walk in that light; that we may come at last to the light of everlasting life; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        commons: ["Confessor: Bishop"],
    },
    {
        day: 17,
        month: 3,
        title: "Saint Patrick",
        subtitle: "Bishop",
        patronNote: "Patron of Ireland",
        regionNote: "Australia",
        type: "solemnity",
        rank: 3,
        optional: false,
        collect:
            "O Almighty God, who in thy providence didst choose thy servant Patrick k to be an be an apostle to the people of Ireland, to bring those who were wandering in darkness and error to the true light and knowledge of thee: grant us, by his intercession, so to walk in that light; that we may come at last to the light of everlasting life; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        commons: ["Confessor: Bishop"],
        supplements: {
            ep1ProperPsalms: ["110", "112", "113", "132"],
            mpProperPsalms: ["1", "2", "8"],
            ep2ProperPsalms: ["110", "112", "113", "132"],
            properPsalmsOptional: true,
        },
    },
    {
        day: 18,
        month: 3,
        title: "Saint Cyril of Jerusalem",
        subtitle: "Bishop and Doctor of the Church",
        type: "memorial",
        rank: 12,
        optional: true,
        collect:
            "Grant, we beseech thee, Almighty God: that at the intercession of thy blessed Bishop Saint Cyril, we may learn to know thee, the only true God, and Jesus Christ, whom thou hast sent; that we may be found worthy to be numbered for ever among the sheep that hear his voice; through the same Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",

        commons: ["Doctor of the Church", "Confessor: Bishop"],
        supplements: {
            ep2MagId: "doctor cyril",
        },
    },
    {
        day: 19,
        month: 3,
        title: "Saint Joseph",
        subtitle: "Spouse of the Blessed Virgin Mary",
        type: "solemnity",
        rank: 3,
        optional: false,
        collect:
            "O God, who from the house of thy servant David didst raise up Saint Joseph to be the guardian of thine incarnate Son, and spouse of his Virgin Mother: give us grace to imitate his uprightness of life and his obedience to thy commands; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        supplements: {
            ep1HymnId: "let angels chant thy praise",
            ep1VandR: [
                "He made him lord al_so of his house.",
                "And ru_ler of all his sub_stance.",
            ],
            ep1MagId: "joseph then joseph being raised",
            invId: "joseph inv",
            mpHymnId: "joseph thou splendour of the heavenly mansions",
            mpVandR: [
                "The mouth of the right_eous is ex_er_cised in wis_dom.",
                "And his tongue will be talk_ing of judge_ment.",
            ],
            mpBenId: "joseph jesus himself",
            ep2HymnId: "let angels chant thy praise",
            ep2VandR: [
                "Rich_es and plen_teous_ness shall be in his house.",
                "And his righ_teous_ness re_main_eth for e_ver.",
            ],
            ep2MagId: "joseph behold a faithful",
        },
    },
    {
        day: 23,
        month: 3,
        title: "Saint Turibius de Mogrovejo",
        subtitle: "Bishop",
        type: "memorial",
        rank: 12,
        optional: true,
        collect:
            "O God, who gavest increase to thy Church through the apostolic labours and zeal for truth of the Bishop Saint Turibius: grant that the people consecrated to thee may always receive new growth in faith and holiness; through Jesus Christ thy Son our Lord, who liveth and reigneth thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        commons: ["Confessor: Bishop"],
    },
    {
        day: 25,
        month: 3,
        title: "The Annunciation of the Lord",
        subtitle: "",
        type: "solemnity",
        rank: 3,
        optional: false,
        commonlyCalled: "Lady Day",
        collect:
            "We beseech thee, O Lord, pour thy grace into our hearts: that, as we have known the Incarnation of thy Son Jesus Christ by the message of an Angel; so by his Cross and Passion we may be brought unto the glory of his Resurrection; through the same Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        commons: ["Blessed Virgin Mary"],
        supplements: {
            ep1VandR: [
                "Hail, Ma_ry, full of grace.",
                "Be it un_to me ac_cord_ing to thy word.",
            ],
            ep1MagId: "annunciation the holy ghost",
            invId: "annunciation inv",
            mpVandR: [
                "Hail, Ma_ry, full of grace.",
                "Be it un_to me ac_cord_ing to thy word.",
            ],
            mpBenId: "annunciation how shall this be",
            ep2VandR: [
                "Hail, Ma_ry, full of grace.",
                "Be it un_to me ac_cord_ing to thy word.",
            ],
            ep2MagId: "annunciation the angel gabriel",
        },
    },
    {
        day: 2,
        month: 4,
        title: "Saint Francis of Paola",
        subtitle: "Hermit",
        type: "memorial",
        rank: 12,
        optional: true,
        collect:
            "O God, who, by exalting the humble, didst raise the blessed Confessor Francis to the glory of thy Saints: grant, we beseech thee; that, through his merits and example, we may attain with gladness unto the rewards promised to them that are lowly of heart; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        commons: ["Religious"],
    },
    {
        day: 4,
        month: 4,
        title: "Saint Isidore",
        subtitle: "Bishop and Doctor of the Church",
        type: "memorial",
        rank: 12,
        optional: true,
        collect:
            "O God, by whose providence blessed Isidore was sent to guide thy people in the way of everlasting salvation: grant, we beseech thee; that as we have learned of him the doctrine of life on earth, so we may be found worthy to have him for our advocate in heaven; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        commons: ["Doctor of the Church", "Confessor: Bishop"],
        supplements: {
            ep2MagId: "doctor isidore",
        },
    },
    {
        day: 5,
        month: 4,
        title: "Saint Vincent Ferrer",
        subtitle: "Priest",
        type: "memorial",
        rank: 12,
        optional: true,
        collect:
            "O God, who didst vouchsafe to illumine thy Church by the merits and preaching of blessed Vincent thy Confessor: grant to us thy servants; that we may both be instructed by his example, and by his advocacy be delivered from all adversities; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        commons: ["Confessor: Missionary"],
    },
    {
        day: 7,
        month: 4,
        title: "Saint John Baptist de la Salle",
        subtitle: "Priest",
        type: "memorial",
        rank: 10,
        optional: false,
        collect:
            "O God, who for the Christian education of the poor, and for the confirmation of the young in the way of truth, didst raise up the holy Confessor John Baptist de la Salle, and through him didst gather a new family in the Church: graciously grant that by his intercession and example we, being kindled with zeal for thy glory in the salvation of souls, may be enabled to be made partakers of his crown in heaven; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        commons: ["Confessor: Priest", "Educator"],
    },
    {
        day: 11,
        month: 4,
        title: "Saint Stanislaus",
        subtitle: "Bishop and Martyr",
        type: "memorial",
        rank: 10,
        optional: false,
        collect:
            "O God, for whose honour the glorious Bishop Stanislaus was slain by the swords of wicked men: grant, we beseech thee; that all who call upon him for succour, may obtain the saving effect of their petition; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        commons: ["Martyr: Pope or Bishop"],
    },
    {
        day: 13,
        month: 4,
        title: "Saint Martin I",
        subtitle: "Pope and Martyr",
        type: "memorial",
        rank: 12,
        optional: true,
        collect:
            "Everlasting Shepherd, favourably look upon thy flock: and keep it with perpetual protection through the prayers of blessed Martin, thy Martyr and Supreme Pontiff, whom thou didst appoint to be shepherd of the whole Church; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        commons: ["Martyr: Pope or Bishop", "Confessor: Holy Pope"],
    },
    {
        day: 16,
        month: 4,
        title: "Saint Magnus of Orkney",
        subtitle: "Martyr",
        type: "memorial",
        rank: 12,
        optional: true,
        collect:
            "Protect us, O Lord, by the glorious merits of Saint Magnus thy Martyr: and may we, proclaiming the works of thy majesty therein, likewise obtain thy succour both in this world, and in that which is to come; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        commons: ["One Martyr"],
    },
    {
        day: 19,
        month: 4,
        title: "Saint Alphege",
        subtitle: "Bishop and Martyr",
        type: "memorial",
        rank: 12,
        optional: true,
        collect:
            "O God, who didst adorn blessed Alphege, most devout Confessor of thy Name, with the dignity of the priesthood and the palm of martyrdom: mercifully grant that we may so be aided by his intercession before thee; that we that we may be enabled to rejoice with him in eternal felicity; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        commons: ["Martyr: Pope or Bishop"],
    },
    {
        day: 20,
        month: 4,
        title: "Saint Beuno",
        subtitle: "Abbot",
        regionNote: "Wales",
        type: "memorial",
        rank: 12,
        optional: true,
        collect:
            "Grant, O Lord, we pray thee: that by the intercession of Saint Beuno, thy Church may increase both in wisdom and fidelity to thee; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        commons: ["Abbot or Abbess"],
    },
    {
        day: 21,
        month: 4,
        title: "Saint Anselm of Canterbury",
        subtitle: "Bishop and Doctor of the Church",
        type: "memorial",
        rank: 12,
        optional: true,
        collect:
            "O everlasting God, who gavest to thy Bishop Anselm singular gifts as a pastor and teacher: grant that we, like him, may desire thee with our whole heart; and, so desiring, may seek thee and, seeking, may find thee; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        commons: ["Doctor of the Church", "Confessor: Bishop"],
    },
    {
        day: 21,
        month: 4,
        title: "Saint Anselm of Canterbury",
        subtitle: "Bishop and Doctor of the Church",
        regionNote: "Australia",
        type: "memorial",
        rank: 10,
        optional: false,
        collect:
            "O everlasting God, who gavest to thy Bishop Anselm singular gifts as a pastor and teacher: grant that we, like him, may desire thee with our whole heart; and, so desiring, may seek thee and, seeking, may find thee; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        commons: ["Doctor of the Church", "Confessor: Bishop"],
    },
    {
        day: 23,
        month: 4,
        title: "Saint George",
        subtitle: "Martyr",
        type: "memorial",
        rank: 10,
        optional: false,
        collect:
            "O God of hosts, who didst so kindle the flame of love in the heart of thy servant George that he bore witness to the risen Lord by his life and by his death: grant us the same power of faith and love; that we, who rejoice in his triumphs, may come to share with him the fulness of the Resurrection; through the same Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        commons: ["One Martyr"],
        supplements: {
            ep1VandR: [
                "With thy lo_ving kind_ness.",
                "Thou hast de_fen_ded him as with a shield.",
            ],
            ep1MagId: "st george the saints through faith",
            mpVandR: [
                "With thy lo_ving kind_ness.",
                "Thou hast de_fen_ded him as with a shield.",
            ],
            mpBenId: "st george the saints through faith",
            ep2VandR: [
                "With thy lo_ving kind_ness.",
                "Thou hast de_fen_ded him as with a shield.",
            ],
            ep2MagId: "st george the saints through faith",
        },
    },
    {
        day: 23,
        month: 4,
        title: "Saint George",
        subtitle: "Martyr",
        patronNote: "Patron of England",
        regionNote: "England",
        type: "solemnity",
        rank: 3,
        optional: false,
        collect:
            "O God of hosts, who didst so kindle the flame of love in the heart of thy servant George that he bore witness to the risen Lord by his life and by his death: grant us the same power of faith and love; that we, who rejoice in his triumphs, may come to share with him the fulness of the Resurrection; through the same Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        commons: ["One Martyr"],
        supplements: {
            ep1VandR: [
                "With thy lo_ving kind_ness.",
                "Thou hast de_fen_ded him as with a shield.",
            ],
            ep1MagId: "st george the saints through faith",
            mpVandR: [
                "With thy lo_ving kind_ness.",
                "Thou hast de_fen_ded him as with a shield.",
            ],
            mpBenId: "st george the saints through faith",
            ep2VandR: [
                "With thy lo_ving kind_ness.",
                "Thou hast de_fen_ded him as with a shield.",
            ],
            ep2MagId: "st george the saints through faith",
        },
        properPsalmsOptional: true,
    },
    {
        day: 24,
        month: 4,
        title: "Saint Fidelis of Sigmaringen",
        subtitle: "Priest and Martyr",
        type: "memorial",
        rank: 12,
        optional: true,
        collect:
            "O God, who didst enkindle blessed Fidelis with seraphic ardour of spirit in the propagation of the true Faith, and didst vouchsafe to adorn him with the palm of martyrdom, and with glorious miracles: we beseech thee; that, by his merits and intercession, thou wouldest so confirm us through thy grace in faith and charity; that in thy service we may be worthy to be found faithful, even unto death; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        commons: ["One Martyr", "Confessor: Priest"],
    },
    {
        day: 24,
        month: 4,
        title: "Saint Adalbert",
        subtitle: "Bishop and Martyr",
        type: "memorial",
        rank: 12,
        optional: true,
        collect:
            "O God, who didst bestow the crown of martyrdom on the Bishop Saint Adalbert, as he burned with zeal for souls: grant, we pray, by his prayers; that the obedience of the flock may never fail the shepherds, nor the care of the shepherds be ever lacking to the flock; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        commons: ["One Martyr"],
    },
    {
        day: 24,
        month: 4,
        title: "Saint Mellitus",
        subtitle: "Bishop",
        regionNote: "Britain",
        type: "memorial",
        rank: 12,
        optional: true,
        collect:
            "We beseech thee, O Lord, graciously hear the prayers we offer unto thee on the festival of blessed Mellitus, thy Confessor and Bishop: that, as he was found worthy to do thee fitting service; so by his interceding merits we may be absolved from all our sins; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        commons: ["Confessor: Bishop"],
    },
    {
        day: 25,
        month: 4,
        title: "Saint Mark",
        subtitle: "Evangelist",
        type: "feast",
        feastHasFirstEvensong: true,
        rank: 7,
        optional: false,
        collect:
            "O Almighty God, who hast instructed thy holy Church with the heavenly doctrine of thy Evangelist Saint Mark: give us grace; that, being not like children carried away with every blast of vain doctrine, we may be established in the truth of thy holy Gospel; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        commons: ["Apostles"],
    },
    {
        day: 25,
        month: 4,
        title: "ANZAC Day",
        regionNote: "Australia",
        type: "misc",
        rank: 7,
        optional: false,
        collect:
            "O Lord our God, whose Name only is excellent and thy praise above heaven and earth: we thank thee for all those who counted not their lives dear unto themselves but laid them down for their friends; grant us, we beseech thee, that having them always in remembrance we may imitate their faithfulness and sacrifice; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        propersOfTheFeria: true,
    },
    {
        day: 26,
        month: 4,
        title: "Saint Mark",
        subtitle: "Evangelist",
        regionNote: "Australia",
        type: "feast",
        feastHasFirstEvensong: true,
        rank: 7,
        optional: false,
        collect:
            "O Almighty God, who hast instructed thy holy Church with the heavenly doctrine of thy Evangelist Saint Mark: give us grace; that, being not like children carried away with every blast of vain doctrine, we may be established in the truth of thy holy Gospel; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        commons: ["Apostles"],
    },
    {
        day: 27,
        month: 4,
        title: "Saint Louis Grignion de Montfort",
        regionNote: "Australia",
        subtitle: "Priest",
        type: "memorial",
        rank: 12,
        optional: true,
        collect:
            "O God, who didst will to direct the steps of thy Priest Saint Louis de Montfort along the way of salvation and of the love of Christ, in the company of the Blessed Virgin: grant us, by his example; that, meditating on the mysteries of thy love, we may strive tirelessly for the building up of thy Church; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        commons: ["Confessor: Priest"],
    },
    {
        day: 28,
        month: 4,
        title: "Saint Peter Chanel",
        subtitle: "Priest and Martyr",
        type: "memorial",
        rank: 12,
        optional: true,
        collect:
            "O God, who for the spreading of thy Church didst crown Saint Peter Chanel with martyrdom: grant that, in these day days of Paschal joy, we may so celebrate the mysteries of Christ's Death and Resurrection as to bear worthy witness to newness of life; through the same Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        commons: ["One Martyr", "Confessor: Missionary"],
    },
    {
        day: 28,
        month: 4,
        title: "Saint Peter Chanel",
        subtitle: "Priest and Martyr",
        regionNote: "Australia",
        type: "memorial",
        rank: 10,
        optional: false,
        collect:
            "O God, who for the spreading of thy Church didst crown Saint Peter Chanel with martyrdom: grant that, in these day days of Paschal joy, we may so celebrate the mysteries of Christ's Death and Resurrection as to bear worthy witness to newness of life; through the same Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        commons: ["One Martyr", "Confessor: Missionary"],
    },
    {
        day: 28,
        month: 4,
        title: "Saint Louis Grignion de Montfort",
        subtitle: "Priest",
        type: "memorial",
        rank: 12,
        optional: true,
        collect:
            "O God, who didst will to direct the steps of thy Priest Saint Louis de Montfort along the way of salvation and of the love of Christ, in the company of the Blessed Virgin: grant us, by his example; that, meditating on the mysteries of thy love, we may strive tirelessly for the building up of thy Church; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        commons: ["Confessor: Priest"],
    },
    {
        day: 29,
        month: 4,
        title: "Saint Catherine of Siena",
        subtitle: "Virgin and Doctor of the Church",
        patronNote: "Patron of Europe",
        type: "memorial",
        rank: 10,
        optional: false,
        collect:
            "O God, who gavest to thy servant Saint Catherine of Siena a wondrous love of the Passion of Christ: grant that, through her prayers; we thy people may be united to him in his majesty and rejoice for ever in the revelation of his glory; who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        commons: ["Doctor of the Church", "Virgin"],
        supplements: {
            ep2MagId: "doctor catherine",
        },
    },
    {
        day: 29,
        month: 4,
        title: "Saint Catherine of Siena",
        subtitle: "Virgin and Doctor of the Church",
        patronNote: "Patron of Europe",
        regionNote: "Britain",
        type: "feast",
        rank: 7,
        optional: false,
        collect:
            "O God, who gavest to thy servant Saint Catherine of Siena a wondrous love of the Passion of Christ: grant that, through her prayers; we thy people may be united to him in his majesty and rejoice for ever in the revelation of his glory; who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        commons: ["Doctor of the Church", "Virgin"],
        supplements: {
            ep2MagId: "doctor catherine",
        },
    },
    {
        day: 30,
        month: 4,
        title: "Saint Pius V",
        subtitle: "Pope",
        type: "memorial",
        rank: 12,
        optional: true,
        collect:
            "O God, who, for the overthrow of the enemies of thy Church and for the restoring of divine worship, wast pleased to choose blessed Pius to be Supreme Pontiff: grant that we may be defended by his protection, and so cleave to thy service; that overcoming the devices of all our enemies, we may rejoice in perpetual peace; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        commons: ["Confessor: Holy Pope"],
    },
    {
        day: 1,
        month: 5,
        title: "Saint Joseph the Worker",
        subtitle: "",
        type: "memorial",
        rank: 12,
        optional: true,
        collect:
            "O God, the Creator of all things, who hast appointed for mankind the law of labour: graciously grant that through the example and patronage of Saint Joseph we may accomplish the work that thou hast commanded, and attain unto the rewards that thou dost promise; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        supplements: {
            // No EP1 when this is a memorial
            ep1HymnId: "let angels chant thy praise",
            ep1VandR: [
                "He made him lord al_so of his house.",
                "And ru_ler of all his sub_stance.",
            ],
            ep1MagId: "joseph then joseph being raised",
            invId: "joseph inv",
            mpHymnId: "joseph thou splendour of the heavenly mansions",
            mpVandR: [
                "The mouth of the right_eous is ex_er_cised in wis_dom.",
                "And his tongue will be talk_ing of judge_ment.",
            ],
            mpBenId: "joseph jesus himself",
            ep2HymnId: "let angels chant thy praise",
            ep2VandR: [
                "Rich_es and plen_teous_ness shall be in his house.",
                "And his righ_teous_ness re_main_eth for e_ver.",
            ],
            ep2MagId: "joseph behold a faithful",
        },
    },
    {
        day: 2,
        month: 5,
        title: "Saint Athanasius",
        subtitle: "Bishop and Doctor of the Church",
        type: "memorial",
        rank: 10,
        optional: false,
        collect:
            "Everliving God, whose servant Saint Athanasius bore witness to the mystery of the Word made flesh for our salvation: give us grace, with thy Saints, to contend for the truth and to grow into the likeness of thy Son Jesus Christ our Lord; who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        commons: ["Doctor of the Church", "Confessor: Bishop"],
        supplements: {
            ep2MagId: "doctor athanasius",
        },
    },
    {
        day: 3,
        month: 5,
        title: "Saints Philip and James",
        subtitle: "Apostles",
        type: "feast",
        feastHasFirstEvensong: true,
        rank: 7,
        optional: false,
        collect:
            "O Almighty God, whom truly to know is everlasting life: grant us perfectly to know thy Son Jesus Christ to be the way, the truth and the life; that, following the steps of thy holy Apostles Saint Philip and Saint James, we may steadfastly walk in the way that leadeth to eternal life; through the same Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        commons: ["Apostles"],
    },
    {
        day: 4,
        month: 5,
        title: "The English Martyrs",
        subtitle: "",
        type: "memorial",
        rank: 10,
        optional: false,
        collect:
            "O merciful God, who, when thy Church on earth was torn apart by the ravages of sin, didst raise up men and women in England who witnessed to their faith with courage and constancy: give unto thy Church that peace which is thy will, and grant that those who have been divided on earth may be reconciled in heaven and be partakers together in the vision of thy glory; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        commons: ["Several Martyrs"],
    },
    {
        day: 4,
        month: 5,
        title: "The English Martyrs",
        subtitle: "",
        regionNote: "Britain",
        type: "feast",
        rank: 7,
        optional: false,
        collect:
            "O merciful God, who, when thy Church on earth was torn apart by the ravages of sin, didst raise up men and women in England who witnessed to their faith with courage and constancy: give unto thy Church that peace which is thy will, and grant that those who have been divided on earth may be reconciled in heaven and be partakers together in the vision of thy glory; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        commons: ["Several Martyrs"],
    },
    {
        day: 5,
        month: 5,
        title: "Saint Asaph",
        subtitle: "Bishop",
        regionNote: "Britain",
        type: "memorial",
        rank: 12,
        optional: true,
        collect:
            "Grant, O Lord, we pray thee: that, as Saint Asaph made an offering of perpetual praise in thy Church; so through his intercession thou mayest sustain us in devoted service of thy Name; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        commons: ["Confessor: Bishop"],
    },
    {
        day: 6,
        month: 5,
        title: "Saint John the Apostle in Eastertide",
        subtitle: "",
        regionNote: "Britain",
        type: "memorial",
        rank: 12,
        optional: true,
        collect:
            "O God, who with the oil of gladness didst anoint blessed John a companions in the tribulation and patience of the Lord Jesus: grant us likewise so to rejoice in the fellowship of Christ's sufferings; that when his glory shall be revealed, we may be glad with exceeding joy; through the same Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",

        commons: ["Apostles"],
        supplements: {
            mpHymnId: "beloved disciple of thy lord",
            ep2MagId: "jaiet john the",
        },
    },
    {
        day: 10,
        month: 5,
        title: "Saint John of Avila",
        subtitle: "Priest and Doctor of the Church",
        type: "memorial",
        rank: 12,
        optional: true,
        collect:
            "O God, who willest to be glorified in thy Saints, and didst raise up thy servant John of Avila to shine as a light in the world: shine, we pray thee, in our hearts; that aided by his prayers we also in our generation may show forth thy praises, who hast called us out of darkness into thy marvellous light; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        alternativeCollects: [
            "O God, who makest us glad with the yearly feast of blessed John of Avila, thy Priest and Confessor: mercifully grant that, as we now observe his heavenly birthday; so we may follow him in all virtuous and godly living; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
            "O God, by whose providence blessed John of Avila was sent to guide thy people in the way of everlasting salvation: grant, we beseech thee; as we on earth have learned of him the doctrine of life, so we may be found worthy to have him for our advocate in heaven; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        ],
        commons: ["Doctor of the Church", "Confessor: Priest"],
        supplements: {
            ep2MagId: "doctor john",
        },
    },
    {
        day: 12,
        month: 5,
        title: "Saints Nereus and Achilleus",
        subtitle: "Martyrs",
        type: "memorial",
        rank: 12,
        optional: true,
        collect:
            "Grant, O Lord, that this holy festival of thy blessed Martyrs Nereus and Achilleus, may ever assist us in thy service: and that we may thereby be rendered worthy to walk after thy commandments; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        commons: ["Several Martyrs"],
    },
    {
        day: 12,
        month: 5,
        title: "Saint Pancras",
        subtitle: "Martyr",
        type: "memorial",
        rank: 12,
        optional: true,
        collect:
            "May thy Church rejoice, O God, confident in the intercession of the Martyr Saint Pancras and by his glorious prayers may she persevere in devotion to thee and stand ever firm; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        commons: ["One Martyr"],
    },
    {
        day: 13,
        month: 5,
        title: "Our Lady of Fatima",
        subtitle: "",
        type: "memorial",
        rank: 12,
        optional: true,
        collect:
            "O God, who didst choose the Mother of thy Son to be our Mother also: grant us that, persevering in penance and prayer for the salvation of the world, we may further more effectively each day the reign of Christ; who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        commons: ["Blessed Virgin Mary"],
    },
    {
        day: 14,
        month: 5,
        title: "Saint Matthias",
        subtitle: "Apostle",
        type: "feast",
        rank: 7,
        optional: false,
        feastHasFirstEvensong: true,
        collect:
            "O Almighty God, who into the place of the traitor Judas didst choose thy faithful servant Matthias to be of the number of the twelve Apostles: grant that thy Church, being always preserved from false Apostles, may be ordered and guided by faithful and true pastors; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        athanasianCreed: true,
        commons: ["Apostles"],
        supplements: {
            ep1HymnId:
                "lord of creation bow thine ear o christ to hear st matthias",
        },
    },
    {
        day: 18,
        month: 5,
        title: "Saint John I",
        subtitle: "Pope and Martyr",
        type: "memorial",
        rank: 12,
        optional: true,
        collect:
            "O everlasting Shepherd, mercifully look upon thy flock: and through blessed John, thy Martyr and Supreme Pontiff, whom thou didst appoint to be shepherd of the whole Church, keep her with thy perpetual protection; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        commons: ["Martyr: Pope or Bishop", "Confessor: Holy Pope"],
    },
    {
        day: 19,
        month: 5,
        title: "Saint Dunstan",
        subtitle: "Bishop",
        type: "memorial",
        rank: 12,
        optional: true,
        collect:
            "O God, who dost ever hallow and protect thy Church: raise up therein through Spirit good and faithful stewards of the mysteries of Christ, as thou didst in thy Bishop Dunstan; that by their ministry and example, thy people may abide in thy favour and walk in the way of truth; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        commons: ["Confessor: Bishop"],
    },
    {
        day: 20,
        month: 5,
        title: "Saint Bernardine of Siena",
        subtitle: "Priest",
        type: "memorial",
        rank: 12,
        optional: true,
        collect:
            "O Lord Jesus Christ, who didst endue blessed Bernardine, thy Confessor, with pre-eminent love of thy most holy Name: we beseech thee; that, by the virtue of his merits and intercession, thou wouldest graciously pour into our hearts the spirit of love who livest and reignest with the Father in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        commons: ["Confessor: Missionary", "Religious"],
    },
    {
        day: 21,
        month: 5,
        title: "Saint Christopher Magallanes, Priest, and Companions, Martyrs",
        subtitle: "Saint Christopher Magallanes and Companions",
        type: "memorial",
        rank: 12,
        optional: true,
        collect:
            "Almighty and eternal God, who madest Saint Christopher Magallanes and his Companions faithful to Christ the King even unto martyrdom: grant us, through their intercession; that, persevering in confession of the true faith, we may always hold fast to the commandments of thy love; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        commons: ["Several Martyrs"],
    },
    {
        day: 21,
        month: 5,
        title: "Saint Godric of Finchale",
        subtitle: "Religious",
        regionNote: "Britain",
        type: "memorial",
        rank: 12,
        optional: true,
        collect:
            "Almighty God, our heavenly Father: we remember before thee all thy servants who have served thee faithfully in their generation, and have entered into rest, especially Godric of Finchale, beseeching thee to give us grace so to follow in their steps; that with them we may be partakers of thy heavenly kingdom; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        commons: ["Religious"],
    },
    {
        day: 21,
        month: 5,
        title: "Saint Helena",
        subtitle: "",
        regionNote: "Britain",
        type: "memorial",
        rank: 12,
        optional: true,
        collect:
            "O Lord Jesus Christ, who didst reveal to blessed Helena the place where thy Cross lay hid, that through her, thou mightest enrich thy Church with this precious treasure: grant unto us at her intercession; that by the ransom of the life-giving Tree we may attain unto the rewards of eternal life; who livest and reignest with the Father, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        commons: ["Any Matron or Holy Woman"],
        supplements: {
            invId: "helena inv",
        },
    },
    {
        day: 22,
        month: 5,
        title: "Saint Rita of Cascia",
        subtitle: "Religious",
        type: "memorial",
        rank: 12,
        optional: true,
        collect:
            "Bestow upon us, we pray, O Lord, the wisdom and strength of the Cross, with which thou wert pleased to endow Saint Rita: so that, suffering in every tribulation with Christ, we may participate ever more deeply in his Paschal Mystery; through the same Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        commons: ["Religious"],
    },
    {
        day: 23,
        month: 5,
        title: "Saint Petroc",
        subtitle: "Abbot",
        regionNote: "Britain",
        type: "memorial",
        rank: 12,
        optional: true,
        collect:
            "Grant, we beseech thee, O Lord that the prayers of thy holy Abbot, blessed Petroc, may commend us unto thee: that we, who have no power of ourselves to help ourselves, may by his advocacy find favour in thy sight; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        alternativeCollects: [
            "O God, who to the blessed Abbot Petroc gavest grace to imitate Christ in his poverty, and with humble heart to follow him to the end: grant that all who enter the path of Gospel perfection may neither look back nor go astray from the way; but hastening to thee without stumbling, may attain the crown of eternal life whereunto thou dost call them; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        ],
        commons: ["Abbot or Abbess"],
    },
    {
        day: 24,
        month: 5,
        title: "Our Lady, Help of Christians",
        regionNote: "Australia",
        patronNote: "Patron of Australia",
        type: "solemnity",
        rank: 3,
        optional: false,
        collect:
            "Almighty and merciful God, who for the defence of the Christian people didst in the most Blessed Virgin Mary wondrously establish a perpetual help: graciously grant that we, being strengthened by her sure protection, as we strive in life, may in death have strength to win the victory over the malicious enemy; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        supplements: {
            ep1HymnId: "ofttimes when hemmed around by hostile arms",
            ep1VandR: [
                "Grant that I may praise thee, Ho_ly Vir_gin.",
                "Give me strength a_gainst thy e_ne_mies.",
            ],
            ep1MagId: "olhoc behold mary",
            invId: "bvm inv",
            mpHymnId: "mother of our lord and saviour",
            mpVandR: [
                "Grace is poured out u_pon thy lips.",
                "There_fore God hath blessed thee for_e_ver.",
            ],
            mpBenId: "olhoc to thee we cried",
            ep2HymnId: "ofttimes when hemmed around by hostile arms",
            ep2VandR: [
                "Grant that I may praise thee, Ho_ly Vir_gin.",
                "Give me strength a_gainst thy e_ne_mies.",
            ],
            ep2MagId: "olhoc holy mary help",
        },
    },
    {
        day: 24,
        month: 5,
        title: "Saint Aldhelm",
        subtitle: "Bishop",
        regionNote: "Britain",
        type: "memorial",
        rank: 12,
        optional: true,
        collect:
            "O God, who as on this day didst exalt thy blessed Bishop Saint Aldhelm to everlasting felicity: we pray thee; that by his merits and intercession, thy mercy may bring us unto that place whither he is gone before; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        commons: ["Confessor: Bishop"],
    },
    {
        day: 25,
        month: 5,
        title: "Saint Bede the Venerable",
        subtitle: "Priest and Doctor of the Church",
        type: "memorial",
        rank: 12,
        optional: true,
        collect:
            "O God, who hast caused thy Church to shine with the learning of blessed Bede, thy Confessor and Doctor: mercifully grant that we thy servants may ever be enlightened by his wisdom, and holpen for his merits' sake; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        commons: ["Doctor of the Church", "Religious"],
        supplements: {
            ep2MagId: "doctor bede",
        },
    },
    {
        day: 25,
        month: 5,
        title: "Saint Bede the Venerable",
        subtitle: "Priest and Doctor of the Church",
        regionNote: "Britain",
        type: "memorial",
        rank: 10,
        optional: false,
        collect:
            "O God, who hast caused thy Church to shine with the learning of blessed Bede, thy Confessor and Doctor: mercifully grant that we thy servants may ever be enlightened by his wisdom, and holpen for his merits' sake; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        commons: ["Doctor of the Church", "Religious"],
        supplements: {
            ep2MagId: "doctor bede",
        },
    },
    {
        day: 25,
        month: 5,
        title: "Saint Gregory VII",
        subtitle: "Pope",
        type: "memorial",
        rank: 12,
        optional: true,
        collect:
            "O God, the strength of them that put their trust in thee, who didst stablish thy blessed Confessor and Pope Gregory the Seventh with the strength of constancy to defend the freedom of thy Church: grant, we pray thee; that by his prayers and good example, we may valiantly conquer all things contrary to our salvation; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        commons: ["Confessor: Holy Pope"],
    },
    {
        day: 25,
        month: 5,
        title: "Saint Mary Magdalene de' Pazzi",
        subtitle: "Virgin",
        type: "memorial",
        rank: 12,
        optional: true,
        collect:
            "O God, lover of virginity, who didst adorn with heavenly gifts the Virgin Saint Mary Magdalene de' Pazzi, setting her on fire with thy love: grant, we pray; that we, who honour her today, may imitate her example of purity and love; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        commons: ["Virgin", "Religious"],
    },
    {
        day: 26,
        month: 5,
        title: "Saint Philip Neri",
        subtitle: "Priest",
        type: "memorial",
        rank: 10,
        optional: false,
        collect:
            "O God, who didst exalt thy blessed Confessor Philip to the glory of thy Saints: mercifully grant that we, who rejoice in his festival, may learn to follow example of his virtues; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        commons: ["Confessor: Priest"],
    },
    {
        day: 27,
        month: 5,
        title: "Saint Augustine of Canterbury",
        subtitle: "Bishop",
        patronNote:
            "Patron of the Ordinariate of Our Lady of the Southern Cross",
        type: "memorial",
        rank: 12,
        optional: true,
        collect:
            "O God, who by the preaching and miracles of blessed Augustine, thy Confessor and Bishop, had enlightened the English people with the light of the true faith: mercifully grant that by his intercession the hearts of them that have gone astray may return to the unity of thy truth; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        commons: ["Confessor: Bishop"],
    },
    {
        day: 27,
        month: 5,
        title: "Saint Augustine of Canterbury",
        subtitle: "Bishop",
        patronNote:
            "Patron of the Ordinariate of Our Lady of the Southern Cross",
        type: "feast",
        rank: 7,
        optional: false,
        regionNote: "Australia, Britain",
        collect:
            "O God, who by the preaching and miracles of blessed Augustine, thy Confessor and Bishop, had enlightened the English people with the light of the true faith: mercifully grant that by his intercession the hearts of them that have gone astray may return to the unity of thy truth; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        commons: ["Confessor: Bishop"],
    },
    {
        day: 28,
        month: 5,
        title: "Saint Gregory VII",
        subtitle: "Pope",
        type: "memorial",
        rank: 12,
        optional: true,
        collect:
            "O God, the strength of them that put their trust in thee, who didst stablish thy blessed Confessor and Pope Gregory the Seventh with the strength of constancy to defend the freedom of thy Church: grant, we pray thee; that by his prayers and good example, we may valiantly conquer all things contrary to our salvation; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        commons: ["Confessor: Holy Pope"],
        regionNote: "Britain",
    },
    {
        day: 28,
        month: 5,
        title: "Saint Mary Magdalene de' Pazzi",
        subtitle: "Virgin",
        type: "memorial",
        rank: 12,
        optional: true,
        collect:
            "O God, lover of virginity, who didst adorn with heavenly gifts the Virgin Saint Mary Magdalene de' Pazzi, setting her on fire with thy love: grant, we pray; that we, who honour her today, may imitate her example of purity and love; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        commons: ["Virgin", "Religious"],
        regionNote: "Britain",
    },
    {
        day: 29,
        month: 5,
        title: "Saint Paul VI",
        subtitle: "Pope",
        type: "memorial",
        rank: 12,
        optional: true,
        collect:
            "O everlasting Shepherd, mercifully look upon thy flock: and through the intercession of blessed Paul the Sixth thy Supreme Pontiff, whom thou didst appoint to be shepherd of the whole Church, keep her with thy perpetual protection; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        commons: ["Confessor: Holy Pope"],
    },
    {
        day: 31,
        month: 5,
        title: "The Visitation of the Blessed Virgin Mary",
        subtitle: "",
        type: "feast",
        feastHasFirstEvensong: true,
        rank: 7,
        optional: false,
        collect:
            "O God, who didst lead the Blessed Virgin Mary to visit Elizabeth, to their exceeding joy and comfort: grant unto thy people; that as Mary did rejoice to be called the Mother of the Lord, so we may ever rejoice to believe the Incarnation of thine Only Begotten Son; who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        supplements: {
            ep1HymnId: "now in holy celebration",
            ep1VandR: [
                "Bless_ed art thou a_mong wo_men.",
                "And bless_ed is the fruit of thy womb.",
            ],
            ep1MagId: "visit blessed art thou",
            invId: "visit inv",
            mpHymnId: "portal of the worlds salvation",
            mpVandR: [
                "Bless_ed art thou a_mong wo_men.",
                "And bless_ed is the fruit of thy womb.",
            ],
            mpBenId: "visit when elizabeth",
            ep2HymnId: "now in holy celebration",
            ep2VandR: [
                "Bless_ed art thou a_mong wo_men.",
                "And bless_ed is the fruit of thy womb.",
            ],
            ep2MagId: "visit all generations",
        },
    },
    {
        day: 1,
        month: 6,
        title: "Saint Justin",
        subtitle: "Martyr",
        type: "memorial",
        rank: 10,
        optional: false,
        collect:
            "O God, who through the foolishness of the Cross didst wondrously teach blessed Justin Martyr the excellent knowledge of Jesus Christ: grant to us by his intercession; that, driving away the errors that beset us, we may attain unto steadfastness of faith; through the same Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        commons: ["One Martyr"],
    },
    {
        day: 2,
        month: 6,
        title: "Saints Marcellinus and Peter",
        subtitle: "Martyrs",
        type: "memorial",
        rank: 12,
        optional: true,
        collect:
            "O God, who makest us glad with the yearly festival of thy Martyrs Marcellinus and Peter: grant, we beseech thee; that as we do rejoice in their merits, so we may be enkindled to follow them in all virtuous and godly living; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        commons: ["Several Martyrs"],
    },
    {
        day: 3,
        month: 6,
        title: "Saint Charles Lwanga and Companions",
        subtitle: "Martyrs",
        type: "memorial",
        rank: 10,
        optional: false,
        collect:
            "O God, who hast made the blood of Martyrs to be the seed of Christians: mercifully grant that the field which is thy Church, watered with the blood shed by Saints Charles Lwanga and his Companions, may be fertile and always yield thee an abundant harvest; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        commons: ["Several Martyrs"],
    },
    {
        day: 5,
        month: 6,
        title: "Saint Boniface",
        subtitle: "Bishop and Martyr",
        type: "memorial",
        rank: 10,
        optional: false,
        collect:
            "O God, who raised up the holy Bishop and Martyr Saint Boniface from the English nation enlighten many peoples with the Gospel of Christ: grant, we pray; that we may hold fast in our hearts that faith which he taught with his lips and sealed with his blood; through the same Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        commons: ["One Martyr"],
    },
    {
        day: 6,
        month: 6,
        title: "Saint Norbert",
        subtitle: "Bishop",
        type: "memorial",
        rank: 12,
        optional: true,
        collect:
            'O God, who didst make blessed Norbert, thy Confessor and Bishop, an illustrious preacher of thy Word, and through him didst render thy Church fruitful with a new offspring: grant, we beseech thee; that by his intercession and merits, we may be enabled by thy help to practise what he taught, both in word and deed; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God," without end. Amen.',
        commons: ["Confessor: Bishop", "Religious"],
    },
    {
        day: 9,
        month: 6,
        title: "Saint Ephrem",
        subtitle: "Deacon and Doctor of the Church",
        type: "memorial",
        rank: 12,
        optional: true,
        collect:
            "O God, who didst will to illumine thy Church with the wondrous learning and splendid merits of blessed Ephrem, thy Confessor and Doctor: we humbly beseech thee, at his intercession; that thou wouldest ever defend her by thy continual power against the snares of error and wickedness; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        commons: ["Doctor of the Church"],
        supplements: {
            ep2MagId: "doctor ephrem",
        },
    },
    {
        day: 9,
        month: 6,
        title: "Saint Columba",
        subtitle: "Abbot",
        type: "memorial",
        rank: 12,
        optional: true,
        collect:
            "We pray thee, O Lord, inspire our hearts with the desire of heavenly glory: and grant that we, bringing our sheaves with us, may hither attain where the holy Abbot Columba shineth like a star before thee; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        commons: ["Abbot or Abbess"],
    },
    {
        day: 11,
        month: 6,
        title: "Saint Barnabas",
        subtitle: "Apostle",
        type: "memorial",
        rank: 10,
        optional: false,
        collect:
            "O Lord God Almighty, who didst endue thy holy Apostle Barnabas with singular gifts of the Holy Spirit: leave us not, we beseech thee, destitute of thy manifold gifts, nor yet of grace to use them always to thy honour and glory; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        commons: ["Apostles"],
    },
    {
        day: 13,
        month: 6,
        title: "Saint Anthony of Padua",
        subtitle: "Priest and Doctor of the Church",
        type: "memorial",
        rank: 10,
        optional: false,
        collect:
            "Grant, O Lord, that the solemn festival of thy holy Confessor Saint Anthony may bring gladness to thy Church: that being defended by thy succour in all things spiritual, we may be found worthy to attain to everlasting felicity; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        commons: ["Doctor of the Church", "Confessor: Priest"],
        supplements: {
            ep2MagId: "doctor anthony",
        },
    },
    {
        day: 16,
        month: 6,
        title: "Saint Richard of Chichester",
        subtitle: "Bishop",
        type: "memorial",
        rank: 12,
        optional: true,
        collect:
            "Most merciful Redeemer, who gavest to thy Bishop Richard a love of learning, a zeal for souls, and a devotion to the poor: grant that, encouraged by his example, and aided by his prayers, we may know thee more clearly, love thee more dearly, and follow thee more nearly, day by day; who livest and reignest with the Father in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        commons: ["Confessor: Bishop"],
    },
    {
        day: 19,
        month: 6,
        title: "Saint Romuald",
        subtitle: "Abbot",
        type: "memorial",
        rank: 12,
        optional: true,
        collect:
            "O God, who through Saint Romuald didst renew the manner of life of hermits in thy Church: grant, we beseech thee; that, denying ourselves and following Christ, we may merit to reach the heavenly realms on high; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        commons: ["Abbot or Abbess"],
    },
    {
        day: 20,
        month: 6,
        title: "Saint Alban",
        subtitle: "Martyr",
        type: "memorial",
        rank: 12,
        optional: true,
        collect:
            "O Eternal Father, who, when the Gospel of Christ first came to England, didst gloriously confirm the faith of Alban by making him the first to win the martyr's crown: grant that, assisted by his prayers and following his example in the fellowship of the Saints, we may worship thee, the living God, and faithfully witness to Jesus Christ thy Son our Lord; who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        commons: ["One Martyr"],
    },
    {
        day: 20,
        month: 6,
        title: "Saints Alban, Julius and Aaron",
        subtitle: "Protomartyrs of Britain",
        regionNote: "Wales",
        type: "memorial",
        rank: 12,
        optional: true,
        collect:
            "O God, by whose grace Saints Alban, Julius, and Aaron received the martyr's crown, as the first in this land to shed their blood for Christ: grant, we pray thee; that, following their example and supported by their prayers, we may worship thee, the living God, and give true witness to Christ our Lord; who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        commons: ["Several Martyrs"],
    },
    {
        day: 21,
        month: 6,
        title: "Saint Aloysius Gonzaga",
        subtitle: "Religious",
        type: "memorial",
        rank: 10,
        optional: false,
        collect:
            "O God, the giver of all spiritual gifts, who in the angelic youth of thy blessed Saint Aloysius didst unite a wondrous penitence to a wondrous innocence of life: grant, by his merits and intercession; that although we have not followed the pattern of his innocence, yet we may imitate the example of his penitence; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        commons: ["Confessor: Priest", "Religious"],
    },
    {
        day: 22,
        month: 6,
        title: "Saints John Fisher, Bishop, and Thomas More",
        subtitle: "Martyrs",
        shortTitle: "Saints John Fisher and Thomas More",
        type: "memorial",
        rank: 10,
        optional: false,
        collect:
            "O God, who didst raise up amongst the English people thy blessed Martyrs John and Thomas to be defenders of the faith and to witness to the primacy of the Roman Church: grant by their merits and prayers; that in the profession of one faith we may all be made one in Christ, and in him continue to be at one with one another; through the same Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        commons: ["Several Martyrs"],
    },
    {
        day: 22,
        month: 6,
        title: "Saints John Fisher, Bishop, and Thomas More",
        subtitle: "Martyrs",
        shortTitle: "Saints John Fisher and Thomas More",
        regionNote: "Britain",
        type: "feast",
        rank: 7,
        optional: false,
        collect:
            "O God, who didst raise up amongst the English people thy blessed Martyrs John and Thomas to be defenders of the faith and to witness to the primacy of the Roman Church: grant by their merits and prayers; that in the profession of one faith we may all be made one in Christ, and in him continue to be at one with one another; through the same Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        commons: ["Several Martyrs"],
    },
    {
        day: 23,
        month: 6,
        title: "Saint Paulinus of Nola",
        subtitle: "Bishop",
        type: "memorial",
        rank: 12,
        optional: true,
        collect:
            "O God, who to them that forsake all things in this world for thee, hast promised a hundredfold in the world to come: graciously grant that, following in the footsteps of thy holy Bishop Paulinus, we e may be enabled to despise earthly things and to seek only things heavenly; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",

        commons: ["Confessor: Bishop"],
    },
    {
        day: 23,
        month: 6,
        title: "Saint Etheldreda (Audrey)",
        subtitle: "Virgin",
        type: "memorial",
        rank: 12,
        optional: true,
        collect:
            "O God, who art the Spouse of virgin souls, the refreshment of the pure in heart, and the salvation of all who love thee: mercifully grant that we, who reverently celebrate the feast of thy blessed virgin Etheldreda, may through her prayers obtain mercy of thy divine majesty; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity Holy Spirit, ever one God, world of the without end. Amen.",
        commons: ["Virgin"],
    },
    {
        day: 24,
        month: 6,
        title: "The Nativity of Saint John the Baptist",
        subtitle: "",
        type: "solemnity",
        rank: 3,
        optional: false,
        collect:
            "Almighty God, by whose providence thy servant Saint John the Baptist was wonderfully born, and sent to prepare the way of thy Son our Saviour, by preaching of repentance: make us so to follow his doctrine and holy life, that we may truly repent according to his preaching; and after his example constantly speak the truth, boldly rebuke vice, and patiently suffer for the truth's sake; through the same Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        athanasianCreed: true,
        supplements: {
            ep1HymnId: "let thine example holy john remind us",
            ep1VandR: [
                "There was a man sent from God.",
                "Whose name was John.",
            ],
            ep1MagId: "njb when zacharias",
            invId: "njb inv",
            mpHymnId: "een in thy childhood mid the desert places",
            mpVandR: [
                "This Child shall be great in the sight of the Lord.",
                "For the hand of the Lord is with him.",
            ],
            mpBenId: "njb the mouth",
            ep2HymnId: "let thine example holy john remind us",
            ep2VandR: [
                "This Child shall be great in the sight of the Lord.",
                "For the hand of the Lord is with him.",
            ],
            ep2MagId: "njb the child",
        },
    },
    {
        day: 27,
        month: 6,
        title: "Saint Cyril of Alexandria",
        subtitle: "Bishop and Doctor of the Church",
        type: "memorial",
        rank: 12,
        optional: true,
        collect:
            "O God, who didst strengthen thy blessed Confessor and Bishop Saint Cyril, invincibly to maintain the divine motherhood of the Blessed Virgin Mary: vouchsafe that at his intercession we, believing her to be indeed the Mother of God, may as her children rejoice in her protection; through the same Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        commons: ["Doctor of the Church", "Confessor: Bishop"],
        supplements: {
            ep2MagId: "doctor cyril",
        },
    },
    {
        day: 28,
        month: 6,
        title: "Saint Irenaeus",
        subtitle: "Bishop and Martyr",
        type: "memorial",
        rank: 10,
        optional: false,
        collect:
            "O God, who didst bestow upon blessed Irenaeus, thy Martyr and Bishop, grace to overcome false doctrine by the teaching of the truth, and to establish thy Church in peace and prosperity: we beseech thee; that thou wouldest give thy people constancy in thy true religion; and grant us thy peace all the days of our life; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        commons: ["Martyr: Bishop", "Confessor: Bishop"],
    },
    {
        day: 29,
        month: 6,
        title: "Saints Peter and Paul",
        subtitle: "Apostles",
        type: "solemnity",
        rank: 3,
        optional: false,
        collect:
            "O God, who hast hallowed this day by the martyrdom of thine Apostles Peter and Paul: grant unto thy Church, in all things, to follow the precepts of those through whom she received the beginning of religion; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        supplements: {
            ep1HymnId: "this holy feast through all the quarters of the world",
            ep1VandR: [
                "Their sound is gone out in_to all lands.",
                "And their words in_to the ends of the world.",
            ],
            ep1MagId: "chair of st peter thou art",
            invId: "apostles inv",
            mpHymnId: "with golden splendour and with roseate hues of morn",
            mpVandR: [
                "They de_clared the work of God.",
                "And wise_ly con_si_dered his do_ings.",
            ],
            mpBenId: "chair of st peter whatsoever",
            ep2HymnId: "this holy feast through all the quarters of the world",
            ep2VandR: [
                "They de_clared the work of God.",
                "And wise_ly con_si_dered his do_ings.",
            ],
            ep2MagId: "peter and paul today",
        },
    },
    {
        day: 30,
        month: 6,
        title: "The First Martyrs of the Holy Roman Church",
        subtitle: "",
        type: "memorial",
        rank: 12,
        optional: true,
        collect:
            "O God, who didst consecrate the abundant first fruits of the Roman Church by the blood of the Martyrs: grant, we beseech thee; that with firm courage we may together draw strength from so great a struggle and ever rejoice at the triumph of faithful love; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        commons: ["Several Martyrs"],
    },
    {
        day: 1,
        month: 7,
        title: "Saint Oliver Plunkett",
        subtitle: "Bishop and Martyr",
        type: "memorial",
        rank: 12,
        optional: true,
        collect:
            "O God, who for the defence of the Catholic faith didst vouchsafe to endue blessed Oliver, thy Martyr and Bishop, with wondrous strengths of spirit: grant unto us by his intercession and example; that we may imitate his constancy in faith, and in danger perceive his advocacy; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        commons: ["Martyr: Bishop", "Confessor: Bishop"],
    },
    {
        day: 3,
        month: 7,
        title: "Saint Thomas",
        subtitle: "Apostle",
        type: "feast",
        feastHasFirstEvensong: true,
        rank: 7,
        optional: false,
        collect:
            "Almighty and everliving God, who for the greater confirmation of the faith didst suffer thy holy Apostle Thomas to be doubtful in thy Son's Resurrection: grant us so perfectly, and without all doubt, to believe in thy Son Jesus Christ; that our faith in thy sight may never be reproved; through the same Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        commons: ["Apostles"],
        supplements: {
            ep1HymnId:
                "lord of creation bow thine ear o christ to hear st thomas",
        },
    },
    {
        day: 4,
        month: 7,
        title: "Saint Elizabeth of Portugal",
        subtitle: "",
        type: "memorial",
        rank: 12,
        optional: true,
        collect:
            "O God, the author of peace and lover of charity, who didst adorn Saint Elizabeth of Portugal with a marvellous grace for reconciling those in conflict: grant, through her intercession; that we may become peacemakers, and so be called children of God; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        commons: ["Any Matron or Holy Woman"],
        supplements: {
            invId: "elizabeth inv",
        },
    },
    {
        day: 5,
        month: 7,
        title: "Saint Anthony Zaccaria",
        subtitle: "Priest",
        type: "memorial",
        rank: 12,
        optional: true,
        collect:
            "Grant us, O Lord God Almighty: that we, being filled with the spirit of thy blessed Apostle Paul, may learns that pre-eminent knowledge of Christ Jesus, whereby thou didst wondrously teach blessed Anthony Mary to establish in thy Church new households of priests and virgins; through the same Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        commons: ["Confessor: Priest", "Religious", "Educator"],
    },
    {
        day: 6,
        month: 7,
        title: "Saint Maria Goretti",
        subtitle: "Virgin and Martyr",
        type: "memorial",
        rank: 12,
        optional: true,
        collect:
            "O God, the author of innocence and lover of chastity, who didst bestow the grace of martyrdom on thy handmaid, the Virgin Saint Maria Goretti, in her youth: grant, we pray, through her intercession; that, as thou gavest her a crown for her steadfastness, so we too may be firm in obeying thy commandments; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        commons: ["Virgin Martyr"],
    },
    {
        day: 7,
        month: 7,
        title: "Blessed Peter To Rot",
        subtitle: "Martyr",
        regionNote: "Australia",
        type: "memorial",
        rank: 12,
        optional: true,
        collect:
            "Almighty and everliving God, who dost adorn the Church with the witness of Martyrs: grant us, we beseech thee; that through the intercession of Blessed Peter To Rot, we may be strengthened in faith and constancy, and, like him, be made steadfast in all trials; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        commons: ["One Martyr", "Educator"],
    },
    {
        day: 9,
        month: 7,
        title: "Saint Augustine Zhao Rong, Priest, and Companions, Martyrs",
        type: "memorial",
        shortTitle: "Saint Augustine Zhao Rong and Companions",
        rank: 12,
        optional: true,
        collect:
            "O God, who in thy wonderful providence hast strengthened thy Church through the confession of the Martyrs Saint Augustine Zhao and Companions: grant that thy people, faithful to the mission entrusted to them, may enjoy ever greater freedom, and witness to the truth before the world; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        commons: ["Several Martyrs"],
    },
    {
        day: 11,
        month: 7,
        title: "Saint Benedict",
        subtitle: "Abbot",
        patronNote: "Patron of Europe",
        type: "memorial",
        rank: 10,
        optional: false,
        collect:
            "O eternal God, who didst make thine Abbot Saint Benedict a wise master in the school of thy service, and a guide for many called into the common life to follow the rule of Christ: grant that we may put thy love above all things, and seek with joy the way of thy commandments; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        commons: ["Abbot or Abbess"],
    },
    {
        day: 11,
        month: 7,
        title: "Saint Benedict",
        subtitle: "Abbot",
        patronNote: "Patron of Europe",
        regionNote: "Britain",
        type: "feast",
        rank: 7,
        optional: false,
        collect:
            "O eternal God, who didst make thine Abbot Saint Benedict a wise master in the school of thy service, and a guide for many called into the common life to follow the rule of Christ: grant that we may put thy love above all things, and seek with joy the way of thy commandments; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        commons: ["Abbot or Abbess"],
    },
    {
        day: 12,
        month: 7,
        title: "Saint John Jones",
        subtitle: "Priest and Martyr",
        regionNote: "Wales",
        type: "memorial",
        rank: 12,
        optional: true,
        collect:
            "O God, by whose grace Saint John Jones combined a love of the Cross with a zeal for souls, and gave his life for his priestly fidelity: grant us, we beseech thee, through his intercession, constant fervour; and bring us to a share in his reward; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        commons: ["One Martyr"],
    },
    {
        day: 13,
        month: 7,
        title: "Saint Henry",
        subtitle: "",
        type: "memorial",
        rank: 12,
        optional: true,
        collect:
            "O God, whose abundant grace prepared Saint Henry to be raised by thee in a wonderful way from the cares of earthly rule to heavenly realms: grant, we pray, through his intercession; that amid the uncertainties of this world, we may hasten towards thee in perfect purity of heart; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        commons: ["Any Holy Man"],
    },
    {
        day: 15,
        month: 7,
        title: "Saint Bonaventure",
        subtitle: "Bishop and Doctor of the Church",
        type: "memorial",
        rank: 12,
        optional: true,
        collect:
            "O God, by whose providence blessed Bonaventure was sent to guide thy people in the way of everlasting salvation: grant, we beseech thee; that as we have learned of him the doctrine of life on earth, so we may be found worthy to have him for our advocate in heaven; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        commons: ["Doctor of the Church", "Confessor: Bishop"],
        supplements: {
            ep2MagId: "doctor bonaventure",
        },
    },
    {
        day: 15,
        month: 7,
        title: "Saint Swithun",
        subtitle: "Bishop",
        type: "memorial",
        rank: 12,
        optional: true,
        collect:
            "Almighty God, by whose grace we celebrate again the festival of thy servant Swithun: grant that, as he governed with gentleness the people committed to his care; so we, rejoicing in our inheritance in Christ, may ever seek to build up thy Church in unity and love; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        commons: ["Confessor: Bishop"],
    },
    {
        day: 16,
        month: 7,
        title: "Our Lady of Mount Carmel",
        subtitle: "",
        type: "memorial",
        rank: 12,
        optional: true,
        collect:
            "May the venerable intercession of the glorious Virgin Mary come to our aid, we pray thee, O Lord: so that, fortified by her protection, we may reach the mountain which is Christ; who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        commons: ["Blessed Virgin Mary"],
    },
    {
        day: 16,
        month: 7,
        title: "Saint Osmund",
        subtitle: "Bishop",
        regionNote: "Britain",
        type: "memorial",
        rank: 12,
        optional: true,
        collect:
            "O God, whose miracles of old we perceive to shine forth even in our time to the glory and praise of thy Name, and to the honour of thy holy Confessor and Bishop Saint Osmund: mercifully grant that we who keep his festival may by his prayers both glorify thee in this present time, and be deemed worthy to enjoy thee in the world to come; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        commons: ["Confessor: Bishop"],
    },
    {
        day: 20,
        month: 7,
        title: "Saint Apollinaris",
        subtitle: "Bishop and Martyr",
        type: "memorial",
        rank: 12,
        optional: true,
        collect:
            "O God, who dost reward the souls of the faithful and who hast hallowed, this day by thy martyrdom of blessed Apollinaris, thy Bishop: grant unto us, thy servants; that we who keep his festival, may by his prayers obtain the pardon of thy mercy; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        commons: ["Martyr: Bishop"],
    },
    {
        day: 20,
        month: 7,
        title: "Saint Margaret of Antioch",
        subtitle: "Martyr",
        regionNote: "Britain",
        type: "memorial",
        rank: 12,
        optional: true,
        collect:
            "Grant, O Lord, that, like as blessed Margaret, thy Virgin and Martyr, by the merits of her chastity and the godliness of her conduct, did ever walk acceptably in thy sight: so she may at all times effectually intercede for our forgiveness; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        commons: ["Virgin Martyr"],
    },
    {
        day: 21,
        month: 7,
        title: "Saint Lawrence of Brindisi",
        subtitle: "Priest and Doctor of the Church",
        type: "memorial",
        rank: 12,
        optional: true,
        collect:
            "O God, who didst bestow on blessed Lawrence of Brindisi, thy Confessor and Doctor, the spirit of wisdom and fortitude to endure every labour for the glory of thy Name and the salvation of souls: grant us, in the same spirit, both to perceive what we ought to do, and by his intercession to perform the same; through Jesus Christ thy Son our Lord, who liveth, and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        commons: ["Doctor of the Church", "Confessor: Priest", "Religious"],
        supplements: {
            ep2MagId: "doctor lawrence",
        },
    },
    {
        day: 22,
        month: 7,
        title: "Saint Mary Magdalene",
        subtitle: "",
        type: "feast",
        rank: 7,
        optional: false,
        collect:
            "Almighty God, whose blessed Son did call and sanctify Mary Magdalene to be a witness to his Resurrection: mercifully grant that by thy grace, and assisted by her prayers, we may be healed of all our infirmities, and always serve thee in the power of his endless life; who, liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        supplements: {
            ep1HymnId: "sing we now the joys and sorrows",
            ep1VandR: [
                "Full of grace are thy lips.",
                "Be_cause God hath blessed thee for_e_ver.",
            ],
            ep1MagId: "mary mag a woman",
            invId: "mary mag inv",
            mpHymnId:
                "as the gardener him addressing well and rightly she believed",
            mpVandR: [
                "God hath cho_sen and fore_cho_sen her.",
                "He hath made her to dwell in his ta_ber_na_cle.",
            ],
            mpBenId: "mary mag mary anointed",
            ep2HymnId: "mary weep not weep no longer",
            ep2VandR: [
                "God hath cho_sen and fore_cho_sen her.",
                "He hath made her to dwell in his ta_ber_na_cle.",
            ],
            ep2MagId: "mary mag a woman in the city",
        },
    },
    {
        day: 23,
        month: 7,
        title: "Saint Bridget of Sweden",
        subtitle: "Religious",
        patronNote: "Patron of Europe",
        type: "memorial",
        rank: 12,
        optional: true,
        collect:
            "O God Most High, the Creator of all mankind: we bless thy holy Name for the virtue and grace which thou hast given unto holy women in all ages, especially Saint Bridget; and we pray that her intercession and the example of her faith and purity may inspire many souls in this generation to look unto thee, and to follow thy blessed Son Jesus Christ our Lord; who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        commons: ["Religious"],
    },
    {
        day: 23,
        month: 7,
        title: "Saint Bridget of Sweden",
        subtitle: "Religious",
        patronNote: "Patron of Europe",
        regionNote: "Britain",
        type: "feast",
        rank: 7,
        optional: false,
        collect:
            "O God Most High, the Creator of all mankind: we bless thy holy Name for the virtue and grace which thou hast given unto holy women in all ages, especially Saint Bridget; and we pray that her intercession and the example of her faith and purity may inspire many souls in this generation to look unto thee, and to follow thy blessed Son Jesus Christ our Lord; who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        commons: ["Religious"],
    },
    {
        day: 24,
        month: 7,
        title: "Saint Sharbel Makhluf",
        subtitle: "Priest",
        type: "memorial",
        rank: 12,
        optional: true,
        collect:
            "O God, who didst call the Priest Saint Sharbel Makhluf to the solitary combat of the desert and imbued him with all manner of devotion: grant us, we pray thee; that, being made imitators of the Lord's Passion, we may merit to be coheirs of his kingdom; through the same Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        commons: ["Confessor: Priest", "Religious"],
    },
    {
        day: 25,
        month: 7,
        title: "Saint James",
        subtitle: "Apostle",
        type: "feast",
        feastHasFirstEvensong: true,
        rank: 7,
        optional: false,
        collect:
            "Grant, O merciful God, that as thine holy Apostle Saint James, leaving his father and all that he had, without delay was obedient unto the calling of thy Son Jesus Christ, and followed him: so we, forsaking all worldly and carnal affections, may be evermore ready to follow thy holy commandments; through the same Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        athanasianCreed: true,
        commons: ["Apostles"],
        supplements: {
            ep1HymnId:
                "lord of creation bow thine ear o christ to hear st james",
        },
    },
    {
        day: 26,
        month: 7,
        title: "Saints Joachim and Anne",
        subtitle: "Parents of the Blessed Virgin Mary",
        type: "memorial",
        rank: 10,
        optional: false,
        collect:
            "O God, who didst choose blessed Joachim and holy Anne that of  them might be born the Mother of thine Only Begotten Son: grant unto us, at their intercession, a place in the fellowship of thine elect, wherein for ever to praise thee for thy loving-kindness; through the same Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        supplements: {
            invId: "joachim and anne inv",
            mpHymnId: "the morning star succeeds to night",
            mpVandR: [
                "The just ex_ult in their glo_ry.",
                "They shout for joy on their beds.",
            ],
            mpBenId: "joachim and anne i am",
            ep2HymnId: "let all the saints in concert sing",
            ep2VandR: [
                "The just ex_ult in their glo_ry.",
                "They shout for joy on their beds.",
            ],
            ep2MagId: "joachim and anne the glorious",
        },
    },
    {
        day: 29,
        month: 7,
        title: "Saints Martha, Mary and Lazarus",
        subtitle: "",
        type: "memorial",
        rank: 10,
        optional: false,
        collect:
            "O Almighty God, who willest to be glorified in thy Saints, and didst raise up thy servants Martha, Mary and Lazarus to shine as lights in the world: shine, we pray thee, in our hearts; that aided by their prayers we also in our generation may show forth thy praises, who hast called us out of darkness into thy marvellous light; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        supplements: {
            invId: "martha inv",
            mpHymnId: "christs noble servants we would sing your greatness",
            mpVandR: [
                "The se_cret of the Lord is a_mong them that fear him, and he will show them his co_ve_nant.",
                "Yea, gra_cious and righ_teous is the Lord.",
            ],
            mpBenId: "martha watch and pray",
            ep2HymnId: "lo christ hath gone to bethany",
            ep2VandR: [
                "The righ_teous shall give thanks un_to thy Name.",
                "And the just shall con_tin_ue in thy sight.",
            ],
            ep2MagId: "martha since the",
        },
    },
    {
        day: 30,
        month: 7,
        title: "Saint Peter Chrysologus",
        subtitle: "Bishop and Doctor of the Church",
        type: "memorial",
        rank: 12,
        optional: true,
        collect:
            "O God, who madest the Bishop Saint Peter Chrysologus an illustrious preacher of thy incarnate Word: grant, through his intercession; that we may constantly ponder in our hearts the mysteries of thy salvation and faithfully manifest them in our lives; through the same Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        commons: ["Doctor of the Church", "Confessor: Bishop"],
        supplements: {
            ep2MagId: "doctor peter",
        },
    },
    {
        day: 31,
        month: 7,
        title: "Saint Ignatius of Loyola",
        subtitle: "Priest",
        type: "memorial",
        rank: 10,
        optional: false,
        collect:
            "O God, who for the greater glory of thy Name, didst endue thy Church militant with an increase of strength through the life and labours of blessed Ignatius: grant us, by his help and example, so to wage our earthly warfare; that with him we may be found worthy of a heavenly crown; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        commons: ["Religious", "Confessor: Priest"],
    },
    {
        day: 1,
        month: 8,
        title: "Saint Alphonsus Liguori",
        subtitle: "Bishop and Doctor of the Church",
        type: "memorial",
        rank: 10,
        optional: false,
        collect:
            "O God, who didst inflame blessed Alphonsus, thy Confessor and Bishop, with zeal for souls, and didst through him enrich thy Church with a new offspring: we beseech thee; that being taught by his wholesome precepts and strengthened by his example, we may be enabled to attain in gladness unto thee; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        commons: ["Doctor of the Church", "Confessor: Bishop"],
        supplements: {
            ep2MagId: "doctor alphonsus",
        },
    },
    {
        day: 2,
        month: 8,
        title: "Saint Eusebius of Vercelli",
        subtitle: "Bishop",
        type: "memorial",
        rank: 12,
        optional: true,
        collect:
            "Lead us, O Lord God, to imitate the constancy of Saint Eusebius in affirming the divinity of thy Son: that, by preserving the faith he taught as thy Bishop, we may merit a share in the very life of the same Jesus Christ thy Son our Lord; who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        commons: ["Confessor: Bishop"],
    },
    {
        day: 2,
        month: 8,
        title: "Saint Peter Julian Eymard",
        subtitle: "Priest",
        type: "memorial",
        rank: 12,
        optional: true,
        collect:
            "O God, who didst adorn Saint Peter Julian Eymards with a wonderful love for the sacred mysteries of the Body and Blood of thy Son: graciously grant that we, too, may be worthy to receive the delights he drew from this divine banquet; through the same Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        commons: ["Religious", "Confessor: Priest"],
    },
    {
        day: 3,
        month: 8,
        title: "Saint Germanus of Auxerre",
        subtitle: "Bishop",
        regionNote: "Wales",
        type: "memorial",
        rank: 12,
        optional: true,
        collect:
            "O Lord, who didst call Saint Germanus to Wales to confirm the faith and strengthen monastic life: grant that, through his intercession and example, we may attain a share in the glory of Christ; who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        commons: ["Confessor: Bishop"],
    },
    {
        day: 4,
        month: 8,
        title: "Saint John Mary Vianney",
        subtitle: "Priest",
        type: "memorial",
        rank: 10,
        optional: false,
        collect:
            "Almighty and merciful God, who didst wonderfully endue Saint John Vianney with pastoral zeal and a continual desire for prayer and penance: grant, we beseech thee; that by his example and intercession, we may win the souls of our brethren for Christ, and with them attain glory everlasting; through the same Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        commons: ["Confessor: Priest"],
    },
    {
        day: 5,
        month: 8,
        title: "The Dedication of the Basilica of Saint Mary Major",
        subtitle: "",
        type: "memorial",
        rank: 12,
        optional: true,
        collect:
            "Almighty God, who by the obedience of Mary didst repair the sin of Eve: grant that we who venerate the glorious and ever-Virgin Mother, may imitate her purity, rejoice in her succour, and share her triumph; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        commons: ["Blessed Virgin Mary"],
    },
    {
        day: 5,
        month: 8,
        title: "Saint Oswald",
        subtitle: "Martyr",
        regionNote: "Britain",
        type: "memorial",
        rank: 12,
        optional: true,
        collect:
            "Almighty everlasting God, who by the martyrdom of blessed King Oswald hast hallowed this day with holy joy and gladness: grant unto our hearts the increase of thy charity; that we, who honour his glorious battle for the faith, may imitate his constancy even unto death; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        commons: ["One Martyr"],
    },
    {
        day: 6,
        month: 8,
        title: "The Transfiguration of the Lord",
        subtitle: "",
        type: "feast",
        feastHasFirstEvensong: true,
        rank: 5,
        optional: false,
        collect:
            "O God, who on the holy mount didst reveal to chosen witnesses thine Only Begotten Son wonderfully transfigured, in raiment white and glistening: mercifully grant that we, being delivered from the disquietude of this world, may be permitted to behold the King in his beauty; who with thee, O Father, and thee, O Holy Spirit, liveth and reigneth, ever one God, world without end. Amen.",
        supplements: {
            ep1HymnId: "an image of that heavenly light",
            ep1VandR: [
                "Glo_rious didst thou ap_pear in the sight of the Lord.",
                "Be_cause the Lord hath clothed thee with ma_je_sty.",
            ],
            ep1MagId: "transfiguration christ jesus",
            invId: "transfiguration inv",
            mpHymnId: "author of all things christ the worlds redeemer",
            mpVandR: [
                "A crown of gold is set u_pon his head.",
                "Where_in is en_graved ho_li_ness, glo_ry, and ho_nour.",
            ],
            mpBenId: "transfiguration and behold",
            ep2HymnId: "o light of light by love inclined",
            ep2VandR: [
                "Glo_rious didst thou ap_pear in the sight of the Lord.",
                "Be_cause the Lord hath clothed thee with ma_je_sty.",
            ],
            ep2MagId: "transfiguration and when",
        },
    },
    {
        day: 7,
        month: 8,
        title: "Saint Sixtus II, Pope, and Companions",
        subtitle: "Martyrs",
        shortTitle: "Saint Sixtus II and Companions",
        type: "memorial",
        rank: 12,
        optional: true,
        collect:
            "O God, who dost vouchsafe unto us to celebrate the heavenly birthday of thy holy Martyrs blessed Pope Sixtus and his Companions: grant us to rejoice in the perpetual felicity of their fellowship in heaven; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        commons: ["Several Martyrs"],
    },
    {
        day: 7,
        month: 8,
        title: "Saint Cajetan",
        subtitle: "Priest",
        type: "memorial",
        rank: 12,
        optional: true,
        collect:
            "O God, who didst give to thy blessed Confessor Cajetan to live after the likeness of thine Apostles: grant to us also, by his intercession and example, to put our trust in thee, and to seek after heavenly things alone; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        commons: ["Confessor: Priest", "Religious"],
    },
    {
        day: 7,
        month: 8,
        title: "Saint Dominic",
        subtitle: "Priest",
        regionNote: "Australia",
        type: "memorial",
        rank: 12,
        optional: true,
        collect:
            "Almighty God, whose Priest Dominic grew in the knowledge of thy truth, and formed an order of preachers to proclaim the faith of Christ: by thy grace, grant to all thy people a love for thy word and a longing to share the Gospel; that the whole world may be filled with the knowledge of thee and of thy Son Jesus Christ our Lord; who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        commons: ["Confessor: Priest", "Religious"],
    },
    {
        day: 8,
        month: 8,
        title: "Saint Dominic",
        subtitle: "Priest",
        regionNote: "Britain",
        type: "memorial",
        rank: 10,
        optional: false,
        collect:
            "Almighty God, whose Priest Dominic grew in the knowledge of thy truth, and formed an order of preachers to proclaim the faith of Christ: by thy grace, grant to all thy people a love for thy word and a longing to share the Gospel; that the whole world may be filled with the knowledge of thee and of thy Son Jesus Christ our Lord; who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        commons: ["Confessor: Priest", "Religious"],
    },
    {
        day: 8,
        month: 8,
        title: "Saint Mary of the Cross",
        subtitle: "Virgin",
        regionNote: "Australia",
        type: "solemnity",
        rank: 3,
        optional: false,
        collect:
            "O God, the source of all goodness, who hast shown us in Saint Mary a woman of faith living by the power of the Cross: teach us, we pray thee, by her example to live by the Gospel in changing times; and to respect and defend the human dignity of all in our land; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        commons: ["Virgin", "Religious", "Educator"],
        supplements: {
            ep1MagId: "smotc let us",
            mpBenId: "smotc forsaking",
            ep2MagId: "smotc inspired",
        },
    },
    {
        day: 9,
        month: 8,
        title: "Saint Teresa Benedicta of the Cross",
        subtitle: "Virgin and Martyr",
        patronNote: "Patron of Europe",
        type: "memorial",
        rank: 12,
        optional: true,
        collect:
            "O God of our fathers, who didst lead the blessed Martyr Saint Teresa Benedicta of the Cross to know thy crucified Son and imitate him even unto death: mercifully grant that, by her intercession, all men may know Christ as Saviour, and through him come to thine eternal vision; through the same Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        commons: ["Virgin Martyr"],
    },
    {
        day: 9,
        month: 8,
        title: "Saint Teresa Benedicta of the Cross",
        subtitle: "Virgin and Martyr",
        patronNote: "Patron of Europe",
        regionNote: "Britain",
        type: "feast",
        rank: 7,
        optional: false,
        collect:
            "O God of our fathers, who didst lead the blessed Martyr Saint Teresa Benedicta of the Cross to know thy crucified Son and imitate him even unto death: mercifully grant that, by her intercession, all men may know Christ as Saviour, and through him come to thine eternal vision; through the same Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        commons: ["Virgin Martyr"],
    },
    {
        day: 10,
        month: 8,
        title: "Saint Lawrence",
        subtitle: "Deacon and Martyr",
        type: "feast",
        rank: 7,
        optional: false,
        collect:
            "Almighty God, who didst endue blessed Lawrence with power to overcome the fires of his torments: give us grace, we beseech thee, to quench the flames of our sins; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        commons: ["One Martyr"],
    },
    {
        day: 11,
        month: 8,
        title: "Saint Clare",
        subtitle: "Virgin",
        type: "memorial",
        rank: 10,
        optional: false,
        collect:
            "Graciously hear us, O God of our salvation: of that we who rejoice in the festival of blessed Clare, thy Virgin, may grow in the knowledge and love of true devotion; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        commons: ["Virgin", "Religious"],
    },
    {
        day: 12,
        month: 8,
        title: "Saint Jane Frances de Chantal",
        subtitle: "Religious",
        type: "memorial",
        rank: 12,
        optional: true,
        collect:
            "O God, who madest Saint Jane Frances de Chantal radiant with outstanding merits in divers paths of life in the way of perfection: grant us, through her intercession; that, walking faithfully in our vocation, we may ever be examples of thy shining light; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        commons: ["Religious"],
    },
    {
        day: 13,
        month: 8,
        title: "Saints Pontian, Pope, and Hippolytus, Priest",
        subtitle: "Martyrs",
        shortTitle: "Saints Pontian and Hippolytus",
        type: "memorial",
        rank: 12,
        optional: true,
        collect:
            "Grant, we beseech thee, Almighty God: that we, who on this day devoutly observe the festival of thy holy Martyrs blessed Pontian and Hippolytus, may thereby increase in godliness to the attainment of everlasting salvation; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, without end. Amen.",
        commons: ["Several Martyrs"],
    },
    {
        day: 14,
        month: 8,
        title: "Saint Maximillian Mary Kolbe",
        subtitle: "Priest and Martyr",
        type: "memorial",
        rank: 10,
        optional: false,
        collect:
            "Most gracious God, who didst fill thy Priest and Martyr Maximilian Kolbe with zeal for thine house and love of his neighbour: vouchsafe that, holpen by the prayers of this devoted servant of the immaculate Mother of God; we too may strive to serve others for thy glory, and become like unto thy dear Son, who loved his own even unto the end; who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        commons: ["One Martyr"],
    },
    {
        day: 15,
        month: 8,
        title: "The Assumption of the Blessed Virgin Mary",
        subtitle: "",
        type: "solemnity",
        rank: 3,
        optional: false,
        collect:
            "Almighty and everlasting God, who didst assume the Immaculate Virgin Mary, Mother of thy Son, body and soul to the glory of heaven: grant us, we beseech thee; that being ever intent on things above, we may be worthy to be partakers of her glory hereafter; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        supplements: {
            ep1HymnId: "blest maid to highest dignity",
            ep1VandR: [
                "Thou art ex_al_ted, O ho_ly Mo_ther of God.",
                "A_bove the choir of An_gels, un_to the hea_ven_ly king_dom.",
            ],
            ep1MagId: "assump o virgin",
            invId: "assump inv",
            mpHymnId: "virgin most holy robed in golden sunlight",
            mpVandR: [
                "Thou art ex_al_ted, O ho_ly Mo_ther of God.",
                "A_bove the choir of An_gels, un_to the hea_ven_ly king_dom.",
            ],
            mpBenId: "assump who is she",
            ep2HymnId: "candlemas sequence",
            ep2VandR: [
                "Thou art ex_al_ted, O ho_ly Mo_ther of God.",
                "A_bove the choir of An_gels, un_to the hea_ven_ly king_dom.",
            ],
            ep2MagId: "assump today",
        },
    },
    {
        day: 16,
        month: 8,
        title: "Saint Stephen of Hungary",
        subtitle: "",
        type: "memorial",
        rank: 12,
        optional: true,
        collect:
            "Grant thy Church, we pray, Almighty God: that she may have Saint Stephen of Hungary, who fostered her growth while a king on earth, as her glorious defender in heaven; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        commons: ["Any Holy Man"],
    },
    {
        day: 19,
        month: 8,
        title: "Saint John Eudes",
        subtitle: "Priest",
        type: "memorial",
        rank: 12,
        optional: true,
        collect:
            "O God, who didst wonderfully choose thy Priest Saint John Eudes to proclaim the unfathomable riches of Christ: grant us, by his example and teachings; that, growing in knowledge of thee, we may live faithfully by the light of the Gospel; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        commons: ["Confessor: Priest", "Religious"],
    },
    {
        day: 20,
        month: 8,
        title: "Saint Bernard",
        subtitle: "Abbot and Doctor of the Church",
        type: "memorial",
        rank: 10,
        optional: false,
        collect:
            "O God, by whose grace the blessed Abbot Bernard, kindled with the fire of thy love, became a burning and a shining light in thy Church: grant, his intercession; that we may be inflamed with the same spirit of love, and ever walk before thee as children of light; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        commons: ["Doctor of the Church", "Abbot or Abbess"],
        supplements: {
            ep2MagId: "doctor bernard",
        },
    },
    {
        day: 21,
        month: 8,
        title: "Saint Pius X",
        subtitle: "Pope",
        type: "memorial",
        rank: 10,
        optional: false,
        collect:
            "O God, who for the defence of the Catholic faith, and the restoring of all things in Christ, didst fill thy Supreme Pontiff Saint Pius the Tenth, with heavenly wisdom and apostolic fortitude: graciously grant that, following his teaching and example, we may attain unto eternal rewards; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        commons: ["Confessor: Holy Pope"],
    },
    {
        day: 22,
        month: 8,
        title: "The Queenship of the Blessed Virgin Mary",
        subtitle: "",
        type: "memorial",
        rank: 10,
        optional: false,
        collect:
            "Grant to us, we beseech thee, O Lord: that we, who celebrate the festival of the Blessed Virgin Mary our Queen, being defended by her succour, may obtain peace in this world, and glory in that which is to come; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        commons: ["Blessed Virgin Mary"],
    },
    {
        day: 23,
        month: 8,
        title: "Saint Rose of Lima",
        subtitle: "Virgin",
        type: "memorial",
        rank: 12,
        optional: true,
        collect:
            "Almighty God, the giver of all good gifts, who didst will that Saint Rose, bedewed with heavenly graces, should blossom forth among the peoples of the Americas as a flower of virginity and suffering: grant to us thy servants, so to run after her in the fragrance of her sweetness; that we may be found worthy to be made a sweet savour unto Christ our Lord; who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        commons: ["Virgin"],
    },
    {
        day: 24,
        month: 8,
        title: "Saint Bartholomew",
        subtitle: "Apostle",
        feastHasFirstEvensong: true,
        type: "feast",
        rank: 7,
        optional: false,
        collect:
            "O Almighty and everlasting God, who didst give to thine Apostle Bartholomew grace truly to believe and to preach thy Word: grant, we beseech thee, unto thy Church; to love that Word which he believed, and both to preach and to receive the same; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        athanasianCreed: true,
        commons: ["Apostles"],
        supplements: {
            ep1HymnId:
                "lord of creation bow thine ear o christ to hear st bartholomew",
        },
    },
    {
        day: 25,
        month: 8,
        title: "Saint Louis",
        subtitle: "",
        type: "memorial",
        rank: 12,
        optional: true,
        collect:
            "O God, who didst exalt blessed Louis from the cares of earthly rule to the glory of thy heavenly kingdom: grant, we pray thee, through his intercession; that, by fulfilling our duties on earth, we may be made heirs of the King of kings, even Jesus Christ thy Son our Lord; who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        commons: ["Any Holy Man"],
    },
    {
        day: 25,
        month: 8,
        title: "Saint Joseph Calasanz",
        subtitle: "Priest",
        type: "memorial",
        rank: 12,
        optional: true,
        collect:
            "O God, who didst adorn the Priest Saint Joseph Calasanz with such charity and patience that he la laboured tirelessly to educate children and endow them with every virtue: grant, we pray; that we, who venerate him as a teacher of wisdom, may constantly imitate his work for thy truth; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        commons: ["Educator", "Confessor: Priest"],
    },
    {
        day: 26,
        month: 8,
        title: "Blessed Dominic of the Mother of God",
        subtitle: "Priest",
        regionNote: "Britain",
        type: "memorial",
        rank: 12,
        optional: true,
        collect:
            "O God, who didst choose thy Priest Blessed Dominic Barberi to be a minister of thy salvation, so that his teaching and example might help many to find peace and reconciliation in thy Church: mercifully guide our steps, we humbly pray, along that same way of love and truth, until by thy grace we gain its eternal reward; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        commons: ["Confessor: Priest", "Religious"],
    },
    {
        day: 26,
        month: 8,
        title: "Saint David Lewis",
        subtitle: "Martyr",
        regionNote: "Wales",
        type: "memorial",
        rank: 12,
        optional: true,
        collect:
            "O God, who through the precious Blood of thy only Son hast redeemed mankind: grant that, as we rejoice that thy Martyr r Saint David Lewis was united to the sacrifice of thy Son; thy saving work may be accomplished in us, and we may become worthy to gather its fruit; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        commons: ["One Martyr", "Confessor: Priest"],
    },
    {
        day: 27,
        month: 8,
        title: "Saint Monica",
        subtitle: "",
        type: "memorial",
        rank: 10,
        optional: false,
        collect:
            "O God, who art the Comforter of them that mourn, and the Salvation of them that hope in thee, who didst graciously regard the tearful pleading of blessed Monica for the conversion of her son Augustine: grant, we beseech thee, at their united intercession; that we may truly lament our sins and be made worthy to obtain thy gracious pardon; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        commons: ["Any Matron or Holy Woman"],
        supplements: {
            invId: "monica inv",
        },
    },
    {
        day: 28,
        month: 8,
        title: "Saint Augustine of Hippo",
        subtitle: "Bishop and Doctor of the Church",
        type: "memorial",
        rank: 12,
        optional: false,
        collect:
            "O merciful Lord, who didst turn Saint Augustine from his sins to be a faithful Bishop and teacher: grant that we may follow him in penitence and godly discipline; till our restless hearts find their rest in thee; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        commons: ["Doctor of the Church", "Confessor: Bishop"],
        supplements: {
            ep2MagId: "doctor augustine",
        },
    },
    {
        day: 29,
        month: 8,
        title: "The Passion of Saint John the Baptist",
        subtitle: "",
        type: "memorial",
        rank: 10,
        optional: false,
        collect:
            "O God, who didst send thy messenger, Saint John the Baptist, to be the forerunner of the Lord, and to glorify thee by his death: grant that we, who have received the truth of thy most holy Gospel, may bear our witness thereunto; and after his example and aided by his prayers, constantly speak the truth, boldly rebuke vice, and patiently suffer for the truth's sake; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        commons: ["One Martyr"],
        supplements: {
            mpBenId: "bsjb herod sent",
            ep2MagId: "bsjb the unbelieving king",
        },
    },
    {
        day: 30,
        month: 8,
        title: "Saints Margaret Clitherow, Anne Line, and Margaret Ward",
        subtitle: "Martyrs",
        type: "memorial",
        rank: 12,
        optional: true,
        collect:
            "Steadfast God, as we honour the fidelity in life and constancy in death of thy holy Martyrs Margaret Clitherow, Anne Line, and Margaret Ward: we pray thee to raise up in our day women of courage and resource to care for thy household the Church; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        commons: ["Several Martyrs"],
    },
    {
        day: 31,
        month: 8,
        title: "Saint Aidan, Bishop, and the Saints of Lindisfarne",
        subtitle: "",
        shortTitle: "Saint Aidan and the Saints of Lindisfarne",
        type: "memorial",
        rank: 12,
        optional: true,
        collect:
            "O Everlasting God, who didst send thy gentle Bishop Aidan to proclaim the Gospel in Britain: grant that, aided by his prayers, we may live after his teaching in simplicity, humility, and love for the poor; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        commons: ["Confessor: Bishop"],
    },
    {
        day: 1,
        month: 9,
        title: "Our Lady of the Southern Cross",
        subtitle: "",
        type: "solemnity",
        rank: 3,
        optional: false,
        collect:
            "Almighty and everlasting God, who stooped to raise fallen humanity through the child-bearing of Blessed Mary: grant that we, who have seen thy glory revealed in our human nature and thy love made perfect in our weakness, may daily be renewed in thine image and, through her constant intercession, conformed to the pattern of thy Son Jesus Christ our Lord; liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        commons: ["Blessed Virgin Mary"],
        supplements: {
            ep1MagId: "olsc the blessed",
            mpBenId: "olsc our lady",
            ep2MagId: "olsc we turn",
        },
        regionNote: "Australia",
    },
    {
        day: 3,
        month: 9,
        title: "Saint Gregory the Great",
        subtitle: "Pope and Doctor of the Church",
        type: "memorial",
        rank: 10,
        optional: false,
        collect:
            "Almighty and merciful God, who didst raise up thy servant Pope Gregory to be the servant of the servants of God, and didst inspire him to send missionaries to preach the Gospel to the English people: preserve in thy Church the Catholic and Apostolic Faith they taught; that thy people, being fruitful in every good work, may receive t the crown of glory that fadeth not away; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        commons: ["Doctor of the Church", "Confessor: Holy Pope"],
        supplements: {
            mpHymnId: "of angles once apostle thou",
            ep2HymnId: "of angles once apostle thou",
            ep2MagId: "doctor gregory",
        },
    },
    {
        day: 3,
        month: 9,
        title: "Saint Gregory the Great",
        subtitle: "Pope and Doctor of the Church",
        regionNote: "Britain",
        type: "feast",
        rank: 7,
        optional: false,
        collect:
            "Almighty and merciful God, who didst raise up thy servant Pope Gregory to be the servant of the servants of God, and didst inspire him to send missionaries to preach the Gospel to the English people: preserve in thy Church the Catholic and Apostolic Faith they taught; that thy people, being fruitful in every good work, may receive the crown of glory that fadeth not away; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        commons: ["Doctor of the Church", "Confessor: Holy Pope"],
        supplements: {
            mpHymnId: "of angles once apostle thou",
            ep2HymnId: "of angles once apostle thou",
            ep2MagId: "doctor gregory",
        },
    },
    {
        day: 4,
        month: 9,
        title: "Saint Cuthbert",
        subtitle: "Bishop",
        type: "memorial",
        rank: 12,
        optional: true,
        collect:
            "Almighty God, who didst call thy servant Cuthbert from tending sheep to follow thy Son and to be a shepherd of thy people: in thy mercy, grant that we may so follow his example; that we may bring those who are lost home to thy fold; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        commons: ["Confessor: Bishop"],
    },
    {
        day: 8,
        month: 9,
        title: "The Nativity of the Blessed Virgin Mary",
        subtitle: "",
        type: "feast",
        feastHasFirstEvensong: true,
        rank: 7,
        optional: false,
        collect:
            "O Lord, we beseech thee, bestow on thy servants the gift of heavenly grace: that as our redemption began to dawn in the child-bearing of the Blessed Virgin Mary; so this festival of her Nativity may yield us an increase of peace; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        supplements: {
            ep1HymnId: "hail o star that pointest",
            ep1VandR: [
                "To_day is the Na_ti_vi_ty of the ho_ly Vir_gin Ma_ry.",
                "Whose glo_rious life il_lu_mi_neth all the chur_ches.",
            ],
            ep1MagId: "nativity bvm let us celebrate",
            invId: "nativity bvm inv",
            mpHymnId: "the god whom earth and sea and sky",
            mpVandR: [
                "To_day is the Na_ti_vi_ty of the ho_ly Vir_gin Ma_ry.",
                "Whose glo_rious life il_lu_mi_neth all the chur_ches.",
            ],
            mpBenId: "nativity bvm today let us",
            ep2HymnId: "candlemas sequence",
            ep2VandR: [
                "To_day is the Na_ti_vi_ty of the ho_ly Vir_gin Ma_ry.",
                "Whose glo_rious life il_lu_mi_neth all the chur_ches.",
            ],
            ep2MagId: "nativity bvm thy nativity",
        },
    },
    {
        day: 9,
        month: 9,
        title: "Saint Peter Claver",
        subtitle: "Priest",
        type: "memorial",
        rank: 12,
        optional: true,
        collect:
            "O God, who madest Saint Peter Claver a slave of slaves, and strengthened him with wonderful charity an and patience as he came to their help: grant, through his intercession; that, seeking the things of Christ, we may love our neighbour in deeds and in truth; through the same Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        commons: ["Confessor: Priest"],
    },
    {
        day: 12,
        month: 9,
        title: "The Most Holy Name of Mary",
        subtitle: "",
        type: "memorial",
        rank: 12,
        optional: true,
        collect:
            "Grant, we beseech thee, Almighty God: that thy faithful people who rejoice in the name and protections of the most holy Virgin Mary, may by her loving intercession, be delivered from all evils on earth and be found worthy to come to everlasting joys in heaven; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        commons: ["Blessed Virgin Mary"],
    },
    {
        day: 13,
        month: 9,
        title: "Saint John Chrysostom",
        subtitle: "Bishop and Doctor of the Church",
        type: "memorial",
        rank: 10,
        optional: false,
        collect:
            "O God of truth and love, who gavest to thy Bishop John Chrysostom eloquence to declare thy righteousness in the great congregation, and courage to bear reproach for the honour of thy Name: mercifully grant to the ministers of thy Word such excellence in preaching; that all people may share with them in the glory that shall be revealed; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        commons: ["Doctor of the Church", "Confessor: Bishop"],
        supplements: {
            ep2MagId: "doctor john",
        },
    },
    {
        day: 14,
        month: 9,
        title: "The Exaltation of the Holy Cross",
        subtitle: "",
        type: "feast",
        feastHasFirstEvensong: true,
        rank: 5,
        optional: false,
        collect:
            "Almighty God, whose Son our Saviour Jesus Christ was lifted high upon the Cross that he might draw the whole world unto himself: mercifully grant that we, who glory in the mystery of our redemption, may have grace to take up our cross and follow him; who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        supplements: {
            ep1HymnId: "fulfilled is all that david told",
            ep1VandR: [
                "This sign of the Cross shall be in hea_ven.",
                "When the Lord shall come to judge_ment.",
            ],
            ep1MagId: "holy cross o cross",
            invId: "holy cross inv",
            mpHymnId: "sing my tongue the glorious battle",
            mpVandR: [
                "We a_dore thee, O Christ, and we bless thee.",
                "Be_cause by thy Cross thou hast re_deemed the world.",
            ],
            mpBenId: "holy cross above all",
            ep2HymnId: "faithful cross above all other",
            ep2VandR: [
                "This sign of the Cross shall be in hea_ven.",
                "When the Lord shall come to judge_ment.",
            ],
            ep2MagId: "holy cross he the holy",
        },
    },
    {
        day: 15,
        month: 9,
        title: "Our Lady of Sorrows",
        subtitle: "",
        type: "memorial",
        rank: 10,
        optional: false,
        collect:
            "O Lord, in whose Passion, according to the word of Simeon, the sword of sorrow didst pierce the most sweet soul of the glorious Virgin and Mother Mary: graciously grant that we, who devoutly call to mind her sorrows, may obtain the blessed effects of thy Passion; who livest and reignest with the Father, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        supplements: {
            invId: "sorrows inv",
            mpHymnId: "o god the merciful and true",
            mpVandR: [
                "O Vir_gin Ma_ry, by the vir_tue of so ma_ny sor_rows.",
                "Make us to re_joice in the hea_ven_ly king_dom.",
            ],
            mpBenId: "sorrows come ye",
            ep2HymnId: "what a sea of tears and sorrow",
            ep2VandR: [
                "Pray for us, O Queen of Mar_tyrs.",
                "Who didst stand by the Cross of Je_sus.",
            ],
            ep2MagId: "sorrows sorrow hath weighed",
        },
    },
    {
        day: 16,
        month: 9,
        title: "Saints Cornelius, Pope, and Cyprian, Bishop",
        subtitle: "Martyrs",
        shortTitle: "Saints Cornelius and Cyprian",
        type: "memorial",
        rank: 10,
        optional: false,
        collect:
            "O God, who didst give Saints Cornelius and Cyprian to thy people as diligent shepherds and valiant Martyrs: grant that,  through their intercession, we may be strengthened in faith and constancy and spend ourselves without reserve for the unity of the Church; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        commons: ["Several Martyrs"],
    },
    {
        day: 17,
        month: 9,
        title: "Saint Robert Bellarmine",
        subtitle: "Bishop and Doctor of the Church",
        type: "memorial",
        rank: 12,
        optional: true,
        collect:
            "O God, who didst adorn blessed Robert Bellarmine, thy Bishop and Doctor, with wondrous learning and virtue, to banish the wiles of error and contend for the rights of the Apostolic See: grant by his merits and intercession; that we may grow in the love of truth, and that the hearts of those that err may return to the unity of thy Church; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        commons: ["Doctor of the Church", "Confessor: Bishop"],
        supplements: {
            ep2MagId: "doctor robert",
        },
    },
    {
        day: 17,
        month: 9,
        title: "Saint Hildegard of Bingen",
        subtitle: "Virgin and Doctor of the Church",
        type: "memorial",
        rank: 12,
        optional: true,
        collect:
            "Grant, we beseech thee, O Lord, that the prayers of thy holy Abbess, blessed Hildegard of Bingen, may commend us unto thee: that we, who have no power of ourselves to help ourselves, may by her advocacy find favour in thy sight; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        alternativeCollects: [
            "O God, who to the blessed Abbess Hildegard of Bingen gavest grace to imitate Christ in his poverty, and with humble heart to follow him to the end: grant that all who enter the path of Gospel perfection may neither look back nor go astray from the way; but hastening to thee without stumbling, may attain the crown of eternal life whereunto thou dost call them; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
            "O God, by whose providence blessed Hildegard of Bingen was sent to guide thy people in the way of everlasting salvation: grant, we beseech thee; as we on earth have learned of her the doctrine of life, so we may be found worthy to have her for our advocate in heaven; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        ],
        commons: ["Doctor of the Church", "Abbot or Abbess"],
        supplements: {
            ep2MagId: "doctor hildegard",
        },
    },
    {
        day: 17,
        month: 9,
        title: "Saint Ninian",
        subtitle: "Bishop",
        regionNote: "Britain",
        type: "memorial",
        rank: 12,
        optional: true,
        collect:
            "O God, who hast made this day to be observed in honour of blessed Ninian, thy Confessor and Bishop: mercifully grant that as through his teaching the light of truth hath been shed forth in our hearts, so through his intercession we may attain to the joys of life everlasting; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        commons: ["Confessor: Bishop"],
    },
    {
        day: 17,
        month: 9,
        title: "Saint Edith of Wilton",
        subtitle: "Religious",
        regionNote: "Britain",
        type: "memorial",
        rank: 12,
        optional: true,
        collect:
            "O God, who didst will that thy Son our Lord should be born of a Virgin, and didst thereby give unto thy faithful a pattern of chastity: grant to us, through the prayers of Saint Edith thy Virgin, that purity of mind and body, whereby we may obtain the felicity promised to us by thee; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        commons: ["Virgin", "Religious"],
    },
    {
        day: 19,
        month: 9,
        title: "Saint Januarius",
        subtitle: "Bishop and Martyr",
        type: "memorial",
        rank: 12,
        optional: true,
        collect:
            "O God, who dost grant us to venerate the memory of the Martyr Saint Januarius: give us, we pray, the joy of his company in blessed happiness for all eternity; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        commons: ["Martyr: Bishop"],
    },
    {
        day: 19,
        month: 9,
        title: "Saint Theodore of Canterbury",
        subtitle: "Bishop",
        type: "memorial",
        rank: 12,
        optional: true,
        collect:
            "O God, whose Bishop Theodore was called to England (to this land), to set the Church on a firm foundation: by his prayers, build us always anew on the rock that is Christ; and keep thy household faithful to the call we have received; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever ne God, world without end. Amen.",
        commons: ["Confessor: Bishop"],
    },
    {
        day: 20,
        month: 9,
        title: "Saint Andrew Kim Tae-gon, Priest, Paul Chong Ha-sang and Companions",
        subtitle: "Martyrs",
        shortTitle: "Saint Andrew Kim Tae-gon and Companions",
        type: "memorial",
        rank: 10,
        optional: false,
        collect:
            "O God, who wast pleased to increase thy adopted children in all the world, and who made the blood of thy Martyrs Saint Andrew Kim Tae-gon and his Companions a most fruitful seed of Christians: grant that we may be defended by their help and profit always from their example; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        commons: ["Several Martyrs"],
    },
    {
        day: 21,
        month: 9,
        title: "Saint Matthew",
        subtitle: "Apostle and Evangelist",
        type: "feast",
        rank: 7,
        optional: false,
        feastHasFirstEvensong: true,
        collect:
            "O Almighty God, who by thy blessed Son didst call Saint Matthew from the receipt of custom to be an Apostle and Evangelist: grant us grace to forsake all covetous desires and inordinate love of riches; and to follow the same thy Son Jesus Christ; who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        athanasianCreed: true,
        commons: ["Apostles"],
        supplements: {
            ep1HymnId:
                "lord of creation bow thine ear o christ to hear st matthew",
        },
    },
    {
        day: 23,
        month: 9,
        title: "Saint Pius of Pietrelcina",
        subtitle: "Priest",
        type: "memorial",
        rank: 10,
        optional: false,
        collect:
            "Almighty everliving God, who, by a singular grace, didst give the Priest Saint Pius a share in the Cross of thy Son and, by means of his ministry, renewed the wonders of thy mercy: grant that, through his intercession, we may be united constantly to the sufferings of Christ, and so brought happily to the glory of the Resurrection; through the same Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        commons: ["Confessor: Priest", "Religious"],
    },
    {
        day: 24,
        month: 9,
        title: "Our Lady of Walsingham",
        type: "feast",
        feastHasFirstEvensong: true,
        rank: 7,
        optional: false,
        collect:
            "O God, who, through the mystery of the Word made flesh, didst in thy mercy sanctify the house of the Blessed Virgin Mary: do thou grant that we may keep aloof from the tabernacle of sinners, and become worthy indwellers of thy house; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        alternativeCollects: [
            "Grant, we pray thee, O Lord God, that as in the mystery of the Incarnation the blessed and ever-Virgin Mary conceived thy Son in her heart before she conceived him in her womb: so we, thy pilgrim people, rejoicing in her patronage, may welcome him into our hearts; and, like her, be made a holy house fit for his eternal dwelling; who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        ],
        supplements: {
            ep1HymnId: "hail o star that pointest",
            ep1VandR: ["And the Word was made flesh.", "And dwelt a_mong us."],
            ep1MagId: "dedication the lord hath",
            invId: "walsingham inv",
            mpHymnId: "the god whom earth and sea and sky",
            mpVandR: [
                "We will go /in_to ' his ta_ber_na_cle.",
                "And fall low on our /knees ' be_fore his foot_stool.",
            ],
            mpBenId: "walsingham behold the tabernacle",
            ep2HymnId: "hail o star that pointest",
            ep2VandR: ["And the Word was made flesh.", "And dwelt a_mong us."],
            ep2MagId: "dedication o how",
        },
    },
    {
        day: 24,
        month: 9,
        title: "Our Lady of Walsingham",
        type: "solemnity",
        regionNote: "Ordinariate of OLW",
        rank: 3,
        optional: false,
        collect:
            "O God, who, through the mystery of the Word made flesh, didst in thy mercy sanctify the house of the Blessed Virgin Mary: do thou grant that we may keep aloof from the tabernacle of sinners, and become worthy indwellers of thy house; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        alternativeCollects: [
            "Grant, we pray thee, O Lord God, that as in the mystery of the Incarnation the blessed and ever-Virgin Mary conceived thy Son in her heart before she conceived him in her womb: so we, thy pilgrim people, rejoicing in her patronage, may welcome him into our hearts; and, like her, be made a holy house fit for his eternal dwelling; who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        ],
        supplements: {
            ep1HymnId: "hail o star that pointest",
            ep1VandR: ["And the Word was made flesh.", "And dwelt a_mong us."],
            ep1MagId: "dedication the lord hath",
            invId: "walsingham inv",
            mpHymnId: "the god whom earth and sea and sky",
            mpVandR: [
                "We will go /in_to ' his ta_ber_na_cle.",
                "And fall low on our /knees ' be_fore his foot_stool.",
            ],
            mpBenId: "walsingham behold the tabernacle",
            ep2HymnId: "hail o star that pointest",
            ep2VandR: ["And the Word was made flesh.", "And dwelt a_mong us."],
            ep2MagId: "dedication o how",
        },
    },
    {
        day: 26,
        month: 9,
        title: "Saints Cosmas and Damian",
        subtitle: "Martyrs",
        type: "memorial",
        rank: 12,
        optional: true,
        collect:
            "Grant, we beseech thee, Almighty God: that we who celebrate the heavenly birthday of Saints Cosmas and Damian, thy Martyrs, may by their intercession be delivered from all evils that beset us; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        commons: ["Several Martyrs"],
    },
    {
        day: 27,
        month: 9,
        title: "Saint Vincent de Paul",
        subtitle: "Priest",
        type: "memorial",
        rank: 10,
        optional: false,
        collect:
            "O God, who didst strengthen blessed Vincent de Paul with apostolic power for preaching the Gospel to the poor, and for promoting worthiness in the clergy: grant, we beseech thee; that we who reverence his pious deeds may also be taught by the example of his virtues; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        commons: ["Confessor: Priest"],
    },
    {
        day: 28,
        month: 9,
        title: "Saint Wenceslaus",
        subtitle: "Martyr",
        type: "memorial",
        rank: 12,
        optional: true,
        collect:
            "O God, who by the victory of martyrdom didst exalt blessed Wenceslaus f from an earthly realm to the g glory of thy heavenly kingdom: grant, we pray thee; that by his merits and intercession, we may be made heirs of the King of kings, even Jesus Christ thy Son our Lord; who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        commons: ["One Martyr"],
    },
    {
        day: 28,
        month: 9,
        title: "Saint Lawrence Ruiz and Companions",
        subtitle: "Martyrs",
        type: "memorial",
        rank: 12,
        optional: true,
        collect:
            "Grant us, we pray, Lord God, the same perseverance shown by thy Martyrs Saint Lawrence Ruiz and his Companions in serving thee and their neighbour: even as those persecuted for the sake of righteousness are blessed in thy kingdom; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        commons: ["Several Martyrs"],
    },
    {
        day: 29,
        month: 9,
        title: "Saints Michael, Gabriel, and Raphael",
        subtitle: "Archangels",
        type: "feast",
        rank: 7,
        optional: false,
        collect:
            "O everlasting God, who hast ordained and constituted the services of Angels and men in a wonderful order: mercifully grant that as thy holy Angels always do thee service in heaven; so by thy appointment they may succour and defend us on earth; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        supplements: {
            invId: "archangels inv",
        },
    },
    {
        day: 30,
        month: 9,
        title: "Saint Jerome",
        subtitle: "Priest and Doctor of the Church",
        type: "memorial",
        rank: 10,
        optional: false,
        collect:
            "O God, who hast given us the holy Scriptures for a light to shine upon our path: grant us, after the example of thy servant Saint Jerome and assisted by his prayers, so to learn of thee according to thy holy Word; that we may find in it the light that shineth more and more unto the perfect day; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        commons: ["Doctor of the Church", "Confessor: Priest"],
        supplements: {
            ep2MagId: "doctor jerome",
        },
    },
    {
        day: 1,
        month: 10,
        title: "Saint Thérèse of the Child Jesus",
        subtitle: "Virgin and Doctor of the Church",
        type: "memorial",
        rank: 10,
        optional: false,
        collect:
            "O Lord Jesus Christ, who hast said except ye become as little children ye shall not enter into the kingdom of heaven: grant us, we beseech thee, in meekness and lowliness of heart to follow the footsteps of blessed Thérèse thy Virgin; and so at last to come unto thine everlasting kingdom; who livest and reignest with the Father in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        commons: ["Virgin", "Doctor of the Church"],
        supplements: {
            ep2MagId: "doctor therese",
        },
    },
    {
        day: 2,
        month: 10,
        title: "Holy Guardian Angels",
        subtitle: "",
        type: "memorial",
        rank: 10,
        optional: false,
        collect:
            "O God, who in thine ineffable providence dost vouchsafe to send thy holy Angels to guard us: grant, of thy bountiful goodness; that we thy humble servants may continue in safety under their protection, and hereafter rejoice in their abiding fellowship; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        supplements: {
            invId: "angels inv",
            mpHymnId: "eternal ruler of the sky",
            mpVandR: [
                "E_ven be_fore the An_gels will I sing praise un_to thee, O my God.",
                "I will wor_ship to_ward thy ho_ly tem_ple, and praise thy Name.",
            ],
            mpBenId: "hga the angel",
            ep2HymnId: "angelic guardians blest",
            ep2VandR: [
                "E_ven be_fore the An_gels will I sing praise un_to thee, O my God.",
                "I will wor_ship to_ward thy ho_ly tem_ple, and praise thy Name.",
            ],
            ep2MagId: "hga holy angels",
        },
    },
    {
        day: 3,
        month: 10,
        title: "Saint Thomas of Hereford",
        subtitle: "Bishop",
        type: "memorial",
        rank: 12,
        optional: true,
        collect:
            "O God, who didst adorn thy Church with the angelic purity and glorious virtues of the blessed Bishop Thomas of Hereford: grant unto us thy servants; that, by the pleading of his merits, we may with him be worthy to be joined unto the company of Angels; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        commons: ["Confessor: Bishop"],
    },
    {
        day: 4,
        month: 10,
        title: "Saint Francis of Assisi",
        subtitle: "",
        type: "memorial",
        rank: 10,
        optional: false,
        collect:
            "O God, who dost ever delight to reveal thyself to the childlike and lowly of heart: grant that, following the example of blessed Francis, and aided by his prayers; we may count the wisdom of this world as foolishness and know only Jesus Christ and him crucified; who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        commons: ["Religious"],
    },
    {
        day: 5,
        month: 10,
        title: "Saint Faustina Kowalska",
        subtitle: "Virgin",
        type: "memorial",
        rank: 12,
        optional: true,
        collect:
            "Graciously hear us, O God of our salvation: that, like as we do rejoice in the festival of blessed Faustina, thy holy Virgin, so we may learn to follow her in all godly and devout affections; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, without end. Amen.",
        alternativeCollects: [
            "O God who didst endue thy holy Virgin Faustina, with grace to witness a holy life: grant that we, after her example and aided by her prayers, may be found ready when the Bridegroom cometh, and enter with him to the marriage feast; through the same Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        ],
        commons: ["Virgin", "Religious"],
    },
    {
        day: 6,
        month: 10,
        title: "Saint Bruno",
        subtitle: "Priest",
        type: "memorial",
        rank: 12,
        optional: true,
        collect:
            "Almighty and everlasting God, who dost prepare mansions in heaven for them that forsake the world: we humbly entreat thy boundless mercy; that at the intercession of blessed Bruno, thy Confessor, we may faithful to vows that we have made, and may obtain, to our eternal salvation, the rewards which thou hast promised to them that persevere unto the end; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        commons: ["Religious", "Confessor: Priest"],
    },
    {
        day: 7,
        month: 10,
        title: "Our Lady of the Rosary",
        subtitle: "",
        type: "memorial",
        rank: 10,
        optional: false,
        collect:
            "O God, whose Only Begotten Son by his life, death, and Resurrection, hath purchased for us the rewards of eternal salvation: grant, we beseech thee; that meditating upon the mysteries of the Rosary, our devotion may bud forth as the rose in honour of the Blessed Virgin Mary; and that we may so follow the pattern of their teaching, that we may finally be made partakers of thy heavenly promises; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        commons: ["Blessed Virgin Mary"],
    },
    {
        day: 8,
        month: 10,
        title: "Saint Denis, Bishop and Companions",
        subtitle: "Martyrs",
        shortTitle: "Saint Denis and Companions",
        type: "memorial",
        rank: 12,
        optional: true,
        collect:
            "O God, who didst strengthen blessed Denis, thy Martyr and Bishop, with the virtue constancy in his suffering, and didst vouchsafe to join unto him Rusticus and Eleutherius, for the preaching of thy glory to the heathen: grant us, we beseech thee, by their example, to despise the prosperity of this world, and to fear none of its adversities; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        commons: ["Several Martyrs"],
    },
    {
        day: 8,
        month: 10,
        title: "Saint John Leonardi",
        subtitle: "Priest",
        type: "memorial",
        rank: 12,
        optional: true,
        collect:
            "O God, who didst wondrously vouchsafe to raise up blessed John Leonardi, thy Confessor, to spread abroad the faith among the nations, and through him didst gather a new family in thy Church for the instructing of the faithful: grant unto us thy servants, so to profit by his teaching; that we may attain unto eternal rewards; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        commons: ["Confessor: Missionary"],
    },
    {
        day: 9,
        month: 10,
        title: "Saint John Henry Newman",
        subtitle: "Priest",
        patronNote: "Patron of the Ordinariate of Our Lady of Walsingham",
        regionNote: "Britain",
        type: "feast",
        rank: 7,
        optional: false,
        collect:
            "O God, who didst bestow upon thy Priest Saint John Henry Newman, the grace to follow thy kindly light and find peace in thy Church: graciously grant that, through his intercession and example, we may be led out of shadows and images into the fulness of thy truth; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        commons: ["Confessor: Priest"],
        supplements: {
            mpHymnId: "hail dearest patron well beloved master",
            mpBenId: "jhn this man",
            ep2HymnId: "hail dearest patron well beloved master",
            ep2MagId: "jhn every scribe",
        },
    },
    {
        day: 9,
        month: 10,
        title: "Saint John Henry Newman",
        subtitle: "Priest",
        patronNote: "Patron of the Ordinariate of Our Lady of Walsingham",
        regionNote: "Ordinariates of CSP and OLSC",
        type: "memorial",
        rank: 10,
        optional: false,
        collect:
            "O God, who didst bestow upon thy Priest Saint John Henry Newman, the grace to follow thy kindly light and find peace in thy Church: graciously grant that, through his intercession and example, we may be led out of shadows and images into the fulness of thy truth; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        commons: ["Confessor: Priest"],
        supplements: {
            mpHymnId: "hail dearest patron well beloved master",
            mpBenId: "jhn this man",
            ep2HymnId: "hail dearest patron well beloved master",
            ep2MagId: "jhn every scribe",
        },
    },
    {
        day: 10,
        month: 10,
        title: "Saint Paulinus of York",
        subtitle: "Bishop",
        type: "memorial",
        rank: 12,
        optional: true,
        collect:
            "O God, our Saviour, who didst send thy servant Paulinus to preach and to baptise, and so to build up thy Church: grant that, being inspired by his example, we may proclaim to the whole world thy truth; that with him we may receive the reward thou hast prepared for all thy faithful servants; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        commons: ["Confessor: Bishop"],
    },
    {
        day: 11,
        month: 10,
        title: "Saint John XXIII",
        subtitle: "Pope",
        type: "memorial",
        rank: 12,
        optional: true,
        collect:
            "Almighty and eternal God, who in Pope Saint John the Twenty-third didst give to the whole world the shining example of a good shepherd: grant, we beseech thee; that, through his intercession, we may with joy spread abroad the fulness of Christian charity; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        commons: ["Confessor: Holy Pope"],
    },
    {
        day: 11,
        month: 10,
        title: "Saint Ethelburga",
        subtitle: "Abbess",
        type: "memorial",
        rank: 12,
        optional: true,
        collect:
            "Grant, we beseech thee, O Lord, that the prayers of thy holy Abbess, blessed Ethelburga, may commend us unto thee: that we, who have no power of ourselves to help ourselves, may by her advocacy find favour in thy sight; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        alternativeCollects: [
            "O God, who to the blessed Abbess Ethelhurga gavest grace to imitate Christ in his poverty, and with humble heart to follow him to the end: grant that all who enter the path of Gospel perfection may neither look back nor go astray from the way; but hastening to thee without stumbling, may attain the crown of eternal life whereunto thou dost call them; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        ],
        commons: ["Abbot or Abbess"],
    },
    {
        day: 12,
        month: 10,
        title: "Saint Wilfrid",
        subtitle: "Bishop",
        type: "memorial",
        rank: 12,
        optional: true,
        collect:
            "Almighty God, who didst call our forebears to the light of by the preaching of thy servant Wilfrid: grant us, who keep his life and labour in remembrance, to glorify thy Name by following the example of his zeal and perseverance; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        commons: ["Confessor: Bishop"],
    },
    {
        day: 13,
        month: 10,
        title: "Saint Edward the Confessor",
        subtitle: "",
        type: "memorial",
        rank: 12,
        optional: true,
        collect:
            "O God, who hast crowned thy blessed Confessor King Edward with eternal glory: grant that we who venerate him on earth, may be found worthy to reign with him in heaven; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        commons: ["Any Holy Man"],
    },
    {
        day: 14,
        month: 10,
        title: "Saint Callistus I",
        subtitle: "Pope and Martyr",
        type: "memorial",
        rank: 12,
        optional: true,
        collect:
            "O God, who didst raise up Pope Saint Callistus to serve the Church and attend devoutly to Christ's faithful departed: strengthen us, we pray, by his witness to the faith; so that, rescued from the slavery of corruption, we may merit an incorruptible inheritance; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        commons: ["Martyr: Pope or Bishop", "Confessor: Holy Pope"],
    },
    {
        day: 15,
        month: 10,
        title: "Saint Teresa of Jesus",
        subtitle: "Virgin and Doctor of the Church",
        type: "memorial",
        rank: 10,
        optional: false,
        collect:
            "Merciful God, who by thy Spirit didst raise up thy servant Saint Teresa of Jesus to reveal to thy Church the way of perfection: grant that her teaching may awaken in us a longing for holiness until, assisted by her intercession, we attain to the perfect union of love in Jesus Christ thy Son our Lord; who liveth and reigneth with thee, in the unity of the of the Holy Spirit, ever one God, world without end. Amen.",
        commons: ["Doctor of the Church", "Virgin"],
        supplements: {
            ep2MagId: "doctor teresa",
        },
    },
    {
        day: 16,
        month: 10,
        title: "Saint Hedwig",
        subtitle: "Religious",
        type: "memorial",
        rank: 12,
        optional: true,
        collect:
            "O God, who didst tech blessed Hedwig to forsake the pomps and vanities of this life, for the humble following of the Cross: mercifully grant that, by her merits and example, we may learn to trample underfoot the transitory pleasures of this world, and cleaving steadfastly to the Cross of Christ to overcome all things contrary to our salvation; through the same Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        commons: ["Religious"],
    },
    {
        day: 16,
        month: 10,
        title: "Saint Margaret Mary Alacoque",
        subtitle: "Virgin",
        type: "memorial",
        rank: 12,
        optional: true,
        collect:
            "O Lord Jesus Christ, who unto thy holy Virgin Margaret Mary Alacoque didst reveal the unsearchable riches of thy Sacred Heart: grant us, by her merits and example, to love thee in all things and above all things, and so find in thy loving Heart an everlasting habitation; who livest and reignest with the Father in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        commons: ["Virgin"],
    },
    {
        day: 16,
        month: 10,
        title: "Saint Richard Gwyn",
        subtitle: "Martyr",
        regionNote: "Wales",
        type: "memorial",
        rank: 12,
        optional: true,
        collect:
            "Almighty God, by whose grace and power thy Martyr Richard was enabled to witness to the truth and to be faithful unto death: grant that we who now remember him before thee, may likewise so bear witness unto thee in this world; that we may receive with him the crown of glory that fadeth not away; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        alternativeCollects: [
            "Grant, we beseech thee, Almighty God: that we, who this day keep the heavenly birthday of blessed Richard thy holy Martyr, may by his prayers be stablished in the love of thy holy Name; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        ],
        commons: ["One Martyr"],
    },
    {
        day: 17,
        month: 10,
        title: "Saint Ignatius of Antioch",
        subtitle: "Bishop and Martyr",
        type: "memorial",
        rank: 10,
        optional: false,
        collect:
            "Feed us, O Lord, with the living Bread and make us drink deep of the cup of salvation: that, following the teaching of thy Bishop Ignatius, and rejoicing in the faith with which he embraced the death of a Martyr, we may be nourished for that eternal life which he ever desired; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        commons: ["Martyr: Bishop", "Confessor: Bishop"],
    },
    {
        day: 18,
        month: 10,
        title: "Saint Luke",
        subtitle: "Evangelist",
        type: "feast",
        feastHasFirstEvensong: true,
        rank: 7,
        optional: false,
        collect:
            "Almighty God, who didst call Saint Luke, whose praise is in the Gospel, to be an Evangelist and physician of the soul: may it please thee; that, by the wholesome medicines of the doctrine delivered by him, all the diseases of our souls may be healed; through the merits of Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        commons: ["Apostles"],
    },
    {
        day: 19,
        month: 10,
        title: "Saints Jean de Brébeuf and Isaac Jogues, Priests, and Companions, Martyrs",
        subtitle: "",
        shortTitle: "Saints Jean de Brébeuf, Isaac Jogues and Companions",
        type: "memorial",
        rank: 12,
        optional: true,
        collect:
            "O God, who amongst the peoples of North America didst hallow the first fruits of the Faith both in the preaching and in the blood of many holy Martyrs: graciously grant by the intercession of Saints Jean de Brébeuf, Isaac Jogues and Companions; that everywhere from day to day the harvest of souls may abound to the increase of thy faithful people; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        commons: ["Several Martyrs"],
    },
    {
        day: 19,
        month: 10,
        title: "Saint Paul of the Cross",
        subtitle: "Priest",
        type: "memorial",
        rank: 12,
        optional: true,
        collect:
            "May the Priest Saint Paul, whose only love was the Cross, obtain for us thy grace, O Lord: so that, urged on more strongly by his example, we may each embrace our own cross with courage; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        commons: ["Confessor: Priest"],
    },
    {
        day: 19,
        month: 10,
        title: "Saint Frideswide",
        subtitle: "Abbess",
        type: "memorial",
        rank: 12,
        optional: true,
        collect:
            "Almighty everlasting God, the author of virtue and lover of virginity: grant us, we beseech thee; that as Saint Frideswide, thy Virgin and Abbess, was pleasing unto thee by the merit of her chastity, so by her prayers we may find favour with thee; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        commons: ["Abbot or Abbess"],
    },
    {
        day: 22,
        month: 10,
        title: "Saint John Paul II",
        subtitle: "Pope",
        type: "memorial",
        rank: 12,
        optional: true,
        collect:
            "O God, who art rich in mercy and who didst will that Saint John Paul the Second should preside as Pope over thy universal Church: grant, we pray; that instructed by his teaching, we may open our hearts to the saving grace of Christ, the sole Redeemer of mankind; who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        commons: ["Confessor: Holy Pope"],
    },
    {
        day: 23,
        month: 10,
        title: "Saint John of Capistrano",
        subtitle: "Priest",
        type: "memorial",
        rank: 12,
        optional: true,
        collect:
            "O God, who didst raise up Saint John of Capistrano to comfort thy faithful people in tribulation: place us, we pray, under thy safe protection, and keep thy Church in everlasting peace; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        commons: ["Confessor: Missionary", "Religious"],
    },
    {
        day: 24,
        month: 10,
        title: "Saint Anthony Mary Claret",
        subtitle: "Bishop",
        type: "memorial",
        rank: 12,
        optional: true,
        collect:
            "O God, who for the evangelisation of peoples didst strengthen the Bishop Saint Anthony Mary Claret with admirable charity and long-suffering: grant, through his intercession; that, seeking the things that are thine, we may earnestly devote ourselves to winning our brethren for Christ; who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        commons: ["Confessor: Missionary", "Confessor: Bishop"],
    },
    {
        day: 25,
        month: 10,
        title: "Six Welsh Martyrs and their Companions",
        subtitle: "",
        regionNote: "Wales",
        type: "feast",
        rank: 7,
        optional: false,
        collect:
            "Almighty God, who in our country raised up Martyrs from every walk of life to vindicate the authority of thy Church in teaching and worship: grant at their intercession, we pray thee; that all our people may be gathered once again to celebrate the same Sacraments under the one Shepherd, Jesus Christ thy Son; who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        commons: ["Several Martyrs"],
    },
    {
        day: 26,
        month: 10,
        title: "Saints Chad and Cedd",
        subtitle: "Bishops",
        type: "memorial",
        rank: 12,
        optional: true,
        collect:
            "Almighty and everlasting God, who didst enkindle the flame of thy love in the hearts of thy Bishops Chad and Cedd: grant to us, thy humble servants, the same faith and power of love; that, as we rejoice in their triumph, we may profit by their example and prayers; through Jesus Christ thy Son our Lord, Lord, who live liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        commons: ["Confessor: Bishop"],
    },
    {
        day: 28,
        month: 10,
        title: "Saints Simon and Jude",
        subtitle: "Apostles",
        type: "feast",
        feastHasFirstEvensong: true,
        rank: 7,
        optional: false,
        collect:
            "O Almighty God, who hast built thy Church upon the foundation of the Apostles and Prophets, Jesus Christ himself being the head corner-stone: grant us so to be joined together in unity of spirit by their doctrine; that we may be made an holy temple acceptable unto thee; through the same Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        athanasianCreed: true,
        commons: ["Apostles"],
        supplements: {
            ep1HymnId:
                "lord of creation bow thine ear o christ to hear sts simon and jude",
        },
    },
    {
        day: 1,
        month: 11,
        title: "All Saints",
        subtitle: "",
        type: "solemnity",
        rank: 3,
        optional: false,
        collect:
            "Almighty God, who hast knit together thine elect in one communion and fellowship, in the mystical body of thy Son Christ our Lord: grant us grace so to follow thy blessed Saints in all virtuous and godly living; that through their intercession we may come to those unspeakable joys, which thou hast prepared for them that unfeignedly love thee; through the same Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        supplements: {
            ep1HymnId: "o saviour jesu not alone",
            ep1VandR: [
                "Be glad, O ye /right_eous ' and re_joice in the Lord.",
                "And be /joy_ful ' all ye that are true of heart.",
            ],
            ep1MagId: "as angels archangels",
            invId: "as inv",
            mpHymnId: "the fathers pardon from above",
            mpVandR: [
                "Let the Saints be joy_ful in glo_ry.",
                "Let them re_joice in their beds.",
            ],
            mpBenId: "as the glorious",
            ep2HymnId: "the fathers pardon from above",
            ep2VandR: [
                "Let the Saints be joy_ful in glo_ry.",
                "Let them re_joice in their beds.",
            ],
            ep2MagId: "as o how glorious",
        },
    },
    {
        day: 2,
        month: 11,
        title: "All Souls",
        type: "misc",
        rank: 3,
        optional: false,
        collect:
            "O God, the Creator and Redeemer of all them that believe: grant unto the souls of thy servants and handmaids the remission of all their sins; that as they have ever desired thy merciful pardon, so by the supplications of their brethren they may receive the same; who livest and reignest with the Father, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        alternativeCollects: [
            "O God, whose nature and property is ever to have mercy and to forgive: have compassion on the souls of thy servants and handmaids, and grant unto them, the remission of all their sins; that, being delivered from the bonds of this our mortal nature, they may be found worthy to pass into everlasting life; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        ],
    },
    {
        day: 3,
        month: 11,
        title: "Saint Martin de Porres",
        subtitle: "Religious",
        type: "memorial",
        rank: 12,
        optional: true,
        collect:
            "O God, who didst lead Saint Martin de Porres by the way of humility to heavenly glory: grant that we may so follow the example of his holiness; that we may be worthy to be exalted with him to heaven; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        commons: ["Religious"],
    },
    {
        day: 3,
        month: 11,
        title: "Saint Winefride",
        subtitle: "Virgin",
        regionNote: "Britain",
        type: "memorial",
        rank: 12,
        optional: true,
        collect:
            "Almighty everlasting God, who didst bestow on blessed Winefride the rewards of virginity: make us, we beseech thee, by her intercession, to lay aside the enticements of this world, and with her to win a throne of everlasting glory; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        commons: ["Virgin"],
    },
    {
        day: 4,
        month: 11,
        title: "Saint Charles Borromeo",
        subtitle: "Bishop",
        type: "memorial",
        rank: 10,
        optional: false,
        collect:
            "Keep, O Lord, thy Church by the continual protection of Saint Charles Borromeo: that as his zeal for thy flock did render him glorious; so his intercession may ever make us fervent in thy love; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        commons: ["Confessor: Bishop"],
    },
    {
        day: 6,
        month: 11,
        title: "Saint Illtud",
        subtitle: "Abbot",
        regionNote: "Wales",
        type: "memorial",
        rank: 12,
        optional: true,
        collect:
            "Through to the example and intercession of Saint Illtud, O Lord: grant us the grace to seek thee in wisdom, and bring us to find thee in prayer; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        commons: ["Abbot or Abbess"],
    },
    {
        day: 7,
        month: 11,
        title: "Saint Willibrord",
        subtitle: "Bishop",
        type: "memorial",
        rank: 12,
        optional: true,
        collect:
            "O God, who didst vouchsafe to send thy blessed Saint Willibrord to preach thy glory to the nations: we humbly pray thee; that by his merits and intercession we may both see and know the things which we ought to do, and by thy mercy be enabled to perform the same; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        commons: ["Confessor: Bishop"],
    },
    {
        day: 8,
        month: 11,
        title: "All Saints of England",
        subtitle: "",
        regionNote: "England",
        type: "feast",
        rank: 7,
        optional: false,
        collect:
            "Grant, we beseech thee, Almighty God: that we may in all things be comforted by the intercession of holy Mary, Mother of God, of all the holy Apostles, Martyrs, Confessors and Virgins, and all the Saints of England (Wales): and that like as we do call to mind their godliness of life; so we may be effectually defended by their help; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        supplements: {
            ep1HymnId: "o saviour jesu not alone",
            ep1VandR: [
                "Be glad, O ye right_eous and re_joice in the Lord.",
                "And be joy_ful all ye that are true of heart.",
            ],
            ep1MagId: "as angels archangels",
            invId: "as inv",
            mpHymnId: "the fathers pardon from above",
            mpVandR: [
                "Let the Saints be joy_ful in glo_ry.",
                "Let them re_joice in their beds.",
            ],
            mpBenId: "as the glorious",
            ep2HymnId: "the fathers pardon from above",
            ep2VandR: [
                "Let the Saints be joy_ful in glo_ry.",
                "Let them re_joice in their beds.",
            ],
            ep2MagId: "as o how glorious",
        },
    },
    {
        day: 9,
        month: 11,
        title: "The Dedication of the Lateran Basilica",
        subtitle: "",
        type: "feast",
        feastHasFirstEvensong: true,
        rank: 5,
        optional: false,
        collect:
            "O most blessed Saviour, who didst vouchsafe thy gracious presence at the Feast of Dedication: be present with us at this time by thy Holy Spirit, and so possess our souls by thy grace; that we may be living temples, holy and acceptable unto thee; who livest and reignest with the Father, in the unity of the same Holy Spirit, ever one God, world without end. Amen.",
        commons: ["Dedication of a Church"],
    },
    {
        day: 10,
        month: 11,
        title: "Saint Leo the Great",
        subtitle: "Pope and Doctor of the Church",
        type: "memorial",
        rank: 10,
        optional: false,
        collect:
            "O Lord Jesus Christ, who didst strengthen thy holy Bishop and Doctor Pope Leo to maintain both by word and deed the verity of thy sacred Humanity: grant, we beseech thee; that guided by the light of his doctrine, we may earnestly defend the faith of thy holy Incarnation; who livest and reignest with the Father, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        commons: ["Doctor of the Church", "Confessor: Holy Pope"],
        supplements: {
            ep2MagId: "doctor leo",
        },
    },
    {
        day: 11,
        month: 11,
        title: "Saint Martin of Tours",
        subtitle: "Bishop",
        type: "memorial",
        rank: 10,
        optional: false,
        collect:
            "O God, who seest that we are not able to stand in our own strength: mercifully grant that, through the prayers of blessed Martin, thy Confessor and Bishop, we may be defended from all adversities; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        commons: ["Confessor: Bishop"],
    },
    {
        day: 12,
        month: 11,
        title: "Saint Josaphat",
        subtitle: "Bishop and Martyr",
        type: "memorial",
        rank: 10,
        optional: false,
        collect:
            "Stir up in thy Church, we pray, O Lord, the Spirit that filled Saint Josaphat: that, as he laid down his life for the sheep; so through his intercession we, too, may be strengthened by the same Spirit and not fear to lay down our life for our brethren; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        commons: ["Martyr: Bishop", "Confessor: Bishop"],
    },
    {
        day: 15,
        month: 11,
        title: "Saint Albert the Great",
        subtitle: "Bishop and Doctor of the Church",
        type: "memorial",
        rank: 12,
        optional: true,
        collect:
            "O God, who gavest grace unto blessed Albert, thy Bishop and Doctor, to become truly great in the subjection of human wisdom to divine faith: grant us, we beseech thee, so to follow in the footsteps of his teaching; that we may enjoy the perfect light in heaven; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        commons: ["Doctor of the Church", "Confessor: Bishop"],
        supplements: {
            ep2MagId: "doctor albert",
        },
    },
    {
        day: 16,
        month: 11,
        title: "Saint Margaret of Scotland",
        subtitle: "",
        type: "memorial",
        rank: 12,
        optional: true,
        collect:
            "O God, who didst call thy servant Margaret to an earthly throne that she might advance thy heavenly kingdom, and didst endue her with zeal for thy Church and charity towards thy people: mercifully grant that we who ask her prayers and commemorate her example may be fruitful in good works, and attain to the glorious fellowship of thy Saints; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        commons: ["Any Matron or Holy Woman"],
        supplements: {
            invId: "margaret of scotland inv",
        },
    },
    {
        day: 16,
        month: 11,
        title: "Saint Gertrude",
        subtitle: "Virgin",
        type: "memorial",
        rank: 12,
        optional: true,
        collect:
            "O God, who didst prepare a delightful dwelling for thyself in the heart of the Virgin Saint Gertrude: graciously bring light, through her intercession, to the darkness of our hearts; that we may joyfully experience thee present and at work within us; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        commons: ["Virgin", "Religious"],
    },
    {
        day: 16,
        month: 11,
        title: "Saint Edmund of Abingdon",
        subtitle: "Bishop",
        regionNote: "Britain",
        type: "memorial",
        rank: 12,
        optional: true,
        collect:
            "O God who, by the providence of thine abundant bounty, didst adorn thy Church with the merits of the wondrous life of blessed Edmund, thy Confessor and Bishop, and gladdened her with his glorious miracles: mercifully grant to us thy servants; that by his example we may learn to amend our lives, and by his intercession be defended against all adversities; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, the unity of the Holy Spirit, ever one God, world without end. Amen.",
        commons: ["Confessor: Bishop"],
    },
    {
        day: 17,
        month: 11,
        title: "Saint Elizabeth of Hungary",
        subtitle: "Religious",
        type: "memorial",
        rank: 12,
        optional: true,
        collect:
            "O Lord God, who didst teach Saint Elizabeth of Hungary to recognize and to reverence Christ in the poor of this world: grant that we, being strengthened by her example and assisted by her prayers, may so love and serve the afflicted and those in need that we may honour thy Son, the servant King; who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        commons: ["Any Matron or Holy Woman"],
        supplements: {
            invId: "elizabeth of hungary inv",
        },
    },
    {
        day: 17,
        month: 11,
        title: "Saint Hilda",
        subtitle: "Abbess",
        type: "memorial",
        rank: 12,
        optional: true,
        collect:
            "Almighty and everlasting God, who didst endow thy Abbess Hilda with purity and strength: grant by her intercession; we may subdue our souls and bodies to thy most holy will; through Jesus Christ thy S thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        commons: ["Abbot or Abbess"],
    },
    {
        day: 17,
        month: 11,
        title: "Saint Hugh of Lincoln",
        subtitle: "Bishop",
        type: "memorial",
        rank: 12,
        optional: true,
        collect:
            "O God, who didst endow thy servant Saint Hugh of Lincoln with a wise and cheerful boldness and didst teach him to commend to earthly rulers the discipline of a holy life: by his intercession, give us grace like him to be bold in the service of the Gospel, putting our confidence in Christ alone; who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        commons: ["Confessor: Bishop"],
    },
    {
        day: 18,
        month: 11,
        title: "Dedication of the Basilicas of Saints Peter and Paul",
        subtitle: "Apostles",
        type: "memorial",
        rank: 12,
        optional: true,
        collect:
            "Defend thy Church, O Lord, by the protection of the holy Apostles: that, as she received from them the beginnings of her knowledge of things divine; so through them she may receive, even to the end of the world, an increase in heavenly grace; through Jesus Christ thy Son our Lord, who liveth a and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        commons: ["Dedication of a Church"],
    },
    {
        day: 20,
        month: 11,
        title: "Saint Edmund",
        subtitle: "Martyr",
        type: "memorial",
        rank: 12,
        optional: true,
        collect:
            "O God, of unspeakable, mercy, who didst give thy blessed Saint Edmund grace to overcome the enemy by dying for thy Name: mercifully grant to us thy servants; that by intercession we may be found worthy to conquer and subdue the temptations of our ancient adversary; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        commons: ["One Martyr"],
    },
    {
        day: 21,
        month: 11,
        title: "The Presentation of the Blessed Virgin Mary",
        subtitle: "",
        type: "memorial",
        rank: 10,
        optional: false,
        collect:
            "Almighty and everlasting God, who by the overshadowing of the Holy Spirit didst prepare the body and soul; of the glorious Virgin Mother Mary to be a dwelling-place for thy Son: grant that we who rejoice in her Presentation may at her tender intercession be kept unspotted, and made a pure temple for his dwelling; who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        commons: ["Blessed Virgin Mary"],
    },
    {
        day: 22,
        month: 11,
        title: "Saint Cecilia",
        subtitle: "Virgin and Martyr",
        type: "memorial",
        rank: 10,
        optional: false,
        collect:
            "O God, who makest us glad with the yearly festival of blessed Cecilia, thy Virgin and Martyr: grant that we who venerate her in our service, may also follow the example of her godly life; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        commons: ["Virgin Martyr"],
    },
    {
        day: 23,
        month: 11,
        title: "Saint Clement I",
        subtitle: "Pope and Martyr",
        type: "memorial",
        rank: 12,
        optional: true,
        collect:
            "O everlasting Shepherd, favourably look upon thy flock: and keep it with perpetual protection, through the intercession of blessed Clement, thy Pope and Martyr, whom thou didst appoint to be shepherd of the whole Church; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        commons: ["Martyr: Pope or Bishop", "Confessor: Holy Pope"],
    },
    {
        day: 23,
        month: 11,
        title: "Saint Columban",
        subtitle: "Abbot",
        type: "memorial",
        rank: 12,
        optional: true,
        collect:
            "O God, who in Saint Columban wonderfully didst join the work of evangelisation to zeal for the monastic life: grant, we pray, that through his intercession and example, we may strive to seek thee above all things, and to bring increase to thy faithful people; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        commons: ["Confessor: Missionary", "Abbot or Abbess"],
    },
    {
        day: 24,
        month: 11,
        title: "Saint Andrew Dung-Lac, Priest, and Companions, Martyrs",
        subtitle: "",
        shortTitle: "Saint Andrew Dung-Lac and Companions",
        type: "memorial",
        rank: 10,
        optional: false,
        collect:
            "O God, source and origin of all fatherhood, who kept the Martyrs Saint Andrew Dung-Lȧc and his Companions faithful to the Cross of thy Son, even to the shedding of their blood: grant, through their intercession; that, spreading thy love among our brethren, we may be thy children both in name and in truth; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        commons: ["Several Martyrs"],
    },
    {
        day: 25,
        month: 11,
        title: "Saint Catherine of Alexandria",
        subtitle: "Virgin and Martyr",
        type: "memorial",
        rank: 12,
        optional: true,
        collect:
            "O God, whose dwelling-place is in the pure of heart: grant we beseech thee; that we who venerate the memory of the Martyr Catherine, thy faithful bride, may have grace to follow the example of her holiness and courage; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        commons: ["Virgin Martyr"],
    },
    {
        day: 30,
        month: 11,
        title: "Saint Andrew",
        subtitle: "Apostle",
        type: "feast",
        feastHasFirstEvensong: true,
        rank: 7,
        optional: false,
        collect:
            "Almighty God, who didst give such grace unto thy holy Apostle Saint Andrew, that he readily obeyed the calling of thy Son Jesus Christ, and followed him without delay: grant unto us all; that we, being called by thy holy Word, may forthwith give up ourselves obediently to fulfil thy holy commandments; through the same Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        athanasianCreed: true,
        commons: ["Apostles"],
        supplements: {
            ep1HymnId:
                "lord of creation bow thine ear o christ to hear st andrew",
            ep1MagId: "andrew on of the two",
            ep2MagId: "andrew on of the two",
        },
    },
    {
        day: 1,
        month: 12,
        title: "Saint Edmund Campion",
        subtitle: "Priest and Martyr",
        type: "memorial",
        rank: 10,
        optional: true,
        collect:
            "Almighty and everliving God, who gavest thy Martyr Edmund Campion courage to witness to the Gospel of Christ, even unto the giving of his life: grant that, by his prayers; we may endure all suffering for love of thee, and may seek thee with all our hearts, for thou alone art the source of life; through the same Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        commons: ["One Martyr", "Confessor: Priest"],
    },
    {
        day: 1,
        month: 12,
        title: "Saint Edmund Campion",
        subtitle: "Priest and Martyr",
        regionNote: "Britain",
        type: "memorial",
        rank: 10,
        optional: false,
        collect:
            "Almighty and everliving God, who gavest thy Martyr Edmund Campion courage to witness to the Gospel of Christ, even unto the giving of his life: grant that, by his prayers; we may endure all suffering for love of thee, and may seek thee with all our hearts, for thou alone art the source of life; through the same Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        commons: ["One Martyr", "Confessor: Priest"],
    },
    {
        day: 3,
        month: 12,
        title: "Saint Francis Xavier",
        subtitle: "Priest",
        type: "memorial",
        rank: 10,
        optional: false,
        collect:
            "O God, who by the preaching and wondrous works of Saint Francis Xavier didst unite unto thy Church the peoples of India: mercifully grant that we who venerate his glorious merits, may likewise follow him in all virtuous and godly living; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        commons: ["Confessor: Missionary", "Confessor: Priest"],
    },
    {
        day: 4,
        month: 12,
        title: "Saint John Damascene",
        subtitle: "Priest and Doctor of the Church",
        type: "memorial",
        rank: 12,
        optional: true,
        collect:
            "Almighty everlasting God, who, for the defence of the veneration of sacred images, didst endue blessed John Damascene with heavenly doctrine and wondrous strength of spirit: grant unto us, by his intercession and example; that we may imitate the virtues and perceive the advocacy of those whose images we honour; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        commons: ["Confessor: Priest"],
    },
    {
        day: 6,
        month: 12,
        title: "Saint Nicholas",
        subtitle: "Bishop",
        type: "memorial",
        rank: 12,
        optional: true,
        collect:
            "O God, who didst adorn thy blessed Bishop Saint Nicholas with power to work many and great miracles: grant, we beseech thee; that by his prayers and merits, we may be delivered from the fires of everlasting torment; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, the unity of the Holy Spirit, ever one God, world without end. Amen.",
        commons: ["Confessor: Bishop"],
    },
    {
        day: 7,
        month: 12,
        title: "Saint Ambrose",
        subtitle: "Bishop and Doctor of the Church",
        type: "memorial",
        rank: 10,
        optional: false,
        collect:
            "O God, who didst set thy blessed Bishop Saint Ambrose in thy Church as a Doctor and defender of the Catholic faith and an example of apostolic fortitude: grant, we beseech thee; that aided by his intercession, we may escape the dangers of error, and never be ashamed to confess thy truth; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        commons: ["Doctor of the Church", "Confessor: Bishop"],
        supplements: {
            ep2MagId: "doctor ambrose",
        },
    },
    {
        day: 8,
        month: 12,
        title: "The Immaculate Conception of the Blessed Virgin Mary",
        subtitle: "",
        shortTitle: "The Immaculate Conception",
        type: "solemnity",
        rank: 3,
        optional: false,
        collect:
            "O God, who in the foreknowledge of thy Son's most precious death didst consecrate for him a dwelling-place by the Immaculate Conception of the Blessed Virgin Mary: mercifully grant that she who was preserved from all defilement, may evermore pray for us, until we attain unto thee in purity of heart; through the same Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        commons: ["Blessed Virgin Mary"],
        supplements: {
            ep1MagId: "icbvm all generations",
            invId: "icbvm inv",
            mpBenId: "icbvm today let us",
            ep2MagId: "icbvm let us celebrate",
        },
    },
    {
        day: 9,
        month: 12,
        title: "Saint Juan Diego Cuauhtlatoatzin",
        subtitle: "",
        type: "memorial",
        rank: 12,
        optional: true,
        collect:
            "O God, who by means of Saint Juan Diego didst show the love of the most holy Virgin Mary for thy people: grant, through his intercession; that, by following the counsels our Mother gave at Guadalupe, we may be ever constant in fulfilling thy will; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        commons: ["Any Holy Man"],
    },
    {
        day: 10,
        month: 12,
        title: "Our Lady of Loreto",
        subtitle: "",
        type: "memorial",
        rank: 12,
        optional: true,
        collect:
            "Grant, we beseech thee, O Lord God, that we thy servants may enjoy y perpetual health of mind and body: and, at the glorious intercession the blessed and ever-Virgin Mary, may be delivered from present sadness, and may rejoice in continual gladness; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        commons: ["Blessed Virgin Mary"],
    },
    {
        day: 10,
        month: 12,
        title: "Saint John Roberts",
        subtitle: "Priest and Martyr",
        regionNote: "Wales",
        type: "memorial",
        rank: 12,
        optional: true,
        collect:
            "Preserve among thy people, O Lord, we pray, that faith which was handed down from thine Apostles, assured by the authority of the Holy See, and professed by the blood of Saint John Roberts, thy holy Martyr; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        commons: ["One Martyr", "Confessor: Priest"],
    },
    {
        day: 11,
        month: 12,
        title: "Saint Damasus I",
        subtitle: "Pope",
        type: "memorial",
        rank: 12,
        optional: true,
        collect:
            "Grant, we pray thee, O Lord: that we may constantly exalt the merits of thy Martyrs, whom Pope Saint Damasus so venerated and loved; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        commons: ["Confessor: Holy Pope"],
    },
    {
        day: 12,
        month: 12,
        title: "Our Lady of Guadalupe",
        subtitle: "",
        type: "memorial",
        rank: 12,
        optional: true,
        collect:
            "O God, who hast willed that under the special patronage of the most Blessed Virgin Mary of Guadalupe, we should receive an abundant measure of unceasing favours: grant us, thy suppliant people; that as we rejoice to honour her upon earth, so we may enjoy the vision of her in heaven; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        commons: ["Blessed Virgin Mary"],
    },
    {
        day: 13,
        month: 12,
        title: "Saint Lucy",
        subtitle: "Virgin and Martyr",
        type: "memorial",
        rank: 10,
        optional: false,
        collect:
            "O Lord our Saviour, who art the true Light that lighteneth every man: graciously hear us; that like as we do rejoice in the festival of blessed Lucy, thy Virgin and Martyr, so we may learn to follow her in all godly and devout affections; who liveth and reigneth with the Father, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        commons: ["Virgin Martyr"],
    },
    {
        day: 14,
        month: 12,
        title: "Saint John of the Cross",
        subtitle: "Priest and Doctor of the Church",
        type: "memorial",
        rank: 10,
        optional: false,
        collect:
            "O God, who didst inspire thy holy Confessor Saint John with an ardent love of self-denial and of the Cross: grant that by constantly following his example, we may attain to everlasting glory; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        commons: ["Doctor of the Church", "Confessor: Priest"],
        supplements: {
            ep2MagId: "doctor john",
        },
    },
    {
        day: 21,
        month: 12,
        title: "Saint Peter Canisius",
        subtitle: "Priest and Doctor of the Church",
        type: "memorial",
        rank: 12,
        optional: true,
        collect:
            "O God, who didst strengthen Saint Peter Canisius, thy Confessor, for the defence of the Catholic faith: mercifully grant that by his example and teaching, the erring may be made wise unto salvation, and the faithful may stand fast in the confession of truth; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        commons: ["Doctor of the Church", "Confessor: Priest"],
        supplements: {
            ep2MagId: "doctor peter",
        },
    },
    {
        day: 23,
        month: 12,
        title: "Saint John of Kanty",
        subtitle: "Priest",
        type: "memorial",
        rank: 12,
        optional: true,
        collect:
            "Grant, we beseech thee, Almighty God: that after the example of blessed John of Kanty, thy Confessor, we may so advance in the knowledge of the Saints, and likewise so learn to show mercy to all men; that by his merits we may be counted worthy to attain thy pardon; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        commons: ["Confessor: Priest"],
    },
    {
        day: 26,
        month: 12,
        title: "Saint Stephen",
        subtitle: "The First Martyr",
        type: "feast",
        rank: 7,
        optional: false,
        collect:
            "Grant, O Lord, that in all our sufferings here upon earth, for the testimony of thy truth, we may steadfastly look up to heaven, and by faith behold the glory that shall be revealed: and, being filled with the Holy Spirit, may learn to love and bless our persecutors, by the example of thy first Martyr Saint Stephen; who prayed for his murderers to thee, O blessed Jesus, who standest at e right hand of God to succour all those that Suffer for thee, our Mediator and Advocate; who livest and reignest with the Father, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        supplements: {
            invId: "stephen inv",
            mpHymnId: "saint of god elect and precious",
            mpVandR: [
                "De_vout men car_ried Ste_phen to his bu_ri_al.",
                "And made great la_men_ta_tion o_ver him.",
            ],
            mpBenId: "stephen but stephen",
            ep2HymnId: "saint of god elect and precious",
            ep2VandR: [
                "Ste_phen saw the hea_vens o_pened.",
                "He saw and en_tered: how bless_ed is the man un_to whom hea_ven stood o_pen.",
            ],
            ep2MagId: "stephen devout men",
        },
    },
    {
        day: 27,
        month: 12,
        title: "Saint John",
        subtitle: "Apostle & Evangelist",
        type: "feast",
        rank: 7,
        optional: false,
        collect:
            "Merciful Lord, we beseech thee to cast thy bright beams of light upon thy Church: that she being enlightened by the doctrine of thy blessed Apostle and Evangelist Saint John, may so walk in the light of thy truth, that she may at length attain to the light of everlasting life; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end Amen.",
        commons: ["Apostles"],
        supplements: {
            mpVandR: [
                "This is the di_sci_ple which tes_ti_fi_eth of these things.",
                "And we know that his tes_ti_mo_ny is true.",
            ],
            mpBenId: "john this is that john",
            ep2VandR: [
                "Right wor_thy of ho_nour is bless_ed John the A_po_stle.",
                "Who leaned on the Lord's bo_som at sup_per.",
            ],
            ep2MagId: "john this saying",
        },
    },
    {
        day: 28,
        month: 12,
        title: "The Holy Innocents",
        subtitle: "Martyrs",
        type: "feast",
        rank: 7,
        optional: false,
        collect:
            "O Almighty God, who out of the mouths of babes and nurslings hast ordained strength, and madest infants to glorify thee by their deaths: mortify and kill all vices in us; and so strengthen us by thy grace, that by the innocency of our lives, and constancy of our faith, even unto death, we may glorify thy holy Name; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        supplements: {
            invId: "martyr inv",
            mpHymnId: "all hail ye little martyr flowers",
            mpVandR: [
                "He_rod was ex_ceed_ing wroth, and slew many chil_dren.",
                "In Beth_le_hem of Ju_daea, the ci_ty of Da_vid.",
            ],
            mpBenId: "hi these are they",
            ep2HymnId: "all hail ye little martyr flowers",
            ep2VandR: [
                "Un_der the throne of God all the Saints cry out.",
                "A_venge our blood, O Lord our God.",
            ],
            ep2MagId: "hi many innocent",
        },
    },
    {
        day: 29,
        month: 12,
        title: "Saint Thomas Becket",
        subtitle: "Bishop and Martyr",
        patronNote: "Patron of Pastoral Clergy in England and Wales",
        regionNote: "Britain",
        type: "feast",
        rank: 7,
        optional: false,
        collect:
            "O God, for whose Church the glorious Bishop Thomas Becket fell by the swords of wicked men: grant, we beseech thee; that all who call upon him for succour may be profited by the obtaining of all that they desire; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        commons: ["Martyr: Bishop", "Confessor: Bishop"],
    },
    {
        day: 29,
        month: 12,
        title: "Saint Thomas Becket",
        subtitle: "Bishop and Martyr",
        patronNote: "Patron of Pastoral Clergy in England and Wales",
        type: "memorial",
        rank: 12,
        optional: true,
        collect:
            "O God, for whose Church the glorious Bishop Thomas Becket fell by the swords of wicked men: grant, we beseech thee; that all who call upon him for succour may be profited by the obtaining of all that they desire; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        commons: ["Martyr: Bishop", "Confessor: Bishop"],
    },
    {
        day: 31,
        month: 12,
        title: "Saint Sylvester I",
        subtitle: "Pope",
        type: "memorial",
        rank: 12,
        optional: true,
        collect:
            "Be merciful to the people of thy flock, O Lord, eternal Shepherd of our souls: and keep us in thy continual protection at the intercession of Saint Sylvester, whom thou didst raise up to be shepherd of the whole Church; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        commons: ["Confessor: Holy Pope"],
    },
];
