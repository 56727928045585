import React, { useRef, useState } from "react";
import ReactPlayer from "react-player";
import { useInterval } from "usehooks-ts";
import { Padding } from "../Padding";
import { getHymnUrl } from "../content/hymn-urls";
import { TEXT_COLOR, TEXT_INDENT, TEXT_SIZE } from "../tokens";
import { isSmallScreen } from "../utils";

export const Hymn: React.FC<{ hymnId: string; title: string; text: string }> = (
    props
) => {
    const { hymnId, title, text } = props;

    const videoPlayerRef = useRef<ReactPlayer>(null);

    const [isPlaying, setIsPlaying] = useState(false);

    const [percentageDone, setPercentageDone] = useState(0);

    const stop = () => {
        videoPlayerRef.current?.seekTo(0);
        setIsPlaying(false);
    };

    useInterval(() => {
        if (videoPlayerRef.current) {
            setPercentageDone(
                Math.min(
                    1,
                    videoPlayerRef.current.getCurrentTime() /
                        videoPlayerRef.current.getDuration() +
                        0.000001
                ) * 100
            );

            if (percentageDone === 100) {
                stop();
            }
        }
    }, 100);

    const hymnUrl = getHymnUrl(hymnId);

    return (
        <Padding onlySides>
            {hymnId ? (
                <>
                    {/* <ReactPlayer
                        ref={videoPlayerRef}
                        url={hymnUrl}
                        playing={isPlaying}
                        height={0}
                    />
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            gap: 8,
                            paddingBottom: 12,
                            paddingLeft: isSmallScreen()
                                ? TEXT_INDENT / 2
                                : TEXT_INDENT,
                            paddingRight: isSmallScreen()
                                ? TEXT_INDENT / 2
                                : TEXT_INDENT,
                        }}
                    >
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                cursor: "pointer",
                            }}
                            className="hover"
                            onClick={() => {
                                if (isPlaying) {
                                    stop();
                                } else {
                                    console.log(
                                        videoPlayerRef.current
                                            ?.getInternalPlayer()
                                            .playVideo()
                                    );
                                    setIsPlaying(true);
                                }
                            }}
                        >
                            {isPlaying ? (
                                <svg
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <rect
                                        x="6"
                                        y="6"
                                        width="12"
                                        height="12"
                                        fill={RUBRIC_COLOR}
                                    />
                                </svg>
                            ) : (
                                <svg
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M19 12L7 18.9282L7 5.0718L19 12Z"
                                        fill={RUBRIC_COLOR}
                                    />
                                </svg>
                            )}
                        </div>
                        <div
                            style={{
                                height: 4,
                                backgroundColor: PAGE_BACKGROUND,
                                flexGrow: 1,
                            }}
                        >
                            <div
                                style={{
                                    width: `${percentageDone}%`,
                                    height: "100%",
                                    backgroundColor: RUBRIC_COLOR,
                                }}
                            ></div>
                        </div>
                    </div> */}
                    <div className="yt-container">
                        <iframe
                            src={hymnUrl?.replace("/watch", "/embed")}
                        ></iframe>
                    </div>
                </>
            ) : (
                <></>
            )}
            <div
                style={{
                    paddingLeft: isSmallScreen()
                        ? TEXT_INDENT / 2
                        : TEXT_INDENT,
                    paddingRight: isSmallScreen()
                        ? TEXT_INDENT / 2
                        : TEXT_INDENT,
                    fontSize: isSmallScreen() ? TEXT_SIZE * 0.9 : TEXT_SIZE,
                    textAlign: "left",
                    color: TEXT_COLOR,
                    paddingTop: 6,
                    paddingBottom: 6,
                }}
            >
                <div
                    style={{
                        fontStyle: "italic",
                        textAlign: "center",
                        paddingBottom: 8,
                    }}
                >
                    {title}
                </div>
                {text
                    .split("\n")
                    .map((line) => line.trim())
                    .map((line) => {
                        return (
                            <div style={{ minHeight: TEXT_SIZE * 0.5 }}>
                                {line}
                            </div>
                        );
                    })}
            </div>
        </Padding>
    );
};
