import React from "react";
import ReactMarkdown from "react-markdown";
import { Padding } from "../Padding";
import { RUBRIC_COLOR, TEXT_INDENT, TEXT_SIZE } from "../tokens";
import { isSmallScreen } from "../utils";

export const Rubric: React.FC<{
    content: string;
}> = (props) => {
    const { content } = props;

    return (
        <Padding onlySides>
            <div
                style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                }}
                className="rubric"
                data-content={content}
            >
                <div
                    style={{
                        paddingLeft: isSmallScreen()
                            ? TEXT_INDENT / 2
                            : TEXT_INDENT,
                        paddingRight: isSmallScreen()
                            ? TEXT_INDENT / 2
                            : TEXT_INDENT,
                        color: RUBRIC_COLOR,
                        fontSize: isSmallScreen() ? TEXT_SIZE * 0.9 : TEXT_SIZE,
                        textAlign: "center",
                        flexGrow: 1,
                        paddingTop: 8,
                        paddingBottom: 8,
                    }}
                >
                    <ReactMarkdown>
                        {("❡__" + content)
                            .replace(/\\n/g, "  \n")
                            .replace(/_/g, "\u00a0")}
                    </ReactMarkdown>
                </div>
            </div>
        </Padding>
    );
};
