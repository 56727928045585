const spreadsheet = `
creator of the earth and sky	https://youtu.be/rOeSn04DXeg	https://www.youtube.com/embed/rOeSn04DXeg
this day the first of days was made	https://youtu.be/foyJMxg9ddE	https://www.youtube.com/embed/foyJMxg9ddE
o blest creator of the light	https://youtu.be/RZkz6wQel0c	https://www.youtube.com/embed/RZkz6wQel0c
o trinity of blessed light	https://youtu.be/0rEZaXWFdqg	https://www.youtube.com/embed/0rEZaXWFdqg
father we praise thee now the night is over	https://youtu.be/OHqXjmZDq24	https://www.youtube.com/embed/OHqXjmZDq24
o splendour of gods glory bright	https://youtu.be/-IpgDhth4kc	https://www.youtube.com/embed/-IpgDhth4kc
o boundless wisdom god most high	https://youtu.be/3n86xpLGAIU	https://www.youtube.com/embed/3n86xpLGAIU
the winged herald of the day	https://youtu.be/EnQMH4kx_Ok	https://www.youtube.com/embed/EnQMH4kx_Ok
earths mighty maker whose command	https://youtu.be/5YjkqPEjYXs	https://www.youtube.com/embed/5YjkqPEjYXs
ye clouds and dakness hosts of night	https://youtu.be/Sr9QzEOsIRE	https://www.youtube.com/embed/Sr9QzEOsIRE
most holy lord and god of heaven	https://youtu.be/r0vPFDbpq-g	https://www.youtube.com/embed/r0vPFDbpq-g
lo golden light rekindles day	https://youtu.be/nxwqCyes6WA	https://www.youtube.com/embed/nxwqCyes6WA
almighty god who from the flood	https://youtu.be/d9PykEbfH3g	https://www.youtube.com/embed/d9PykEbfH3g
eternal glory of the sky	https://youtu.be/gHu33vuDAdc	https://www.youtube.com/embed/gHu33vuDAdc
maker of man who from thy throne	https://youtu.be/RPDglqzzMAI	https://www.youtube.com/embed/RPDglqzzMAI
the dawn is sprinkling in the east	https://youtu.be/xRI2wvII9tU	https://www.youtube.com/embed/xRI2wvII9tU
creator of the stars of night	https://youtu.be/ksZCwZAMNRk	https://www.youtube.com/embed/ksZCwZAMNRk
high word of god who once didst come	https://youtu.be/FlZwWA6-t1s	https://www.youtube.com/embed/FlZwWA6-t1s
hark a herald voice is calling	https://youtu.be/zyd_P0SDfzA	https://www.youtube.com/embed/zyd_P0SDfzA
come thou redeemer of the earth	https://youtu.be/cBxuVD70dFE	https://www.youtube.com/embed/cBxuVD70dFE
o saviour of the world forlorn	https://youtu.be/1p1D3Gbx4R8	https://www.youtube.com/embed/1p1D3Gbx4R9
jesu the fathers only son	https://youtu.be/u5lDfjaxV9I	https://www.youtube.com/embed/u5lDfjaxV9I
from east to west from shore to shore	https://youtu.be/FUJPfes25Kw	https://www.youtube.com/embed/FUJPfes25Kw
now are the churches all with lights agleaming	https://youtu.be/M0whLAZAKCQ	https://www.youtube.com/embed/M0whLAZAKCQ
a blessing rests on nazareth	https://youtu.be/o18ziOPKrgc	https://www.youtube.com/embed/o18ziOPKrgc
o jesu light of hosts above	https://youtu.be/jzuaNGbMb4c	https://www.youtube.com/embed/jzuaNGbMb4c
o happy day when first was poured	https://youtu.be/gJU7pQoPvWI	https://www.youtube.com/embed/gJU7pQoPvWI
conquering kings their titles take	https://youtu.be/wzLu0evI5xc	https://www.youtube.com/embed/wzLu0evI5xc
why impious herod shouldst thou fear	https://youtu.be/KoRGs-c3HRU	https://www.youtube.com/embed/KoRGs-c3HRU
sent down by god to this worlds frame	https://youtu.be/x0dwGGmF0gQ	https://www.youtube.com/embed/x0dwGGmF0gQ
the fast as taught by holy lore	https://youtu.be/4IKXFgJe1mE	https://www.youtube.com/embed/4IKXFgJe1mE
o christ who art the light and day	https://youtu.be/9nhW25wdkJ4	https://www.youtube.com/embed/9nhW25wdkJ5
thou nly hope of all below	https://youtu.be/xTPOiiSssfE	https://www.youtube.com/embed/xTPOiiSssfE
o kind creator bow thine ear	https://youtu.be/QJVLsa6xGX0	https://www.youtube.com/embed/QJVLsa6xGX1
now is the healing time decreed	https://youtu.be/04Z3aSVpvq8	https://www.youtube.com/embed/04Z3aSVpvq9
the glory of these forty days	https://youtu.be/w9G2888z3VM	https://www.youtube.com/embed/w9G2888z3VM
o jesu christ from thee began	https://youtu.be/bO6Pb33W-BE	https://www.youtube.com/embed/bO6Pb33W-BE
the royal banners forward go	https://youtu.be/cb21PPPYkRU	https://www.youtube.com/embed/cb21PPPYkRU
servant of god remember	https://youtu.be/GnUnAmGQNZU	https://www.youtube.com/embed/GnUnAmGQNZU
sing my tongue the glorious battle	https://youtu.be/bcyXQ3PI15M	https://www.youtube.com/embed/bcyXQ3PI15M
thirty years among us dwelling	https://youtu.be/1JNixwl5GKs	https://www.youtube.com/embed/1JNixwl5GKs
ye choirs of new jerusalem	https://youtu.be/O-sQbHcjZIM	https://www.youtube.com/embed/O-sQbHcjZIM
ye choirs of new jerusalem alt	https://youtu.be/7FtBEiIXkxs	https://www.youtube.com/embed/7FtBEiIXkxs
o saviour jesu not alone	https://youtu.be/uHcm4wMUvSs	https://www.youtube.com/embed/uHcm4wMUvSs
the day draws on with golden light	https://youtu.be/MaKaiwwKJmE	https://www.youtube.com/embed/MaKaiwwKJmE
his cheering message from the grave	https://youtu.be/lb3QKJquwhk	https://www.youtube.com/embed/lb3QKJquwhk
the lambs high banquet we await	https://youtu.be/73PJHevzAqU	https://www.youtube.com/embed/73PJHevzAqU
eternal monarch king most high	https://youtu.be/CbIGxlIo-Gs	https://www.youtube.com/embed/CbIGxlIo-Gs
o christ our hope our hearts desire	https://youtu.be/CY0m7mqL2mo	https://www.youtube.com/embed/CY0m7mqL2mo
o christ our joy to whom is given	https://youtu.be/jRAj-9CT4Vs	https://www.youtube.com/embed/jRAj-9CT4Vs
when christ our lord had passed once more	https://youtu.be/ioR69xxWhiU	https://www.youtube.com/embed/ioR69xxWhiU
with joy the apostles breasts are fired	https://youtu.be/XZFgoUP46yg	https://www.youtube.com/embed/XZFgoUP46yg
come o creator spirit come	https://youtu.be/g4wpaffR4d8	https://www.youtube.com/embed/g4wpaffR4d9
come holy ghost our souls inspire	https://youtu.be/xk-OmhSCiPo	https://www.youtube.com/embed/xk-OmhSCiPo
rejoice the year upon its way	https://youtu.be/7Xs7Xwd0cdI	https://www.youtube.com/embed/7Xs7Xwd0cdI
be present holy trinity	https://youtu.be/2rr8-alM4-Y	https://www.youtube.com/embed/2rr8-alM4-Y
father most holy merciful and tender	https://youtu.be/Rj5zI9XtEVg	https://www.youtube.com/embed/Rj5zI9XtEVg
at this our solemn feast	https://youtu.be/3m8oGtwytgg	https://www.youtube.com/embed/3m8oGtwytgg
of the glorious body telling	https://youtu.be/1XZgRX36e1Q	https://www.youtube.com/embed/1XZgRX36e1Q
the word of god proceeding forth	https://youtu.be/jAXJhqvvoow	https://www.youtube.com/embed/jAXJhqvvoow
jesu creator of the world	https://youtu.be/RHQ1pZHkWFA	https://www.youtube.com/embed/RHQ1pZHkWFA
all ye who seek a comfort sure	https://youtu.be/IFuF9R3N8p4	https://www.youtube.com/embed/IFuF9R3N8p5
what proud attack our sins have launched	https://youtu.be/a60LaJski9k	https://www.youtube.com/embed/a60LaJski9k
o heart of jesus ark of god	https://youtu.be/us5Tpb65KmI	https://www.youtube.com/embed/us5Tpb65KmI
o christ to endless ages lord	https://youtu.be/FRdwJlGVBk0	https://www.youtube.com/embed/FRdwJlGVBk1
unfurled the glorious banners fly	https://youtu.be/pQSACnaZEt8	https://www.youtube.com/embed/pQSACnaZEt9
o thou eternal image bright	https://youtu.be/9OYWJ5jaqhk	https://www.youtube.com/embed/9OYWJ5jaqhk
jesu the very thought is sweet	https://youtu.be/Tyz7D2sZmrU	https://www.youtube.com/embed/Tyz7D2sZmrU
jesu of mercy source alone	https://youtu.be/_bZKq8ro50Y	https://www.youtube.com/embed/_bZKq8ro50Y
o let the heart beat high with bliss	https://youtu.be/xiZjyvxybo4	https://www.youtube.com/embed/xiZjyvxybo5
for his lord a soldier glorious	https://youtu.be/4nrqB15lhuE	https://www.youtube.com/embed/4nrqB15lhuE
all prophets hail thee from of old announcing	https://youtu.be/jCwArJKS1F0	https://www.youtube.com/embed/jCwArJKS1F1
now sion to thapproaching king	https://youtu.be/GjHj-RiCugM	https://www.youtube.com/embed/GjHj-RiCugM
come rejoicing	https://youtu.be/iCDMoS2JPKU	https://www.youtube.com/embed/iCDMoS2JPKU
whateer on earth below thy word o peter chained	https://youtu.be/iR6po1Jeig4	https://www.youtube.com/embed/iR6po1Jeig5
let angels chant thy praise	https://youtu.be/2R_7m0hS4Fk	https://www.youtube.com/embed/2R_7m0hS4Fk
joseph thou splendour of the heavenly mansions	https://youtu.be/XNs0ln4Q6Fo	https://www.youtube.com/embed/XNs0ln4Q6Fo
he whom the faithful praise today rejoicing	https://youtu.be/QVz67FEVcPE	https://www.youtube.com/embed/QVz67FEVcPE
beloved disciple of thy lord	https://youtu.be/s1kQaaUA_Ik	https://www.youtube.com/embed/s1kQaaUA_Ik
ofttimes when hemmed around by hostile arms	https://youtu.be/GaruEyPtTCo	https://www.youtube.com/embed/GaruEyPtTCo
mother of our lord and saviour	https://youtu.be/H5whEXjw6Is	https://www.youtube.com/embed/H5whEXjw6Is
o isle of saints in days of old	https://youtu.be/pcYxhmlpZsI	https://www.youtube.com/embed/pcYxhmlpZsI
now in holy celebration	https://youtu.be/mCOHK1ULlTI	https://www.youtube.com/embed/mCOHK1ULlTI
portal of the worlds salvation	https://youtu.be/UcFRqTMJ1A0	https://www.youtube.com/embed/UcFRqTMJ1A1
star of the sea that shinest for our healing	https://youtu.be/E4SlYJ7a9ow	https://www.youtube.com/embed/E4SlYJ7a9ow
lo how the whole world on this day is keeping	https://youtu.be/2vSwZxA2m9c	https://www.youtube.com/embed/2vSwZxA2m9c
let thine example holy john remind us	https://youtu.be/jCnY_0womGo	https://www.youtube.com/embed/jCnY_0womGo
een in thy childhood mid the desert places	https://youtu.be/Vi2HBfJGgDc	https://www.youtube.com/embed/Vi2HBfJGgDc
o more than blessed merit high attaining	https://youtu.be/lD9TSIH_kZ4	https://www.youtube.com/embed/lD9TSIH_kZ5
this holy feast through all the quarters of the world	https://youtu.be/jsGAY0XCpLw	https://www.youtube.com/embed/jsGAY0XCpLw
with golden splendour and with roseae hues of morn	https://youtu.be/zNv5xehmRTc	https://www.youtube.com/embed/zNv5xehmRTc
sing we now the joys and sorrows	https://youtu.be/9QQBXMTfMOE	https://www.youtube.com/embed/9QQBXMTfMOE
as the gardener him addressing	https://youtu.be/hsBxIRTDt4c	https://www.youtube.com/embed/hsBxIRTDt4c
mary weep not weep no longer	https://youtu.be/5BephPWP66s	https://www.youtube.com/embed/5BephPWP66s
the morning star succeeds to night	https://youtu.be/eqDc1E8O53A	https://www.youtube.com/embed/eqDc1E8O53A
let all the saints in concert sing	https://youtu.be/sh9yLyj-Xtg	https://www.youtube.com/embed/sh9yLyj-Xtg
christ noble servants we would sing your greatness	https://youtu.be/ZcHlpXbQm_0	https://www.youtube.com/embed/ZcHlpXbQm_1
lo christ hath gone to bethany	https://youtu.be/w2718r-RnBU	https://www.youtube.com/embed/w2718r-RnBU
an image of that heavenly light	https://youtu.be/IrArPxRuOBQ	https://www.youtube.com/embed/IrArPxRuOBQ
author of all things christ the worlds redeemer	https://youtu.be/tjtFfyxPuu0	https://www.youtube.com/embed/tjtFfyxPuu1
o light of light by love inclined	https://youtu.be/GQXtzac1y6A	https://www.youtube.com/embed/GQXtzac1y6A
o with what glorious lustre thou shinest	https://youtu.be/HQsz9le7EGs	https://www.youtube.com/embed/HQsz9le7EGs
blest maid to highest dignity	https://youtu.be/8BoYa0tJdJU	https://www.youtube.com/embed/8BoYa0tJdJU
virgin most holy robed in golden sunlight	https://youtu.be/CgCWc5Rk-sw	https://www.youtube.com/embed/CgCWc5Rk-sw
of angles once apostle thou	https://youtu.be/Rifo-1_8DX0	https://www.youtube.com/embed/Rifo-1_8DX1
fulfilled is all that david told	https://youtu.be/w-mb_YVbZWM	https://www.youtube.com/embed/w-mb_YVbZWM
faithful cross above all other	https://youtu.be/Tk-g4aawuOY	https://www.youtube.com/embed/Tk-g4aawuOY
o god the merciful and true	https://youtu.be/TggcwlzIe-c	https://www.youtube.com/embed/TggcwlzIe-c
what a sea of tears and sorrow	https://youtu.be/fYYbzeTBQkM	https://www.youtube.com/embed/fYYbzeTBQkM
thee o christ the fathers splendour	https://youtu.be/I5UAugLTCj0	https://www.youtube.com/embed/I5UAugLTCj1
christ the fair glory of the holy angels	https://youtu.be/fQEBKUfWHLA	https://www.youtube.com/embed/fQEBKUfWHLA
eternal ruler of the sky	https://youtu.be/lkgqqk7nGSw	https://www.youtube.com/embed/lkgqqk7nGSw
angelic guardians blest	https://youtu.be/RKsmDDW45cs	https://www.youtube.com/embed/RKsmDDW45cs
hail dearest patron wellbeloved master	https://youtu.be/c-dDhmHpQGs	https://www.youtube.com/embed/c-dDhmHpQGs
the fathers pardon from above	https://youtu.be/0VARt7HNTtY	https://www.youtube.com/embed/0VARt7HNTtY
saint of god elect and precious	https://youtu.be/xq_lRu82XBo	https://www.youtube.com/embed/xq_lRu82XBo
all hail ye little martyr flowers	https://youtu.be/IrUbcXLZYsA	https://www.youtube.com/embed/IrUbcXLZYsA
blessed city heavenly salem	https://youtu.be/zTG4gSzeVAQ	https://www.youtube.com/embed/zTG4gSzeVAQ
christ is made the sure foundation	https://youtu.be/a4Uzpu2XXz4	https://www.youtube.com/embed/a4Uzpu2XXz5
hail o star that pointest	https://youtu.be/vS2CKUyAOtk	https://www.youtube.com/embed/vS2CKUyAOtk
the god whom earth and sea and sky	https://youtu.be/85IGqw918oQ	https://www.youtube.com/embed/85IGqw918oQ
o glorious maid exalted far	https://youtu.be/HnTii3Zh5vA	https://www.youtube.com/embed/HnTii3Zh5vA
lord of creation bow thine ear o christ to hear 4v	https://youtu.be/_lpKn9PcpOQ	https://www.youtube.com/embed/_lpKn9PcpOQ
lord of creation bow thine ear o christ to hear 5v	https://youtu.be/P2z6KOmOTgg	https://www.youtube.com/embed/P2z6KOmOTgg
let the round world with songs rejoice	https://youtu.be/FnRYTI6UdsU	https://www.youtube.com/embed/FnRYTI6UdsU
the eternal gifts of Christ the king	https://youtu.be/nNd0VmOt7Kc	https://www.youtube.com/embed/nNd0VmOt7Kc
the sad apostles mourn him slain	https://youtu.be/1HpIHqF29Nc	https://www.youtube.com/embed/1HpIHqF29Nc
on that fair day of paschal joy	https://youtu.be/oL_3z1kDylI	https://www.youtube.com/embed/oL_3z1kDylI
martyr of god whose strength was steeled	https://youtu.be/Mq_iBJm7znI	https://www.youtube.com/embed/Mq_iBJm7znI
o god thy soldiers crown and guard	https://youtu.be/MNE3mtCbTc4	https://www.youtube.com/embed/MNE3mtCbTc5
the merits of the saints	https://youtu.be/H88jdj0MXo0	https://www.youtube.com/embed/H88jdj0MXo1
o glorious king of martyr hosts	https://youtu.be/phPSFaKpPQ4	https://www.youtube.com/embed/phPSFaKpPQ5
son of a virgin maker of thy mother	https://youtu.be/KT4LkVRPXI8	https://www.youtube.com/embed/KT4LkVRPXI9
jesu the virgins crown do thou	https://youtu.be/_2sujIUR_lg	https://www.youtube.com/embed/_2sujIUR_lg
he whose confession god of old accepted	https://youtu.be/uz37xZTTOog	https://www.youtube.com/embed/uz37xZTTOog
o thou whose all redeeming might	https://youtu.be/xWjretmaLag	https://www.youtube.com/embed/xWjretmaLag
o thou whose allredeeming might	https://youtu.be/xWjretmaLag	https://www.youtube.com/embed/xWjretmaLag
o thou theternal fathers word	https://youtu.be/K-I0-XvNKWo	https://www.youtube.com/embed/K-I0-XvNKWo
jesu eternal truth sublime	https://youtu.be/thVg3BRCN5s	https://www.youtube.com/embed/thVg3BRCN5s
the praises of that saint we sing	https://youtu.be/wHyGyD6hH2o	https://www.youtube.com/embed/wHyGyD6hH2o
sing we of those whom in the forst wild	https://youtu.be/2kk1p1aZXXs	https://www.youtube.com/embed/2kk1p1aZXXs
why fledst thou from all folk apart	https://youtu.be/0jv6uPQTfO8	https://www.youtube.com/embed/0jv6uPQTfO9
when the patriarch was returning	https://youtu.be/dDq_qNeu0dw	https://www.youtube.com/embed/dDq_qNeu0dw
sing o earth for thy redemption	https://youtu.be/rlSSSQq_Cv4	https://www.youtube.com/embed/rlSSSQq_Cv5
o wondrous love that rends in twain	https://youtu.be/CkPDqjTCgbo	https://www.youtube.com/embed/CkPDqjTCgbo
now my soul thy voice upraising	https://youtu.be/IdhnpUOWduc	https://www.youtube.com/embed/IdhnpUOWduc
the advent of our god	https://youtu.be/FzhxVQ388ZM	https://www.youtube.com/embed/FzhxVQ388ZM
on jordans bank the baptists cry	https://youtu.be/H04OXo8nWnI	https://www.youtube.com/embed/H04OXo8nWnI
the rolling years at length fulfil	https://youtu.be/qCYSWRlzhkU	https://www.youtube.com/embed/qCYSWRlzhkU
let sighing cease and woe	https://youtu.be/iUaIn8vSQOM	https://www.youtube.com/embed/iUaIn8vSQOM
what star is this with beams so bright	https://youtu.be/15RGpSJ05Vw	https://www.youtube.com/embed/15RGpSJ05Vw
the word who dwelt above the skies	https://youtu.be/iosynPLtzGw	https://www.youtube.com/embed/iosynPLtzGw
in stature grows the heavenly child	https://youtu.be/rWudo3vnfqo	https://www.youtube.com/embed/rWudo3vnfqo
through judahs land the saviour walks	https://youtu.be/Lt_CHCv4Wgw	https://www.youtube.com/embed/Lt_CHCv4Wgw
maker of earth to thee alone	https://youtu.be/opN9VsywR2A	https://www.youtube.com/embed/opN9VsywR2A
o lord in perfect bliss above	https://youtu.be/2XEdTWuQpeY	https://www.youtube.com/embed/2XEdTWuQpeY
how blest were they who walked in love	https://youtu.be/NxQAoUSg5R4	https://www.youtube.com/embed/NxQAoUSg5R5
o christ who dost prepare a place	https://youtu.be/Bq3IzPiPeNI	https://www.youtube.com/embed/Bq3IzPiPeNI
o saviour who from man hast trod	https://youtu.be/P9WYRxV-vqA	https://www.youtube.com/embed/P9WYRxV-vqA
disposer supreme and judge of the earth	https://youtu.be/dR1rDk1Cjy8	https://www.youtube.com/embed/dR1rDk1Cjy9
captains of the saintly band	https://youtu.be/Dj-3tzaW7f0	https://www.youtube.com/embed/Dj-3tzaW7f1
`;

export const getHymnUrl = (id: string): string | undefined => {
    return spreadsheet
        .split("\n")
        .map((row: string) => {
            return row.split("	");
        })
        .find((row: string[]) => row[0] === id)?.[2];
};
