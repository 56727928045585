import { DataResponse } from "./calendar/get-texts";
import { getPsalmIncipit } from "./calendar/lectionary";
import { Celebration } from "./calendar/ordinariate-calendar";
import { BaseCalendarDay } from "./calendar/utils";
import { getFinalAntiphon } from "./content/antiphon-sheet";
import { getCanticle } from "./content/canticles";
import { HYMNS } from "./content/hymns";
import { LiturgyItem } from "./liturgy-viewer/LiturgyViewer";
import { getOpeningSentence } from "./opening-sentence";

export const capitalizeGabcFirstWord = (gabc: string) => {
    let isInsideNeume = false;

    let charsSoFar = 0;
    let wordsSoFar = 0;
    let result = "";

    for (let charIndex = 0; charIndex < gabc.length; charIndex++) {
        const char = gabc[charIndex];

        if (char === ")") {
            isInsideNeume = false;
        }

        if (isInsideNeume) {
            result += char;
        } else {
            if (char === " " && charsSoFar > 1) {
                wordsSoFar++;
            }

            if (
                "abcdefghijklmnopqrstuvwxyz".includes(
                    char.toLocaleLowerCase()
                ) &&
                wordsSoFar < 1
            ) {
                charsSoFar++;
                result += char.toLocaleUpperCase();
            } else {
                result += char;
            }
        }

        if (char === "(") {
            isInsideNeume = true;
        }
    }
    return result;
};

export const capitalizeFirstWord = (line: string) => {
    if (line.split(" ")[0].length === 1) {
        return (
            line.split(" ")[0].toLocaleUpperCase() +
            " " +
            line.split(" ")[1].toLocaleUpperCase() +
            " " +
            line.split(" ").slice(2).join(" ")
        );
    } else {
        return (
            line.split(" ")[0].toLocaleUpperCase() +
            " " +
            line.split(" ").slice(1).join(" ")
        );
    }
};

const getVAndR = (vAndR: string[], isEastertide: boolean) => {
    return vAndR.map((v, vi) => {
        if (isEastertide) {
            if (v[v.length - 1] === ".") {
                v = v.slice(0, v.length - 1) + ", al_le_lu_ia.";
            } else {
                v += " Al_le_lu_ia.";
            }
        }

        return v.replace(/_/g, "").replace("/", "").replace(" ' ", " ");
    });
};

export const getOrdinariateLiturgy = (
    day: BaseCalendarDay,
    hour: "first-evensong" | "mattins" | "second-evensong",
    celebration: Celebration,
    texts: DataResponse | undefined,
    selectedOtCanticle: string,
    onSelectOtCanticle: (newSelection: string) => void,
    officiantType: string,
    onSelectOfficiantType: (newSelection: string) => void,
    antiphonOption: string,
    onSelectAntiphonOption: (newOption: string) => void,
    selectedCommon: string,
    onSelectCommon: (newCommon: string) => void,
    selectedPsalms: string,
    onSelectPsalms: (newPsalms: string) => void,
    isAdvent: boolean,
    isLent: boolean,
    isEastertide: boolean,
    simplifiedHaecDies: string,
    onSelectSimplifiedHaecDies: (newOption: string) => void,
    includeIntroduction: string,
    onSelectIncludeIntroduction: (newOption: string) => void,
    selectedCollect: string,
    onSelectCollect: (newOption: string) => void
) => {
    const isTriduum =
        celebration.label === "maundy-thursday" ||
        celebration.label === "good-friday" ||
        celebration.label === "holy-saturday";

    const isPassiontide =
        celebration.title.includes("Passion Sunday") ||
        celebration.title.includes("Holy Week") ||
        isTriduum;

    const psalms = texts
        ? hour === "mattins"
            ? texts.psalms.find(
                  (p) => p.title === selectedPsalms || selectedPsalms === ""
              )?.psalms.morning
            : texts.psalms.find(
                  (p) => p.title === selectedPsalms || selectedPsalms === ""
              )?.psalms.evening
        : undefined;

    const firstLesson = texts
        ? hour === "mattins"
            ? texts.lessons.morningFirstLesson
            : texts.lessons.eveningFirstLesson
        : undefined;

    const secondLesson = texts
        ? hour === "mattins"
            ? texts.lessons.morningSecondLesson
            : texts.lessons.eveningSecondLesson
        : undefined;

    const otCanticleOptions: {
        type: "psalm or canticle";
        title: string;
        lines: string[][];
        ant: string | undefined;
    }[] = [];

    const teDeumProhibited =
        day.collectId === "ash-wednesday" ||
        day.collectId.includes("gesima") ||
        day.collectId.includes("lent") ||
        (day.collectId.includes("advent") &&
            day.collectId !== "sunday-before-advent") ||
        celebration.title === "Rogation Monday";
    const teDeumRequired =
        celebration.title !== "Rogation Monday" &&
        ((day.dayOfTheWeek === "sun" && !teDeumProhibited) ||
            celebration.type === "solemnity" ||
            celebration.type === "feast" ||
            day.collectId.includes("easter") ||
            day.collectId.includes("ascension") ||
            day.collectId.includes("whitsun") ||
            day.collectId.includes("christmas"));

    const pushCanticle = (id: string, addGloriaPatri: boolean) => {
        const canticle = getCanticle(id, addGloriaPatri);

        otCanticleOptions.push({
            type: "psalm or canticle",
            title: canticle.title ?? "",
            lines: canticle.lines,
            ant: undefined,
        });
    };

    if (teDeumRequired) {
        pushCanticle("te deum", false);
    } else {
        let canticleDay: string = day.dayOfTheWeek;

        if (canticleDay === "sat") {
            if (
                day.collectId.includes("gesima") ||
                day.collectId.includes("lent") ||
                day.collectId.includes("easter-eve") ||
                day.collectId.includes("ash-wednesday")
            ) {
                canticleDay = "sat-b";
            } else {
                canticleDay = "sat-a";
            }
        }

        if (!teDeumProhibited || celebration.type === "memorial") {
            pushCanticle("te deum", false);
        }

        pushCanticle("benedicte", !isTriduum);

        pushCanticle(canticleDay, !isTriduum);
    }

    const items: LiturgyItem[] = [];

    if (celebration.commons) {
        items.push({
            type: "rubric",
            content: "Supplementary material is taken from the Commons:",
        });

        items.push({
            type: "picker",
            options: celebration.commons,
            selectedOption: selectedCommon,
            onChange: onSelectCommon,
        });

        items.push({ type: "spacer" });
    }

    items.push({
        type: "picker",
        options: ["Include Introduction", "Omit"],
        selectedOption: includeIntroduction,
        onChange: onSelectIncludeIntroduction,
    });
    if (includeIntroduction === "Include Introduction") {
        items.push({ type: "spacer" });
        items.push(...getOpeningSentence(day, "mattins"));

        items.push({
            type: "rubric",
            content:
                "Then the Officiant shall say the following **Exhortation.**",
        });
        items.push({
            type: "text",
            content: `Dearly beloved brethren, the Scripture moveth us, in sundry
            places, to acknowledge and confess our manifold sins and
            wickedness; and that we should not dissemble nor cloak them
            before the face of Almighty God our heavenly Father; but confess
            them with an humble, lowly, penitent, and obedient heart; to the
            end that we may obtain forgiveness of the same, by his infinite
            goodness and mercy. And although we ought, at all times, humbly
            to acknowledge our sins before God; yet ought we most chiefly so
            to do, when we assemble and meet together to render thanks for
            the great benefits that we have received at his hands, to set
            forth his most worthy praise, to hear his most holy Word, and to
            ask those things which are requisite and necessary, as well for
            the body as the soul. Wherefore I pray and beseech you, as many
            as are here present, to accompany me with a pure heart, and
            humble voice, unto the throne of the heavenly grace, saying after me:`,
            dropCap: true,
        });
        items.push({
            type: "rubric",
            content: "All then, kneeling, say:",
        });
        items.push({
            type: "text",
            content: `A**lmighty and most merciful Father; We have erred, and strayed
            from thy ways like lost sheep. We have followed too much the
            devices and desires of our own hearts. We have offended against
            thy holy laws. We have left undone those things which we ought
            to have done; And we have done those things which we ought not
            to have done; And there is no health in us. But thou, O Lord,
            have mercy upon us, miserable offenders. Spare thou them, O God,
            who confess their faults. Restore thou those who are penitent;
            According to thy promises declared unto mankind in Christ Jesu
            our Lord. And grant, O most merciful Father, for his sake; That
            we may hereafter live a godly, righteous, and sober life, To the
            glory of thy holy Name. Amen.**`,
            dropCap: true,
        });

        items.push({
            type: "picker",
            options: ["Lay officiant", "Priest officiant"],
            selectedOption: officiantType,
            onChange: onSelectOfficiantType,
        });

        if (officiantType === "Priest officiant") {
            items.push({
                type: "rubric",
                content:
                    "The Officiant, if he is a Priest, stands and reads the following prayer:",
            });
            items.push({
                type: "text",
                content: `Almighty God, the Father of our Lord Jesus Christ, who
                    desireth not the death of a sinner, but rather that he
                    may turn from his wickedness, and live; and hath given
                    power, and commandment, to his Ministers, to declare and
                    pronounce to his people, being penitent, the Absolution
                    and Remission of their sins: He pardoneth and absolveth
                    all them that truly repent, and unfeignedly believe his
                    holy Gospel. Wherefore let us beseech him to grant us
                    true repentance, and his Holy Spirit, that those things
                    may please him, which we do at this present; and that
                    the rest of our life hereafter may be pure, and holy; so
                    that at the last we may come to his eternal joy; through
                    Jesus Christ our Lord. **Amen.**`,
                dropCap: true,
            });
        } else {
            items.push({
                type: "rubric",
                content:
                    "If the Officiant is not a Priest, the Collect for the Twenty-First Sunday after Trinity is said, all kneeling.",
            });
            items.push({
                type: "text",
                content: `Grant, we beseech thee, merciful Lord, to thy faithful
                    people pardon and peace, that they may be cleansed from
                    all their sins, and serve thee with a quiet mind;
                    through Jesus Christ thy Son our Lord, who liveth and
                    reigneth with thee, in the unity of the Holy Ghost, ever
                    one God, world without end. **Amen.**`,
                dropCap: true,
            });
        }

        items.push({
            type: "rubric",
            content: "Then, all kneeling, say:",
        });
        items.push({
            type: "text",
            content:
                "O**UR Father, who art in heaven, hallowed be thy Name, thy kingdom come, thy will be done, on earth as it is in heaven. Give us this day our daily bread. And forgive us our trespasses, as we forgive those who trespass against us. And lead us not into temptation, but deliver us from evil. Amen.**",
            dropCap: true,
        });
    }
    items.push({ type: "spacer" });

    if (!isTriduum) {
        items.push({
            type: "rubric",
            content: "The Officiant shall say:",
        });

        items.push({
            type: "v and r",
            lines: [
                "✠ O Lord open thou our lips.",
                "And our mouth shall show forth thy praise.",
                "✠ O God, make speed to save us.",
                "O Lord, make haste to help us.",
            ]
                .concat(
                    isTriduum
                        ? [] // no gloria patri in triduum
                        : [
                              "Glory be to the Father, and to the Son, and to the Holy Ghost.",
                              "As it was in the beginning, is now, and ever shall be, world without end. Amen.",
                          ]
                )
                .concat(["Praise ye the Lord.", "The Lord's name be praised."]),
        });

        items.push({ type: "spacer" });

        if (hour === "mattins") {
            // Mattins so add the venite or pascha nostrum

            if (
                celebration.title === "Easter Day" ||
                celebration.title.includes("Octave of Easter")
            ) {
                items.push({
                    type: "rubric",
                    content:
                        "Instead of the Venite the following **Anthems** are said at Morning Prayer of Easter Day and through the Octave:",
                });
                items.push({
                    type: "text",
                    content: "TODO: Pasca nostrum",
                });
                items.push({ type: "spacer" });
            } else {
                items.push({
                    type: "rubric",
                    content: `The **${
                        day.date === 19 ? "Jubliate Deo" : "Venite"
                    }** is recited, and may be accompanied by the appointed Antiphon.`,
                });

                if (day.date === 19) {
                    items.push({
                        type: "psalm or canticle",
                        title: "Jubilate Deo",
                        lines: getCanticle("jubilate", !isPassiontide).lines,
                        ant: getFinalAntiphon(celebration.invId),
                    });
                } else {
                    items.push({
                        type: "psalm or canticle",
                        title: "Venite",
                        lines: getCanticle("simple venite", !isPassiontide)
                            .lines,
                        ant: getFinalAntiphon(celebration.invId),
                    });
                }
            }
        }
    } else {
        items.push({
            type: "rubric",
            content: `The **Venite** is not sung during the days of the Sacred Paschal Triduum, and the Gloria Patri is omitted throughout the office.`,
        });
    }

    items.push({
        type: "spacer",
    });

    items.push({
        type: "rubric",
        content: `The **Appointed_Psalms** are sung.`,
    });

    if (texts) {
        items.push({
            type: "picker",
            options: texts.psalms.map((p) => p.title),
            selectedOption:
                selectedPsalms.length === 0
                    ? texts.psalms[0].title
                    : selectedPsalms,
            onChange: onSelectPsalms,
        });
    }

    if (psalms !== undefined) {
        psalms.forEach((psalm) => {
            items.push({
                type: "psalm or canticle",
                title: `Psalm ${psalm.ref}`,
                lines: psalm.sections.lines,
                ant: undefined,
                incipit: getPsalmIncipit(psalm.ref),
            });
        });
    } else {
        items.push({ type: "loading" });
    }

    items.push({ type: "spacer" });

    items.push({
        type: "rubric",
        content: "The **First_Lesson** is_read.",
    });

    items.push({
        type: "link",
        title: firstLesson?.ref ?? "",
        url:
            `https://www.biblegateway.com/passage/?search=${encodeURIComponent(
                firstLesson?.ref ?? ""
            )}` + `&version=${"RSVCE"}`,
    });
    items.push({
        type: "text",
        content: "Here endeth the first lesson.",
    });

    // if (firstLesson) {
    //     items.push({
    //         type: "scripture",
    //         title: firstLesson.ref,
    //         lesson: firstLesson,
    //     });
    //     items.push({
    //         type: "text",
    //         content: "Here endeth the first lesson.",
    //     });
    // }

    items.push({ type: "spacer" });

    if (hour === "mattins") {
        // Mattins so add the te deum
        items.push({
            type: "rubric",
            content: `The **First Canticle** is recited.`,
        });

        const selectedTitle =
            selectedOtCanticle === ""
                ? otCanticleOptions[0].title
                : selectedOtCanticle;

        if (otCanticleOptions) {
            items.push({
                type: "picker",
                options: otCanticleOptions.map((opt) => opt.title),
                selectedOption: selectedTitle,
                onChange: onSelectOtCanticle,
            });

            const selectedCanticle = otCanticleOptions.find(
                (c) => c.title === selectedTitle
            );

            if (selectedCanticle) {
                items.push({
                    type: "psalm or canticle",
                    title: selectedCanticle.title,
                    lines: selectedCanticle.lines,
                    ant: undefined,
                });
            }

            items.push({ type: "spacer" });
        }
    }

    if (hour !== "mattins") {
        // Evensong so add the hymn and magnificat

        if (isTriduum) {
            items.push({
                type: "rubric",
                content: `No **Office Hymn** is sung during the days of the Sacred Paschal Triduum.`,
            });

            items.push({
                type: "spacer",
            });
        } else if (
            celebration.title === "Easter Day" ||
            celebration.title.includes("Octave of Easter")
        ) {
            items.push({
                type: "rubric",
                content:
                    "Throughout the Octave of Easter, the Hymn and V&R are replaced by the **Hæc Dies**.",
            });

            items.push({
                type: "picker",
                options: ["Full", "Simplified"],
                onChange: onSelectSimplifiedHaecDies,
                selectedOption: simplifiedHaecDies,
            });

            items.push({ type: "text", content: "TODO: haec dies" });

            items.push({
                type: "spacer",
            });
        } else {
            items.push({
                type: "rubric",
                content:
                    "The **Office_Hymn,** together with its Versicle and Response, may be sung.",
            });

            if (hour === "first-evensong" && celebration.ep1VandR) {
                const hymn = HYMNS[celebration.ep1HymnId ?? ""];

                if (hymn && celebration.ep1HymnId) {
                    items.push({
                        type: "hymn",
                        id: celebration.ep1HymnId,
                        title: hymn.title,
                        text: hymn.text,
                    });
                } else {
                    console.log("HYMN NOT FOUND", celebration.ep1HymnId);
                }

                items.push({
                    type: "v and r",
                    lines: getVAndR(celebration.ep1VandR, isEastertide),
                });
            }

            if (hour === "second-evensong" && celebration.ep2VandR) {
                const hymn = HYMNS[celebration.ep2HymnId ?? ""];

                if (hymn && celebration.ep2HymnId) {
                    items.push({
                        type: "hymn",
                        id: celebration.ep2HymnId,
                        title: hymn.title,
                        text: hymn.text,
                    });
                } else {
                    console.log("HYMN NOT FOUND", celebration.ep2HymnId);
                }

                items.push({
                    type: "v and r",
                    lines: getVAndR(celebration.ep2VandR, isEastertide),
                });
            }

            items.push({ type: "spacer" });
        }

        items.push({
            type: "rubric",
            content:
                "The **Magnificat** is recited, and may be accompanied by the appointed Antiphon.",
        });

        items.push({
            type: "psalm or canticle",
            title: "Magnificat",
            lines: getCanticle("magnificat", !isTriduum).lines,
            ant: getFinalAntiphon(
                hour === "first-evensong"
                    ? celebration.ep1MagId
                    : celebration.ep2MagId + (isEastertide ? " [easter]" : "")
            ),
        });

        items.push({ type: "spacer" });
    }

    items.push({
        type: "rubric",
        content: "The **Second_Lesson** is_read.",
    });

    items.push({
        type: "link",
        title: secondLesson?.ref ?? "",
        url:
            `https://www.biblegateway.com/passage/?search=${encodeURIComponent(
                secondLesson?.ref ?? ""
            )}` + `&version=${"RSVCE"}`,
    });
    items.push({
        type: "text",
        content: "Here endeth the second lesson.",
    });

    // if (secondLesson) {
    //     items.push({
    //         type: "scripture",
    //         title: secondLesson.ref,
    //         lesson: secondLesson,
    //     });
    //     items.push({
    //         type: "text",
    //         content: "Here endeth the second lesson.",
    //     });
    // }

    items.push({ type: "spacer" });

    if (hour === "mattins") {
        // Mattins so add the hymn and benedictus

        if (isTriduum) {
            items.push({
                type: "rubric",
                content: `No **Office Hymn** is sung during the days of the Sacred Paschal Triduum.`,
            });

            items.push({
                type: "spacer",
            });
        } else if (
            celebration.title === "Easter Day" ||
            celebration.title.includes("Octave of Easter")
        ) {
            items.push({
                type: "rubric",
                content:
                    "Throughout the Octave of Easter, the Hymn and V&R are replaced by the **Hæc Dies**.",
            });

            items.push({
                type: "picker",
                options: ["Full", "Simplified"],
                onChange: onSelectSimplifiedHaecDies,
                selectedOption: simplifiedHaecDies,
            });

            items.push({ type: "text", content: "TODO: haec dies" });

            items.push({
                type: "spacer",
            });
        } else {
            items.push({
                type: "rubric",
                content:
                    "The **Office_Hymn,** together with its Versicle and Response, may be sung.",
            });

            if (celebration.mpVandR) {
                const hymn = HYMNS[celebration.mpHymnId ?? ""];

                if (hymn && celebration.mpHymnId) {
                    items.push({
                        type: "hymn",
                        id: celebration.mpHymnId,
                        title: hymn.title,
                        text: hymn.text,
                    });
                } else {
                    console.log("HYMN NOT FOUND", celebration.mpHymnId);
                }

                items.push({
                    type: "v and r",
                    lines: getVAndR(celebration.mpVandR, isEastertide),
                });
            }

            items.push({ type: "spacer" });
        }

        items.push({
            type: "rubric",
            content:
                "The **Benedictus** is recited, and may be accompanied by the appointed Antiphon.",
        });

        items.push({
            type: "psalm or canticle",
            title: "Benedictus",
            lines: getCanticle("benedictus", !isTriduum).lines,
            ant: getFinalAntiphon(
                celebration.mpBenId + (isEastertide ? " [easter]" : "")
            ),
        });

        items.push({ type: "spacer" });
    }

    if (hour !== "mattins") {
        // Evensong so add the nunc

        items.push({
            type: "rubric",
            content: "The **Nunc Dimittis** is recited.",
        });

        items.push({
            type: "psalm or canticle",
            title: "Nunc Dimittis",
            lines: getCanticle("nunc dimittis", !isTriduum).lines,
            ant: undefined,
        });

        items.push({ type: "spacer" });
    }

    if (celebration.athanasianCreed && hour === "mattins") {
        items.push({
            type: "rubric",
            content: "On this day, the **Athanasian Creed** is said.",
        });

        items.push({
            type: "psalm or canticle",
            title: "Quicunque vult",
            lines: getCanticle("quicunque vult", !isTriduum).lines,
            ant: undefined,
        });
    } else {
        items.push({
            type: "rubric",
            content:
                "The **Apostle's Creed** shall be said by the Officiant and People standing.",
        });
        items.push({
            type: "text",
            content: `I **BELIEVE in God the Father Almighty, Maker of heaven and
                earth; and in Jesus Christ, his only Son our Lord; Who was
                conceived by the Holy Ghost; Born of the Virgin Mary; Suffered
                under Pontius Pilate; Was crucified, dead, and buried; He
                descended into hell; The third day he rose again from the dead;
                He ascended into heaven, And sitteth on the right hand of God
                the Father Almighty; From thence he shall come to judge the
                quick and the dead. I believe in the Holy Ghost; The holy
                Catholic Church; The Communion of Saints; The Forgiveness of
                sins; The Resurrection of the body; *✠* And the life everlasting.
                Amen.**`,
            dropCap: true,
        });
    }

    items.push({
        type: "rubric",
        content: "And afterward these Prayers are said, all devoutly kneeling.",
    });
    items.push({
        type: "picker",
        options: ["Lay officiant", "Priest officiant"],
        selectedOption: officiantType,
        onChange: onSelectOfficiantType,
    });
    if (officiantType === "Priest officiant") {
        items.push({
            type: "v and r",
            lines: [
                "The Lord be with you.",
                "And with thy spirit.",
                "Let us pray.",
            ],
        });
    } else {
        items.push({
            type: "v and r",
            lines: [
                "O Lord, hear our prayer.",
                "And let our cry come unto thee.",
                "Let us pray.",
            ],
        });
    }

    items.push({
        type: "v and r",
        lines: [
            "Lord have mercy upon us.",
            "Christ have mercy upon us.",
            "Lord have mercy upon us.",
        ],
    });

    items.push({
        type: "rubric",
        content:
            "Then the Officiant and People shall say the **Lord's Prayer.**",
    });
    items.push({
        type: "text",
        content:
            "O**UR Father, who art in heaven, hallowed be thy Name, thy kingdom come, thy will be done, on earth as it is in heaven. Give us this day our daily bread. And forgive us our trespasses, as we forgive those who trespass against us. And lead us not into temptation, but deliver us from evil. Amen.**",
        dropCap: true,
    });

    items.push({
        type: "rubric",
        content: "Then shall follow these **Versicles_and_Responses.**",
    });
    items.push({
        type: "v and r",
        lines: [
            "O Lord, show thy mercy upon us",
            "And grant us thy salvation.",
            "O Lord, save the King.",
            "And mercifully hear us when we call upon thee.",
            "Endue thy Ministers with righteousness.",
            "And make thy chosen people joyful.",
            "O Lord, save thy people.",
            "And bless thine inheritance.",
            "Give peace in our time, O Lord.",
            "Because there is none other that fighteth for us, but only thou, O God.",
            "O God, make clean our hearts within us.",
            "And take not thy Holy Spirit from us.",
        ],
    });
    items.push({ type: "spacer" });

    items.push({
        type: "rubric",
        content: `The **Collect of the Day** is_said.`,
    });

    if (celebration.alternativeCollects) {
        items.push({
            type: "picker",
            options: [celebration.collect]
                .concat(celebration.alternativeCollects)
                .map(
                    (collect, collectIndex) =>
                        collectIndex +
                        1 +
                        ". " +
                        collect?.split(" ").slice(0, 2).join(" ") +
                        " ..."
                ),
            onChange: onSelectCollect,
            selectedOption: selectedCollect,
        });
    }

    [celebration.collect ?? ""]
        .concat(celebration.alternativeCollects ?? [])
        .find(
            (c, cIndex) =>
                cIndex +
                    1 +
                    ". " +
                    c?.split(" ").slice(0, 2).join(" ") +
                    " ..." ===
                selectedCollect
        )
        ?.split("//")
        .forEach((collect, ci) => {
            if (ci > 0) {
                items.push({
                    type: "rubric",
                    content: `This Collect is also said.`,
                });
            }

            items.push({
                type: "text",
                content:
                    (collect.length > 0 ? collect : undefined)?.replace(
                        ". Amen.",
                        ". **Amen.**"
                    ) ?? "Not found",
                dropCap: true,
            });
        });

    if (isLent && celebration.title !== "Ash Wednesday") {
        items.push({
            type: "rubric",
            content: `Throughout Lent, the **Collect for Ash Wednesday** is_said.`,
        });
        items.push({
            type: "text",
            content:
                "Almighty and everlasting God, who hatest nothing that thou hast made and dost forgive the sins of all those who are penitent: create and make in us new and contrite hearts, that we, worthily lamenting our sins and acknowledging our wretchedness, may obtain of thee, the God of all mercy, perfect remission and forgiveness; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. **Amen.**",
            dropCap: true,
        });
    }

    if (
        isAdvent &&
        celebration.collect !==
            "Almighty God, give us grace that we may cast away the works of darkness, and put upon us the armour of light, now in the time of this mortal life, in which thy Son Jesus Christ came to visit us in great humility; that in the last day, when he shall come again in his glorious majesty, to judge both the quick and the dead; we may rise to the life immortal; through the same Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen."
    ) {
        items.push({
            type: "rubric",
            content: `Throughout Advent, the **Collect for Advent I** is_said.`,
        });
        items.push({
            type: "text",
            content:
                "Almighty God, give us grace that we may cast away the works of darkness, and put upon us the armour of light, now in the time of this mortal life, in which thy Son Jesus Christ came to visit us in great humility; that in the last day, when he shall come again in his glorious majesty, to judge both the quick and the dead; we may rise to the life immortal; through the same Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. **Amen.**",
            dropCap: true,
        });
    }

    if (hour === "mattins") {
        items.push({
            type: "rubric",
            content: "The **Collect_for_Peace** is said.",
        });
        items.push({
            type: "text",
            content: `O GOD, who art the author of peace and lover of concord, in knowledge of whom standeth our eternal life, whose service is perfect freedom: defend us thy humble servants in all assaults of our enemies; that we, surely trusting in thy defence, may not fear the power of any adversaries; through the might of Jesus Christ our Lord. **Amen.**`,
            dropCap: true,
        });

        items.push({
            type: "rubric",
            content: "The **Collect_for_Grace** is said.",
        });
        items.push({
            type: "text",
            content: `O LORD, our heavenly Father, Almighty and everlasting God, who
                hast safely brought us to the beginning of this day; Defend us
                in the same with thy mighty power; and grant that this day we
                fall into no sin, neither run into any kind of danger; but that
                all our doings may be ordered by thy governance, to do always
                that is righteous in thy sight; through Jesus Christ our
                Lord. **Amen.**`,
            dropCap: true,
        });
    } else {
        items.push({
            type: "rubric",
            content: "The **Collect_for_Peace** is said.",
        });
        items.push({
            type: "text",
            content: `O GOD, from whom all holy desires, all good counsels, and all
            just works do proceed; Give unto thy servants that peace which
            the world cannot give; that both our hearts may be set to obey
            thy commandments, and also that by thee, we, being defended from the
            fear of our enemies, may pass our time in rest and quietness;
            through the merits of Jesus Christ our Saviour. **Amen.**`,
            dropCap: true,
        });

        items.push({
            type: "rubric",
            content: "The **Collect_for_Aid_Against_all_Perils** is said.",
        });
        items.push({
            type: "text",
            content: `LIGHTEN our darkness, we beseech thee, O Lord; and by thy great
                mercy defend us from all perils and dangers of this night; for
                the love of thy only Son, our Saviour, Jesus Christ. **Amen.**`,
            dropCap: true,
        });
    }

    items.push({
        type: "rubric",
        content:
            "In quires and places where they sing, an Anthem may follow. Then these five prayers may be read, or at least the Prayer of Saint Chrysostom and the Grace.",
    });

    const cfeThursday =
        "(c4) Christ(f/fgf) $(,) be(f)came(fg) for(f) us(ghF'ED.g_e/fgf.) (;) o(f)be(hf/gh!jj/kjk)di(jij)ent(h_g) (,) e(h!jjh)ven(f.) un(ixfiH'Gh)to(f) death.(f_g_f_) (::)";
    const cfeFriday =
        "(c4) Christ(f/fgf) $(,) be(f)came(fg) for(f) us(ghF'ED.g_e/fgf.) (;) o(f)be(hf/gh!jj/kjk)di(jij)ent(h_g) (,) e(h!jjh)ven(f.) un(ixfiH'Gh)to(f) death,(f_g_f_) (:) death(ixf!hhig) on(ixgjj.h!iw!jvIG'hw!ivHG) a(f) cross.(fv.fff_c) (,) (fg!hvGF.e!g'h//fhg/hggf.) (::)";
    const pqed =
        "(c3) Where(d)fore(d) God(fe) hath(eh) high(h)ly(h) ex(h)al(hihhe./h_i_h_/ihhe.,hi!kv//lvKI'jvHF.,h_g/ijh'___ jvIH)ted(jvIH) him,(hvGF.) (:) and(f) gi(f!h'i)ven(i) him(ih/jkihhf.) (,) a(hf) name(hhhh.f!gwh!iv.hi/jhh/iih.) (;) which(d) is(d) a(d)bove(dfE'D/ef) ev(fhG'F)ery(hhh) name.(hhf.) (,) (gxg_fgvED.fgED.fehv.hhhff//dfe/feed.) (::)";

    items.push({ type: "rubric", content: "A Prayer for the King's Majesty" });
    items.push({
        type: "text",
        content:
            "O LORD, our heavenly Father, high and mighty, King of kings, Lord of lords, the only Ruler of princes, who dost from thy throne behold all the dwellers upon earth: Most heartily we beseech thee with thy favour to behold our most gracious Sovereign Lord, King Charles; and so replenish him with the grace of thy Holy Spirit, that he may alway incline to thy will, and walk in thy way. Endue him plenteously with heavenly gifts; grant him in health and wealth long to live; strengthen him that he may vanquish and overcome all his enemies; and finally after this life he may attain everlasting joy and felicity; through Jesus Christ our Lord. **Amen.**",
        dropCap: true,
    });

    items.push({ type: "rubric", content: "A Prayer for the Royal Family" });
    items.push({
        type: "text",
        content:
            "ALMIGHTY God, the fountain of all goodness, we humbly beseech thee to bless Queen Camilla, William Prince of Wales, the Princess of Wales, and all the Royal Family: Endue them with thy Holy Spirit; enrich them with thy heavenly grace; prosper them with all happiness; and bring them to thine everlasting kingdom; through Jesus Christ our Lord. **Amen.**",
        dropCap: true,
    });
    items.push({ type: "rubric", content: "Or in the Commonwealth:" });
    items.push({
        type: "text",
        content:
            "ALMIGHTY God, the fountain of all goodness, we humbly beseech thee to bless our Sovereign Lord, King Charles, the Parliaments of the Commonwealth, and all who are set in authority under him; that they may order all things in wisdom, righteousness, and peace, to the honour of thy holy Name, and the good of thy Church and people; through Jesus Christ our Lord. **Amen.**",
        dropCap: true,
    });

    items.push({
        type: "rubric",
        content: "A Prayer for the Clergy and People",
    });
    items.push({
        type: "text",
        content:
            "ALMIGHTY and everlasting God, who alone workest great marvels: Send down upon our Bishops, and Curates, and all Congregations committed to their charge, the healthful Spirit of thy grace; and that they may truly please thee, pour upon them the continual dew of thy blessing. Grant this, O Lord, for the honour of our Advocate and Mediator, Jesus Christ. **Amen.**",
        dropCap: true,
    });

    items.push({
        type: "rubric",
        content: "A Prayer of Saint Chrysostom",
    });
    items.push({
        type: "text",
        content:
            "ALMIGHTY God, who hast given us grace at this time with one accord to make our common supplications unto thee; and dost promise that when two or three are gathered together in thy Name thou wilt grant their requests: Fulfil now, O Lord, the desires and petitions of thy servants, as may be most expedient for them; granting us in this world knowledge of thy truth, and in the world to come life everlasting. **Amen.**",
        dropCap: true,
    });

    items.push({
        type: "rubric",
        content: "The Grace",
    });
    items.push({
        type: "text",
        content:
            "THE grace *✠* of our Lord Jesus Christ, and the love of God, and the fellowship of the Holy Ghost, be with us all evermore. **Amen.**",
        dropCap: true,
    });

    items.push({ type: "spacer" });

    items.push({
        type: "rubric",
        content: `Here ends the Order of ${
            hour === "mattins" ? "Morning" : "Evening"
        } Prayer.`,
    });

    items.push({ type: "spacer", big: true });

    return items;
};
