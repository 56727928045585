import React, { useEffect } from "react";
import { COMMONS } from "../calendar/commons";
import { getOptionsOrd } from "../calendar/lectionary";
import { getMorningAndEveningCelebrations } from "../calendar/ordinariate-calendar";
import { getCalendarDay } from "../calendar/utils";
import { OrdoItem } from "./OrdoItem";

const Row: React.FC<{ label: string; children: JSX.Element | string }> = (
    props
) => {
    return (
        <div
            style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "flex-start",
                gap: 8,
                paddingLeft: 16,
            }}
        >
            <div style={{ minWidth: 100, fontWeight: "bold" }}>
                {props.label}
            </div>
            <div style={{ fontSize: "85%", whiteSpace: "pre-wrap" }}>
                {props.children === "MISSING" ? (
                    <span style={{ backgroundColor: "#F99" }}>MISSING</span>
                ) : (
                    props.children
                )}
            </div>
        </div>
    );
};

export const Ordo: React.FC<{}> = () => {
    const isEpiphanyTransferred = false;
    const isAscensionTransferred = false;
    const isCorpusChristiTransferred = false;

    const year = getMorningAndEveningCelebrations(
        new Date().getFullYear(),
        isEpiphanyTransferred,
        isAscensionTransferred,
        isCorpusChristiTransferred
    ).slice(365);

    useEffect(() => {
        document
            .getElementById(
                `${new Date().getFullYear()}-${
                    new Date().getMonth() + 1
                }-${new Date().getDate()}`
            )
            ?.scrollIntoView();
    }, []);

    return (
        <>
            {year.map((liturgicalDay, dayIndex) => {
                const prevLiturgicalDay = year[dayIndex - 1];

                const calendarDay = getCalendarDay(
                    liturgicalDay.year,
                    liturgicalDay.month,
                    liturgicalDay.date,
                    false
                );
                const prevCalendarDay = prevLiturgicalDay
                    ? getCalendarDay(
                          prevLiturgicalDay.year,
                          prevLiturgicalDay.month,
                          prevLiturgicalDay.date,
                          false
                      )
                    : undefined;

                const isEastertide =
                    liturgicalDay.morningOptions
                        .concat(liturgicalDay.morningOutranked)
                        ?.find(
                            (celebration) =>
                                celebration.title.includes("Easter") ||
                                celebration.title.includes("Ascension") ||
                                celebration.title === "Rogation Monday" ||
                                celebration.title === "Rogation Tuesday" ||
                                celebration.title === "Rogation Wednesday" ||
                                celebration.title === "Rogation Thursday" ||
                                celebration.title === "Rogation Friday" ||
                                celebration.title === "Rogation Saturday" ||
                                celebration.title.includes("Whit") ||
                                celebration.title.includes("Pentecost")
                        ) !== undefined &&
                    liturgicalDay.morningOptions
                        .concat(liturgicalDay.morningOutranked)
                        ?.find((celebration) =>
                            celebration.title.includes("Trinity Sunday")
                        ) === undefined;

                return (
                    <>
                        {liturgicalDay.date === 1 ? (
                            <div
                                style={{
                                    fontSize: "180%",
                                    fontWeight: "bold",
                                    padding: 12,
                                    borderBottom: "1px solid silver",
                                    textAlign: "center",
                                }}
                            >
                                {
                                    [
                                        "",
                                        "January",
                                        "February",
                                        "March",
                                        "April",
                                        "May",
                                        "June",
                                        "July",
                                        "August",
                                        "September",
                                        "October",
                                        "November",
                                        "December",
                                    ][liturgicalDay.month]
                                }{" "}
                                {liturgicalDay.year}
                            </div>
                        ) : (
                            <></>
                        )}
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "flex-start",
                                borderBottom: "3px solid silver",
                            }}
                            id={`${liturgicalDay.year}-${liturgicalDay.month}-${liturgicalDay.date}`}
                        >
                            <div style={{}}>
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "flex-start",
                                    }}
                                >
                                    <div
                                        style={{
                                            width: 48,
                                            display: "flex",
                                            flexDirection: "column",
                                            alignItems: "center",
                                            flexShrink: 0,
                                        }}
                                    >
                                        <div
                                            style={{
                                                textTransform: "uppercase",
                                                fontSize: "80%",
                                                fontWeight: "bold",
                                                paddingTop: 8,
                                                paddingBottom: 2,
                                            }}
                                        >
                                            {liturgicalDay.day.slice(0, 3)}
                                        </div>
                                        <div
                                            style={{
                                                fontSize: "200%",
                                                lineHeight: 0.8,
                                            }}
                                        >
                                            {liturgicalDay.date}
                                        </div>
                                        <div
                                            style={{
                                                textTransform: "uppercase",
                                                fontSize: "80%",
                                                fontWeight: "bold",
                                            }}
                                        >
                                            {
                                                [
                                                    "",
                                                    "Jan",
                                                    "Feb",
                                                    "Mar",
                                                    "Apr",
                                                    "May",
                                                    "Jun",
                                                    "Jul",
                                                    "Aug",
                                                    "Sep",
                                                    "Oct",
                                                    "Nov",
                                                    "Dec",
                                                ][liturgicalDay.month]
                                            }
                                        </div>
                                        <div
                                            style={{
                                                paddingTop: 12,
                                                fontSize: "150%",
                                                lineHeight: 0.8,
                                            }}
                                        >
                                            AM
                                        </div>
                                    </div>
                                    <div
                                        style={{
                                            flexGrow: 1,
                                            padding: 8,
                                            paddingLeft: 12,
                                            display: "flex",
                                            flexDirection: "column",
                                            gap: 16,
                                            paddingBottom: 16,
                                        }}
                                    >
                                        {liturgicalDay.morningOptions
                                            .flatMap((celebration) => {
                                                if (
                                                    celebration.commons
                                                        ?.length ??
                                                    0 > 0
                                                ) {
                                                    return (
                                                        celebration.commons?.map(
                                                            (common) => {
                                                                return {
                                                                    ...(celebration.specialManner
                                                                        ? {}
                                                                        : {
                                                                              ep1HymnId:
                                                                                  "MISSING",
                                                                              ep1MagId:
                                                                                  "MISSING",
                                                                              invId: "MISSING",
                                                                              mpHymnId:
                                                                                  "MISSING",
                                                                              mpBenId:
                                                                                  "MISSING",
                                                                              ep2HymnId:
                                                                                  "MISSING",
                                                                              ep2MagId:
                                                                                  "MISSING",
                                                                          }),
                                                                    ...COMMONS[
                                                                        common
                                                                    ],
                                                                    ...celebration,
                                                                    title:
                                                                        celebration.title +
                                                                        " (Common of " +
                                                                        common +
                                                                        ")",
                                                                };
                                                            }
                                                        ) ?? []
                                                    );
                                                } else {
                                                    return [celebration];
                                                }
                                            })
                                            .map((opt) => {
                                                return (
                                                    <OrdoItem
                                                        celebration={opt}
                                                        hour="mattins"
                                                        calendarDay={
                                                            calendarDay
                                                        }
                                                        isEastertide={
                                                            isEastertide
                                                        }
                                                    />
                                                );
                                            })}
                                    </div>
                                </div>
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "flex-start",
                                    }}
                                >
                                    <div
                                        style={{
                                            width: 48,
                                            display: "flex",
                                            flexDirection: "column",
                                            alignItems: "center",
                                            flexShrink: 0,
                                        }}
                                    >
                                        <div
                                            style={{
                                                textTransform: "uppercase",
                                                fontSize: "80%",
                                                fontWeight: "bold",
                                                paddingTop: 8,
                                                paddingBottom: 2,
                                            }}
                                        >
                                            {liturgicalDay.day.slice(0, 3)}
                                        </div>
                                        <div
                                            style={{
                                                fontSize: "200%",
                                                lineHeight: 0.8,
                                            }}
                                        >
                                            {liturgicalDay.date}
                                        </div>
                                        <div
                                            style={{
                                                textTransform: "uppercase",
                                                fontSize: "80%",
                                                fontWeight: "bold",
                                            }}
                                        >
                                            {
                                                [
                                                    "",
                                                    "Jan",
                                                    "Feb",
                                                    "Mar",
                                                    "Apr",
                                                    "May",
                                                    "Jun",
                                                    "Jul",
                                                    "Aug",
                                                    "Sep",
                                                    "Oct",
                                                    "Nov",
                                                    "Dec",
                                                ][liturgicalDay.month]
                                            }
                                        </div>
                                        <div
                                            style={{
                                                paddingTop: 12,
                                                fontSize: "150%",
                                                lineHeight: 0.8,
                                            }}
                                        >
                                            PM
                                        </div>
                                    </div>
                                    <div
                                        style={{
                                            flexGrow: 1,
                                            padding: 8,
                                            paddingLeft: 12,
                                            display: "flex",
                                            flexDirection: "column",
                                            gap: 16,
                                            paddingBottom: 16,

                                            borderTop: "1px solid silver",
                                        }}
                                    >
                                        {liturgicalDay.eveningOptions
                                            .flatMap((celebration) => {
                                                if (
                                                    celebration.commons
                                                        ?.length ??
                                                    0 > 0
                                                ) {
                                                    return (
                                                        celebration.commons?.map(
                                                            (common) => {
                                                                return {
                                                                    ep1HymnId:
                                                                        "MISSING",
                                                                    ep1MagId:
                                                                        "MISSING",
                                                                    invId: "MISSING",
                                                                    mpHymnId:
                                                                        "MISSING",
                                                                    mpBenId:
                                                                        "MISSING",
                                                                    ep2HymnId:
                                                                        "MISSING",
                                                                    ep2MagId:
                                                                        "MISSING",
                                                                    ...COMMONS[
                                                                        common
                                                                    ],
                                                                    ...celebration,
                                                                    title:
                                                                        celebration.title +
                                                                        " (Common of " +
                                                                        common +
                                                                        ")",
                                                                };
                                                            }
                                                        ) ?? []
                                                    );
                                                } else {
                                                    return [celebration];
                                                }
                                            })
                                            .map((opt) => {
                                                return (
                                                    <OrdoItem
                                                        celebration={opt}
                                                        hour={
                                                            liturgicalDay.morningOptions.find(
                                                                (mOpt) =>
                                                                    mOpt.label ===
                                                                    opt.label
                                                            ) === undefined
                                                                ? "first-evensong"
                                                                : "second-evensong"
                                                        }
                                                        calendarDay={
                                                            calendarDay
                                                        }
                                                        isEastertide={
                                                            isEastertide
                                                        }
                                                    />
                                                );
                                            })}
                                    </div>
                                </div>

                                {liturgicalDay.morningOptions.map((option) => {
                                    const prevDayLessons =
                                        (option.feastHasFirstEvensong ||
                                            option.type === "solemnity") &&
                                        prevCalendarDay
                                            ? getOptionsOrd(
                                                  prevCalendarDay,
                                                  option.label,
                                                  "evensong"
                                              )
                                            : undefined;

                                    const lessons = getOptionsOrd(
                                        calendarDay,
                                        option.label,
                                        "mattins"
                                    );

                                    return (
                                        <div>
                                            <>
                                                {option.type === "solemnity" ||
                                                option.type === "sunday" ||
                                                (option.type === "feast" &&
                                                    option.feastHasFirstEvensong) ? (
                                                    <></>
                                                ) : (
                                                    <></>
                                                )}

                                                {(liturgicalDay.day === "sat" &&
                                                    (option.type === "feria" ||
                                                        option.type ===
                                                            "memorial")) ||
                                                (liturgicalDay.date === 24 &&
                                                    liturgicalDay.month ===
                                                        12) ||
                                                liturgicalDay.morningOptions.find(
                                                    (opt) =>
                                                        opt.title ===
                                                        "Rogation Wednesday"
                                                ) !== undefined ||
                                                liturgicalDay.morningOptions.find(
                                                    (opt) =>
                                                        opt.title ===
                                                        "Ember Saturday in Whitsun Week"
                                                ) !== undefined ? (
                                                    <></>
                                                ) : (
                                                    <></>
                                                )}
                                            </>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </>
                );
            })}
        </>
    );
};
