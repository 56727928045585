import React from "react";
import { Padding } from "../Padding";
import { RUBRIC_COLOR, TEXT_COLOR, TEXT_INDENT, TEXT_SIZE } from "../tokens";
import { isSmallScreen } from "../utils";

export const PsalmOrCanticle: React.FC<{
    title: string;
    lines: string[][];
    ant: string | undefined;
    incipit?: string;
}> = (props) => {
    const { title, lines, ant, incipit } = props;

    return (
        <div>
            <Padding onlySides>
                <div
                    style={{
                        fontWeight: "bold",
                        paddingTop: 12,
                        fontSize: TEXT_SIZE,
                        textAlign: "center",
                        color: RUBRIC_COLOR,
                    }}
                    className="title"
                    data-content={title}
                >
                    {title}
                </div>
                {incipit ? (
                    <div
                        style={{
                            fontStyle: "italic",
                            textAlign: "center",
                            color: RUBRIC_COLOR,
                        }}
                    >
                        {incipit}
                    </div>
                ) : (
                    <></>
                )}

                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                        paddingLeft: isSmallScreen()
                            ? TEXT_INDENT / 2
                            : TEXT_INDENT,
                        paddingRight: isSmallScreen()
                            ? TEXT_INDENT / 2
                            : TEXT_INDENT,
                        fontSize: isSmallScreen() ? TEXT_SIZE * 0.9 : TEXT_SIZE,
                        textAlign: "left",
                        color: TEXT_COLOR,
                        paddingTop: 12,
                    }}
                >
                    {ant ? (
                        <div style={{ paddingBottom: 12 }}>
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "stretch",
                                }}
                            >
                                <div
                                    style={{
                                        width: 3,
                                        flexShrink: 0,
                                        borderTop: `1.5px solid ${RUBRIC_COLOR}`,
                                        borderLeft: `1.5px solid ${RUBRIC_COLOR}`,
                                        borderBottom: `1.5px solid ${RUBRIC_COLOR}`,
                                    }}
                                ></div>
                                <div
                                    style={{ paddingLeft: 8, paddingRight: 8 }}
                                >
                                    <span
                                        style={{
                                            color: RUBRIC_COLOR,
                                            fontWeight: "bold",
                                        }}
                                    >
                                        Ant.{" "}
                                    </span>
                                    {ant.split("*").map((part, partIndex) => {
                                        return (
                                            <>
                                                {partIndex > 0 ? (
                                                    <span
                                                        style={{
                                                            color: RUBRIC_COLOR,
                                                        }}
                                                    >
                                                        *
                                                    </span>
                                                ) : (
                                                    <></>
                                                )}
                                                {part}
                                            </>
                                        );
                                    })}
                                </div>
                                <div
                                    style={{
                                        width: 3,
                                        flexShrink: 0,
                                        borderTop: `1.5px solid ${RUBRIC_COLOR}`,
                                        borderRight: `1.5px solid ${RUBRIC_COLOR}`,
                                        borderBottom: `1.5px solid ${RUBRIC_COLOR}`,
                                    }}
                                ></div>
                            </div>
                        </div>
                    ) : (
                        <></>
                    )}
                    {lines.map((line, lineIndex) => {
                        return (
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "flex-start",

                                    fontWeight: !title.includes("Psalm")
                                        ? "bold"
                                        : lineIndex % 2 === 1
                                        ? "bold"
                                        : undefined,
                                }}
                            >
                                <div style={{}}>
                                    {line[0]
                                        .split("✠")
                                        .map((part, partIndex) => {
                                            return (
                                                <>
                                                    {partIndex > 0 ? (
                                                        <span
                                                            style={{
                                                                color: RUBRIC_COLOR,
                                                            }}
                                                        >
                                                            ✠
                                                        </span>
                                                    ) : (
                                                        <></>
                                                    )}
                                                    {part}
                                                </>
                                            );
                                        })}
                                    &nbsp;
                                    <span style={{ color: RUBRIC_COLOR }}>
                                        *
                                    </span>
                                </div>
                                <div style={{ paddingLeft: 24 }}>{line[1]}</div>
                            </div>
                        );
                    })}
                    {ant ? (
                        <div style={{ paddingTop: 12 }}>
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "stretch",
                                }}
                            >
                                <div
                                    style={{
                                        width: 3,
                                        flexShrink: 0,
                                        borderTop: `1.5px solid ${RUBRIC_COLOR}`,
                                        borderLeft: `1.5px solid ${RUBRIC_COLOR}`,
                                        borderBottom: `1.5px solid ${RUBRIC_COLOR}`,
                                    }}
                                ></div>
                                <div
                                    style={{ paddingLeft: 8, paddingRight: 8 }}
                                >
                                    <span
                                        style={{
                                            color: RUBRIC_COLOR,
                                            fontWeight: "bold",
                                        }}
                                    >
                                        Ant.{" "}
                                    </span>
                                    {ant.split("*").map((part, partIndex) => {
                                        return (
                                            <>
                                                {partIndex > 0 ? (
                                                    <span
                                                        style={{
                                                            color: RUBRIC_COLOR,
                                                        }}
                                                    >
                                                        *
                                                    </span>
                                                ) : (
                                                    <></>
                                                )}
                                                {part}
                                            </>
                                        );
                                    })}
                                </div>
                                <div
                                    style={{
                                        width: 3,
                                        flexShrink: 0,
                                        borderTop: `1.5px solid ${RUBRIC_COLOR}`,
                                        borderRight: `1.5px solid ${RUBRIC_COLOR}`,
                                        borderBottom: `1.5px solid ${RUBRIC_COLOR}`,
                                    }}
                                ></div>
                            </div>
                        </div>
                    ) : (
                        <></>
                    )}
                </div>
            </Padding>
        </div>
    );
};
