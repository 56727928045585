import React from "react";
import { getOptionsOrd } from "../calendar/lectionary";
import { Celebration } from "../calendar/ordinariate-calendar";
import { BaseCalendarDay } from "../calendar/utils";
import { getFinalAntiphon } from "../content/antiphon-sheet";
import { HYMNS } from "../content/hymns";

const Row: React.FC<{ label: string; children: JSX.Element | string }> = (
    props
) => {
    return (
        <div
            style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "flex-start",
                gap: 8,
                paddingLeft: 16,
            }}
        >
            <div style={{ minWidth: 100, fontWeight: "bold" }}>
                {props.label}
            </div>
            <div style={{ fontSize: "85%", whiteSpace: "pre-wrap" }}>
                {props.children === "MISSING" ? (
                    <span style={{ backgroundColor: "#F99" }}>MISSING</span>
                ) : (
                    props.children
                )}
            </div>
        </div>
    );
};

export const OrdoItem: React.FC<{
    celebration: Celebration;
    hour: "first-evensong" | "mattins" | "second-evensong";
    calendarDay: BaseCalendarDay;
    isEastertide: boolean;
}> = (props) => {
    const { celebration: option, hour, calendarDay, isEastertide } = props;

    let officeLabel = "Mattins";

    if (hour !== "mattins") {
        if (
            option.type === "sunday" ||
            option.type === "solemnity" ||
            (option.type === "feast" && option.feastHasFirstEvensong)
        ) {
            if (hour === "first-evensong") {
                officeLabel = "First Evensong";
            } else {
                officeLabel = "Second Evensong";
            }
        } else {
            officeLabel = "Evensong";
        }
    }

    const lessons = getOptionsOrd(calendarDay, option.label, hour);

    return (
        <div>
            <div style={{ fontSize: "110%" }}>
                {option.regionNote ? option.regionNote + ": " : <></>}
                {officeLabel} of{" "}
                <span
                    style={{
                        fontWeight: "bold",
                    }}
                >
                    {option.rank <= 8
                        ? option.title.toLocaleUpperCase()
                        : option.title}
                </span>{" "}
                ({option.optional ? "optional " : ""}
                {option.type}, {Math.round(option.rank)}){" "}
                <a
                    href={`/pray/${calendarDay.year}/${calendarDay.month}/${calendarDay.date}/${option.label}/${hour}`}
                    target="_blank"
                >
                    Open
                </a>
            </div>
            {hour === "first-evensong" ? (
                <>
                    <Row label="Lessons">
                        {lessons.firstLessonOptions.evening.replace(
                            "-100",
                            "-end"
                        ) +
                            "\n" +
                            lessons.secondLessonOptions.evening.replace(
                                "-100",
                                "-end"
                            )}
                    </Row>
                    <Row label="Hymn">
                        {HYMNS[option.ep1HymnId ?? ""]?.text
                            .split("\n")
                            .map((l) => l.trim())
                            .join("\n") ?? "MISSING"}
                    </Row>
                    <Row label="V&R">
                        {option.ep1VandR
                            ?.map((v) =>
                                v
                                    .replace(/_/g, "")
                                    .replace("/", "")
                                    .replace(" ' ", "")
                            )
                            .join("\n") ?? "MISSING"}
                    </Row>
                    <Row label="Mag">
                        {getFinalAntiphon(
                            option.ep1MagId + (isEastertide ? " [easter]" : "")
                        ) ?? "MISSING"}
                    </Row>
                </>
            ) : hour === "mattins" ? (
                <>
                    {option.title === "Easter Day" ||
                    option.title.includes("Octave of Easter") ? (
                        <Row label="Inv">Anthems replace Venite</Row>
                    ) : (
                        <Row label="Inv">
                            {getFinalAntiphon(
                                option.invId + (isEastertide ? " [easter]" : "")
                            ) ?? "MISSING"}
                        </Row>
                    )}

                    <Row label="Lessons">
                        {lessons.firstLessonOptions.morning.replace(
                            "-100",
                            "-end"
                        ) +
                            "\n" +
                            lessons.secondLessonOptions.morning.replace(
                                "-100",
                                "-end"
                            )}
                    </Row>
                    {option.title === "Easter Day" ||
                    option.title.includes("Octave of Easter") ? (
                        <Row label="Hymn">Haec Dies</Row>
                    ) : (
                        <>
                            <Row label="Hymn">
                                {HYMNS[option.mpHymnId ?? ""]?.text
                                    .split("\n")
                                    .map((l) => l.trim())
                                    .join("\n") ?? "MISSING"}
                            </Row>
                            <Row label="V&R">
                                {option.mpVandR
                                    ?.map((v) =>
                                        v
                                            .replace(/_/g, "")
                                            .replace("/", "")
                                            .replace(" ' ", "")
                                    )
                                    .join("\n") ?? "MISSING"}
                            </Row>
                        </>
                    )}

                    <Row label="Ben">
                        {getFinalAntiphon(
                            option.mpBenId + (isEastertide ? " [easter]" : "")
                        ) ?? "MISSING"}
                    </Row>
                </>
            ) : (
                <>
                    <Row label="Lessons">
                        {lessons.firstLessonOptions.evening.replace(
                            "-100",
                            "-end"
                        ) +
                            "\n" +
                            lessons.secondLessonOptions.evening.replace(
                                "-100",
                                "-end"
                            )}
                    </Row>
                    {option.title === "Easter Day" ||
                    option.title.includes("Octave of Easter") ? (
                        <Row label="Hymn">Haec Dies</Row>
                    ) : (
                        <>
                            <Row label="Hymn">
                                {HYMNS[option.ep2HymnId ?? ""]?.text
                                    .split("\n")
                                    .map((l) => l.trim())
                                    .join("\n") ?? "MISSING"}
                            </Row>
                            <Row label="V&R">
                                {option.ep2VandR
                                    ?.map((v) =>
                                        v
                                            .replace(/_/g, "")
                                            .replace("/", "")
                                            .replace(" ' ", "")
                                    )
                                    .join("\n") ?? "MISSING"}
                            </Row>
                        </>
                    )}

                    <Row label="Mag">
                        {getFinalAntiphon(
                            option.ep2MagId + (isEastertide ? " [easter]" : "")
                        ) ?? "MISSING"}
                    </Row>
                </>
            )}
            <Row label="Collect">{option.collect ?? "MISSING"}</Row>
        </div>
    );
};
