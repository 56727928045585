import { getCanticle } from "../content/canticles";
import { getOrdPointedPsalmSection } from "../content/psalms";
import { ORD_SAINTS_PSALMS, getOptionsOrd } from "./lectionary";
import { Celebration } from "./ordinariate-calendar";
import { BaseCalendarDay } from "./utils";

type DataDependency = {
    day: BaseCalendarDay;
    celebration: Celebration;
    hour: "first-evensong" | "mattins" | "second-evensong";
    hymnIds: string[];
    benAntIds: string[];
    magAntIds: string[];
    invAntIds: string[];
};

export type Lesson = {
    ref: string;
    parts: { title: string; content: ScriptureContent }[];
};
export type ScriptureContent = { text: string; indent: number }[];

export interface DataResponse {
    dependency: DataDependency;
    psalms: {
        title: string;
        psalms: {
            morning: {
                ref: string;
                sections: {
                    verseNumber: number;
                    lines: string[][];
                };
            }[];
            evening: {
                ref: string;
                sections: {
                    verseNumber: number;
                    lines: string[][];
                };
            }[];
        };
    }[];
    lessons: {
        morningFirstLesson: Lesson;
        morningSecondLesson: Lesson;
        eveningFirstLesson: Lesson;
        eveningSecondLesson: Lesson;
    };
    otCanticleOptions: {
        id: string;
        title: string | undefined;
        lines: string[][];
    }[];
}

const getLessonParts = (
    url: string
): Promise<{ title: string; content: ScriptureContent }[]> => {
    return new Promise(function (resolve, reject) {
        var xhr = new XMLHttpRequest();
        xhr.open("get", url, true);
        xhr.onload = function () {
            var status = xhr.status;
            if (status === 200) {
                resolve(JSON.parse(xhr.response));
            } else {
                reject(status);
            }
        };
        xhr.send();
    });
};

const getPassage = async (ref: string): Promise<Lesson> => {
    try {
        const path = `${process.env.PUBLIC_URL}/lections/${ref
            .toLocaleLowerCase()
            .replace(/-/g, "-to-")
            .replace(/; /g, "-and-")
            .replace(/:/g, "-v-")
            .replace(/ /g, "-")}.txt`;

        return { ref, parts: await getLessonParts(path) };
    } catch {
        return { ref, parts: [] };
    }
};

export const getTexts = async (
    dependency: DataDependency
): Promise<DataResponse> => {
    const options = getOptionsOrd(
        dependency.day,
        dependency.celebration.label,
        dependency.hour
    );

    console.log(options);

    // const morningFirstLessonRequest = getPassage(
    //     options.firstLessonOptions.morning
    // );
    // const morningSecondLessonRequest = getPassage(
    //     options.secondLessonOptions.morning
    // );

    // const eveningFirstLessonRequest = getPassage(
    //     options.firstLessonOptions.evening
    // );
    // const eveningSecondLessonRequest = getPassage(
    //     options.secondLessonOptions.evening
    // );

    // const lessons = {
    //     morningFirstLesson: await morningFirstLessonRequest,
    //     morningSecondLesson: await morningSecondLessonRequest,
    //     eveningFirstLesson: await eveningFirstLessonRequest,
    //     eveningSecondLesson: await eveningSecondLessonRequest,
    // };

    const lessons = {
        morningFirstLesson: {
            ref: options.firstLessonOptions.morning,
            parts: [],
        },
        morningSecondLesson: {
            ref: options.secondLessonOptions.morning,
            parts: [],
        },
        eveningFirstLesson: {
            ref: options.firstLessonOptions.evening,
            parts: [],
        },
        eveningSecondLesson: {
            ref: options.secondLessonOptions.evening,
            parts: [],
        },
    };

    const isTriduum =
        dependency.celebration.label === "maundy-thursday" ||
        dependency.celebration.label === "good-friday" ||
        dependency.celebration.label === "holy-saturday";

    console.log("lessons", lessons);

    const ferialPsalms = {
        morning: options.psalms.morning.map((ref) => {
            return {
                ref,
                sections: getOrdPointedPsalmSection(ref, isTriduum),
            };
        }),
        evening: options.psalms.evening.map((ref) => {
            return {
                ref,
                sections: getOrdPointedPsalmSection(ref, isTriduum),
            };
        }),
    };

    console.log("ferial", ferialPsalms);

    let psalms = [
        {
            title: `Day ${
                dependency.day.date === 31 ? "30 repeated" : dependency.day.date
            }`,
            psalms: ferialPsalms,
        },
    ];

    let properPsalms;

    let saintPsalms = ORD_SAINTS_PSALMS[dependency.celebration.label];
    if (!saintPsalms) {
        saintPsalms = [undefined, undefined, undefined];
    }

    console.log("sp", saintPsalms);

    const ep1ProperPsalms = dependency.celebration.ep1ProperPsalms
        ? dependency.celebration.ep1ProperPsalms
        : saintPsalms[0];
    const mpProperPsalms = dependency.celebration.mpProperPsalms
        ? dependency.celebration.mpProperPsalms
        : saintPsalms[1];
    const ep2ProperPsalms = dependency.celebration.ep2ProperPsalms
        ? dependency.celebration.ep2ProperPsalms
        : saintPsalms[2];

    if (dependency.hour === "first-evensong" && ep1ProperPsalms) {
        properPsalms = {
            morning: [],
            evening: ep1ProperPsalms.map((ref) => {
                return {
                    ref,
                    sections: getOrdPointedPsalmSection(ref, isTriduum),
                };
            }),
        };
    }

    if (
        dependency.hour !== "first-evensong" &&
        mpProperPsalms &&
        ep2ProperPsalms
    ) {
        properPsalms = {
            morning: mpProperPsalms.map((ref) => {
                return {
                    ref,
                    sections: getOrdPointedPsalmSection(ref, isTriduum),
                };
            }),
            evening: ep2ProperPsalms.map((ref) => {
                return {
                    ref,
                    sections: getOrdPointedPsalmSection(ref, isTriduum),
                };
            }),
        };
    }

    if (properPsalms) {
        if (dependency.celebration.properPsalmsOptional) {
            psalms = [
                {
                    title: dependency.celebration.title,
                    psalms: properPsalms,
                },
                {
                    title: `Day ${
                        dependency.day.date === 31
                            ? "30 repeated"
                            : dependency.day.date
                    }`,
                    psalms: ferialPsalms,
                },
            ];
        } else {
            psalms = [
                {
                    title: dependency.celebration.title,
                    psalms: properPsalms,
                },
            ];
        }
    }

    console.log("PSALMS");
    psalms.forEach((p) => {
        console.log(p.psalms);
    });

    let otCanticleOptions = [];

    const isInPassiontide =
        dependency.day.collectId.includes("lent-5") ||
        dependency.day.collectId.includes("lent-6") ||
        dependency.day.collectId === "good-friday";

    const isEastertide =
        dependency.day.sentenceId === "easter" ||
        dependency.day.sentenceId === "ascension" ||
        dependency.day.hymnId === "apostle-or-evangelist-easter" ||
        dependency.day.collectId.includes("easter") ||
        (dependency.day.collectId === "whitsun" &&
            dependency.day.dayOfTheWeek === "sun");

    console.log("day", dependency.day);

    if (dependency.hour === "mattins") {
        const teDeum = getCanticle("te deum", false);

        const teDeumProhibited =
            dependency.day.collectId === "ash-wednesday" ||
            dependency.day.collectId.includes("gesima") ||
            dependency.day.collectId.includes("lent") ||
            dependency.day.collectId.includes("advent") ||
            dependency.celebration.title === "Rogation Monday";
        const teDeumRequired =
            dependency.celebration.title !== "Rogation Monday" &&
            ((dependency.day.dayOfTheWeek === "sun" && !teDeumProhibited) ||
                dependency.celebration.type === "solemnity" ||
                dependency.celebration.type === "feast" ||
                dependency.day.collectId.includes("easter") ||
                dependency.day.collectId.includes("ascension") ||
                dependency.day.collectId.includes("whitsun") ||
                dependency.day.collectId.includes("christmas"));

        if (teDeumRequired) {
            otCanticleOptions.push(teDeum);
        } else {
            let canticleDay: string = dependency.day.dayOfTheWeek;

            if (canticleDay === "sat") {
                if (
                    dependency.day.collectId.includes("gesima") ||
                    dependency.day.collectId.includes("lent") ||
                    dependency.day.collectId.includes("easter-eve") ||
                    dependency.day.collectId.includes("ash-wednesday")
                ) {
                    canticleDay = "sat-b";
                } else {
                    canticleDay = "sat-a";
                }
            }

            otCanticleOptions.push(getCanticle(canticleDay, !isTriduum));

            if (
                !teDeumProhibited ||
                dependency.celebration.type === "memorial"
            ) {
                otCanticleOptions.push(teDeum);
            }

            otCanticleOptions.push(getCanticle("benedicte", !isTriduum));
        }
    }

    // let hymns = {};
    // dependency.hymnIds.forEach((hymnId) => {
    //     hymns[hymnId] = getHymn(hymnId);
    // });

    // let bens = {};
    // dependency.benAntIds.forEach((benAntId) => {
    //     const benAnt = getAnt(benAntId, isEastertide);

    //     if (benAnt) {
    //         const ben = getPointedText(
    //             CANTICLES.benedictus.lines,
    //             benAnt.tone,
    //             !isTriduum,
    //             true
    //         );
    //         const simplifiedAnt = getSimplifiedAntiphon(
    //             benAnt.gabc,
    //             benAnt.tone
    //         );
    //         const simplifiedBen = getPointedText(
    //             CANTICLES.benedictus.lines,
    //             benAnt.tone.replace("trans", ""),
    //             !isTriduum,
    //             true
    //         );

    //         bens[benAntId] = {
    //             ant: benAnt,
    //             simplifiedAnt,
    //             ben,
    //             simplifiedBen:
    //                 ben !== simplifiedBen ? simplifiedBen : undefined,
    //         };
    //     }
    // });

    // let mags = {};
    // dependency.magAntIds.forEach((magAntId) => {
    //     const magAnt = getAnt(magAntId, isEastertide);

    //     if (magAnt) {
    //         const mag = getPointedText(
    //             CANTICLES.magnificat.lines,
    //             magAnt.tone,
    //             !isTriduum,
    //             true
    //         );
    //         const simplifiedAnt = getSimplifiedAntiphon(
    //             magAnt.gabc,
    //             magAnt.tone
    //         );
    //         const simplifiedMag = getPointedText(
    //             CANTICLES.magnificat.lines,
    //             magAnt.tone.replace("trans", ""),
    //             !isTriduum,
    //             true
    //         );

    //         mags[magAntId] = {
    //             ant: magAnt,
    //             simplifiedAnt,
    //             mag,
    //             simplifiedMag:
    //                 mag !== simplifiedMag ? simplifiedMag : undefined,
    //         };
    //     }
    // });

    // let invAnts = {};
    // dependency.invAntIds.forEach((invAntId) => {
    //     invAnts[invAntId] = getAnt(invAntId, isEastertide);
    // });

    return {
        dependency,
        lessons,
        psalms,
        otCanticleOptions,
    };
};
