import React from "react";

export const Spacer: React.FC<{ big?: boolean }> = (props) => {
    const { big } = props;

    return (
        <div
            style={{
                height: big ? 64 : 32,
                display: "flex",
                alignItems: "center",
            }}
            className={big ? "big-spacer" : "spacer"}
        >
            <div
                style={{
                    flexGrow: 1,
                }}
            ></div>
        </div>
    );
};
